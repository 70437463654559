import { FilterDefs, singleFilter, toggleFilter } from "@homebound/beam";
import { useMemo } from "react";
import { useSuspense } from "@rest-hooks/react";
import { ReportMetro, UnderwritingReportMetrosEndpoint } from "src/routes/cma/endpoints/reports";
import { beautifyMetro } from "src/routes/reports/ReportsPage";
import { UwPolygonFeatureCollection } from "./PolygonEndpoints";

export type PolygonCollectionFilter = {
  hideInactive?: boolean;
  metro?: string;
};

export function usePolygonCollectionFilter() {
  const { metros } = useSuspense(UnderwritingReportMetrosEndpoint);

  const filterDefs: FilterDefs<PolygonCollectionFilter> = useMemo(() => {
    const metro = singleFilter({
      label: "Metro",
      options: metros ?? [],
      getOptionValue: (option: ReportMetro) => option.metro,
      getOptionLabel: (option) => beautifyMetro(option.metro).full,
    });

    const hideInactive = toggleFilter({
      label: "Hide Inactive",
      defaultValue: true,
    });

    return { metro, hideInactive };
  }, [metros]);

  return filterDefs;
}

export function getFilteredPolygonCollections(
  featureCollections: UwPolygonFeatureCollection[],
  filter: PolygonCollectionFilter,
): UwPolygonFeatureCollection[] {
  const filtered = featureCollections.filter(({ properties }: UwPolygonFeatureCollection) => {
    if (filter.metro && !filter.metro.includes(properties.metro)) {
      return false;
    }
    if (filter.hideInactive && filter.hideInactive !== properties.active) {
      return false;
    }

    return true;
  });
  return filtered.sort((a, b) => sortPolygons(a, b));
}

/** Sort polygon featureCollections by metro, then name */
function sortPolygons(
  { properties: a }: UwPolygonFeatureCollection,
  { properties: b }: UwPolygonFeatureCollection,
): number {
  if (a.metro === b.metro) {
    return a.name.localeCompare(b.name);
  }
  return a.metro.localeCompare(b.metro);
}
