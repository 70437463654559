import { HasIdAndName, Palette } from "@homebound/beam";
import { MultiPolygon, Polygon } from "geojson";
import { LatLngTuple } from "leaflet";
import { Property } from "src/routes/cma/endpoints";
import { PropertyComp } from "src/routes/cma/endpoints/PropertyCompEndpoint";
import { Maybe } from "src/utils";
import { UWPolygonType } from "../admin/polygons";
import { capitalCase } from "change-case";

/** Returns lat/lon center and bounds to position a map based on the subject property and comp coordinates */
export function getMapBoundsFromProperties(
  property: Property,
  comps: PropertyComp[],
): [number, number, [LatLngTuple, LatLngTuple] | undefined] {
  const compCoords = comps.map((c) => [c.longitude, c.latitude]).filter((c) => c[0] && c[1]);
  const allCoords = [...compCoords, [property.longitude, property.latitude]] as LatLngTuple[];

  return getMapBoundsFromCoords(allCoords);
}

/** Returns lat/lon center and bounds to position a map based on a list of coordinates */
export function getMapBoundsFromCoords(coords: LatLngTuple[]): [number, number, [LatLngTuple, LatLngTuple]] {
  let minLng = coords[0][0];
  let maxLng = coords[0][0];
  let minLat = coords[0][1];
  let maxLat = coords[0][1];

  for (const [lng, lat] of coords) {
    if (lat < minLat) {
      minLat = lat;
    } else if (lat > maxLat) {
      maxLat = lat;
    }

    if (lng < minLng) {
      minLng = lng;
    } else if (lng > maxLng) {
      maxLng = lng;
    }
  }

  const latRange = maxLat - minLat;
  const lngRange = maxLng - minLng;

  const latCenter = latRange / 2 + minLat;
  const lngCenter = lngRange / 2 + minLng;

  const bounds: [LatLngTuple, LatLngTuple] = [
    [minLat, minLng],
    [maxLat, maxLng],
  ];

  return [latCenter, lngCenter, bounds];
}

/** Returns lat/lon center and bounds to position a map based on a list of coordinates or a metro */
export function getMapBoundsFromCoordsOrMetro(
  flattenedCoordinates: LatLngTuple[] | undefined,
  metro: Maybe<string>,
): [number, number, [LatLngTuple, LatLngTuple] | undefined] {
  // if there are coordinates, use them
  if (flattenedCoordinates && flattenedCoordinates.length > 0) {
    return getMapBoundsFromCoords(flattenedCoordinates);
  } else {
    return getMapCenterFromMetro(metro);
  }
}

export function getMapCenterFromMetro(metro: Maybe<string>): [number, number, [LatLngTuple, LatLngTuple] | undefined] {
  // if there are no coordinates, use the center of the metro selected
  const metroCenter = getMetroCenter[metro || ""];
  // if there is no metro selected or it's not in the list below, use the center of the US
  return metroCenter ? [metroCenter[0], metroCenter[1], undefined] : USA_COORDINATES;
}

export const getMetroCenter: Record<string, LatLngTuple> = {
  "austin-round_rock-georgetown_tx": [30.266666, -97.73333],
  "cape_coral-fort_myers_fl": [26.6406, -81.8723],
  "dallas-fort_worth-arlington_tx": [32.7767, -96.797],
  "denver-aurora-lakewood_co": [39.7392, -104.9903],
  "houston-the_woodlands-sugar_land_tx": [29.7604, -95.3698],
  "los_angeles-long_beach-anaheim_ca": [34.0522, -118.2437],
  "santa_rosa-petaluma_ca": [38.4405, -122.7141],
  "tampa-st_petersburg-clearwater_fl": [27.9506, -82.4572],
};

/** Lat, Lon, and Bounds for USA */
export const USA_COORDINATES: [number, number, [LatLngTuple, LatLngTuple]] = [
  37.09024,
  -95.712891,
  [
    [25, -125],
    [49, -68],
  ],
];

export function getPolygonPositions(geometry: Maybe<MultiPolygon> | Maybe<Polygon>): { lng: number; lat: number }[] {
  if (!geometry ?? !geometry?.coordinates) return [];

  let coordinates = [];
  if (geometry.type === "MultiPolygon") {
    coordinates = geometry.coordinates[0];
  } else {
    coordinates = geometry.coordinates;
  }

  return coordinates.map((coords) => {
    return coords.map((p) => ({ lng: p[0], lat: p[1] }));
  })[0];
}

export const polygonTypeOptions: HasIdAndName<UWPolygonType>[] = Object.values(UWPolygonType).map((value) => ({
  id: value,
  name: capitalCase(value),
}));

type PolygonColorProps = {
  isActive: Maybe<boolean>;
  color?: Maybe<Palette>;
  type?: Maybe<UWPolygonType>;
};

export function getPolygonColor({ isActive, color, type }: PolygonColorProps): Palette {
  if (!isActive) return Palette.Gray600;

  if (color) return color;

  switch (type) {
    case "dev_area":
      return Palette.Red500;
    case "neighborhood":
      return Palette.Blue700;
    default:
      return Palette.Blue500;
  }
}
