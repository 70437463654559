import { Css, Xss } from "@homebound/beam";
import { checkUnicode, mdashUnicode, xUnicode } from "src/components/mdash";
import { formatNumberToString } from "src/utils";
import { Maybe } from "src/utils/types";

interface ItemWithValue {
  name: string;
  singular?: string;
  value: Maybe<number | boolean | string>;
  truncate?: boolean;
  hide?: boolean;
}

export type Item = string | ItemWithValue;

interface FlatDataListProps {
  items: Item[];
  lineXss?: Xss<"paddingRight" | "fontWeight" | "fontSize" | "lineHeight">;
}

export function FlatDataList({ items, lineXss }: FlatDataListProps) {
  function item(i: string | ItemWithValue) {
    function renderItem(i: string | ItemWithValue) {
      const valueCss = Css.gray800.base.$;
      const nameCss = Css.gray700.mr1.$;

      if (typeof i === "string") {
        return <>{i}</>;
      }

      const dataTestId = `list_${i.name.replaceAll(" ", "_").replaceAll(".", "")}`;

      if (typeof i.value === "boolean") {
        return (
          <>
            <span css={nameCss}>{i.name}</span>
            <span data-testid={dataTestId} css={valueCss}>
              {i.value === undefined ? mdashUnicode : i.value ? checkUnicode : xUnicode}
            </span>
          </>
        );
      }

      if (typeof i.value === "string") {
        return (
          <>
            <span css={nameCss}>{i.name}</span>
            <span data-testid={dataTestId} css={valueCss}>
              {i.value === undefined ? mdashUnicode : i.value}
            </span>
          </>
        );
      }

      const name = i.value === 1 && i.singular ? i.singular : i.name;

      return (
        <>
          <span css={nameCss}>{name}</span>
          <span data-testid={dataTestId} css={valueCss}>
            {i.value ? formatNumberToString(i.value, true, i.truncate ?? true) : mdashUnicode}
          </span>
        </>
      );
    }

    const k = typeof i === "string" ? i : i.name;
    return (
      <li css={{ ...Css.dib.pr4.$, ...lineXss }} key={k}>
        {renderItem(i)}
      </li>
    );
  }

  return (
    <ul css={Css.listReset.add("listStyleType", "disc").$}>
      {items
        .filter((i) => typeof i === "string" || i.hide !== true)
        .map((i) => {
          return item(i);
        })}
    </ul>
  );
}
