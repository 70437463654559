import { Css, Tooltip } from "@homebound/beam";
import { Placement } from "@homebound/beam/dist/components/Tooltip";
import { Typography } from "@homebound/beam/dist/Css";
import React, { ReactNode } from "react";
import { FormattedPrice } from "src/components/FormattedPrice";
import { Icon, IconKey } from "src/components/Icon";
import { Maybe } from "src/utils";

// TODO: Create index file to export all in dir

export type UWLabelProps = {
  label: ReactNode;
  /** Defaults to 'baseBd' and increments the size of icon based on it */
  labelTypography?: Typography;
  value?: ReactNode | Maybe<number>; // TODO: investigate `formatStat` for this to accept a more generic num
  /**
   * When tooltip is empty, no icon is rendered.
   * Defaults icon to 'infoCircle'
   * */
  tooltip?: ReactNode;
  icon?: IconKey;
  tipPlacement?: Placement;
};

export const UWLabel = ({
  label,
  labelTypography = "baseBd",
  tooltip,
  value, // FIXME: May need to memoize value depending on how label ends up used
  tipPlacement = "top",
  icon = "infoCircle",
}: UWLabelProps) => {
  const iconIncrement =
    labelTypography.includes("tiny") || labelTypography.includes("xs")
      ? 1.5
      : labelTypography.includes("sm")
        ? 1.75
        : 2;

  const TooltipIcon = (
    <Tooltip title={tooltip} placement={tipPlacement}>
      <Icon
        xss={Css.display("inline").absolute.add("transform", "translate(10%, -20%)").$}
        inc={iconIncrement}
        icon={icon}
      />
    </Tooltip>
  );

  return (
    <div css={Css.relative.mr(tooltip ? iconIncrement : 0).$}>
      <span css={Css[labelTypography].$}>{label}</span>
      {value && typeof value === "number" ? <FormattedPrice price={value} /> : value}
      {tooltip && TooltipIcon}
    </div>
  );
};
