import { Endpoint } from "@rest-hooks/endpoint";
import { api_url, auth_headers } from "src/context";

export interface SelectedReportReviewsEndpointParams {
  reviewIds: string[];
}

export interface UpdateReportReviewsEndpointParams {
  delete?: SelectedReportReviewsEndpointParams;
  finalize?: SelectedReportReviewsEndpointParams;
}

export const UpdateReportReviewsEndpoint = new Endpoint(
  async (params: UpdateReportReviewsEndpointParams) => {
    // using tagged ids in the table, so strip the tag
    function parsedIds(selected: string[]): number[] {
      return selected.map((id) => {
        const parts = id.split(":");
        return parseInt(parts[1]);
      });
    }

    const headers = {
      "content-type": "application/json",
      ...(await auth_headers()),
    };

    const payload =
      params.delete !== undefined
        ? { delete_report_ids: parsedIds(params.delete.reviewIds) }
        : { finalize_reviews: { review_ids: parsedIds(params.finalize?.reviewIds ?? []) } };

    const res = await fetch(api_url(`/v1/reports/review`), {
      headers,
      method: "POST",
      body: JSON.stringify(payload),
    });

    return await res.json();
  },
  { name: "UpdateReportReviewsEndpoint" },
);
