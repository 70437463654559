import { Endpoint, Entity } from "@rest-hooks/rest";
import * as queryString from "query-string";
import { api_url, auth_headers } from "src/context";
import { PagingOptions, PagingResult, SortOptions } from "src/routes/paging";
import { ReportsFilter } from "src/routes/reports/ReportsFilter";
import { UnderwritingReportStatus } from "../../UnderwritingReportForm";
import { Property } from "../PropertyEndpoint";
import { Collaborator } from "./UnderwritingReportEndpoint";

interface SFDCOpportunity {
  opportunity_id: string;
  stage_name: string;
}

export class UnderwritingReportSummary extends Entity {
  readonly id: number = 0;
  readonly dpid: string = "";
  readonly status: UnderwritingReportStatus = "Unsaved";
  readonly property: Property | undefined;
  readonly collaborators: Collaborator[] = [];
  readonly updated_at: string = "";
  readonly sfdc_opportunities: SFDCOpportunity[] = [];

  pk() {
    return `${this.id}`;
  }

  static key = "UnderwritingReportSummary";
}

type ReportsListParams = ReportsFilter & PagingOptions & SortOptions;

export const UnderwritingReportListEndpoint = new Endpoint(
  async (filter: ReportsListParams) => {
    const headers = await auth_headers();

    const query = queryString.stringify(filter);
    const res = await fetch(api_url(`/v1/reports?${query}`), {
      headers,
    });
    return await res.json();
  },
  {
    schema: { reports: [UnderwritingReportSummary], paging: PagingResult },
    key: (filter: ReportsListParams) => {
      return queryString.stringify(filter);
    },
    dataExpiryLength: 1000 * 5, // 5 seconds
    invalidIfStale: true,
    name: "UnderwritingReportListEndpoint",
  },
);
