import { Checkbox, Css, Palette } from "@homebound/beam";
import { useState } from "react";
import { Maybe } from "src/utils";

type ColorPickerGridProps = {
  setColor: (color: Palette) => void;
  initialColor?: Maybe<Palette>;
  onColorChange?: () => {};
};

export function ColorPickerGrid({ initialColor, setColor }: ColorPickerGridProps) {
  const [selectedColor, setSelectedColor] = useState<Maybe<string>>(initialColor ?? null);

  const colors = [
    Palette.Red900,
    Palette.Purple900,
    Palette.Blue900,
    Palette.Green900,
    Palette.Yellow600,
    Palette.Orange600,
    Palette.Red500,
    Palette.Purple500,
    Palette.Blue500,
    Palette.Green500,
    Palette.Yellow400,
    Palette.Orange400,
  ];

  return (
    <div css={Css.dg.gapPx(4).gtc("repeat(6, 1fr)").$}>
      {colors.map((color) => (
        <ColorCheckMark
          key={color}
          color={color}
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
          setColor={setColor}
        />
      ))}
    </div>
  );
}

type ColorCheckMarkProps = ColorPickerGridProps & {
  selectedColor: Maybe<string>;
  setSelectedColor: (selectedColor: Maybe<string>) => void;
  color: Palette;
};

function ColorCheckMark({ color, selectedColor, setSelectedColor, setColor }: ColorCheckMarkProps) {
  return (
    <div data-testid={color} css={Css.addIn("& span", Css.bgColor(color).bc(color).$).$}>
      <Checkbox
        checkboxOnly
        label={color}
        selected={selectedColor === color}
        onChange={() => {
          setSelectedColor(color);
          setColor(color);
        }}
      />
    </div>
  );
}
