import { Button, Css } from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useState } from "react";
import { useParams } from "react-router";
import { useSuspense } from "@rest-hooks/react";
import { LoadingBoundary } from "src/components/LoadingBoundary";
import { PageHeader } from "src/components/PageHeader";
import { useComputed, useController, useReaction } from "src/hooks";
import {
  SaveUnderwritingReportEndpoint,
  SaveUnderwritingReportInput,
  UnderwritingReport,
  UnderwritingReportEndpoint,
} from "src/routes/cma/endpoints/reports";
import {
  mapOutcomeForm,
  offerOutcomeEstFormConfig,
  OfferOutcomeEstFormState,
} from "src/routes/cma/steps/estimate/estimate-forms/OfferOutcomeForm";
import { checkBlueprintRPTemplateVersion } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlansEndpoint";
import { hasV2ReadyPlans } from "src/routes/cma/steps/readyPlanUtils";
import { convertDateToString } from "src/utils";
import { reportIsReadOnly } from "src/utils/reports";
import { StepperActions } from "../../stepper";
import {
  estimateFormConfig,
  EstimateFormState,
  handleCostMetricPercentFields,
  mapToEstimateForm,
} from "./estimate-forms/EstimateForm";
import { FinalizeUnderwritingReportEndpoint } from "src/routes/cma/endpoints/FinalizeUnderwritingReportEndpoint";
import { ValuationStage } from "src/routes/cma/endpoints/reports/ValuationStage";
import { LoadEstimateReport } from "src/routes/cma/steps/estimate/EstimatePage";

export function EstimateStep() {
  const { dpid, versionId } = useParams<{ dpid: string; versionId: string }>();

  return (
    <>
      <PageHeader title="Estimate" xss={Css.mb0.$} />
      <LoadingBoundary>
        <EstimateStepWithStepper dpid={dpid} versionId={versionId} />
      </LoadingBoundary>
    </>
  );
}

interface EstimateStepWithStepperProps {
  dpid: string;
  versionId: string;
}

export function EstimateStepWithStepper({ dpid, versionId }: EstimateStepWithStepperProps) {
  const { report } = useSuspense(UnderwritingReportEndpoint, { dpid, versionId, shouldGenerateMlSaleConfidence: true });
  const [saving, setSaving] = useState(false);
  const [bpRpTemplateInSync, setBpRpTemplateInSync] = useState(true);
  const { fetch } = useController();

  useReaction(
    () => {
      return {
        isV2: hasV2ReadyPlans(report),
        isUW: report?.valuation_stage === ValuationStage.underwriting,
      };
    },
    async ({ isV2, isUW }) => {
      if (isV2 && isUW) {
        const { isLatestVersion } = await checkBlueprintRPTemplateVersion({
          rpTemplateId: report?.ready_plans![0].bp_ready_plan_id!,
          rpTemplateVersion: report?.ready_plans![0].bp_ready_plan_template_version!,
        });
        setBpRpTemplateInSync(isLatestVersion);
      }
    },
    {
      fireImmediately: hasV2ReadyPlans(report) && !reportIsReadOnly(report),
    },
    [],
  );

  async function saveOfferOutcome(form: OfferOutcomeEstFormState) {
    setSaving(true);

    const underwritten_at = convertDateToString(form.underwritten_at.value!);
    const opportunity = form.sfdc_opportunities.rows.find(
      (opp) => opp.opportunity_id.value === form.opportunity_id.value,
    )?.value;
    const formValue = { ...form.value, underwritten_at, opportunity };
    await fetch(SaveUnderwritingReportEndpoint, {
      report: formValue,
      versionId,
    });

    setSaving(false);
  }

  const outcomeFormState = useFormState({
    config: offerOutcomeEstFormConfig,
    init: {
      input: report,
      map: mapOutcomeForm,
    },
    autoSave: saveOfferOutcome,
    readOnly: reportIsReadOnly(report),
  });

  async function saveCostsAndMetrics(form: EstimateFormState) {
    // Convert percent fields back to decimals before saving
    // TODO: these can be removed once sc-21818 is completed
    const { dpid, costs_and_metrics, reference_property, site_costs } = form.value;
    const updatedMetrics = handleCostMetricPercentFields(costs_and_metrics, true);

    setSaving(true);

    await fetch(SaveUnderwritingReportEndpoint, {
      report: {
        dpid,
        id: report.id,
        reference_property,
        costs_and_metrics: updatedMetrics,
        site_costs,
      } as SaveUnderwritingReportInput,
      versionId: report.id,
    });
    setSaving(false);
  }

  // Parent formState for all EstimatePage child components except OfferOutcomeForm
  const estimateFormState = useFormState({
    config: estimateFormConfig,
    init: {
      input: report,
      map: (report) => mapToEstimateForm(report),
    },
    autoSave: saveCostsAndMetrics,
    readOnly: reportIsReadOnly(report),
  });

  async function finalizeReport() {
    if (!saving && estimateFormState.canSave() && outcomeFormState.canSave()) {
      await fetch(FinalizeUnderwritingReportEndpoint, { dpid, versionId });

      // Updates report status on report page
      // TODO: this won't work now that the report endpoint takes params
      // there is an invalidateAll in a newer version of rest-hooks
      // and potentially a way to invalidate a specific report by id
      //invalidate(UnderwritingReportListEndpoint);
    }
  }

  const reportErrors = useComputed(() => {
    return [
      ...validateEstimateForms(outcomeFormState, estimateFormState, report, bpRpTemplateInSync),
      ...(report.finalize_blockers || []),
    ].flatMap((e, idx) => `${idx + 1}. ${e}`);
  }, [report, bpRpTemplateInSync]);

  function renderForm() {
    return (
      <div css={Css.bgGray100.df.jcc.$} data-testid="estimateStep">
        <LoadEstimateReport estimateFormState={estimateFormState} outcomeFormState={outcomeFormState} report={report} />
        <StepperActions>
          <div css={Css.df.fdc.$} data-testid="finalizeDiv">
            <Button
              // custom attribute to test errors are correct while rendering readable list in tooltip
              data-disabled-reasons={reportErrors.length > 0 && reportErrors.join(". ")}
              disabled={
                saving ? "Saving..." : reportErrors.length > 0 && <ListedReportErrors reportErrors={reportErrors} />
              }
              label={saving ? "Saving..." : "Finalize"}
              icon={saving ? "loader" : undefined}
              onClick={finalizeReport}
            />
          </div>
        </StepperActions>
      </div>
    );
  }

  return <Observer>{() => renderForm()}</Observer>;
}

// TODO: Move both of these to BE, better as a follow up PR for clarity
// Validates that CTAs are resolved and that the bp template version is in sync
function validateEstimateForms(
  outcomeFormState: OfferOutcomeEstFormState | undefined,
  estimateFormState: EstimateFormState | undefined,
  report: UnderwritingReport,
  bpRpTemplateInSync: boolean,
): string[] {
  const errors = [];

  if (report.report_ctas && report.report_ctas.filter((cta) => cta.status === "Open").length > 0) {
    errors.push("All CTAs must be resolved before report can be finalized");
  }

  if (!bpRpTemplateInSync) {
    errors.push("Configured ready plan is out of sync with Blueprint ready plan template.");
  }

  // We need to allow users to change other fields even if the site_acquisition_bid_recommendation is not set
  if (!estimateFormState?.reference_property.site_acquisition_bid_recommendation.value) {
    errors.push("Site Acquisition Bid Recommendation is required");
  }

  // TODO: Defined check is a temp workaround for the initial change to validateCanFinalize && finalizeReport EPs.
  //  The forms will only be undefined when we first set the error state.
  //  outcomeFormState doesn't even need to be checked as all fields are technically optional
  if (!!outcomeFormState && !!estimateFormState && (!estimateFormState.valid || !outcomeFormState.valid)) {
    errors.push("Fix errors in the form");
  }

  return errors;
}

function ListedReportErrors({ reportErrors }: { reportErrors: string[] }) {
  return (
    <div css={Css.df.fdc.p1.$}>
      {reportErrors.map((error, i) => (
        <span key={i}>{error}</span>
      ))}
    </div>
  );
}
