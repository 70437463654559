import React from "react";
import { HomeboundLogo } from "src/components/HomeboundLogo";

export function UnauthedHeader() {
  return (
    <div>
      <HomeboundLogo />
    </div>
  );
}
