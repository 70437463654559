import { Icons } from "@homebound/beam";
import { Tooltip } from "@material-ui/core";
import { Fragment, ReactElement } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Css, increment, Margin, Only, Xss } from "src/Css";
import { useTestIds } from "src/hooks";
import { navLink } from "src/theme/Themes";
import { HasChildren } from "src/utils/types";

export const icons = {
  // beam migrated
  ...Icons,
  alertError: Icons.errorCircle,
  alertCheck: Icons.checkCircle,
  alertInfo: Icons.infoCircle,
  angleDoubleDown: Icons.chevronsDown,
  angleDoubleRight: Icons.chevronsRight,
  back: Icons.arrowBack,
  cancel: Icons.x,
  caretDown: Icons.chevronDown,
  checkboxOn: Icons.checkboxChecked,
  checkboxOff: Icons.checkbox,
  checkmark: Icons.check,
  cloud: Icons.cloudUpload,
  commentEmpty: Icons.comment,
  dollar: Icons.finances,
  expand: Icons.chevronRight,
  collapse: Icons.chevronLeft,
  file: Icons.fileBlank,
  help: Icons.helpCircle,
  image: Icons.file,
  invoiceDollar: Icons.dollar,
  loading: Icons.loader,
  listUl: Icons.projects,
  ok: Icons.checkCircle,
  pdf: Icons.file,
  tools: Icons.tradePartners,
  polaroids: Icons.images,
  openInNew: Icons.linkExternal,

  // custom icons
  close: (
    <path d="M18 7.20857L16.7914 6L12 10.7914L7.20857 6L6 7.20857L10.7914 12L6 16.7914L7.20857 18L12 13.2086L16.7914 18L18 16.7914L13.2086 12L18 7.20857Z" />
  ),

  status: <circle cx="12" cy="12" r="8" />,

  // Should go away with the Beam switch
  toggleOff: (
    <Fragment>
      <path d="M8 9C6.372 9 5 10.372 5 12C5 13.628 6.372 15 8 15C9.628 15 11 13.628 11 12C11 10.372 9.628 9 8 9Z" />
      <path d="M16 6H8C4.704 6 2.018 8.682 2 11.986C2 11.991 2 11.995 2 12V12.016C2 12.02 2 12.024 2 12.028C2.015 15.322 4.703 18 8 18H16C19.309 18 22 15.309 22 12C22 8.691 19.309 6 16 6ZM16 16H8C5.798 16 4.004 14.208 4 12.01C4.004 9.799 5.798 8 8 8H16C18.206 8 20 9.794 20 12C20 14.206 18.206 16 16 16Z" />
    </Fragment>
  ),
} as const;

export type IconKey = keyof typeof icons;

type IconXss = Xss<Margin | "display" | "position" | "transform">;

export interface IconProps<X> {
  id?: string;
  icon: keyof typeof icons;
  // TODO: This should go away and get pushed up into an `IconOnlyButton` component
  // that is a) more semantically correct and b) aria compliant. Basically adding
  // `onClick`s to random elements is an anti-pattern.
  onClick?: () => void;
  /** The size of the icon in increments, i.e. 1 == 8px, default is 3 == 24px. */
  inc?: number;
  /** The color to use, defaults to `currentColor` to match the surrounding text. */
  color?: string;
  xss?: X;
}

export function Icon<X extends Only<IconXss, X>>(props: IconProps<X>): ReactElement {
  const {
    icon,
    // This prop || icon || id is a little excessive, but maybe good?
    id = (props as any)["data-testid"] || icon,
    inc = 3,
    color = "currentColor",
    xss,
    onClick,
  } = props;
  const size = increment(inc);
  return (
    <svg
      aria-hidden={true}
      width={size}
      height={size}
      viewBox={`0 0 24 24`}
      xmlns="http://www.w3.org/2000/svg"
      css={{
        ...Css.fill(color).$,
        ...(xss ? xss : {}),
      }}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick();
        }
      }}
      data-testid={id}
      data-icon={icon}
    >
      {icons[icon]}
    </svg>
  );
}

export type LinkWithIconProps<X> = {
  id?: string;
  href: string;
  tooltip?: string;
} & HasChildren &
  IconProps<X>;

export function LinkWithIcon<X extends Only<IconXss, X>>(props: LinkWithIconProps<X>) {
  const { id = "linkWithIcon", href, tooltip, children, ...iconProps } = props;
  const [testId] = useTestIds(id);
  if (!href.startsWith("/")) {
    // See https://github.com/ReactTraining/react-router/issues/1147
    // I spent more than 5 minutes trying to hack around this and can come back to it. I don't like react-router.
    throw new Error(`Full urls aren't implemented ${href}`);
  }
  const link = (
    <RouterLink to={href} className={navLink} css={Css.df.aic.primary.noUnderline.$} {...testId}>
      <Icon inc={2} {...iconProps} />
      <span css={Css.pl1.$}>{children}</span>
    </RouterLink>
  );
  return maybeWrapWithTooltip(link, tooltip);
}

function maybeWrapWithTooltip(component: JSX.Element, tooltip: string | undefined) {
  return tooltip ? (
    <Tooltip title={tooltip} placement="bottom-start">
      {component}
    </Tooltip>
  ) : (
    component
  );
}
