import { Css, Icon, Palette } from "@homebound/beam";
import L from "leaflet";
import { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { useMap } from "react-leaflet";
import { MapIconColors, houseSvg, mapMarkerImage } from "src/routes/maps/mapIcons";

interface LegendProps {
  content: Record<string, string>;
  inMap?: boolean;
}

export function LegendInMap({ content }: LegendProps) {
  const map = useMap();

  useEffect(() => {
    if (map) {
      const legend = new L.Control({ position: "bottomleft" });

      legend.onAdd = () => {
        const div = L.DomUtil.create("div");
        const r = createRoot(div);
        r.render(Legend({ content, inMap: true }));

        return div;
      };

      legend.addTo(map);
    }
  }, [map, content]);
  return null;
}

export function Legend({ content, inMap = false }: LegendProps) {
  return (
    <div css={Css.bgWhite.df.fdr.br4.jcc.p1.gray700.gap1.relative.if(inMap).pPx(4).$}>
      {Object.entries(content).map(([type, label], i) => (
        <div css={Css.df.gap1.pPx(4).sm.hPx(30).$} key={i}>
          {getIcon(type)}
          <span css={Css.df.aic.$}>{label}</span>
        </div>
      ))}
    </div>
  );
}

function getIcon(type: string) {
  switch (type) {
    case "homebound":
      return houseSvg(Palette.Gray600);
    case "mlRec":
      return <Icon icon="starFilled" />;
    default:
      return mapMarkerImage(type as MapIconColors);
  }
}
