import { MultiPolygon } from "geojson";
import { useRef } from "react";
import { Polygon as ReactLeafletPolygon } from "react-leaflet";
import { UwLeafletPolygon } from "src/routes/admin/polygons/components/PolygonFeatureView";
import { getPolygonColor } from "src/routes/maps/mapUtils";
import { Maybe } from "src/utils/types";

interface PolygonFeatureViewProps {
  compFilterGeometry: Maybe<MultiPolygon>;
  isActive?: boolean;
}

export function CompFilterPolygonFeatureView({ compFilterGeometry, isActive = true }: PolygonFeatureViewProps) {
  const lpRef = useRef<UwLeafletPolygon>(null);

  function asPolygons(): { lng: number; lat: number }[][] {
    if (compFilterGeometry === undefined || compFilterGeometry === null) return [];
    return compFilterGeometry.coordinates.map((coords) => {
      return coords[0].map((p) => ({ lng: p[0], lat: p[1] }));
    });
  }

  const color = getPolygonColor({ isActive });

  return (
    <>
      {asPolygons().map((poly, i) => {
        return <ReactLeafletPolygon key={i} ref={lpRef} positions={poly} fillColor={color} color={color} weight={4} />;
      })}
    </>
  );
}
