import { Css, IconButton, Palette, SelectField } from "@homebound/beam";
import { Dispatch, SetStateAction } from "react";
import { PagingResult } from "src/routes/paging/PagingResult";

export type PageSettings = {
  page: number;
  perPage: number;
};

type PaginationProps = {
  label: string;
  paging: PagingResult;
  setSettings: Dispatch<SetStateAction<PageSettings>>;
};

export function Pagination(props: PaginationProps) {
  const { label, setSettings, paging } = props;
  const pageOptions = [25, 50, 100, 200].map((o) => ({ id: o, name: o.toString() }));
  const { page, perPage } = paging;

  const [firstResult, lastResult] = paging.resultRange;

  return (
    <div css={Css.df.bcGray300.xs.gray500.pt2.pb1.$}>
      <div css={Css.wPx(100).$}>
        <SelectField
          compact
          labelStyle="hidden"
          label={`${label} per page`}
          options={pageOptions}
          value={perPage}
          onSelect={(val) => {
            setSettings({ page: 1, perPage: val! });
          }}
        />
      </div>
      <div css={Css.df.mya.ml2.$}>{label} per page</div>

      <div css={Css.mla.mya.df.$}>
        <div css={Css.df.mya.ml2.$}>
          {firstResult} to {lastResult} of {paging.totalResults}
        </div>

        <IconButton
          icon="chevronLeft"
          color={paging.hasPrev ? Palette.Blue700 : Palette.Blue100}
          onClick={() => setSettings({ page: page - 1, perPage })}
          disabled={!paging.hasPrev}
        />
        <IconButton
          icon="chevronRight"
          color={paging.hasNext ? Palette.Blue700 : Palette.Blue100}
          onClick={() => setSettings({ page: page + 1, perPage })}
          disabled={!paging.hasNext}
        />
      </div>
    </div>
  );
}
