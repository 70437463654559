import { Endpoint, Entity } from "@rest-hooks/rest";
import { api_url, auth_headers } from "src/context";
import { Maybe } from "src/utils/types";

export class PropertySummary extends Entity {
  readonly dpid: string = "";
  readonly full_street_address: string = "";
  readonly city_name: string = "";
  readonly state: string = "";
  readonly zip_code: string = "";
  readonly property_type_simple: string | undefined = undefined;
  readonly unit_number: Maybe<string> = undefined;
  readonly latitude: Maybe<number> = undefined;
  readonly longitude: Maybe<number> = undefined;

  pk() {
    return this.dpid;
  }

  static key = "PropertySummary";
}

interface PropertySearchParams {
  full_street_address_search: string | undefined;
}

export const PropertySearchEndpoint = new Endpoint(
  async ({ full_street_address_search }: PropertySearchParams) => {
    const headers = await auth_headers();
    const qp = full_street_address_search
      ? "?full_street_address_search=" + full_street_address_search.toUpperCase()
      : "";
    const res = await fetch(api_url(`/v1/properties${qp}`), {
      headers,
    });
    return await res.json();
  },
  {
    schema: { properties: [PropertySummary] },
    key: ({ full_street_address_search }) => {
      return `/v1/properties?full_street_address_search=${full_street_address_search}`;
    },
    name: "PropertySearchEndpoint",
  },
);
