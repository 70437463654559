import { Css, Palette, Tooltip } from "@homebound/beam";
import {
  CtaTrackedEnumValue,
  CtaTrackedRangeValue,
  CtaTrackedThresholdValue,
  RangeCtaValues,
  ThresholdValues,
} from "src/routes/cma/endpoints/reports";
import { formatMagnitude, formatNumberToString } from "src/utils";

export function ThresholdTrackedValuesRender(props: CtaTrackedThresholdValue) {
  const { current, resolved, initial } = props;
  const comparisonValues = !!resolved ? resolved : initial;

  return (
    <>
      <div css={Css.dg.gtc("auto 1fr 1fr").gap1.$}>
        <div />
        <div css={Css.df.jcc.$}>Evaluation</div>
        <div css={Css.df.jcc.$}>Threshold ∆</div>
        <ThresholdRow
          rowTitle={!!resolved ? "Resolved @" : "Triggered @"}
          values={comparisonValues}
          withColor={!!resolved}
        />
        <ThresholdRow rowTitle={"Latest"} values={current} withColor />
      </div>
    </>
  );
}

type ThresholdRowProps = {
  values: ThresholdValues;
  rowTitle: string;
  withColor?: boolean;
};

function ThresholdRow({ values, rowTitle, withColor = false }: ThresholdRowProps) {
  const { threshold, delta, value, unit } = values;
  const atOrAboveThreshold = value >= threshold;

  return (
    <>
      <div>{rowTitle}</div>
      <Tooltip title={value + unit}>
        <div
          css={{
            ...Css.fw8.df.jcc.ba.bcGray700.br4.$,
            ...(withColor && { ...Css.if(atOrAboveThreshold).red500.bcRed500.else.green500.bcGreen500.$ }),
          }}
        >
          {formatNumberToString(value) + unit}
        </div>
      </Tooltip>
      <Tooltip title={Math.abs(delta) + unit}>
        <div
          css={{
            ...Css.fw8.df.jcc.ba.bcGray700.br4.$,
            ...(withColor && { ...Css.if(atOrAboveThreshold).red500.bcRed500.else.green500.bcGreen500.$ }),
          }}
        >
          {atOrAboveThreshold ? "+" : "-"} {formatNumberToString(Math.abs(delta)) + unit}
        </div>
      </Tooltip>
    </>
  );
}

const paletteMap: { [x: string]: Palette } = {
  High: Palette.Green500,
  Mid: Palette.Yellow500,
  Low: Palette.Red500,
};

export function MessageTrackedValuesRender(props: CtaTrackedEnumValue) {
  const { current, initial, resolved } = props;
  const comparisonValue = !!resolved ? resolved : initial;
  // Not necessary but it's a prettier way to show `low_confidence` trigger
  const currentColorPalette = paletteMap[current] || Palette.Gray800;
  const comparisonColorPalette = paletteMap[comparisonValue] || Palette.Gray800;

  return (
    <>
      <div css={Css.dg.gtc("1fr 2fr").gap1.$}>
        <div />
        <div css={Css.df.jcc.$}>Evaluation</div>
        <div>{!!resolved ? "Resolved @" : "Triggered @"}</div>
        <div
          css={{
            ...Css.fw8.df.jcc.ba.bcGray700.br4.$,
            ...(!!resolved && { ...Css.color(comparisonColorPalette).bc(comparisonColorPalette).$ }),
          }}
        >
          {comparisonValue}
        </div>
        <div>Latest</div>
        <div
          css={{
            ...Css.fw8.df.jcc.ba.bcGray700.br4.color(currentColorPalette).bc(currentColorPalette).$,
          }}
        >
          {current}
        </div>
      </div>
    </>
  );
}

export function RangeTrackedValuesRender(props: CtaTrackedRangeValue) {
  const { current, resolved, initial } = props;
  const comparisonValues = !!resolved ? resolved : initial;

  return (
    <>
      <div css={Css.dg.gtc("auto 1fr 1fr").gap1.$}>
        <div />
        <div css={Css.df.jcc.$}>Evaluation</div>
        <div css={Css.df.jcc.$}>Exp Range</div>
        <RangeRow
          rowTitle={!!resolved ? "Resolved @" : "Triggered @"}
          withColor={!!resolved}
          values={comparisonValues}
        />
        <RangeRow rowTitle="Latest" withColor values={current} />
      </div>
    </>
  );
}

type RangeRowProps = {
  values: RangeCtaValues;
  rowTitle: string;
  withColor?: boolean;
};

function RangeRow({ values, rowTitle, withColor = false }: RangeRowProps) {
  const { thresholdHigh, thresholdLow, value, unit } = values;
  const outsideRange = value < thresholdLow || value > thresholdHigh;
  const isDollarRange = unit === "$";

  return (
    <>
      <div>{rowTitle}</div>
      <Tooltip title={isDollarRange ? `$${formatNumberToString(value, true, true)}` : value + unit}>
        <div
          css={{
            ...Css.fw8.df.jcc.ba.bcGray700.br4.$,
            ...(withColor && { ...Css.if(outsideRange).red500.bcRed500.else.green500.bcGreen500.$ }),
          }}
        >
          {isDollarRange ? `${formatMagnitude(value)}` : formatNumberToString(value) + unit}
        </div>
      </Tooltip>
      <Tooltip
        title={
          isDollarRange
            ? `$${formatNumberToString(thresholdLow, true, true)} - $${formatNumberToString(thresholdHigh, true, true)}`
            : `${thresholdLow}${unit} - ${thresholdHigh}${unit}`
        }
      >
        <div
          css={{
            ...Css.fw8.df.jcc.ba.bcGray700.br4.$,
            ...(withColor && { ...Css.if(outsideRange).red500.bcRed500.else.green500.bcGreen500.$ }),
          }}
        >
          {isDollarRange
            ? `$${formatMagnitude(thresholdLow)} - $${formatMagnitude(thresholdHigh)}`
            : `${formatNumberToString(thresholdLow)}${unit} - ${formatNumberToString(thresholdHigh)}${unit}`}
        </div>
      </Tooltip>
    </>
  );
}
