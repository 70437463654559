import { BoundSelectField, BoundTextField, Button, Css, useTestIds } from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import React, { ReactNode, useEffect, useState } from "react";
import { useController } from "src/hooks";
import { BoundSubmitButton } from "src/components/BoundSubmitButton";
import {
  ReopenCtaEndpoint,
  ReportCta,
  ReportCtaStatus,
  ReportCtaTrigger,
  ResolveCtaEndpoint,
} from "src/routes/cma/endpoints/reports";
import {
  MessageTrackedValuesRender,
  ThresholdTrackedValuesRender,
  RangeTrackedValuesRender,
} from "src/routes/reports/components/cta/CtaValueRenders";

export type OptionWithMenuLabel = { menuLabel: ReactNode; value: string; label: string };

type ResolveCtaCardProps = {
  reportCta: ReportCta;
  resolutionFieldOptions: OptionWithMenuLabel[];
  ctaTrigger: ReportCtaTrigger;
};

const resolveCtaFormConfig: ObjectConfig<
  Pick<ReportCta, "id" | "status" | "resolution_action_name" | "resolution_note">
> = {
  id: { type: "value", readOnly: true },
  status: { type: "value", readOnly: true },
  resolution_action_name: { type: "value", rules: [required] },
  resolution_note: { type: "value" },
};

export function ResolveCtaCard(props: ResolveCtaCardProps) {
  const testIds = useTestIds(props, "resolve-cta-card");
  const { fetch } = useController();
  const { reportCta, resolutionFieldOptions, ctaTrigger } = props;
  const [ctaIsResolved, setCtaIsResolved] = useState(reportCta.status === ReportCtaStatus.resolved);

  const ctaFormState = useFormState({
    config: resolveCtaFormConfig,
    init: {
      input: reportCta,
      map: (i) => {
        return {
          id: i.id,
          status: i.status,
          resolution_action_name: i.resolution_action_name,
          resolution_note: i.resolution_note,
        };
      },
    },
    // Make notes required when dismissed or other
    addRules: ({ resolution_action_name, resolution_note }) => {
      resolution_note.rules.push((v) => {
        const requireNotes = resolution_action_name.value === "dismissed" || resolution_action_name.value === "other";
        return requireNotes ? required(v) : undefined;
      });
    },
    readOnly: ctaIsResolved,
  });

  async function updateCta() {
    await fetch(ResolveCtaEndpoint, {
      cta_id: ctaFormState.id.value,
      resolution_action_name: ctaFormState.resolution_action_name.value!,
      resolution_note: ctaFormState.resolution_note.value,
    });
  }

  async function reopenCta() {
    await fetch(ReopenCtaEndpoint, {
      cta_id: ctaFormState.id.value,
    });
  }

  useEffect(() => {
    setCtaIsResolved(reportCta.status === ReportCtaStatus.resolved);
  }, [reportCta.status]);

  return (
    <div {...testIds} css={Css.df.fdc.gap1.ba.bcGray600.bw2.p2.br8.if(ctaIsResolved).bcGreen600.$}>
      <div css={Css.df.jcsb.fw5.$}>
        <div>{ctaTrigger.label}</div>
      </div>
      <div css={Css.df.fdc.gap1.xs.$}>
        <span>{ctaTrigger.description}</span>
        <CtaEvaluations reportCta={reportCta} />
      </div>
      <BoundSelectField
        compact
        getOptionLabel={(o) => o.label}
        getOptionMenuLabel={(o) => o.menuLabel}
        getOptionValue={(o) => o.value}
        options={resolutionFieldOptions}
        field={ctaFormState.resolution_action_name}
        label="Resolution"
      />

      <BoundTextField compact label="Note" field={ctaFormState.resolution_note} />
      <div css={Css.df.fdrr.jcsb.pt1.aic.$}>
        {ctaIsResolved ? (
          <>
            <Button variant="secondary" label={"Reopen"} onClick={reopenCta} />
            <div css={Css.fw6.green600.$}>{reportCta.status}</div>
          </>
        ) : (
          <BoundSubmitButton form={ctaFormState} label={"Resolve"} onClick={updateCta} />
        )}
      </div>
    </div>
  );
}

function CtaEvaluations({ reportCta }: { reportCta: ReportCta }) {
  const { tracked_values, id, trigger_name, underwriting_report_ready_plan_id } = reportCta;

  if (tracked_values.type === "threshold") {
    return <ThresholdTrackedValuesRender {...tracked_values} />;
  } else if (tracked_values.type === "enum") {
    return <MessageTrackedValuesRender {...tracked_values} />;
  } else if (tracked_values.type === "range") {
    return <RangeTrackedValuesRender {...tracked_values} />;
  }

  // Throw error to dd in case we see this in prod
  console.error({
    message: "CTA does not contain tracked values",
    ctaId: id,
    ctaTrigger: trigger_name,
    readyPlanId: underwriting_report_ready_plan_id,
  });
  return null;
}
