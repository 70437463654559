import { MultiPolygon, Polygon } from "geojson";
import { Polygon as ReactLeafletPolygon } from "react-leaflet";
import { getPolygonColor, getPolygonPositions } from "src/routes/maps/mapUtils";
import { Maybe } from "src/utils/types";

interface SimplePolygonFeatureViewProps {
  searchGeometry?: Maybe<MultiPolygon> | Maybe<Polygon>;
  isActive?: boolean;
}

export function SimplePolygonFeatureView({ searchGeometry, isActive = true }: SimplePolygonFeatureViewProps) {
  // display active as blue and inactive as grey
  const color = getPolygonColor({ isActive });

  return <ReactLeafletPolygon positions={getPolygonPositions(searchGeometry)} fillColor={color} color={color} />;
}
