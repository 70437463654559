import { Css, ModalBody, ModalHeader } from "@homebound/beam";
import React from "react";
import { Property, PropertyComp, PropertyImage } from "../routes/cma/endpoints";
import { PropertyImageViewer } from "./PropertyImageViewer";

export interface PropertyImageModalProps {
  property: Property | PropertyComp;
  images: PropertyImage[];
  startIndex?: number;
}

// TODO: Expecting to need to replace this with some kind of overlay/lightBox.
//  We're capped at how big of a modal we can get (cause beam) and react-light-gallery is both old and heavy for what we need.
export function PropertyImageModal({ property, images = [], startIndex = 0 }: PropertyImageModalProps) {
  return (
    <>
      <ModalHeader>
        <div css={Css.ttc.$}>
          {property.full_street_address.toLocaleLowerCase()}
          {property.unit_number ? " " + property.unit_number : ""}
        </div>
      </ModalHeader>
      <ModalBody virtualized={true}>
        <div css={Css.df.w100.h100.pb4.$}>
          <PropertyImageViewer
            property={property}
            images={images}
            // subtracted paddings from modal component for largest area
            width={852}
            height={568}
            startIndex={startIndex}
          />
        </div>
      </ModalBody>
    </>
  );
}
