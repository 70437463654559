import {
  BoundSelectField,
  Button,
  Css,
  HasIdAndName,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { Maybe } from "src/utils";
import { useController } from "../../hooks/useController";
import { CreateValuationReportsEndpoint } from "./endpoints/CreateValuationReportsEndpoint";

interface GenerateValuationReportsFormProps {
  onReportsCreated: (reportsCreated: number) => Promise<void>;
}

export function GenerateValuationReportsForm({ onReportsCreated }: GenerateValuationReportsFormProps) {
  const { fetch } = useController();
  const { triggerNotice } = useSnackbar();
  const { closeModal } = useModal();

  const formState = useFormState({
    config: generateValuationReportsFormConfig,
  });

  async function generateValuationReports() {
    try {
      const result = await fetch(CreateValuationReportsEndpoint, { metros: [formState.metro.value!] });

      await onReportsCreated(result.reports_created);

      if (result.reports_created === 0) {
        triggerNotice({ icon: "info", message: `No Valuation reports generated` });
      } else {
        triggerNotice({ icon: "success", message: `Generated ${result.reports_created} Valuation reports` });
      }
      closeModal();
    } catch (e) {
      console.error(e);
    }
  }

  function renderForm() {
    return (
      <>
        <ModalHeader>Generate New Valuation Reports</ModalHeader>
        <ModalBody>
          <>
            <div css={Css.pb2.$}>
              This will generate new Valuation reports for the selected metro.
              <div css={Css.pt2.sm.$}>
                <i>Note: This may take a few minutes to complete.</i>
              </div>
            </div>
            <BoundSelectField
              compact
              field={formState.metro}
              options={metro_options}
              getOptionLabel={(o) => o.name}
              getOptionValue={(o) => o.id}
              labelStyle="inline"
            />
          </>
        </ModalBody>
        <ModalFooter>
          <Button label="Cancel" onClick={closeModal} />
          <Button label="Generate" onClick={generateValuationReports} disabled={!formState.valid} />
        </ModalFooter>
      </>
    );
  }

  return <Observer>{() => renderForm()}</Observer>;
}

const metro_options: HasIdAndName[] = [
  "austin-round_rock-georgetown_tx",
  "dallas-fort_worth-arlington_tx",
  "denver-aurora-lakewood_co",
  "houston-the_woodlands-sugar_land_tx",
  "los_angeles-long_beach-anaheim_ca",
  "santa_rosa-petaluma_ca",
].map((metro) => ({ id: metro, name: metro }));

interface GenerateValuationReportsFormInput {
  metro?: Maybe<string>;
}

export type GenerateValuationReportsFormState = ObjectState<GenerateValuationReportsFormInput>;

export const generateValuationReportsFormConfig: ObjectConfig<GenerateValuationReportsFormInput> = {
  metro: {
    type: "value",
    rules: [required],
  },
};
