import {
  Button,
  column,
  condensedStyle,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  GridTableApi,
  RowStyles,
  ScrollableContent,
  selectColumn,
  simpleHeader,
  Tooltip,
} from "@homebound/beam";
import { capitalCase } from "change-case";
import { maybeStringToDateLabel } from "src/utils";
import { Sortable } from "../paging";
import { beautifyMetro } from "../reports/ReportsPage";
import { createCmaAdjustmentsUrl, createCmaComparablesUrl, createCmaEstimateUrl } from "../routesDef";
import { ReportReviewQueueEntry } from "./endpoints/ReportReviewEndpoint";
import { FormattedNumberChange } from "./FormattedNumberChange";

interface ReviewTableProps extends Sortable {
  reviews: ReportReviewQueueEntry[];
  tableApi: GridTableApi<Row>;
}

export function ReviewTable({ tableApi, reviews, onSort }: ReviewTableProps) {
  return (
    <ScrollableContent>
      <GridTable
        api={tableApi}
        columns={columns}
        rows={createRows(reviews)}
        style={condensedStyle}
        sorting={{ on: "server", value: ["updated_at", "DESC"], onSort }}
        rowStyles={rowStyles}
        stickyHeader
      />
    </ScrollableContent>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: ReportReviewQueueEntry };
export type Row = HeaderRow | DataRow;

const columns: GridColumn<Row>[] = [
  selectColumn<Row>(),
  column<Row>({
    id: "address-column",
    header: "Address",
    data: ({ property }) => capitalCase(property.full_street_address),
  }),
  column<Row>({
    id: "metro-column",
    header: "Metro",
    data: (row) => {
      const { short, full } = beautifyMetro(row.property?.metro);

      return {
        content: (
          <Tooltip title={full}>
            <span>{short}</span>
          </Tooltip>
        ),
        value: full,
      };
    },
    clientSideSort: false,
  }),
  column<Row>({
    header: "Stage",
    data: ({ opportunity }) => opportunity?.stage_name,
  }),
  column<Row>({
    header: "Price Change",
    data: (entry) => {
      const change = entry.report_flags?.final_weighted_price_diff;
      return {
        content: () => {
          if (entry.report_flags?.custom_price_updates_needed) {
            return <CompPriceReviewButton entry={entry} />;
          }

          return <FormattedNumberChange change={change} />;
        },
        value: change,
      };
    },
    serverSideSortKey: "final_weighted_price_diff",
  }),
  column<Row>({
    header: "Margin Change",
    data: (entry) => {
      const change = entry.report_flags?.investor_margin_diff;
      return {
        content: () => {
          if (entry.report_flags?.custom_price_updates_needed) {
            return <CompPriceReviewButton entry={entry} />;
          }

          return <FormattedNumberChange change={change} format="percent" />;
        },
        value: change,
      };
    },
    serverSideSortKey: "investor_margin_diff",
  }),
  column<Row>({
    header: "New Comps",
    data: (entry) => {
      return { content: <NewCompsButton entry={entry} />, value: entry.report_flags?.new_comps_available };
    },
  }),
  column<Row>({
    header: "Updated",
    data: ({ queue: { updated_at } }) => {
      return { content: maybeStringToDateLabel(updated_at, "basicDate"), value: updated_at };
    },
    serverSideSortKey: "updated_at",
  }),
  column<Row>({
    header: "Parent",
    data: ({ report: { dpid, parent_id } }) => {
      return {
        content: <Button label="View" variant="text" onClick={createCmaEstimateUrl(dpid, "" + parent_id!)} />,
        value: parent_id,
      };
    },
  }),
];

function createRows(entries: ReportReviewQueueEntry[]): GridDataRow<Row>[] {
  return [simpleHeader, ...entries.map((e) => ({ kind: "data" as const, id: `rq:${e.queue.id!}`, data: e }))];
}

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {
    rowLink: ({
      data: {
        report: { dpid, id },
      },
    }) => createCmaEstimateUrl(dpid, id!.toString()),
  },
};

function NewCompsButton({ entry }: { entry: ReportReviewQueueEntry }) {
  const { new_comps_available, new_comps_available_by_rp } = entry.report_flags || {};

  if (!new_comps_available) return null;

  const { dpid, id } = entry.report;
  const rp = Object.keys(new_comps_available_by_rp || {})[0];

  return (
    <Button
      label={<span css={Css.bgGray400.white.px1.br24.$}>{new_comps_available}</span>}
      variant="secondary"
      onClick={createCmaComparablesUrl(dpid, id!.toString(), rp)}
    />
  );
}

function CompPriceReviewButton({ entry }: { entry: ReportReviewQueueEntry }) {
  const { custom_price_updates_needed } = entry.report_flags || {};
  const { dpid, id } = entry.report;
  const rp = Object.keys(custom_price_updates_needed || {})[0];

  return (
    <Button
      icon="error"
      label="Review Comp Price"
      variant="text"
      onClick={createCmaAdjustmentsUrl(dpid, id!.toString(), rp)}
    />
  );
}
