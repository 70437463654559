import { useEffect, useRef } from "react";
import { FeatureGroup, useMap } from "react-leaflet";
import { SavePolygonInput } from "src/routes/admin/polygons";
import { usePolygonContext } from "src/routes/admin/polygons/PolygonContext";
import { getPolygonColor } from "src/routes/maps/mapUtils";
import { OverlayPolygonFeatureView } from "./OverlayPolygonFeatureView";
import { CompFilterPolygonGeometry } from "./ComparablesStep";

type OverlayPolygonFeatureGroupProps = {
  overlayPolygons: SavePolygonInput[];
  compFilterPolygon: CompFilterPolygonGeometry | undefined;
  setCompFilterPolygon: (filter: CompFilterPolygonGeometry | undefined) => void;
};

export function OverlayPolygonsFeatureGroup(props: OverlayPolygonFeatureGroupProps) {
  const { overlayPolygons, compFilterPolygon, setCompFilterPolygon } = props;
  const map = useMap();
  const { setSelectedPolygonId } = usePolygonContext();
  const polygonClickedRef = useRef(false);

  useEffect(() => {
    // when map is clicked, clear the selected polygon id
    map.on("click", () => {
      if (polygonClickedRef.current) {
        // Ignore map click if it follows a polygon click
        polygonClickedRef.current = false;
      } else {
        setSelectedPolygonId(null);
      }
    });
  }, [map, setSelectedPolygonId]);

  return (
    <FeatureGroup>
      {overlayPolygons.map((p) => {
        const color = getPolygonColor({ isActive: true, color: p.color, type: p.type });
        return (
          <OverlayPolygonFeatureView
            key={p.id}
            p={p}
            color={color}
            polygonClickedRef={polygonClickedRef}
            compFilterPolygon={compFilterPolygon}
            setCompFilterPolygon={setCompFilterPolygon}
          />
        );
      })}
    </FeatureGroup>
  );
}
