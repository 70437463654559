import {
  actionColumn,
  column,
  condensedStyle,
  Css,
  Direction,
  Filters,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  ScrollableContent,
  simpleHeader,
  Tooltip,
  usePersistedFilter,
} from "@homebound/beam";
import { capitalCase } from "change-case";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSuspense } from "@rest-hooks/react";
import { LoadingBoundary } from "src/components/LoadingBoundary";
import { PageHeader } from "src/components/PageHeader";
import { useDocumentTitle } from "src/hooks/useDocumentTitle";
import { OpenVersionsDrawerButton } from "src/routes/reports/components/ReportVersionsTable";
import { createCmaEstimateUrl, createCmaSubjectPropertyUrl } from "src/routes/routesDef";
import { formatReportCollaborators, maybeStringToDateLabel } from "src/utils";
import {
  UnderwritingReportListEndpoint,
  UnderwritingReportSummary,
} from "../cma/endpoints/reports/UnderwritingReportListEndpoint";
import { PagingOptions, Sortable, SortOptions } from "../paging";
import { PageSettings, Pagination } from "./components/Pagination";
import { ReportsFilter, useReportsFilter } from "./ReportsFilter";
import { useDebounce } from "src/hooks";

export function ReportsPage() {
  useDocumentTitle("Reports");

  return (
    <>
      <PageHeader title="Underwriting Reports" />
      <LoadingBoundary useProgressBar>
        <LoadReports />
      </LoadingBoundary>
    </>
  );
}

export function LoadReports() {
  const filterDefs = useReportsFilter();
  const { filter, setFilter } = usePersistedFilter<ReportsFilter>({
    storageKey: "reportsFilter",
    filterDefs,
  });
  const [settings, setSettings] = useState<PagingOptions>({ page: 1, perPage: 25 });
  const [sort, setSort] = useState<SortOptions>({ orderBy: "updated_at", direction: "DESC" });

  function onSort(orderBy: string | undefined = "updated_at", direction: Direction | undefined = "DESC") {
    setSort({ orderBy, direction });
    setSettings({ page: 1, perPage: settings.perPage });
  }

  function onFilterChange(filter: ReportsFilter) {
    setFilter(filter);
    setSettings({ page: 1, perPage: settings.perPage });
  }

  return (
    <>
      <div css={Css.df.jcsb.$}>
        <Filters<ReportsFilter>
          numberOfInlineFilters={6}
          filter={filter}
          filterDefs={filterDefs}
          onChange={onFilterChange}
        />
      </div>
      <LoadingBoundary useProgressBar>
        <LoadReportsTable filter={filter} sort={sort} settings={settings} setSettings={setSettings} onSort={onSort} />
      </LoadingBoundary>
    </>
  );
}

interface LoadReportsTableProps extends Sortable {
  filter: ReportsFilter;
  sort: SortOptions;
  settings: PagingOptions;
  setSettings: Dispatch<SetStateAction<PageSettings>>;
}

function LoadReportsTable({ filter, sort, settings, setSettings, onSort }: LoadReportsTableProps) {
  const debouncedFilter = useDebounce(filter, 750);
  const reportsResult = useSuspense(UnderwritingReportListEndpoint, { ...debouncedFilter, ...sort, ...settings });

  const paging = reportsResult.paging;

  return (
    reportsResult.reports && (
      <>
        <Pagination label="Reports" setSettings={setSettings} paging={paging} />
        <ReportsTable reports={reportsResult.reports} onSort={onSort} />
      </>
    )
  );
}

interface ReportsTableProps {
  reports: UnderwritingReportSummary[];
  onSort: (orderBy: string | undefined, direction: Direction | undefined) => void;
}

export function ReportsTable({ reports, onSort }: ReportsTableProps) {
  return (
    <ScrollableContent>
      <GridTable
        columns={createColumns()}
        rows={createRows(reports)}
        sorting={{ on: "server", value: ["updated_at", "DESC"], onSort }}
        style={condensedStyle}
        rowStyles={rowStyles}
        stickyHeader
      />
    </ScrollableContent>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: UnderwritingReportSummary };
type Row = HeaderRow | DataRow;

function createColumns(): GridColumn<Row>[] {
  return [
    column<Row>({
      id: "status-column",
      header: "Status",
      data: (row) => row.status,
      w: "75px",
    }),
    column<Row>({
      id: "editor-column",
      header: "Editor",
      data: (row) => formatReportCollaborators(row.collaborators),
      mw: "200px",
    }),
    column<Row>({
      id: "address-column",
      header: "Address",
      serverSideSortKey: "full_street_address",
      data: (row) => row.property?.full_street_address && capitalCase(row.property.full_street_address),
      mw: "200px",
    }),
    column<Row>({
      id: "city-column",
      header: "City",
      serverSideSortKey: "city_name",
      w: ".75fr",
      mw: "125px",
      data: (row) => row.property?.city_name && capitalCase(row.property.city_name),
    }),
    column<Row>({
      id: "state-column",
      header: "State",
      w: "60px",
      align: "center",
      data: (row) => row.property?.state,
    }),
    column<Row>({
      id: "zip-code-column",
      header: "Zip Code",
      w: ".5fr",
      mw: "75px",
      align: "center",
      data: (row) => row.property?.zip_code,
    }),
    column<Row>({
      id: "metro-column",
      header: "Metro",
      w: ".5fr",
      mw: "75px",
      data: (row) => {
        const { short, full } = beautifyMetro(row.property?.metro);

        return {
          content: (
            <Tooltip title={full}>
              <span>{short}</span>
            </Tooltip>
          ),
          value: full,
        };
      },
      clientSideSort: false,
    }),
    column<Row>({
      header: "Opportunity Stage",
      data: (row) => {
        return row.sfdc_opportunities.map((o) => o.stage_name).join(", ");
      },
      w: ".75fr",
    }),
    column<Row>({
      id: "last-updated-column",
      header: "Last Updated",
      serverSideSortKey: "updated_at",
      w: ".75fr",
      data: (row) => {
        return { content: maybeStringToDateLabel(row.updated_at), value: row.updated_at };
      },
    }),
    actionColumn<Row>({
      id: "versions-column",
      header: "Versions",
      data: (row) => <OpenVersionsDrawerButton property={row.property!} size={"sm"} />,
      wrapAction: false,
      w: ".5fr",
    }),
  ];
}

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {
    cellCss: Css.aic.$,
    rowLink: ({ data: { dpid, status, id } }) =>
      status === "Finalized"
        ? createCmaEstimateUrl(dpid, id!.toString())
        : createCmaSubjectPropertyUrl({ dpid, versionId: id!.toString() }),
  },
};

function createRows(reports: UnderwritingReportSummary[]): GridDataRow<Row>[] {
  return [
    simpleHeader,
    ...reports.map((c) => ({
      kind: "data" as const,
      id: `${c.id}`,
      data: c,
    })),
  ];
}

// reformat metro string to be more human. Removes underscore and capitalizes first letter of each string
interface MetroName {
  short: string;
  full: string;
}

export function beautifyMetro(metro?: string): MetroName {
  if (!metro) return { short: "", full: "" };

  const splitMetros = metro.replace(metro.slice(metro.lastIndexOf("_")), "").split("-");

  const parts = splitMetros.map((m) => capitalCase(m.replace("_", " ")));

  return { short: parts[0], full: parts.join(", ") };
}
