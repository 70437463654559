import { Css } from "@homebound/beam";
import React from "react";

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorState {
  hasError?: boolean | undefined;
  message?: string;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    if (Object.prototype.hasOwnProperty.call(error, "message")) {
      return { hasError: true, message: error.message };
    }
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <StandardErrorContainer>
          {this.state.message && <div css={Css.p2.mt8.fontFamily("monospace").bgRed50.$}>{this.state.message}</div>}
        </StandardErrorContainer>
      );
    }
    return this.props.children;
  }
}

// Exported for use in DLE dependent components that should not break entire page
export const StandardErrorContainer = ({ children }: { children: React.ReactNode }) => (
  <div css={Css.w100.py4.$}>
    <div css={Css.xl3.$}>An Error Has Occurred</div>
    <div css={Css.lg.py2.$}>
      If the problem persists, please ask for help in the{" "}
      <span css={Css.wsp.bgGray100.p1.$}>#uw_app_feedback_and_updates</span> channel on slack.
    </div>
    <img src="/images/bug_fixing.svg" alt="Fixing" />
    {children}
  </div>
);
