import { Css } from "@homebound/beam";
import { groupBy } from "lodash";
import {
  ReadyPlanOption,
  RPOptionConflictPrerequisite,
} from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlanOptionsEndpoint";
import { summarizeProgramData } from "./summarizeProgramData";

export interface ReadyPlanOptionLabelProps {
  option: ReadyPlanOption;
}
export function ReadyPlanOptionLabel({ option }: ReadyPlanOptionLabelProps) {
  const summary = summarizeProgramData(option.programData);

  return (
    <div css={Css.w100.addIn(":hover > #pre-req-label", Css.gray800.$).$}>
      <div>{option.displayName}</div>
      <OptionPrerequisiteLabel optionPrereqs={option.optionPrerequisites} />
      <div css={Css.tiny.gray800.$}>
        {summary.map((s) => (
          <span css={Css.pr1.$} key={s.key}>
            {s.summary}
          </span>
        ))}
      </div>
    </div>
  );
}

function OptionPrerequisiteLabel({ optionPrereqs }: { optionPrereqs: RPOptionConflictPrerequisite[] }) {
  if (optionPrereqs.length === 0) {
    return null;
  }

  const entries = groupBy(optionPrereqs, (p) => p.type.name);

  return (
    <div id={"pre-req-label"} css={Css.tiny.$}>
      <div>Requires Pre-Requisite Selection</div>
      {Object.entries(entries).map(([type, value]) => (
        <div css={Css.df.fdc.$} key={type}>
          <span css={Css.fw6.$}>{type}:</span>
          <span css={Css.pl1.$}>{value.map((v) => v.displayName).join(" OR ")}</span>
        </div>
      ))}
    </div>
  );
}
