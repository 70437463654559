import { Step } from "@homebound/beam";
import { useDLE } from "@rest-hooks/react";
import { useEffect, useMemo, useState } from "react";
import { PropertyEndpoint } from "src/routes/cma/endpoints";
import {
  ReadyPlan,
  UnderwritingReport,
  UnderwritingReportEndpoint,
} from "src/routes/cma/endpoints/reports/UnderwritingReportEndpoint";
import { sortBy } from "src/utils";
import { getReadyPlanDisplayName } from "src/utils/mappers";
import { CmaStep } from "./CmaStep";

// Hook to handle generating step data based on the report
export function useStepData(dpid: string, versionId: string, currentStep: string) {
  const { data: propertyData } = useDLE(PropertyEndpoint, dpid ? { dpid } : null);
  const { data: reportData } = useDLE(
    UnderwritingReportEndpoint,
    dpid && versionId ? { dpid, versionId, shouldGenerateMlSaleConfidence: currentStep === CmaStep.estimate } : null,
  );

  const report = reportData?.report;
  const property = propertyData?.property;

  const stepsData: Step[] = useMemo(() => generateSteps(report), [report]);
  const [steps, setSteps] = useState<Step[]>(stepsData);

  useEffect(() => {
    if (report) {
      setSteps(generateSteps(report));
    }
  }, [report, dpid, currentStep]);

  return { steps, property, report };
}

const baseSteps: Step[] = [propertyStep(), readyPlanStep(), compsStep(), adjustmentsStep(), estimateStep()];

export function generateSteps(report: UnderwritingReport | undefined): Step[] {
  if (!report) return baseSteps;

  let steps = [propertyStep(report), readyPlanStep(report), ...generateCompAdjEstSteps(report)];

  if (report.status === "Finalized") {
    steps.forEach((s) => {
      s.state = "complete";
      s.disabled = false;
    });
    steps.push(hpoStep());
  }

  return steps;
}

function generateCompAdjEstSteps(report: UnderwritingReport): Step[] {
  const plans = report?.ready_plans ?? [];

  if (plans.length < 1) {
    return [compsStep(), adjustmentsStep(), estimateStep()];
  }

  let steps: Step[] = [];

  // sort by id, so we get deterministic order regardless of how they were returned
  const sortedPlans = sortBy(plans, (p) => p.bp_ready_plan_id ?? p.ready_plan_sub_id);

  let usedPrefixes = new Map<string, number>();

  let enableEstimateStep = true;

  sortedPlans.forEach((plan) => {
    // we don't need to prefix a name if there's only one plan
    let prefix = plans.length < 2 ? "" : `${getReadyPlanDisplayName(plan)} `;

    // if the prefix is already used (same ready plan w/ different options),
    // use a number suffix for the step name
    if (prefix.length > 0) {
      if (usedPrefixes.has(prefix)) {
        usedPrefixes.set(prefix, (usedPrefixes.get(prefix) ?? 1) + 1);
        prefix = `${prefix}(${usedPrefixes.get(prefix)}) `;
      } else {
        usedPrefixes.set(prefix, 1);
      }
    }

    const hasSelectedComps = !!(plan?.comps?.length && plan.comps.length > 0);
    const allCompsHavePrice = plan.comps?.every((c) => c.estimated_price && c.estimated_price > 0);

    steps.push(compsStep(hasSelectedComps, prefix, plan, true));
    steps.push(adjustmentsStep(hasSelectedComps, allCompsHavePrice, prefix, plan));

    // Disable estimate step if any RP is missing pricing
    if (!hasSelectedComps || !allCompsHavePrice) {
      enableEstimateStep = false;
    }
  });

  return [...steps, estimateStep(report, enableEstimateStep)];
}

function propertyStep(report?: UnderwritingReport): Step {
  return {
    state: report ? "complete" : "incomplete",
    label: "Subject Property",
    value: CmaStep.subject_property,
  };
}

function readyPlanStep(report?: UnderwritingReport): Step {
  const hasReadyPlans = report?.ready_plans && report?.ready_plans?.length > 0;
  return {
    state: hasReadyPlans ? "complete" : "incomplete",
    label: "Ready Plan",
    value: CmaStep.ready_plan,
    disabled: !report?.id,
  };
}

function compsStep(hasSelectedComps: boolean = false, prefix: string = "", plan?: ReadyPlan, enabled?: boolean): Step {
  return {
    state: hasSelectedComps ? "complete" : "incomplete",
    label: `${prefix}Comps`,
    value: plan ? cmaStepComps(plan) : CmaStep.comparables,
    disabled: !enabled,
  };
}

function adjustmentsStep(
  hasSelectedComps: boolean = false,
  allCompsHavePrice = false,
  prefix: string = "",
  plan?: ReadyPlan,
): Step {
  return {
    state: hasSelectedComps && allCompsHavePrice ? "complete" : "incomplete",
    label: `${prefix}Adjustments`,
    value: plan ? cmaStepAdjustments(plan) : CmaStep.adjustments,
    disabled: !hasSelectedComps,
  };
}

function estimateStep(report?: UnderwritingReport, enabled: boolean = false): Step {
  return {
    state: report?.status === "Finalized" ? "complete" : "incomplete",
    label: "Estimate",
    value: CmaStep.estimate,
    disabled: !enabled,
  };
}

function hpoStep(): Step {
  return {
    state: "complete",
    label: "HPO",
    value: CmaStep.hpo,
    disabled: false,
  };
}

function cmaStepComps(rp: ReadyPlan) {
  return `comps-${rp.id}`;
}

function cmaStepAdjustments(rp: ReadyPlan) {
  return `adjustments-${rp.id}`;
}
