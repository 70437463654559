import { UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { EstimateFormState } from "src/routes/cma/steps/estimate/estimate-forms/EstimateForm";
import { BoundNumberField, Css, Tooltip } from "@homebound/beam";
import {
  EstimateSection,
  EstSectionFooterCss,
  EstStatContainer,
  EstSingleStat,
  EstSingleChildStat,
} from "src/routes/cma/steps/estimate/components";
import React from "react";
import { ValuationStage } from "src/routes/cma/endpoints/reports/ValuationStage";

type RevenueAndCostsProps = {
  report: UnderwritingReport;
  estimateFormState: EstimateFormState;
};

export function RevenueAndCostFields({ report, estimateFormState }: RevenueAndCostsProps) {
  const saleClosingCost = report.costs_and_metrics
    ? report.costs_and_metrics.exp_price_with_appreciation * (report.costs_and_metrics.net_sale_price_realtor_fee / 100)
    : undefined;

  return (
    <EstimateSection title="Revenue & Cost Summary" forceOpen>
      <div>
        <EstStatContainer
          title="Gross Expected Sales Price"
          type="money"
          value={report.costs_and_metrics?.exp_price_with_appreciation}
        >
          <Tooltip title="Total expected appreciation over the project">
            <EstSingleChildStat
              title="Projected Appreciation:"
              value={report.costs_and_metrics?.appreciation_time_of_delivery_appreciation}
              type="percentage"
            />
          </Tooltip>
        </EstStatContainer>
        <EstStatContainer title="Sales Closing Costs" type="money" value={saleClosingCost}>
          <EstSingleChildStat
            highlight={!estimateFormState.readOnly}
            title="Sales Closing Cost rate: "
            value={
              <Tooltip title="Sales Closing Cost/Realtor Fee Percentage">
                <div css={Css.wPx(75).$}>
                  <BoundNumberField
                    label="Sales Closing Cost Percentage"
                    readOnly={report.valuation_stage !== ValuationStage.underwriting || estimateFormState.readOnly}
                    field={estimateFormState.costs_and_metrics.net_sale_price_realtor_fee}
                    numFractionDigits={2}
                    type="percent"
                    labelStyle="hidden"
                    // Inherit font styles when field becomes readonly
                    xss={Css.tac.color("inherit").fw("inherit").lh("inherit").add("fontSize", "inherit").$}
                    compact
                  />
                </div>
              </Tooltip>
            }
            type="percentage"
          />
        </EstStatContainer>
      </div>
      <div css={EstSectionFooterCss}>
        <EstSingleStat bold title="Total Net Revenue" value={report.costs_and_metrics?.net_sale_revenue} type="money" />
        <EstSingleStat bold title="Total Costs" value={report.costs_and_metrics?.total_costs} type="money" />
      </div>
    </EstimateSection>
  );
}
