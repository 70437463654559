import {
  BoundCheckboxField,
  column,
  condensedStyle,
  Css,
  dateColumn,
  GridCellContent,
  GridColumn,
  GridDataRow,
  GridStyle,
  GridTable,
  GridTableApi,
  numericColumn,
  Palette,
  RowStyles,
  simpleHeader,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useEffect, useMemo } from "react";
import { mdash, mdashUnicode } from "src/components/mdash";
import { parseCompBuildDate } from "src/routes/cma/steps/readyPlanUtils";
import { formatNumberToString, FORMAT_MONTH_DAY_YEAR } from "src/utils";
import { mapPropertyTypeName } from "src/utils/mappers";
import { addressCell, domCell, numberCell, percentageCell, priceCell } from "src/utils/tableUtils";
import { Maybe } from "src/utils/types";
import { PropertyComp } from "../../endpoints/PropertyCompEndpoint";
import { SaveCompInput } from "../../endpoints/reports";
import { ReadyPlanCompSelectionInput } from "./CompForm";
import { CompTierTag } from "./CompTierTag";

export interface PropertyCompTableProps {
  formState: ObjectState<ReadyPlanCompSelectionInput>;
  comps: PropertyComp[];
  selectedComp: string | undefined;
  tableApi: GridTableApi<Row>;
  onCompSelected: (dpid: string | undefined) => void;
}

const style: GridStyle = {
  ...condensedStyle,
  // Overwrite forced white bg from condensed but keep rest
  cellCss: {
    ...condensedStyle.cellCss,
    ...Css.bgColor("inherit").$,
  },
  rootCss: Css.addIn(
    "& > div:nth-of-type(2)", // grid div
    Css.addIn("& > div:nth-of-type(odd)", Css.bgWhite.$) // odd rows
      .addIn("& > div:nth-of-type(even)", Css.bgGray100.$).$, // even rows
  ).$,
  activeBgColor: Palette.Blue100,
  emptyCell: mdash,
  headerCellCss: Css.wsnw.bgGray100.$,
};

export function PropertyCompTable(props: PropertyCompTableProps) {
  const { formState, comps, selectedComp, tableApi, onCompSelected } = props;

  useEffect(() => {
    if (selectedComp) {
      tableApi.setActiveRowId(`data_${selectedComp}`);
    } else {
      tableApi.setActiveRowId(undefined);
    }
  }, [selectedComp, tableApi]);

  const rows = useMemo(() => createRows(formState, comps), [formState, comps]);
  const rowStyles = useMemo(() => createRowStyles(onCompSelected), [onCompSelected]);

  function renderTable() {
    return (
      <div css={Css.os.hPx(800).$}>
        <GridTable
          api={tableApi}
          columns={compTableColumns}
          rows={rows}
          rowStyles={rowStyles}
          sorting={{ on: "client", initial: ["selected-column", "DESC"] }}
          fallbackMessage="No comps found for this property."
          style={style}
          stickyHeader
        />
      </div>
    );
  }

  return <Observer>{() => renderTable()}</Observer>;
}

interface PropertyCompRow {
  comp: PropertyComp;
  selection: (dpid: string) => ObjectState<SaveCompInput>;
}

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: PropertyCompRow };
export type Row = HeaderRow | DataRow;

export const compTableColumns: GridColumn<Row>[] = [
  column<Row>({
    id: "selected-column",
    header: "Selected",
    data: (row) => {
      const rowState = row.selection(row.comp.dpid_of_neighbor);
      return rowState
        ? {
            content: <BoundCheckboxField label="Select" checkboxOnly field={rowState.user_selected_comp} />,
            // sort on whether checkbox is selected or not
            sortValue: rowState.user_selected_comp.value,
          }
        : null;
    },
    w: "90px",
    mw: "90px",
    align: "center",
    sticky: "left",
  }),
  column<Row>({
    id: "tier-column",
    header: "Tier",
    name: "Tier",
    canHide: true,
    initVisible: true,
    data: ({ comp: { comp_tier } }) => {
      return {
        content: <CompTierTag compTier={comp_tier} />,
        value: comp_tier,
      };
    },
    w: "90px",
    mw: "90px",
    sticky: "left",
  }),
  column<Row>({
    id: "address-column",
    header: {
      content: "Address",
      css: Css.br.bcGray500.$,
    },
    data: ({ comp: { neighbor } }) => addressCell(neighbor?.full_street_address, neighbor?.unit_number, true),
    mw: "200px",
    sticky: "left",
  }),
  column<Row>({
    id: "hb-comp-column",
    header: { content: "HB Comp", tooltip: "Homebound property that is active/pending or sold within the past year" },
    name: "HB Comp",
    canHide: true,
    initVisible: true,
    data: ({ comp: { is_hb_comp } }) => {
      return {
        content: is_hb_comp ? "Yes" : mdashUnicode,
        value: is_hb_comp,
      };
    },
    mw: "110px",
  }),
  column<Row>({
    id: "ml-comp-column",
    header: { content: "ML Comp", tooltip: "ML-recommended comp" },
    name: "ML Comp",
    canHide: true,
    initVisible: true,
    data: ({ comp: { auto_uw_rec } }) => {
      return {
        content: auto_uw_rec ? "Yes" : mdashUnicode,
        value: auto_uw_rec,
      };
    },
    mw: "110px",
  }),
  column<Row>({
    id: "comp-score-column",
    header: {
      content: "Comp Score",
      tooltip:
        "ML generated assessment of how similar the comp is to the subject. Value can range from 0-100%, with a higher score signifying a better comp.",
    },
    name: "Comp Score",
    canHide: true,
    initVisible: true,
    data: ({ comp: { comp_score } }) => percentageCell(comp_score, false),
    mw: "125px", // just enough to keep sort label visible
  }),
  column<Row>({
    id: "spec-level-column",
    header: {
      content: "Spec Level",
      tooltip: "Level is assigned by analysts and can be set on the adjustments step for selected comps.",
    },
    name: "Spec Level",
    canHide: true,
    initVisible: true,
    data: ({ comp: { spec_level } }) => ({
      content: <span css={Css.ttc.$}>{spec_level}</span>,
      sortValue: spec_level ? SPEC_LEVEL_VALUE_MAP[spec_level] : 10,
    }),
    mw: "125px",
  }),
  column<Row>({
    id: "type-column",
    header: "Type",
    name: "Type",
    canHide: true,
    initVisible: true,
    data: ({ comp: { neighbor } }) => propertyType(neighbor?.property_type_simple),
    mw: "120px",
  }),
  column<Row>({
    id: "mls-status-column",
    header: "MLS Status",
    name: "MLS Status",
    canHide: true,
    initVisible: true,
    data: ({ comp: { neighbor } }) => neighbor?.mls_status,
    mw: "105px",
  }),
  numericColumn<Row>({
    id: "dom-column",
    header: {
      content: "DOM",
      tooltip: "Days on Market = list date to sold date or current date if still active",
    },
    name: "DOM",
    canHide: true,
    initVisible: true,
    data: ({ comp: { neighbor } }) => domCell(neighbor),
    mw: "80px",
  }),
  numericColumn<Row>({
    id: "year-built-column",
    header: "Year Built",
    name: "Year Built",
    canHide: true,
    initVisible: true,
    data: ({ comp: { neighbor } }) => parseCompBuildDate(neighbor?.year_built_or_renovated),
    mw: "100px",
  }),
  column<Row>({
    id: "builder-column",
    header: "Builder",
    name: "Builder Name",
    canHide: true,
    initVisible: true,
    data: ({ comp }) => comp.builder_name ?? "-",
    mw: "120px",
  }),
  dateColumn<Row>({
    id: "last-sold-column",
    header: "Last Sold",
    name: "Last Sold",
    canHide: true,
    initVisible: true,
    data: ({ comp: { neighbor } }) => {
      const content = neighbor?.last_sold_date
        ? FORMAT_MONTH_DAY_YEAR.format(new Date(neighbor.last_sold_date))
        : mdashUnicode;

      const sortValue = neighbor?.last_sold_date ? new Date(neighbor.last_sold_date) : undefined;

      return { content, sortValue };
    },
    mw: "125px",
  }),
  numericColumn<Row>({
    id: "list-price-column",
    header: "List Price",
    name: "List Price",
    canHide: true,
    initVisible: true,
    data: ({ comp: { neighbor } }) => priceCell(neighbor?.mls_list_price),
    mw: "120px",
  }),
  numericColumn<Row>({
    id: "last-sold-price-column",
    header: "Last Sold Price",
    name: "Last Sold Price",
    canHide: true,
    initVisible: true,
    data: ({ comp: { neighbor } }) => priceCell(neighbor?.last_sold_price),
    mw: "120px",
  }),
  numericColumn<Row>({
    id: "sold-price-column",
    header: "Sold Price Adj.",
    name: "Sold Price Adj.",
    canHide: true,
    initVisible: true,
    data: ({ comp: { neighbor } }) => priceCell(neighbor?.last_sold_price_adj),
    mw: "120px",
  }),
  numericColumn<Row>({
    id: "hb-avm-column",
    header: "HB AVM",
    name: "HB AVM",
    canHide: true,
    initVisible: true,
    data: ({ comp: { neighbor } }) => priceCell(neighbor?.homebound_avm),
    mw: "100px",
  }),
  numericColumn<Row>({
    id: "adj-column",
    header: "Adj. PSF",
    name: "Adj. PSF",
    canHide: true,
    initVisible: true,
    data: ({ comp: { neighbor } }) => priceCell(neighbor?.last_sold_price_adj_per_sqft, false),
    mw: "90px",
  }),
  numericColumn<Row>({
    id: "hb-avm-psf-column",
    header: "HB AVM PSF",
    name: "HB AVM PSF",
    canHide: true,
    initVisible: true,
    data: ({ comp: { neighbor } }) =>
      neighbor?.homebound_avm && neighbor?.finished_sqft
        ? priceCell(neighbor.homebound_avm / neighbor.finished_sqft, false)
        : mdashUnicode,
    mw: "120px",
  }),
  numericColumn<Row>({
    id: "lot-sqft-column",
    header: "Lot Sqft",
    name: "Lot Sqft",
    canHide: true,
    initVisible: true,
    data: ({ comp: { neighbor } }) => numberCell(neighbor?.lot_size),
    mw: "80px",
  }),
  numericColumn<Row>({
    id: "sellable-sqft-column",
    header: "Sellable Sqft",
    name: "Sellable Sqft",
    canHide: true,
    initVisible: true,
    data: ({ comp: { neighbor } }) => numberCell(neighbor?.finished_sqft),
    mw: "120px",
  }),
  numericColumn<Row>({
    id: "sellable-above-ground-sqft-column",
    header: { content: "Abv Sqft", tooltip: "Sellable Above Ground Sqft" },
    name: "Sellable Above Ground Sqft",
    canHide: true,
    initVisible: true,
    data: ({ comp: { neighbor } }) => numberCell(neighbor?.above_ground_sqft),
    mw: "120px",
  }),
  numericColumn<Row>({
    id: "sellable-below-ground-sqft-column",
    header: { content: "Blw Sqft", tooltip: "Sellable Below Ground Sqft" },
    name: "Sellable Below Ground Sqft",
    canHide: true,
    initVisible: true,
    data: ({ comp: { neighbor } }) => numberCell(neighbor?.finished_basement_sqft),
    mw: "120px",
  }),
  numericColumn<Row>({
    id: "unfinished-below-ground-sqft-column",
    header: { content: "UnFin. Blw Sqft", tooltip: "Unfinished Below Ground Sqft" },
    name: "Unfinished Below Ground Sqft",
    canHide: true,
    initVisible: true,
    data: ({ comp: { neighbor } }) => numberCell(neighbor?.unfinished_basement_sqft),
    mw: "120px",
  }),
  numericColumn<Row>({
    id: "beds-column",
    header: "Beds",
    name: "Beds",
    canHide: true,
    initVisible: true,
    data: ({ comp: { neighbor } }) => neighbor?.num_bedrooms,
    w: "70px",
  }),
  numericColumn<Row>({
    id: "baths-column",
    header: "Baths",
    name: "Baths",
    canHide: true,
    initVisible: true,
    data: ({ comp: { neighbor } }) => neighbor?.num_baths,
    w: "70px",
  }),
  numericColumn<Row>({
    id: "distance-column",
    header: "Distance",
    name: "Distance",
    canHide: true,
    initVisible: true,
    data: ({ comp: { dist } }) => distanceCell(dist),
    w: "90px",
  }),
];

function createRows(formState: ObjectState<ReadyPlanCompSelectionInput>, comps: PropertyComp[]): GridDataRow<Row>[] {
  function selection(dpid: string): ObjectState<SaveCompInput> {
    return formState.comps.rows.find((r) => r.dpid_of_neighbor.value === dpid)!;
  }

  return [
    simpleHeader,
    ...(comps.map((c) => ({
      kind: "data" as const,
      id: c.dpid_of_neighbor,
      data: { comp: c, selection },
    })) || []),
  ];
}

function createRowStyles(_onClick: (dpid: string) => void): RowStyles<Row> {
  return {
    header: {},
    data: {
      onClick: (row, api) => {
        api.setActiveRowId(`${row.kind}_${row.id}`);
        _onClick(row.data.comp.dpid_of_neighbor);
      },
    },
  };
}

function distanceCell(dist: Maybe<number>): GridCellContent {
  return {
    content: dist ? `${formatNumberToString(dist, false, false)} mi` : undefined,
    value: dist,
  };
}

function propertyType(propertyType: Maybe<string>): string | undefined {
  if (!propertyType) {
    return undefined;
  }

  return mapPropertyTypeName(propertyType);
}

/** Values for sorting the spec level column */
const SPEC_LEVEL_VALUE_MAP: Record<string, number> = {
  base: 8,
  essential: 6,
  deluxe: 4,
  premium: 2,
  custom: 0,
};
