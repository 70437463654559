import { FeatureGroup as LeafletFeatureGroup } from "leaflet";
import { useRef } from "react";
import { FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { usePolygonContext } from "src/routes/admin/polygons/PolygonContext";
import { CompFilterPolygonGeometry } from "src/routes/cma/steps";
import { CompFilterPolygonFeatureView } from "./CompFilterPolygonFeatureView";

interface CompFilterPolygonEditorProps {
  compFilterPolygon: CompFilterPolygonGeometry | undefined;
  setCompFilterPolygon: (filter: CompFilterPolygonGeometry | undefined) => void;
  isActive?: boolean;
}

// Note: A key is required on the surrounding MapContainer to force a re-render when the compFilterPolygon changes.
// Otherwise multiple copies of the polygons will be drawn on the map when editing or drawing additional polygons.
export function CompFilterPolygonEditor(props: CompFilterPolygonEditorProps) {
  const { compFilterPolygon, setCompFilterPolygon, isActive } = props;
  const { setSelectedPolygonId } = usePolygonContext();
  const fgRef = useRef<LeafletFeatureGroup>(null);

  function onChange() {
    const { coordinates } = fgRef.current!.toMultiPoint().geometry;

    const multiPolygon = { geometry: { type: "MultiPolygon", coordinates } };

    // @ts-ignore - the coordinates type of MultiPoint is Position[], but for a MultiPolygon it is Position[][][],
    // which is what we are actually getting from the .toMultiPoint() call above and which matches our CompFilterPolygonGeometry type.
    const feature = coordinates.length === 0 ? undefined : (multiPolygon as CompFilterPolygonGeometry);

    setCompFilterPolygon(feature);
  }

  return (
    <FeatureGroup ref={fgRef}>
      <EditControl
        position="topright"
        onCreated={onChange}
        onDeleted={onChange}
        onEdited={onChange}
        // when trash icon is clicked, deselect the overlay polygon to enable deleting comp filter polygons
        onDeleteStart={() => {
          setSelectedPolygonId(null);
          fgRef.current?.bringToFront();
        }}
        draw={{
          circle: false,
          polyline: false,
          marker: false,
          circlemarker: false,
          rectangle: false,
          polygon: {
            showLength: true,
            metric: false,
            feet: false,
          },
        }}
      />
      {compFilterPolygon && (
        <CompFilterPolygonFeatureView compFilterGeometry={compFilterPolygon.geometry} isActive={isActive} />
      )}
    </FeatureGroup>
  );
}
