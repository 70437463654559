import { ObjectConfig, ObjectState } from "@homebound/form-state";
import { UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { Maybe } from "src/utils";

// We just need the id for a place holder value im the table
export type UnderwritingReportVersionInput = Omit<UnderwritingReport, "ready_plans" | "lot_cost"> & {
  ready_plans: { ready_plan_id?: Maybe<string> }[];
};

export interface SimpleUnderwritingReports {
  versions: UnderwritingReportVersionInput[];
}

export type VersionFormState = ObjectState<SimpleUnderwritingReports>;
export const versionsFormConfig: ObjectConfig<SimpleUnderwritingReports> = {
  versions: {
    type: "list",
    config: {
      dpid: { type: "value", readOnly: true },
      id: { type: "value", readOnly: true },
      status: { type: "value", readOnly: true },
      ready_plans: {
        type: "list",
        config: {
          ready_plan_id: { type: "value", readOnly: true },
        },
      },
      collaborators: {
        type: "list",
        config: {
          id: { type: "value", readOnly: true },
          email: { type: "value", readOnly: true },
        },
      },
      final_weighted_price: { type: "value", readOnly: true },
      final_weighted_price_with_appreciation: { type: "value", readOnly: true },
      underwritten_at: { type: "value", readOnly: true },
      created_at: { type: "value", readOnly: true },
      updated_at: { type: "value", readOnly: true },
      activity_state: { type: "value" },
      version_name: { type: "value" },
      valuation_stage: { type: "value", readOnly: true },
    },
  },
};
