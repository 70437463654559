import { EstimateFormState } from "src/routes/cma/steps/estimate/estimate-forms/EstimateForm";
import { EstimateSection, EstSectionFooterCss, EstSingleStat } from "src/routes/cma/steps/estimate/components";
import { BoundNumberField, Css, Tooltip } from "@homebound/beam";
import React from "react";
import { BoundNumberFieldProps } from "@homebound/beam/dist/forms/BoundNumberField";

type CycleTimeFieldsProps = {
  estimateFormState: EstimateFormState;
};

export function CycleTimeFields({ estimateFormState }: CycleTimeFieldsProps) {
  return (
    <EstimateSection title="Cycle Time Assumptions" numColumns={3}>
      {/* Preopulated but eventually pull from BP for C2C */}
      <div css={Css.df.fdc.gap1.$}>
        <EstSingleStat
          highlight={!estimateFormState.readOnly}
          title="PreCon"
          type="number"
          value={
            <Tooltip title="Duration of the PreCon stage in months">
              <div css={Css.maxwPx(50).$}>
                <BoundNumberField
                  {...commonCycleFieldProps}
                  label="PreCon Duration"
                  field={estimateFormState.costs_and_metrics.project_length_pre_con_months}
                />
              </div>
            </Tooltip>
          }
        />
        <EstSingleStat
          highlight={!estimateFormState.readOnly}
          title="Construction"
          type="number"
          value={
            <Tooltip title="Duration of the Construction stage in months">
              <div css={Css.maxwPx(50).$}>
                <BoundNumberField
                  {...commonCycleFieldProps}
                  label="Construction Duration"
                  field={estimateFormState.costs_and_metrics.project_length_construction_months}
                />
              </div>
            </Tooltip>
          }
        />
        <EstSingleStat
          highlight={!estimateFormState.readOnly}
          title="Sales"
          type="number"
          value={
            <Tooltip title="Duration of the Sales stage in months">
              <div css={Css.maxwPx(50).$}>
                <BoundNumberField
                  {...commonCycleFieldProps}
                  label="Sales Duration"
                  field={estimateFormState.costs_and_metrics.project_length_sales_period_months}
                />
              </div>
            </Tooltip>
          }
        />
      </div>
      <div css={EstSectionFooterCss}>
        <EstSingleStat
          bold
          title="Total Cycle Time (mo)"
          type="number"
          fractional
          value={
            (estimateFormState.costs_and_metrics?.project_length_pre_con_months.value ?? 0) +
            (estimateFormState.costs_and_metrics?.project_length_construction_months.value ?? 0) +
            (estimateFormState.costs_and_metrics?.project_length_sales_period_months.value ?? 0)
          }
        />
      </div>
    </EstimateSection>
  );
}

const commonCycleFieldProps: Pick<BoundNumberFieldProps, "labelStyle" | "compact" | "numFractionDigits" | "xss"> = {
  labelStyle: "hidden",
  compact: true,
  numFractionDigits: 1,
  // Inherit type scale from parent when readonly
  xss: Css.tac.color("inherit").fw("inherit").lh("inherit").add("fontSize", "inherit").$,
};
