import { Endpoint, Entity } from "@rest-hooks/rest";
import { api_url, auth_headers } from "src/context";
import { BlueprintProgramData } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlansEndpoint";
import { Maybe } from "src/utils";

export interface ReadyPlanChildOption {
  id: string;
  name: string;
  active: boolean;
}

export interface RPOptionConflictPrerequisite {
  id: string;
  displayName: string;
  type: { name: string };
}

export interface ReadyPlanOptionBase {
  id: string;
  name: string;
  displayName: string;
  code: string;
  active: boolean;
  type: { name: string };
}

export interface ReadyPlanOption extends ReadyPlanOptionBase {
  // Can ONLY be selected when all of these options are selected
  optionPrerequisites: RPOptionConflictPrerequisite[];
  // Cannot be selected in conjunction with any of these options
  optionConflicts: RPOptionConflictPrerequisite[];
  programData: Maybe<Partial<BlueprintProgramData>>;
  optionDefaultChildren: ReadyPlanOptionBase[];
  optionDefaultsIf: ReadyPlanOptionBase[];
}

export class BlueprintReadyPlanOptionGroup extends Entity {
  readonly name: string = "";
  readonly required: boolean = false;
  readonly options: ReadyPlanOption[] = [];
  readonly isSingleOptionGroup: boolean = false;
  readonly isMultiOptionGroup: boolean = false;
  readonly order: number = 0;

  pk() {
    return this.name;
  }

  static key = "BlueprintReadyPlanOptionGroup";
}

export interface BlueprintOptionGroupsEndpointOptions {
  devId: string;
  bp_ready_plan_id: string;
}

export const BlueprintOptionGroupsEndpoint = new Endpoint(
  async ({ devId, bp_ready_plan_id }: BlueprintOptionGroupsEndpointOptions) => {
    const headers = await auth_headers();

    const res = await fetch(api_url(`/v2/ready_plans/developments/${devId}/ready_plans/${bp_ready_plan_id}/options`), {
      headers,
    });

    return await res.json();
  },
  {
    schema: { optionGroups: [BlueprintReadyPlanOptionGroup] },
    name: "BlueprintOptionGroupsEndpoint",
  },
);
