import { Css, Margin, Tag, Typography, Xss } from "@homebound/beam";
import { CompTier } from "../../endpoints";

interface CompTierTagProps {
  compTier: CompTier;
  size?: Typography;
  xss?: Xss<Margin>;
}

export function CompTierTag({ compTier, size = "xsSb", xss }: CompTierTagProps) {
  return (
    <div css={Css.addIn("& > span", { ...Css[size].$, ...tagColor[compTier] }).$}>
      <Tag xss={xss} text={compTier} />
    </div>
  );
}

const tagColor: Record<CompTier, any> = {
  "Tier 1": Css.bgGreen100.green900.$,
  "Tier 2": Css.bgPurple200.purple900.$,
  "Tier 3": Css.bgYellow200.yellow900.$,
  "Tier 4": Css.bgRed100.red900.$,
  "Tier 5": Css.bgGray200.$,
};
