import { Css } from "@homebound/beam";
import { mdashUnicode } from "src/components/mdash";
import { formatNumberToString, Maybe } from "src/utils";

interface FormattedNumberChangeProps {
  change: number | undefined;
  format?: "percent" | "currency";
}

export function FormattedNumberChange({ change, format = "currency" }: FormattedNumberChangeProps) {
  const formatted = format === "percent" ? formatPercent(change) : formatPrice(change);

  return (
    <div css={Css.df.gap1.w100.$}>
      <ChartDirectionChange change={change} />
      <span css={Css.df.aic.$}>{formatted}</span>
    </div>
  );
}

function ChartDirectionChange({ change }: FormattedNumberChangeProps) {
  if (change === undefined) {
    return null;
  }

  const dir = change > 0 ? "growth" : "decline";
  return <img src={`/images/chart_${dir}.svg`} alt={dir} />;
}

function formatPrice(price: Maybe<number>): string {
  return price ? "$" + formatNumberToString(price, true, true) : mdashUnicode;
}

function formatPercent(percent: Maybe<number>): string {
  return percent ? formatNumberToString(percent * 100, false, false) + "%" : mdashUnicode;
}
