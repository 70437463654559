import { Css, Icon, Palette, Tooltip } from "@homebound/beam";
import React from "react";
import { Link } from "react-router-dom";
import { ClickableImageWithMapImages } from "src/components/ClickableImage";
import { CompTier, Property } from "src/routes/cma/endpoints";
import { houseSvg } from "src/routes/maps/mapIcons";

export interface PropertyHeaderProps {
  property: Property;
  isSubjectProperty?: boolean;
  compTier?: CompTier;
  isHbComp?: boolean;
  isMlComp?: boolean;
}

export const PropertyHeader = React.memo(
  ({ property, isSubjectProperty, compTier, isHbComp = false, isMlComp = false }: PropertyHeaderProps) => {
    const addr = property.full_street_address.toLowerCase();

    function renderImage() {
      return (
        <div>
          {isSubjectProperty && (
            <div css={Css.bgBlue500.white.baseMd.p1.df.gap1.aic.brt4.$}>
              <span>Subject Property</span>
            </div>
          )}
          {compTier && (
            <div
              css={{
                ...Css.baseMd.p1.df.gap1.aic.brt4.$,
                ...tierColors[compTier],
              }}
            >
              {isHbComp && (
                <Tooltip placement="left" title={"HB Comp"}>
                  {houseSvg(Palette.Transparent, 24, 16)}
                </Tooltip>
              )}
              {isMlComp && (
                <Tooltip placement="left" title={"ML-Recommended Comp"}>
                  <Icon icon="starFilled" />
                </Tooltip>
              )}
              <span>{compTier}</span>
            </div>
          )}
          <div css={Css.dg.gap1.$}>
            <ClickableImageWithMapImages property={property} />
            <ClickableImageWithMapImages initialImage="overheadView" property={property} />
          </div>
        </div>
      );
    }

    const unit = property.unit_number ? `Unit ${property.unit_number}` : "";

    // Note: this isn't the official way to do this, but it's the only way to open google map with a satellite view
    const gMapUrlParams = new URLSearchParams({
      q: `${addr} ${property.city_name} ${property.state} ${property.zip_code}`,
      t: "k",
    });

    return (
      <div css={Css.df.fdc.asfe.$} data-testid="propertyHeader">
        <div css={Css.df.aife.$}> {renderImage()}</div>
        <div css={Css.py1.$}>
          <div css={Css.lgSb.ttc.df.gap1.aife.jcfe.$}>
            <span>
              {addr}
              {unit && `, ${unit}`}
            </span>
            <Link to={{ pathname: `https://maps.google.com/maps?${gMapUrlParams}` }} target="_blank">
              <Icon icon={"linkExternal"} />
            </Link>
          </div>
          <div css={Css.df.sm.ttc.jcfe.$}>
            {property.city_name.toLowerCase()}, {property.state} {property.zip_code}
          </div>
        </div>
      </div>
    );
  },
);

const tierColors: Record<CompTier, any> = {
  "Tier 1": Css.bgGreen100.green900.$,
  "Tier 2": Css.bgPurple200.purple900.$,
  "Tier 3": Css.bgYellow200.yellow900.$,
  "Tier 4": Css.bgRed100.red900.$,
  "Tier 5": Css.bgGray200.$,
};
