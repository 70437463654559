import { Css, ScrollableParent } from "@homebound/beam";
import { Route, Switch } from "react-router-dom";
import { NotFound } from "src/components/NotFound";
import { cmaPath, cmaPaths } from "../routesDef";
import { StepperContextProvider } from "./stepper/StepperContext";
import { AdjustmentsStep, EstimateStep, ReadyPlanStep, SubjectPropertyStep } from "./steps";
import { ComparablesStep } from "./steps/comparables/ComparablesStep";
import { HpoStep } from "./steps/hpo/HpoStep";

export function CmaPage() {
  return (
    <StepperContextProvider>
      <ScrollableParent>
        {/* padding bottom to account for stepper height to view all content */}
        <div css={Css.pb8.w100.$}>
          <Switch>
            <Route path={cmaPath} exact component={SubjectPropertyStep} />
            <Route path={cmaPaths.subject} exact component={SubjectPropertyStep} />
            <Route path={cmaPaths.rp} exact component={ReadyPlanStep} />
            <Route path={cmaPaths.comps} exact component={ComparablesStep} />
            <Route path={cmaPaths.adjustments} exact component={AdjustmentsStep} />
            <Route path={cmaPaths.estimate} exact component={EstimateStep} />
            <Route path={cmaPaths.hpo} exact component={HpoStep} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </ScrollableParent>
    </StepperContextProvider>
  );
}
