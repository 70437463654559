import { Endpoint, Entity } from "@rest-hooks/rest";
import { api_url, auth_headers } from "src/context";

export class AmenityAdjustment extends Entity {
  readonly dpid: string = "";
  readonly ready_plan_id: string = "";
  readonly sub_ready_plan_id: string = "";
  readonly dpid_of_comp: string = "";
  readonly lot_size_adj: number = 0;
  readonly year_built_or_renovated_adj: number = 0;
  readonly num_stories_adj: number = 0;
  readonly garage_num_cars_adj: number = 0;
  readonly above_ground_sqft_adj: number = 0;
  readonly finished_sqft_adj: number = 0;
  readonly num_baths_adj: number = 0;
  readonly amenity_adjustment: number = 0;

  readonly has_pool_adj: number = 0;
  readonly close_to_road_rail_industrial_adj: number = 0;
  readonly close_to_river_or_lake_adj: number = 0;
  readonly basement_sqft_adj: number = 0;
  readonly finished_basement_sqft_adj: number = 0;
  readonly unfinished_basement_sqft_adj: number = 0;
  readonly single_family_and_not_single_family_adj: number = 0;

  readonly spec_level_quality_adj: number = 0;
  readonly outdoor_amenity_quality_adj: number = 0;
  readonly street_quality_adj: number = 0;

  // Following _adj are calculated in the backend but manually added rows by the user
  readonly lot_quality_adj: number = 0;
  readonly close_to_commercial_adj: number = 0;
  readonly close_to_major_road_adj: number = 0;
  readonly close_to_powerlines_adj: number = 0;
  readonly close_to_school_adj: number = 0;
  readonly has_scenic_view_adj: number = 0;
  readonly num_bedrooms_adj: number = 0;

  readonly used_features: string[] = [];
  readonly used_adjustments: UsedAdjustmentTypes[] = [];

  pk() {
    return `${this.dpid}-${this.dpid_of_comp}`;
  }

  static key = "AmenityAdjustment";
}

export interface AmenityModelInput {
  dpid: string;
  ready_plan_id: string;
  sub_ready_plan_id: string;
  dpid_of_comp: string;
  metro: string;
  diff_lot_size: number;
  diff_year_built_or_renovated: number;
  diff_num_stories: number;
  diff_garage_num_cars: number;
  diff_finished_sqft: number;
  diff_num_baths: number;

  diff_has_pool: number;
  close_to_major_road: boolean;
  close_to_road_rail_industrial: boolean;
  close_to_river_or_lake: boolean;
  diff_basement_sqft: number;

  diff_above_ground_sqft: number;
  diff_finished_basement: number;
  diff_unfinished_basement: number;
  // Note: this is a boolean if we ever decide to directly call AmenityAdjustmentEndpoint
  // on SavePropertyCompInput we're using property_type_simple.
  // SC-XXXXX will change that into a dropdown we can map back
  single_family_and_not_single_family: boolean;
}

export const AmenityAdjustmentEndpoint = new Endpoint(
  async ({ input }: { input: AmenityModelInput[] }) => {
    const headers = {
      "content-type": "application/json",
      ...(await auth_headers()),
    };
    const res = await fetch(api_url(`/app_amenity_model`), {
      method: "POST",
      headers,
      body: JSON.stringify(input),
    });
    return await res.json();
  },
  {
    schema: [AmenityAdjustment],
    key: () => "/app_amenity_model",
    name: "AmenityAdjustmentEndpoint",
  },
);

// Use in conjuction with `used_features` to dynamically show market feature rows
export const POSSIBLE_USED_MARKET_AMENITIES = [
  "diff_lot_size",
  "diff_year_built_or_renovated",
  "diff_num_stories",
  "diff_garage_num_cars",
  "diff_finished_sqft",
  "diff_num_baths",
  "diff_has_pool",
  "close_to_major_road",
  "close_to_road_rail_industrial",
  "close_to_river_or_lake",
  "diff_basement_sqft",
  "diff_spec_level_quality",
  "diff_finished_basement_sqft",
  "diff_unfinished_basement_sqft",
  "diff_outdoor_amenity_quality",
  "diff_street_quality",
  "diff_lot_quality",
  "close_to_commercial",
  "close_to_powerlines",
  "close_to_school",
  "diff_has_scenic_view",
  "diff_num_bedrooms",
  "diff_above_ground_sqft",
];

export enum UsedAdjustmentTypes {
  finished_sqft_adj = "finished_sqft_adj",
  lot_size_adj = "lot_size_adj",
  year_built_or_renovated_adj = "year_built_or_renovated_adj",
  single_family_and_not_single_family_adj = "single_family_and_not_single_family_adj",
  num_baths_adj = "num_baths_adj",
  has_pool_adj = "has_pool_adj",
  garage_num_cars_adj = "garage_num_cars_adj",
  num_stories_adj = "num_stories_adj",
  basement_sqft_adj = "basement_sqft_adj",
  close_to_road_rail_industrial_adj = "close_to_road_rail_industrial_adj",
  close_to_river_or_lake_adj = "close_to_river_or_lake_adj",
  close_to_major_road_adj = "close_to_major_road_adj",
  spec_level_quality_adj = "spec_level_quality_adj",
  finished_basement_sqft_adj = "finished_basement_sqft_adj",
  unfinished_basement_sqft_adj = "unfinished_basement_sqft_adj",
  outdoor_amenity_quality_adj = "outdoor_amenity_quality_adj",
  street_quality_adj = "street_quality_adj",
  lot_quality_adj = "lot_quality_adj",
  close_to_commercial_adj = "close_to_commercial_adj",
  close_to_powerlines_adj = "close_to_powerlines_adj",
  close_to_school_adj = "close_to_school_adj",
  has_scenic_view_adj = "has_scenic_view_adj",
  num_bedrooms_adj = "num_bedrooms_adj",
  above_ground_sqft_adj = "above_ground_sqft_adj",
}
