import { Button, Css, EditColumnsButton, Filters, SelectField, useGridTableApi, useModal } from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { comparer } from "mobx";
import { Observer } from "mobx-react";
import { ReactNode, useMemo, useState } from "react";
import { useController, useReaction } from "src/hooks";
import { SavePolygonInput } from "src/routes/admin/polygons";
import { Property, PropertyComp } from "src/routes/cma/endpoints";
import {
  ReadyPlan,
  SaveUnderwritingReportEndpoint,
  UnderwritingReport,
  UnderwritingReportEndpoint,
} from "src/routes/cma/endpoints/reports";
import { StepperActions } from "src/routes/cma/stepper";
import { useStepperContext } from "src/routes/cma/stepper/StepperContext";
import { CompFilterPolygonGeometry } from "src/routes/cma/steps/comparables/ComparablesStep";
import { MapBox } from "src/routes/cma/steps/comparables/MapBox";
import { areEquivalentArrays } from "src/utils";
import { reportIsReadOnly } from "src/utils/reports";
import { CompFilter, runSelectedCompFilter, useCompFilter } from "./CompFilter";
import { FormState, formConfig, mapFormToSaveReport, mapToForm } from "./CompForm";
import { CompsMapPolygonPane } from "./CompsMapPolygonPane";
import { PropertyCompTable, Row, compTableColumns } from "./PropertyCompTable";
import { AddCompModalContent } from "./add/AddCompModalContent";

interface MapWithCompsProps {
  property: Property;
  report: UnderwritingReport;
  readyPlan: ReadyPlan;
  comps: PropertyComp[];
  compFilter: CompFilter;
  setCompFilter: (filter: CompFilter) => void;
  // Maintains correct cache key to keep table updated when add comp modal regenerates comps
  compFilterPolygon?: CompFilterPolygonGeometry | undefined;
  children?: ReactNode;
  overlayPolygons: SavePolygonInput[];
}

export function MapWithComps({
  property,
  readyPlan,
  report,
  comps,
  compFilter,
  setCompFilter,
  compFilterPolygon,
  children,
  overlayPolygons,
}: MapWithCompsProps) {
  const { goToNextStep, disableCurrentStep } = useStepperContext();
  const { fetch, invalidate } = useController();
  const { openModal } = useModal();
  const tableApi = useGridTableApi<Row>();

  const [enableSaveSelections, setEnableSaveSelections] = useState(false);
  // selected filter is client side
  const [selectedFilter, setSelectedFilter] = useState<string>("all");
  // selected comp opens the property comp card and highlights the comp row in the table
  const [selectedComp, setSelectedComp] = useState<string | undefined>(undefined);

  const isFinalized = report.status === "Finalized";

  const initialCompDpids = useMemo(() => comps.filter((c) => !!c.id).map((c) => c.dpid_of_neighbor), [comps]);
  const tableComps = useMemo(() => {
    if (isFinalized) {
      // only the selected comps are saved on a ready plan
      return readyPlan.comps ?? [];
    } else {
      return comps.map((comp) => {
        // We need to update the id's of the comps to match the selected rp comps since we don't regenerate comps
        // when selecting and unselecting comps
        // this is so the "Show Selected" filter works properly
        const matchingSelectedComp = readyPlan.comps?.find((c) => c.dpid_of_neighbor === comp.dpid_of_neighbor);
        if (matchingSelectedComp) {
          comp.id = matchingSelectedComp.id;
        } else {
          // don't remove the id if it's a manual comp
          if (comp.comp_type !== "manual") {
            comp.id = undefined;
          }
        }
        return comp;
      });
    }
  }, [isFinalized, readyPlan.comps, comps]);

  const formState = useFormState({
    config: formConfig,
    init: {
      input: { report, readyPlan, comps: tableComps },
      map: mapToForm,
    },
    readOnly: reportIsReadOnly(report),
  });

  // This is the list of comps that will be shown in the table
  const feFilteredComps = useMemo(
    () => runSelectedCompFilter(tableComps, selectedFilter),
    [selectedFilter, tableComps],
  );

  // serverSide filter definition
  const ssFilterDefs = useCompFilter();

  async function saveReport() {
    const reportInput = mapFormToSaveReport(formState);
    await Promise.all([
      disableCurrentStep({ disabled: true }),
      // Invalidate any previous MlSaleConfidence requests to ensure its recalculated when loading est page
      invalidate(UnderwritingReportEndpoint, {
        dpid: report.dpid,
        versionId: report.id!.toString(),
        shouldGenerateMlSaleConfidence: true,
      }),
      fetch(SaveUnderwritingReportEndpoint, { report: reportInput, versionId: report.id }),
    ]).then(() => disableCurrentStep({ disabled: false }));
  }

  async function saveAndContinue() {
    await saveReport();
    goToNextStep();
  }

  // disable other steps if form has errors
  useReaction(
    () => formState.valid,
    (valid) => {
      disableCurrentStep({ disabled: !valid });
    },
    { equals: comparer.shallow },
    [formState],
  );

  // Inform user they have unsaved changes
  useReaction(
    () => formState.comps.rows.filter((c) => c.user_selected_comp.value).map((c) => c.value.dpid_of_neighbor),
    (formSelectedDpids) => {
      setEnableSaveSelections(!areEquivalentArrays(initialCompDpids, formSelectedDpids));
    },
    { equals: comparer.shallow },
    [formState],
  );

  function renderView() {
    return (
      <>
        <div css={Css.df.gap2.$}>
          <div data-testid="mapBox" css={Css.w100.$}>
            <MapBox
              property={property}
              comps={feFilteredComps}
              formState={formState}
              onCompSelected={setSelectedComp}
              selectedComp={selectedComp}
              children={children}
            />
          </div>
          <CompsMapPolygonPane overlayPolygons={overlayPolygons} />
        </div>
        <div css={Css.df.gap1.mt2.$}>
          <Button
            variant="primary"
            label="Add a Comp"
            onClick={() => {
              openModal({
                content: (
                  <AddCompModalContent
                    versionId={report.id!.toString()}
                    dpid={report.dpid}
                    subjectProperty={property}
                    compDpids={tableComps.map((c) => c.dpid_of_neighbor)}
                    readyPlan={readyPlan}
                    compFilter={compFilter}
                    compFilterPolygon={compFilterPolygon}
                  />
                ),
              });
            }}
            disabled={formState.readOnly}
          />
          <Button
            variant="tertiaryDanger"
            label="Deselect all comps"
            onClick={() => formState.comps.rows.forEach((comp) => comp.user_selected_comp.set(false))}
            disabled={formState.readOnly}
          />
        </div>
        <div css={Css.df.jcsb.py2.$}>
          <div css={Css.df.gap1.$} data-testid="filters">
            <SelectField
              label="Show"
              options={[
                { id: "all", name: "All" },
                { id: "selectedOnly", name: "Selected Only" },
              ]}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
              onSelect={(value) => setSelectedFilter(value ?? "all")}
              value={selectedFilter}
              labelStyle="inline"
              compact
              sizeToContent
              data-testid="showFilter"
            />
            <Filters<CompFilter>
              filter={compFilter}
              filterDefs={ssFilterDefs}
              onChange={setCompFilter}
              numberOfInlineFilters={1}
            />
          </div>
          <EditColumnsButton
            api={tableApi}
            trigger={{ label: "Set Columns" }}
            columns={compTableColumns}
            title="Select columns to show"
            placement="right"
          />
        </div>
        <PropertyCompTable
          tableApi={tableApi}
          formState={formState}
          comps={feFilteredComps}
          selectedComp={selectedComp}
          onCompSelected={setSelectedComp}
        />
        <StepperActions>
          <>
            <span
              css={
                Css.addIn(
                  "> button",
                  Css.bn.fw6
                    .add("transition", "0.1s")
                    .if(!(!formState.valid || formState.readOnly || !enableSaveSelections)).bshBasic.white.bgGreen500.$,
                ).$
              }
            >
              <Button
                variant="secondary"
                label="Save Changes"
                onClick={saveReport}
                disabled={!formState.valid || formState.readOnly || !enableSaveSelections}
              />
            </span>
            <Button
              label="Save &amp; Continue"
              onClick={saveAndContinue}
              disabled={formState.valid ? false : <FormValidationMessage formState={formState} />}
            />
          </>
        </StepperActions>
      </>
    );
  }
  return <Observer>{() => renderView()}</Observer>;
}

function FormValidationMessage({ formState }: { formState: FormState }) {
  return <span>{formState.comps.errors}</span>;
}
