import { Css, Icon, SelectField, useModal } from "@homebound/beam";
import { AddManualAdjustmentModal } from "src/routes/cma/steps/adjustments/AddManualAdjustmentModal";
import { Maybe } from "src/utils";

interface AddCommonAdjustmentSelectFieldProps {
  addManualAdjustment: (description: Maybe<string>) => Promise<void>;
  addCommonAdjustmentRow: (adjAttribute: string, remove?: boolean) => void;
  currentAddedAdjustments: string[];
  disabled: boolean;
  borderless?: boolean;
}

const optionalAdjOptions = [
  { id: "manual", name: "New Manual Adjustment", tooltip: "Create a new Manual Adjustment Row" },
  { id: "diff_lot_quality", name: "Lot Type/Quality", tooltip: "E.g., cul-de-sac, corner lot, interior lot" },
  { id: "close_to_commercial", name: "Near Commercial" },
  { id: "close_to_major_road", name: "Near Major Road" },
  { id: "close_to_powerlines", name: "Near Powerlines" },
  { id: "close_to_school", name: "Near School" },
  {
    id: "diff_has_scenic_view",
    name: "Scenic View",
    tooltip: "E.g., water view, park view, golf course view, mountain view",
  },
];

export function AddCommonAdjustmentSelectField({
  addManualAdjustment,
  disabled,
  addCommonAdjustmentRow,
  currentAddedAdjustments,
}: AddCommonAdjustmentSelectFieldProps) {
  const { openModal } = useModal();

  return (
    <SelectField<{ id: string; name: string; tooltip?: string }, string | undefined>
      placeholder="Add Adjustment"
      label="Add Adjustment"
      options={optionalAdjOptions.filter((o) => !currentAddedAdjustments.includes(o.id))}
      value={undefined}
      disabled={disabled}
      getOptionMenuLabel={(o) => (
        // FIXME: Tooltip is cool but it makes the click interactions buggy
        // <Tooltip title={o.tooltip} disabled={!o.tooltip}>
        <div css={Css.fg1.df.aic.if(o.id === "manual").fw5.$}>
          {o.id === "manual" && (
            <span css={Css.fs0.mr1.$}>
              <Icon inc={2} icon="pencil" />
            </span>
          )}
          {o.name}
        </div>
        // </Tooltip>
      )}
      onSelect={(option) => {
        if (option === "manual") {
          openModal({
            content: <AddManualAdjustmentModal onAdd={addManualAdjustment} />,
          });
        } else {
          addCommonAdjustmentRow(option!);
        }
      }}
      // styling
      compact
      getOptionLabel={(o) => o.name}
      getOptionValue={(o) => o.id}
      borderless={false}
      labelStyle="hidden"
    />
  );
}
