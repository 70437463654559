import { Palette } from "@homebound/beam";
import { Endpoint, Entity } from "@rest-hooks/rest";
import { Polygon } from "geojson";
import { api_url, auth_headers } from "src/context";
import { Maybe } from "src/utils";

type UwPolygonFeatureCollectionProperties = {
  name: string;
  metro: string;
  active: boolean;
};

export enum UWPolygonType {
  dev_area = "dev_area",
  neighborhood = "neighborhood",
  zoning = "zoning",
  flood_zone = "flood_zone",
  school_district = "school_district",
  school_zone = "school_zone",
  zip_code = "zip_code",
  subdivision = "subdivision",
  city = "city",
  county = "county",
}

export class UwPolygonFeatureCollection extends Entity {
  readonly id: number = 0;
  readonly type: "FeatureCollection" = "FeatureCollection";
  readonly features: UwPolygonFeature[] = [];
  readonly properties: UwPolygonFeatureCollectionProperties = { name: "", metro: "", active: true };

  pk() {
    return `${this.id}`;
  }

  static key = "UwPolygonFeatureCollection";
}

type UwPolygonProperties = {
  type: UWPolygonType;
  group_id: number;
  name: string;
  color: Maybe<Palette>;
  active: boolean;
};

export const defaultPolygonName = "Untitled Polygon";

export class UwPolygonFeature extends Entity {
  readonly id: number = 0;
  readonly type: "Feature" = "Feature";
  readonly properties: UwPolygonProperties = {
    name: defaultPolygonName,
    active: true,
    type: UWPolygonType.neighborhood,
    color: null,
    group_id: 0,
  };
  readonly geometry: Polygon = { type: "Polygon", coordinates: [] };

  pk() {
    return `${this.id}`;
  }

  static key = "UwPolygonFeature";
}

export type PolygonFeatureGeometry = {
  geometry: Maybe<Polygon>;
};

/**
 * Get a list of all polygon collections
 */
export const PolygonListEndpoint = new Endpoint(
  async () => {
    const headers = await auth_headers();
    const res = await fetch(api_url(`/v1/polygons`), { headers });

    return await res.json();
  },
  {
    schema: { feature_collections: [UwPolygonFeatureCollection] },
    key: () => {
      return `/v1/polygons`;
    },
    name: "PolygonListEndpoint",
  },
);

/**
 * Get a polygon collection by id
 */
export const PolygonGroupEndpoint = new Endpoint(
  async ({ polygonCollectionId }: { polygonCollectionId: string }) => {
    const headers = await auth_headers();
    const res = await fetch(api_url(`/v1/polygons/${polygonCollectionId}`), { headers });

    return await res.json();
  },
  {
    schema: { feature_collection: UwPolygonFeatureCollection },
    key: ({ polygonCollectionId }) => {
      return `/v1/polygons/${polygonCollectionId}`;
    },
    name: "PolygonGroupEndpoint",
  },
);

export type SavePolygonGroupInput = {
  id?: number;
  active: Maybe<boolean>;
  name: Maybe<string>;
  metro: Maybe<string>;
  polygons: SavePolygonInput[];
};

export type SavePolygonInput = {
  id?: number;
  active: Maybe<boolean>;
  name: Maybe<string>;
  type: Maybe<UWPolygonType>;
  color: Maybe<Palette>;
  geometry: Polygon;
};

/**
 * Create a new polygon group
 */
export const CreatePolygonGroupEndpoint = new Endpoint(
  async (polygon_group: SavePolygonGroupInput) => {
    const headers = {
      "content-type": "application/json",
      ...(await auth_headers()),
    };

    const res = await fetch(api_url(`/v1/polygons`), {
      headers,
      method: "POST",
      body: JSON.stringify(polygon_group),
    });

    return await res.json();
  },
  {
    schema: { feature_collection: UwPolygonFeature },
    key: () => `/v1/polygons`,
    name: "CreatePolygonGroupEndpoint",
  },
);

/**
 * Update an existing polygon group
 */
export const UpdatePolygonGroupEndpoint = new Endpoint(
  async (polygon_group: SavePolygonGroupInput) => {
    const headers = {
      "content-type": "application/json",
      ...(await auth_headers()),
    };

    const res = await fetch(api_url(`/v1/polygons/${polygon_group.id}`), {
      headers,
      method: "PUT",
      body: JSON.stringify(polygon_group),
    });

    return await res.json();
  },
  {
    schema: { feature_collection: UwPolygonFeature },
    key: ({ id }) => {
      return `/v1/polygons/${id}`;
    },
    name: "UpdatePolygonGroupEndpoint",
  },
);
