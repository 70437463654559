import { Css } from "@homebound/beam";
import { Property, PropertyComp } from "../../endpoints";
import { mdashUnicode } from "src/components/mdash";

interface PropertyAddressHeaderProps {
  property: Property | PropertyComp;
  size?: "sm" | "md" | "lg";
}

const addressCss = {
  sm: Css.baseSb.$,
  md: Css.xlSb.$,
  lg: Css.xl2Sb.$,
};

const cityCss = {
  sm: Css.sm.$,
  md: Css.base.$,
  lg: Css.lg.$,
};

const developmentCss = {
  sm: Css.tiny.$,
  md: Css.tiny.$,
  lg: Css.xs.$,
};

export function PropertyAddressHeader({ property, size = "md" }: PropertyAddressHeaderProps) {
  const { full_street_address, unit_number, city_name, state, zip_code } = property;

  return (
    <div css={Css.ttc.$}>
      <div css={addressCss[size]}>{full_street_address.toLowerCase()}</div>
      <div css={addressCss[size]}>{unit_number ? `Unit ${unit_number.toLowerCase()}` : undefined}</div>
      <div css={cityCss[size]}>
        {city_name.toLowerCase()}, {state} {zip_code}
      </div>
      {property instanceof Property && (
        <div css={developmentCss[size]}>
          Dev Area: {property?.polygon_nearest_in_rec_geo_name ?? mdashUnicode} | Neighborhood:{" "}
          {property?.polygon_neighborhood_name ?? mdashUnicode}
        </div>
      )}
    </div>
  );
}
