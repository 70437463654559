import {
  column,
  condensedStyle,
  Css,
  dateColumn,
  GridColumn,
  GridDataRow,
  GridStyle,
  GridTable,
  Icon,
  numericColumn,
  Palette,
  RowStyles,
} from "@homebound/beam";
import { Observer } from "mobx-react";
import { useMemo } from "react";
import { mdash } from "src/components/mdash";
import { UWLabel } from "src/components/UWLabel";
import { parseCompBuildDate } from "src/routes/cma/steps/readyPlanUtils";
import { houseSvg, mapMarkerImage } from "src/routes/maps/mapIcons";
import { formatNumberToString, FORMAT_BASIC_DATE, hpoTooltips } from "src/utils";
import { getReadyPlanDisplayName } from "src/utils/mappers";
import {
  addressCell,
  calcFinalPrice,
  calcTotalAdjustments,
  isActOrPnd,
  numberCell,
  percentageCell,
  priceCell,
  uwLabelPriceCell,
} from "src/utils/tableUtils";
import { Property } from "../../endpoints";
import { AmenityAdjustment, UsedAdjustmentTypes } from "../../endpoints/adj/AmenityAdjustmentEndpoint";
import { PropertyComp } from "../../endpoints/PropertyCompEndpoint";
import { ReadyPlan } from "../../endpoints/reports";
import {
  AMENITY_ADJUSTMENT_TO_NAME_MAPPER,
  calcAnnualizedAppreciationRate,
  calcOtherAdjustments,
  calcTotalOtherAdjustments,
  getCalculatedWeightedAvg,
  getCompWeightedAvg,
  getManualWeightedNum,
  getNumColsToShow,
  getSortedAmenityAdjustments,
  getSortedManualAdjustments,
  ManualAdjustmentSummaryProps,
} from "./HpoCompTableCalcs";
import { statusToColor } from "./StaticMap";

export interface HpoCompTableProps {
  comps: PropertyComp[];
  readyPlan: ReadyPlan;
  property: Property;
}

export function HpoCompTable({ comps, readyPlan, property }: HpoCompTableProps) {
  const style: GridStyle = useMemo(
    () => ({
      ...condensedStyle,
      activeBgColor: Palette.Blue100,
      emptyCell: <></>,
    }),
    [],
  );

  const rowStyles: RowStyles<Row> = {
    section: { cellCss: Css.bgGray100.smBd.jcc.$ },
    header2: { cellCss: Css.xsBd.jcc.$ },
    total: { cellCss: Css.bgGray100.xsBd.myPx(4).$ },
    subject: { cellCss: Css.bgBlue50.xsBd.myPx(4).$ },
  };

  const columns = useMemo(() => createColumns({ comps }), [comps]);
  const rows = useMemo(() => createRows(comps, readyPlan, property), [comps, readyPlan, property]);

  function renderTable() {
    return (
      <GridTable
        columns={columns}
        rows={rows}
        fallbackMessage="No comps found for this property."
        style={style}
        rowStyles={rowStyles}
      />
    );
  }

  return <Observer>{() => renderTable()}</Observer>;
}

// non_adj means it's a regular property, not an adjustment
export type AdjType = keyof AmenityAdjustment | "total_adj" | "final_adj_psf" | "final_val" | "non_adj";

type SectionRow = { kind: "section" };
// calling this header2 so it can go below the section row
type HeaderRow = { kind: "header2" };
type DataRow = { kind: "data"; data: { comp: PropertyComp } };
type SubjectRow = { kind: "subject"; data: { readyPlan: ReadyPlan; property: Property } };
type TotalRow = {
  kind: "total";
  data: {
    id: "weighted";
    name: string;
    // property and adjustments calculations
    getCompAvg: (comps: PropertyComp[], prop: keyof PropertyComp, adj: AdjType, useMLWeight?: boolean) => number;
    // manual adjustment calculations
    getManualAvg: (sortedManualAdjs: ManualAdjustmentSummaryProps[], desc: string, numComps: number) => number;
    // calculations that have additional logic
    getCalculatedAvg: (comps: PropertyComp[], type: string) => number;
  };
};
export type Row = SectionRow | HeaderRow | TotalRow | DataRow | SubjectRow;

function createRows(comps: PropertyComp[], readyPlan: ReadyPlan, property: Property): GridDataRow<Row>[] {
  return [
    { kind: "section", id: "section", data: undefined },
    { kind: "header2", id: "header2", data: undefined },
    ...(comps.map((c) => ({
      kind: "data" as const,
      id: c.dpid_of_neighbor,
      data: { comp: c },
    })) || []),
    {
      kind: "total",
      id: "Weighted Average",
      data: {
        id: "weighted",
        name: "Weighted Average",
        getCompAvg: (comps, prop, adj, useMLWeight = false) => getCompWeightedAvg(comps, prop, adj, useMLWeight),
        getManualAvg: (sortedManualAdjs, desc) => getManualWeightedNum(sortedManualAdjs, desc),
        getCalculatedAvg: (comps, type) => getCalculatedWeightedAvg(comps, type),
      },
    },
    {
      kind: "subject",
      id: "Subject",
      data: { readyPlan, property },
    },
  ];
}

// TODO: Gridtable has a built in tooltip we should leverage instead of UWLabel but we need to stop it from truncating the label and shrinking single cols
function createColumns({ comps }: { comps: PropertyComp[] }) {
  const adjustmentColumns = createAdjCols(comps);

  const propertyAttributeColumns = [
    column<Row>({
      id: "status-column",
      name: "Status",
      w: "40px",
      sticky: "left",
      section: () => ({ content: "", css: Css.bgGray100.$ }),
      header2: () => ({ content: "", css: Css.bgGreen100.$ }),
      data: ({ comp: { neighbor, is_hb_comp, auto_uw_rec } }) => {
        const color = statusToColor(neighbor?.mls_status);
        return is_hb_comp ? (
          houseSvg(color)
        ) : auto_uw_rec ? (
          // @ts-ignore: ignoring color type error
          <Icon icon="starFilled" color={color} />
        ) : (
          mapMarkerImage(color)
        );
      },
      total: "",
      subject: "",
    }),
    column<Row>({
      id: "address-column",
      name: "Address",
      w: "180px",
      sticky: "left",
      section: () => ({ content: "", css: Css.bgGray100.$ }),
      header2: () => ({ content: "Address", css: Css.bgGreen100.jcfs.$ }),
      data: ({ comp: { neighbor } }) => {
        return { ...addressCell(neighbor?.full_street_address, neighbor?.unit_number) };
      },
      total: (data) => data.name,
      subject: ({ readyPlan }) => ({ content: getReadyPlanDisplayName(readyPlan), css: Css.ttc.$ }),
    }),
    numericColumn<Row>({
      id: "sellable-sqft-column",
      name: "Sellable SF",
      w: "85px",
      section: () => ({
        content: "Property Attributes",
        colspan: 5,
        css: Css.bgGray100.br.bcGray200.$,
      }),
      header2: () => ({
        content: <UWLabel labelTypography="xsBd" label="Sellable SF" />,
        css: Css.bgGreen100.$,
      }),
      data: ({ comp: { neighbor } }) => numberCell(neighbor?.finished_sqft),
      total: (data) => numberCell(data.getCompAvg(comps, "finished_sqft", "non_adj")),
      subject: ({ readyPlan }) => numberCell(readyPlan.sellable_sqft),
    }),
    numericColumn<Row>({
      id: "sellable-above-ground-sqft-column",
      name: "Abv SF",
      w: "70px",
      section: () => ({
        content: "Property Attributes",
        colspan: 5,
        css: Css.bgGray100.br.bcGray200.$,
      }),
      header2: () => ({ content: <UWLabel labelTypography="xsBd" label="Abv SF" />, css: Css.bgGreen100.$ }),
      data: ({ comp: { neighbor } }) => numberCell(neighbor?.above_ground_sqft),
      total: (data) => numberCell(data.getCompAvg(comps, "above_ground_sqft", "non_adj")),
      subject: ({ readyPlan }) => numberCell(readyPlan.above_ground_sqft),
    }),
    numericColumn<Row>({
      id: "sellable-below-ground-sqft-column",
      name: "Below SF",
      w: "70px",
      section: () => ({
        content: "Property Attributes",
        colspan: 5,
        css: Css.bgGray100.br.bcGray200.$,
      }),
      header2: () => ({ content: <UWLabel labelTypography="xsBd" label="Blw SF" />, css: Css.bgGreen100.$ }),
      data: ({ comp: { neighbor } }) => numberCell(neighbor?.finished_basement_sqft),
      total: (data) => numberCell(data.getCompAvg(comps, "finished_basement_sqft", "non_adj")),
      subject: ({ readyPlan }) => numberCell(readyPlan.sellable_basement_sqft),
    }),
    numericColumn<Row>({
      id: "unfinished-below-ground-sqft-column",
      name: "UnFin. Blw SF",
      w: "70px",
      section: () => ({
        content: "Property Attributes",
        colspan: 5,
        css: Css.bgGray100.br.bcGray200.$,
      }),
      header2: () => ({ content: <UWLabel labelTypography="xsBd" label="UnFin. Blw SF" />, css: Css.bgGreen100.$ }),
      data: ({ comp: { neighbor } }) => numberCell(neighbor?.unfinished_basement_sqft),
      total: (data) => numberCell(data.getCompAvg(comps, "unfinished_basement_sqft", "non_adj")),
      subject: "",
    }),
    numericColumn<Row>({
      id: "lot-sqft-column",
      name: "Lot Size",
      w: "75px",
      section: "",
      header2: () => ({ content: "Lot Size", css: Css.bgGreen100.$ }),
      data: ({ comp: { neighbor } }) => numberCell(neighbor?.lot_size),
      total: (data) => numberCell(data.getCompAvg(comps, "lot_size", "non_adj")),
      subject: ({ readyPlan }) => numberCell(readyPlan.lot_size),
    }),
    numericColumn<Row>({
      id: "year-built-column",
      name: "Year Built",
      w: "80px",
      section: "",
      header2: () => ({ content: "Year Built", css: Css.bgGreen100.$ }),
      data: ({ comp: { neighbor } }) => parseCompBuildDate(neighbor?.year_built_or_renovated),
      total: (data) => Math.round(data.getCompAvg(comps, "year_built_or_renovated", "non_adj")),
      subject: "",
    }),
    numericColumn<Row>({
      id: "beds-column",
      name: "BR",
      w: "50px",
      section: "",
      header2: () => ({ content: "BR", css: Css.bgGreen100.$ }),
      data: ({ comp: { neighbor } }) => neighbor?.num_bedrooms?.toFixed(1),
      total: (data) => data.getCompAvg(comps, "num_bedrooms", "non_adj").toFixed(1),
      subject: ({ readyPlan }) => readyPlan.num_bedrooms.toFixed(1),
    }),
    numericColumn<Row>({
      id: "baths-column",
      name: "BA",
      w: "50px",
      section: "",
      header2: () => ({ content: "BA", css: Css.bgGreen100.br.bcGray200.$ }),
      data: ({ comp: { neighbor } }) => ({ content: neighbor?.num_baths?.toFixed(1), css: Css.br.bcGray200.$ }),
      total: (data) => ({
        content: data.getCompAvg(comps, "num_baths", "non_adj").toFixed(1),
        css: Css.br.bcGray200.$,
      }),
      subject: ({ readyPlan }) => ({ content: readyPlan.num_baths.toFixed(1), css: Css.br.bcGray200.$ }),
    }),
  ];

  const transactionDataColumns = [
    dateColumn<Row>({
      id: "sold-date-column",
      name: "Sold Date",
      w: "90px",
      section: () => ({
        content: "Transaction Data",
        css: Css.bgGray100.br.bcGray200.$,
        colspan: 4,
      }),
      header2: () => ({
        content: "Sold Date",
        css: Css.bgPurple100.$,
      }),
      data: ({ comp: { neighbor } }) => {
        if (isActOrPnd(neighbor) || !neighbor?.last_sold_date) return mdash;
        return {
          content: FORMAT_BASIC_DATE.format(new Date(neighbor.last_sold_date)),
          sortValue: new Date(neighbor.last_sold_date),
          alignment: "right",
        };
      },
      total: "",
      subject: "",
    }),
    numericColumn<Row>({
      id: "sold-price-column",
      name: "Sold Price",
      w: "100px",
      section: "",
      header2: () => ({ content: "Sold Price", css: Css.bgPurple100.br.bcGray200.$ }),
      data: ({ comp: { neighbor } }) => {
        if (isActOrPnd(neighbor) || !neighbor?.last_sold_price) return mdash;
        return priceCell(neighbor?.last_sold_price);
      },
      total: (data) => priceCell(data.getCalculatedAvg(comps, "lastSoldPrice")),
      subject: "",
    }),
    numericColumn<Row>({
      id: "list-price-column",
      name: "List Price",
      w: "100px",
      section: "",
      header2: () => ({ content: "List Price", css: Css.bgPurple100.br.bcGray200.$ }),
      data: ({ comp: { neighbor } }) => {
        if (!isActOrPnd(neighbor) || !neighbor?.mls_list_price) return mdash;
        return priceCell(neighbor?.mls_list_price);
      },
      total: (data) => priceCell(data.getCalculatedAvg(comps, "mlsListPrice")),
      subject: "",
    }),
    numericColumn<Row>({
      id: "price-psf-column",
      name: "$/SF",
      w: "65px",
      section: () => ({ content: "", css: Css.br.bcGray200.$ }),
      header2: () => ({
        content: <UWLabel labelTypography="xsBd" label="$/SF" tooltip={hpoTooltips.marketPPSqft} />,
        css: Css.bgPurple100.br.bcGray200.$,
      }),
      data: ({ comp: { neighbor } }) => {
        if (!neighbor || !neighbor.finished_sqft) return { content: mdash, css: Css.br.bcGray200.$ };
        // use sold price if sold or use list price if active or pending
        const ppsf = isActOrPnd(neighbor)
          ? neighbor.mls_list_price! / neighbor.finished_sqft!
          : neighbor.last_sold_price! / neighbor.finished_sqft!;
        return { ...priceCell(ppsf), css: Css.br.bcGray200.$ };
      },
      total: (data) => ({
        ...priceCell(data.getCalculatedAvg(comps, "soldOrListedPricePerSqft")),
        css: Css.br.bcGray200.$,
      }),
      subject: () => ({ content: "", css: Css.br.bcGray200.$ }),
    }),
  ];

  const marketValueColumns = [
    numericColumn<Row>({
      id: "hb-avm-column",
      name: "Homebound AVM",
      w: "130px",
      section: () => ({ content: "Estimated Market Value", css: Css.bgGray100.br.bcGray200.$, colspan: 4 }),
      header2: () => ({
        content: <UWLabel labelTypography="xsBd" label="Homebound AVM" tooltip={hpoTooltips.hbAvm} />,
        css: Css.bgYellow50.$,
      }),
      data: ({ comp: { neighbor: { homebound_avm } = {}, homebound_avm_low, homebound_avm_high } }) =>
        uwLabelPriceCell(homebound_avm, { low: homebound_avm_low, high: homebound_avm_high }),
      total: (data) => priceCell(data.getCompAvg(comps, "homebound_avm", "non_adj")),
      subject: "",
    }),
    numericColumn<Row>({
      id: "adj-value-column",
      name: "Adjusted Value",
      w: "130px",
      section: "",
      header2: () => ({
        content: <UWLabel labelTypography="xsBd" label="Sold Price Adjusted" tooltip={hpoTooltips.adjValue} />,
        css: Css.bgYellow50.$,
      }),
      data: ({ comp: { neighbor: { last_sold_price_adj } = {}, last_sold_price_adj_low, last_sold_price_adj_high } }) =>
        uwLabelPriceCell(last_sold_price_adj, { low: last_sold_price_adj_low, high: last_sold_price_adj_high }),
      total: (data) => priceCell(data.getCompAvg(comps, "last_sold_price_adj", "non_adj")),
      subject: "",
    }),
    numericColumn<Row>({
      id: "estimated-price-column",
      name: "Est. Current Value",
      w: "130px",
      section: "",
      header2: () => ({
        content: <UWLabel labelTypography="xsBd" label="Est. Current Value" tooltip={hpoTooltips.estCurValue} />,
        css: Css.bgYellow100.$,
      }),
      data: ({ comp: { neighbor } }) => priceCell(neighbor?.estimated_price),
      total: (data) => priceCell(data.getCalculatedAvg(comps, "estimatedCurrentValue")),
      subject: "",
    }),
    numericColumn<Row>({
      id: "appreciation-column",
      name: "Appreciation",
      w: "140px",
      section: () => ({ content: "", css: Css.br.bcGray200.$ }),
      header2: () => ({
        content: <UWLabel labelTypography="xsBd" label="Implied Appreciation" tooltip={hpoTooltips.appreciation} />,
        css: Css.bgYellow100.br.bcGray200.$,
      }),
      data: ({ comp: { neighbor } }) => {
        if (isActOrPnd(neighbor) || !neighbor?.last_sold_price || !neighbor.estimated_price)
          return { content: mdash, css: Css.br.bcGray200.$ };

        const annualizedAppreciation = calcAnnualizedAppreciationRate(neighbor);
        const annualizedAppreciationString = `${annualizedAppreciation}% annualized appreciation rate`;
        const appreciation = `$${formatNumberToString(
          neighbor.estimated_price - neighbor.last_sold_price,
          true,
          true,
        )}`;

        return {
          content: <UWLabel labelTypography="xs" label={appreciation} tooltip={annualizedAppreciationString} />,
          css: Css.br.bcGray200.$,
        };
      },
      total: (data) => ({
        ...priceCell(data.getCalculatedAvg(comps, "appreciation")),
        css: Css.br.bcGray200.$,
      }),
      subject: () => ({ content: "", css: Css.br.bcGray200.$ }),
    }),
  ];

  const adjCompValueColumns = [
    // Final Price
    numericColumn<Row>({
      id: "adj-val-column",
      name: "Adj Val",
      w: "100px",
      align: "center",
      section: () => ({ content: "Adjusted Comp Value", colspan: 4, css: Css.bgGray100.$ }),
      header2: () => ({
        content: <UWLabel labelTypography="xsBd" label="Adj Value" tooltip={hpoTooltips.adjCompValue} />,
        css: Css.bgRed100.$,
      }),
      data: ({ comp }) => priceCell(calcFinalPrice(comp)),
      total: () => "",
      subject: "",
    }),
    numericColumn<Row>({
      id: "weight-column",
      name: "Weight",
      w: "100px",
      align: "center",
      section: "",
      header2: () => ({
        content: <UWLabel labelTypography="xsBd" label="Weight" tooltip={hpoTooltips.finalWeight} />,
        css: Css.bgRed100.$,
      }),
      data: ({ comp }) => percentageCell(comp.weight),
      total: (data) => priceCell(data.getCompAvg(comps, "amenity_adjustment", "final_val")),
      subject: "",
    }),
    numericColumn<Row>({
      id: "ml-weight-column",
      name: "ML Weight",
      w: "100px",
      align: "center",
      section: "",
      header2: () => ({
        content: <UWLabel labelTypography="xsBd" label="ML Weight" tooltip={hpoTooltips.mlWeight} />,
        css: Css.bgRed50.$,
      }),
      data: ({ comp }) => percentageCell(comp.ml_weight),
      total: (data) => {
        const mlAverage = data.getCompAvg(comps, "amenity_adjustment", "final_val", true);
        return {
          ...priceCell(mlAverage),
          css: Css.xs.$, // This cell needs to be standard weight to differentiate it from it's neighbor..
        };
      },
      subject: "",
    }),
    column<Row>({
      id: "tier-column",
      name: "Tier",
      w: "50px",
      align: "center",
      section: "",
      header2: () => ({
        content: <UWLabel labelTypography="xsBd" label="Tier" tooltip={hpoTooltips.compTier} />,
        css: Css.bgRed50.$,
      }),
      data: ({ comp: { comp_tier } }) => ({ content: comp_tier.split(" ")[1], value: comp_tier }),
      total: "",
      subject: "",
    }),
  ];

  return [
    ...propertyAttributeColumns,
    ...transactionDataColumns,
    ...marketValueColumns,
    ...adjustmentColumns,
    ...adjCompValueColumns,
  ];
}

function createAdjCols(comps: PropertyComp[]): GridColumn<Row>[] {
  const usedAmenityAdjustments: UsedAdjustmentTypes[] = comps[0].amenity_adjustment?.used_adjustments ?? [];
  const sortedAmenityAdjs: [UsedAdjustmentTypes, number][] = getSortedAmenityAdjustments(usedAmenityAdjustments, comps);

  const manualAdjustments = comps.map((c) => c.manual_adjustments ?? []).filter((ma) => ma.length > 0);
  const sortedManualAdjs = getSortedManualAdjustments(manualAdjustments, comps);

  // The number of adjustments will vary
  // We want to show the adjustments with the highest values and put the rest in "Other" column
  const { numManualAdjsShown, numAmenAdjsShown, shouldShowOtherColumn } = getNumColsToShow(
    sortedAmenityAdjs.length,
    sortedManualAdjs.length,
  );

  const manualColumns = sortedManualAdjs.slice(0, numManualAdjsShown).map((adj: ManualAdjustmentSummaryProps, i) => {
    return numericColumn<Row>({
      id: `${adj.desc}-column`,
      name: adj.desc as string,
      w: numManualAdjsShown === 1 ? "140px" : "90px",
      section: () => {
        if (i === 0) {
          const singleAdj = numManualAdjsShown === 1;
          return {
            content: (
              <UWLabel
                labelTypography="smBd"
                label={singleAdj ? "Top Analyst Adj." : "Top Analyst Adjustments"}
                tooltip={singleAdj ? hpoTooltips.singleTopAnalystAdj : hpoTooltips.topAnalystAdjs}
              />
            ),
            colspan: numManualAdjsShown,
            css: Css.bgGray100.$,
          };
        }
      },
      header2: () => ({ content: adj.desc, css: Css.bgBlue200.ttc.$ }),
      data: ({ comp }) =>
        priceCell(comp.manual_adjustments?.find((ma) => ma.description === adj.desc)?.amount ?? 0, true),
      total: (data) => priceCell(data.getManualAvg(sortedManualAdjs, adj.desc, comps.length)),
      subject: "",
    });
  });

  const adjustmentColumns = sortedAmenityAdjs
    .slice(0, numAmenAdjsShown)
    .map((adj: [UsedAdjustmentTypes, number], i) => {
      return numericColumn<Row>({
        id: `${adj[0]}-column`,
        name: adj[0] as string,
        w: "110px",
        section: () => {
          if (i === 0) {
            return {
              content: <UWLabel labelTypography="smBd" label="Top ML Adjustments" tooltip={hpoTooltips.topMlAdjs} />,
              colspan: numAmenAdjsShown,
              css: Css.bgGray100.$,
            };
          }
        },
        header2: () => ({ content: AMENITY_ADJUSTMENT_TO_NAME_MAPPER[adj[0]], css: Css.bgBlue100.$ }),
        data: ({ comp }) => priceCell(comp.amenity_adjustment![adj[0]] * comp.neighbor?.estimated_price!, true),
        total: (data) => priceCell(data.getCompAvg(comps, "amenity_adjustment", adj[0])),
        subject: "",
      });
    });

  const otherManualAdj = sortedManualAdjs.slice(numManualAdjsShown);
  const otherAmenityAdj = sortedAmenityAdjs.slice(numAmenAdjsShown);
  const otherAdjustmentColumn = [
    numericColumn<Row>({
      id: "other-column",
      name: "Other",
      w: "90px",
      section: () => ({
        content: <UWLabel labelTypography="smBd" label="Other" tooltip={hpoTooltips.otherAdjs} />,
        css: Css.bgGray100.$,
      }),
      header2: () => ({
        content: "Other",
        css: Css.bgBlue300.$,
      }),
      data: ({ comp }) => priceCell(calcOtherAdjustments({ comp, otherAmenityAdj, otherManualAdj })),
      total: () => priceCell(calcTotalOtherAdjustments({ comps, otherAmenityAdj, otherManualAdj })),
      subject: "",
    }),
  ];

  const totalColumn = numericColumn<Row>({
    id: "total-adj-column",
    name: "Total Adj",
    w: "100px",
    section: () => ({
      content: <UWLabel labelTypography="smBd" label="Total Adj." tooltip={hpoTooltips.totalAdjs} />,
      css: Css.bgGray100.br.bcGray200.$,
    }),
    header2: () => ({ content: "Total", css: Css.bgBlue400.br.bcGray200.$ }),
    data: ({ comp }) => ({ ...priceCell(calcTotalAdjustments(comp)), css: Css.br.bcGray200.$ }),
    total: (data) => ({
      ...priceCell(data.getCompAvg(comps, "amenity_adjustment", "total_adj")),
      css: Css.br.bcGray200.$,
    }),
    subject: () => ({ content: "", css: Css.br.bcGray200.$ }),
  });

  return [...adjustmentColumns, ...manualColumns, ...(shouldShowOtherColumn ? otherAdjustmentColumn : []), totalColumn];
}
