import { ReactElement, ReactNode } from "react";
import { formatNumberToString, Maybe } from "src/utils";
import { mdashUnicode } from "src/components/mdash";
import { Css, Icon, Palette, Tooltip } from "@homebound/beam";

type EstStatType = "money" | "percentage" | "number" | "dollars";

interface RangeLineProps {
  high: number;
  low: number;
  type: EstStatType;
  label?: ReactNode;
}

interface EstStatProps {
  title: string;
  value: Maybe<number | string | ReactElement>;
  type?: EstStatType;
  fractional?: boolean;
  errorMsg?: string;
  testId?: string;
  rangeLine?: RangeLineProps;
  bold?: boolean;
  children?: ReactNode;
  // Adds icon to emphasize the field should be reviewed
  highlight?: boolean;
}

// TODO: clarify if this is pretty enough with product
const HIGHLIGHT_TEST_ICON = "checklistComplete"; // checklistComplete | abacus | starFilled
export const HIGHLIGHT_TEST_COLOR = Palette.Blue500;

function formatStat(value: string | Maybe<number>, type?: EstStatType, fractional?: boolean) {
  if (typeof value === "string") {
    return value;
  } else if (typeof value !== "number") {
    return mdashUnicode;
  }

  const trim = fractional !== true;

  if (type === "money") {
    return "$" + formatNumberToString(value, trim, trim);
  }

  if (type === "percentage") {
    return formatNumberToString(value * 100) + "%";
  }

  return formatNumberToString(value, trim, trim);
}

export function EstSingleChildStat({ title, value, type, fractional, testId, highlight = false }: EstStatProps) {
  const valueIsNode = typeof value === "object";

  return (
    <div css={Css.df.base.aic.gray700.gapPx(4).$} data-testid={testId}>
      {highlight && <FieldHighlight />}
      <div css={Css.$}>{title}</div>
      <div css={Css.$}>{valueIsNode ? value : formatStat(value, type, fractional)}</div>
    </div>
  );
}

export function EstSingleStat({
  title,
  value,
  type,
  fractional,
  errorMsg,
  testId,
  bold = false,
  highlight = false,
}: EstStatProps) {
  const valueIsNode = typeof value === "object";

  return (
    <div css={Css.df.fdc.gapPx(2).$} data-testid={testId}>
      <div css={Css.df.jcsb.aic.base.$}>
        <div css={Css.df.fw7.aic.$}>
          {highlight && <FieldHighlight />}
          {title}
        </div>
        {valueIsNode ? value : <div css={Css.if(bold).fw7.$}>{formatStat(value, type, fractional)}</div>}
      </div>
      {errorMsg && (
        <div css={Css.red600.ml2.sm.df.$}>
          <span css={Css.fs0.$}>
            <Icon icon="error" />
          </span>
          <span css={Css.ml1.mtPx(2).$}>{errorMsg}</span>
        </div>
      )}
    </div>
  );
}

export function EstStatRange({ high, low, type, label = "Range: " }: RangeLineProps) {
  return (
    <span css={Css.sm.gray700.$}>
      {label}
      {formatStat(low, type, false)}&nbsp;-&nbsp;{formatStat(high, type, false)}
    </span>
  );
}

export function EstStatContainer({
  title,
  value,
  type,
  fractional,
  testId,
  children,
  bold = false,
  highlight = false,
}: EstStatProps) {
  const valueIsNode = typeof value === "object";
  return (
    <div css={Css.df.fdc.$} data-testid={testId}>
      <div css={Css.df.jcsb.aic.base.$}>
        <div css={Css.df.fw7.aic.$}>
          {highlight && <FieldHighlight />}
          {title}
        </div>
        <div css={Css.if(bold).fw7.$}>{valueIsNode ? value : formatStat(value, type, fractional)}</div>
      </div>
      {children && <div css={Css.ml2.df.fdc.gapPx(4).$}>{children}</div>}
    </div>
  );
}

function FieldHighlight() {
  return (
    <Tooltip title="Please review accuracy prior to finalizing report">
      <div css={Css.br.bw2.bc(HIGHLIGHT_TEST_COLOR).prPx(4).mrPx(4).$}>
        <Icon inc={3.5} icon={HIGHLIGHT_TEST_ICON} color={HIGHLIGHT_TEST_COLOR} />
      </div>
    </Tooltip>
  );
}
