import {
  BoundSelectField,
  BoundTextField,
  Button,
  FormLines,
  ModalFooter,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useCallback } from "react";
import { useController } from "@rest-hooks/react";
import { Property, PropertySummary } from "src/routes/cma/endpoints";
import { ReadyPlan } from "src/routes/cma/endpoints/reports";
import { GenerateCompsEndpoint } from "src/routes/cma/endpoints/reports/GenerateCompsEndpoint";
import { propertyTypeOptions } from "src/utils/mappers";
import { Maybe } from "src/utils/types";
import { CompFilter } from "../CompFilter";
import { SaveCompPropertyEndpoint } from "./SaveCompPropertyEndpoint";
import { CompFilterPolygonGeometry } from "src/routes/cma/steps/comparables/ComparablesStep";

interface AddCompPropertyDetailsFormProps {
  subjectProperty: Property;
  newComp: PropertySummary | undefined;
  compDpids: string[];
  versionId: string;
  dpid: string;
  readyPlan: ReadyPlan;
  compFilter: CompFilter;
  compFilterPolygon?: CompFilterPolygonGeometry | undefined;
}

export function AddCompPropertyDetailsForm({
  subjectProperty,
  newComp,
  compDpids,
  versionId,
  dpid,
  readyPlan,
  compFilter,
  compFilterPolygon,
}: AddCompPropertyDetailsFormProps) {
  const { closeModal } = useModal();
  const { fetch } = useController();
  const { triggerNotice } = useSnackbar();

  const formState = useFormState({
    config: formConfig,
    init: {
      input: { newComp, subjectProperty },
      map: mapToForm,
    },
  });

  const handleSave = useCallback(async () => {
    const comp = formState.value;
    const compDpid = comp.dpid_of_neighbor;
    const compAddress = comp.full_street_address;

    if (compDpid && compDpids.includes(compDpid)) {
      triggerNotice({ icon: "info", message: `Comp ${compAddress} is already in the table` });
    } else {
      // add new comp
      const response = await fetch(SaveCompPropertyEndpoint, {
        dpid,
        versionId,
        comp,
        uwReadyPlanId: readyPlan.id,
      });

      if (response.Code === "InternalServerError") {
        triggerNotice({ icon: "error", message: `Comp ${compAddress} was unable to be added` });
      } else {
        // fetch to add comp to comp table
        await fetch(GenerateCompsEndpoint, {
          dpid,
          underwriting_report_ready_plan_id: readyPlan.id,
          filter: compFilter,
          polygonFeature: compFilterPolygon,
        });
        triggerNotice({ icon: "success", message: `Added comp ${compAddress}` });
      }
    }
    closeModal();
  }, [
    formState,
    compDpids,
    closeModal,
    fetch,
    triggerNotice,
    readyPlan,
    dpid,
    versionId,
    compFilter,
    compFilterPolygon,
  ]);

  function renderForm() {
    return (
      <>
        <FormLines>
          <BoundTextField field={formState.full_street_address} />
          <BoundTextField field={formState.city_name} />
          <BoundTextField field={formState.state} />
          <BoundTextField field={formState.zip_code} />
          <BoundSelectField
            label="Property Type"
            field={formState.property_type_simple}
            options={propertyTypeOptions}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
          />
        </FormLines>
        <ModalFooter>
          <Button label="Cancel" variant="tertiary" onClick={closeModal} />
          <Button label="Add" onClick={handleSave} disabled={!formState.valid} />
        </ModalFooter>
      </>
    );
  }

  return <Observer>{() => renderForm()}</Observer>;
}

export interface SaveCompPropertyDetailsInput {
  dpid_of_neighbor: Maybe<string>;
  full_street_address: Maybe<string>;
  city_name: Maybe<string>;
  state: Maybe<string>;
  zip_code: Maybe<string>;
  property_type_simple: Maybe<string>;
  latitude: Maybe<number>;
  longitude: Maybe<number>;
}

export type FormInput = SaveCompPropertyDetailsInput;
export type FormState = ObjectState<FormInput>;

export const formConfig: ObjectConfig<FormInput> = {
  dpid_of_neighbor: { type: "value" },
  full_street_address: { type: "value", rules: [required] },
  city_name: { type: "value", rules: [required] },
  state: { type: "value", rules: [required] },
  zip_code: { type: "value", rules: [required] },
  property_type_simple: { type: "value" },
  latitude: { type: "value" },
  longitude: { type: "value" },
};

interface MapToFormInput {
  subjectProperty: Property;
  newComp: PropertySummary | undefined;
}

export function mapToForm({ subjectProperty, newComp: p }: MapToFormInput): FormInput {
  return {
    dpid_of_neighbor: p?.dpid,
    full_street_address: p?.full_street_address,
    city_name: p?.city_name ?? subjectProperty.city_name.toUpperCase(),
    state: p?.state ?? subjectProperty.state,
    zip_code: p?.zip_code ?? subjectProperty.zip_code,
    property_type_simple: p?.property_type_simple,
    latitude: p?.latitude,
    longitude: p?.longitude,
  };
}
