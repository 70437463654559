import { BoundCheckboxField, BoundNumberField, BoundSelectField, BoundTextField, Css } from "@homebound/beam";
import { FieldState, ObjectState } from "@homebound/form-state";
import { capitalCase } from "change-case";
import { runInAction } from "mobx";
import { FormattedPrice } from "src/components/FormattedPrice";
import { UWLabel } from "src/components/UWLabel";
import { Maybe } from "src/utils";
import { formatNum } from "src/utils/tableUtils";
import { AmenityAdjustment, POSSIBLE_USED_MARKET_AMENITIES } from "../../endpoints/adj/AmenityAdjustmentEndpoint";
import {
  ReadyPlan,
  SaveCompPropertyInput,
  SaveManualCompAdjustmentInput,
  SaveManualPropertyAdjustmentInput,
  SaveUnderwritingReportInput,
  UpdateSubjectPropertyAdjMetadata,
} from "../../endpoints/reports";
import { PROPERTY_SPEC_LEVELS } from "../readyPlanUtils";
import { AdjustmentFormState } from "./AdjustmentTableForm";

/*
 * Table Calculations
 */

export function zeroWeights(formState: AdjustmentFormState) {
  runInAction(() => {
    formState.comp_properties.rows.forEach((row) => {
      row.weight.set(0);
    });
  });
}

/*
 * Table Components
 */

interface AdjustedFormPropertyValProps {
  price: Maybe<number>;
  children: React.ReactNode;
}

export function AdjustedFormPropertyVal({ price, children }: AdjustedFormPropertyValProps) {
  return (
    <div css={Css.w100.df.jcsb.aic.$}>
      <div css={Css.pr2.$}>
        <FormattedPrice price={price} colorCoded />
      </div>
      <div>{children}</div>
    </div>
  );
}

type FormTextField = FieldState<string | null | undefined>;
type FormSelectField = FieldState<string | null | undefined>;
type FormNumberField = FieldState<number | null | undefined>;
type FormCheckboxField = FieldState<boolean | null | undefined>;

interface BoundAdjustedFields<X> {
  field: X;
  amount: Maybe<number>;
  numFractionDigits?: number;
}

interface BoundSelectAdjFieldProps extends BoundAdjustedFields<FormSelectField> {
  options?: string[];
}

export function AdjBoundSelectField({
  field,
  amount,
  options = ["above_average", "average", "below_average"], // We only expect spec_level to use diff options
}: BoundSelectAdjFieldProps) {
  return (
    <AdjustedFormPropertyVal price={amount}>
      <BoundSelectField
        options={options.map((o) => ({ id: o, name: o }))}
        getOptionLabel={(o) => {
          // TODO: We can upgrade change-case for more options but it'll require more updates to the codebase to support
          //   Pure ESM packages. Specifically our test setup.
          //   https://github.com/blakeembrey/change-case/releases/tag/change-case%405.0.0
          //   https://gist.github.com/sindresorhus/a39789f98801d908bbc7ff3ecc99d99c
          // return capitalCase(o.name, { suffixCharacters: "+" });
          const plusSuffix = o.name.includes("+") ? "+" : "";
          return capitalCase(o.name.replace("_", " ")) + plusSuffix;
        }}
        getOptionValue={(o) => o.id}
        borderless={false}
        field={field}
      />
    </AdjustedFormPropertyVal>
  );
}

export function AdjBoundTextField({ field, amount }: BoundAdjustedFields<FormTextField>) {
  return (
    <AdjustedFormPropertyVal price={amount}>
      <BoundTextField borderless={false} field={field} xss={Css.tar.$} />
    </AdjustedFormPropertyVal>
  );
}

// Extend extra options for sqft fields to interact with each other as needed
interface BoundNumberAdjFieldProps extends BoundAdjustedFields<FormNumberField> {
  readOnly?: boolean;
  onChange?: (value: number | null | undefined) => void;
}

export function AdjBoundNumberField({ field, amount, numFractionDigits = 0, ...others }: BoundNumberAdjFieldProps) {
  return (
    <AdjustedFormPropertyVal price={amount}>
      <BoundNumberField borderless={false} field={field} numFractionDigits={numFractionDigits} {...others} />
    </AdjustedFormPropertyVal>
  );
}

export function AdjBoundCheckboxField({ field, amount, ...others }: BoundAdjustedFields<FormCheckboxField>) {
  return (
    <AdjustedFormPropertyVal price={amount}>
      <BoundCheckboxField field={field} checkboxOnly />
    </AdjustedFormPropertyVal>
  );
}

/*
 * Table Mappers
 */

export function mapAllAdjustmentsToSaveReportInput(
  adjustmentsForm: AdjustmentFormState,
  readyPlan: ReadyPlan,
): SaveUnderwritingReportInput {
  const { dpid, comp_properties, ready_plan_id, ready_plan_sub_id, ready_plans } = adjustmentsForm.value;

  const { property_adjustments, bp_ready_plan_id, bp_ready_plan_name, id } = readyPlan;

  // we're assuming a single adjustment for now
  const savedAdj = property_adjustments && property_adjustments[0];

  const propertyAdjustment: SaveManualPropertyAdjustmentInput = {
    description: savedAdj?.description ?? "Property Percent Adjustment",
    value_type: savedAdj?.value_type ?? "percent",
    value: adjustmentsForm.subjectPropertyPercentAdj.value ?? 0,
    id: savedAdj?.id,
  };

  function formatManualComps(): SaveManualCompAdjustmentInput[] {
    function cleanId(id: Maybe<string>): Maybe<string> {
      // don't send the temporary/UI generated IDs to the backend
      if (id && id.startsWith("_new_")) {
        return undefined;
      }
      return id;
    }
    return adjustmentsForm.adjustments.value
      .filter((row) => !POSSIBLE_USED_MARKET_AMENITIES.includes(row.id))
      .flatMap((row) => {
        return row.adjustmentsByProperty
          .filter((pa) => pa.amount !== undefined)
          .map((pa) => {
            return {
              id: cleanId(pa.id),
              dpid_of_neighbor: pa.dpid,
              amount: pa.amount! / 100, // field is as cents
              description: row.description!,
            };
          });
      });
  }

  function formatComps() {
    return comp_properties.map((c) => {
      const { dpid_of_neighbor, weight, last_sold_date, ...p } = c;
      return {
        dpid_of_neighbor,
        weight,
        last_sold_date: (last_sold_date ? last_sold_date.toISOString().split("T")[0] : undefined) as any,
        ...p,
      };
    });
  }

  // Prune metadata to values only
  function pruneMetadata() {
    const metadata = adjustmentsForm.subject_property_metadata.value;
    let m: { [x: string]: boolean | string } = {};

    for (const [key, v] of Object.entries(metadata)) {
      if (v !== null && v !== undefined) {
        m[key] = v;
      }
    }

    return m;
  }

  return {
    dpid,
    subject_property_metadata: pruneMetadata(),
    ready_plans: [
      ...ready_plans,
      {
        id,
        ready_plan_id,
        ready_plan_sub_id,
        bp_ready_plan_id,
        bp_ready_plan_name,
        added_standard_adjustment_rows: adjustmentsForm.added_standard_adjustment_rows.value,
        comps: formatComps(),
        manual_comp_adjustments: formatManualComps(),
        property_adjustments: [propertyAdjustment],
      },
    ],
  };
}

// Helper for getting correct properties when building AdjustmentTable
// Forcing typescript here to accept that these fields (!) exist is fine because these rows will have
// already been used in our backend calculations.

// TODO: subject -> subjectField...
export const marketAmenityColumnRowMapper: {
  [x: string]: {
    subject: (r: ReadyPlan, sf: ObjectState<UpdateSubjectPropertyAdjMetadata>) => number | string | JSX.Element;
    field: (a: ObjectState<SaveCompPropertyInput>, b: Maybe<number>) => JSX.Element;
    rowDescription: string;
    adjustmentCoef: (amenityAdj?: AmenityAdjustment) => number;
    order: number;
  };
} = {
  diff_year_built_or_renovated: {
    rowDescription: "Year Built",
    subject: (readyPlan: ReadyPlan) => new Date().getFullYear(),
    field: (field, amount) => <AdjBoundTextField field={field!.year_built_or_renovated} amount={amount} />,
    adjustmentCoef: (adjustment) => adjustment!.year_built_or_renovated_adj,
    order: 0,
  },
  diff_num_stories: {
    rowDescription: "Stories",
    subject: (readyPlan: ReadyPlan) => readyPlan.num_stories,
    field: (field, amount) => <AdjBoundNumberField field={field!.num_stories} amount={amount} />,
    adjustmentCoef: (adjustment) => adjustment!.num_stories_adj,
    order: 1,
  },
  diff_num_bedrooms: {
    rowDescription: "Bedrooms",
    subject: (readyPlan: ReadyPlan) => readyPlan.num_bedrooms,
    field: (field, amount) => <AdjBoundNumberField field={field!.num_bedrooms} amount={amount} numFractionDigits={1} />,
    adjustmentCoef: (adjustment) => adjustment!.num_bedrooms_adj,
    order: 2,
  },
  diff_num_baths: {
    rowDescription: "Baths",
    subject: (readyPlan: ReadyPlan) =>
      readyPlan?.num_half_baths
        ? `Full: ${readyPlan.num_baths} Half: ${readyPlan.num_half_baths}`
        : `Total: ${readyPlan.num_baths}`,
    field: (field, amount) => <AdjBoundNumberField field={field!.num_baths} amount={amount} numFractionDigits={1} />,
    adjustmentCoef: (adjustment) => adjustment!.num_baths_adj,
    order: 3,
  },
  diff_garage_num_cars: {
    rowDescription: "Garage",
    subject: (readyPlan: ReadyPlan) => readyPlan.num_garage_attached,
    field: (field, amount) => <AdjBoundNumberField field={field!.garage_num_cars} amount={amount} />,
    adjustmentCoef: (adjustment) => adjustment!.garage_num_cars_adj,
    order: 4,
  },
  diff_lot_size: {
    rowDescription: "Lot Sqft.",
    subject: (readyPlan: ReadyPlan) => `${formatNum(readyPlan.lot_size)}`,
    field: (field, amount) => <AdjBoundNumberField field={field!.lot_size} amount={amount} />,
    adjustmentCoef: (adjustment) => adjustment!.lot_size_adj,
    order: 5,
  },
  // NOTE: We should NOT be adjusting for this since it is a combination of two other values.
  // It may still be a used adjustment on legacy reports finalized prior to sqft split, otherwise we set it here in our
  // form as a way to update the comp's property_attribute record
  diff_finished_sqft: {
    rowDescription: "Finished Sqft.",
    subject: (readyPlan: ReadyPlan) => (
      <UWLabel labelTypography="xsBd" label={`${formatNum(readyPlan.sellable_sqft)} (sellable)`} />
    ),
    field: (field, amount) => <AdjBoundNumberField field={field!.finished_sqft} amount={amount} readOnly={true} />,
    adjustmentCoef: (adjustment) => adjustment!.finished_sqft_adj,
    order: 6,
  },
  diff_above_ground_sqft: {
    rowDescription: "Above Ground Sqft.",
    subject: (readyPlan: ReadyPlan) => `${formatNum(readyPlan.above_ground_sqft)}`,
    field: (field, amount) => (
      <AdjBoundNumberField
        field={field!.above_ground_sqft}
        amount={amount}
        onChange={(nv) => {
          if (nv) {
            field.above_ground_sqft.set(nv);
            field.finished_sqft.set(nv + (field.finished_basement_sqft.value ?? 0));
          }
        }}
      />
    ),
    adjustmentCoef: (adjustment) => adjustment!.above_ground_sqft_adj,
    order: 7,
  },
  // Note: legacy report adjustment prior to sqft split
  diff_basement_sqft: {
    rowDescription: "Basement Sqft.",
    subject: (readyPlan: ReadyPlan) => "",
    field: (field, amount) => <AdjBoundNumberField field={field!.basement_sqft} amount={amount} />,
    adjustmentCoef: (adjustment) => adjustment!.basement_sqft_adj,
    order: 8,
  },
  diff_finished_basement_sqft: {
    rowDescription: "Finished Basement Sqft.",
    subject: (readyPlan: ReadyPlan) => `${formatNum(readyPlan.sellable_basement_sqft)}`,
    field: (field, amount) => (
      <AdjBoundNumberField
        field={field!.finished_basement_sqft}
        amount={amount}
        onChange={(nv) => {
          if (nv !== undefined && nv !== null) {
            field.finished_basement_sqft.set(nv);
            field.finished_sqft.set(nv + (field.above_ground_sqft.value ?? 0));
          }
        }}
      />
    ),
    adjustmentCoef: (adjustment) => adjustment!.finished_basement_sqft_adj,
    order: 9,
  },
  diff_unfinished_basement_sqft: {
    rowDescription: "Unfinished Basement Sqft.",
    subject: (readyPlan: ReadyPlan) => `${formatNum(readyPlan.below_ground_sqft)}`,
    field: (field, amount) => <AdjBoundNumberField field={field!.unfinished_basement_sqft} amount={amount} />,
    adjustmentCoef: (adjustment) => adjustment!.unfinished_basement_sqft_adj,
    order: 10,
  },
  diff_has_pool: {
    rowDescription: "Pool",
    subject: (readyPlan: ReadyPlan) => "",
    field: (field, amount) => <AdjBoundCheckboxField field={field!.has_pool} amount={amount} />,
    adjustmentCoef: (adjustment) => adjustment!.has_pool_adj,
    order: 11,
  },
  close_to_major_road: {
    rowDescription: "Close To Major Road",
    subject: (_, subjectField) => <BoundCheckboxField field={subjectField.close_to_major_road} checkboxOnly />,
    field: (field, amount) => <AdjBoundCheckboxField field={field!.close_to_major_road} amount={amount} />,
    adjustmentCoef: (adjustment) => adjustment!.close_to_major_road_adj,
    order: 12,
  },
  close_to_road_rail_industrial: {
    rowDescription: "Close To Industrial Railroad",
    subject: (readyPlan: ReadyPlan) => "",
    field: (field, amount) => <AdjBoundCheckboxField field={field!.close_to_road_rail_industrial} amount={amount} />,
    adjustmentCoef: (adjustment) => adjustment!.close_to_road_rail_industrial_adj,
    order: 13,
  },
  close_to_river_or_lake: {
    rowDescription: "Close To River Or Lake",
    subject: (readyPlan: ReadyPlan) => "",
    field: (field, amount) => <AdjBoundCheckboxField field={field!.close_to_river_or_lake} amount={amount} />,
    adjustmentCoef: (adjustment) => adjustment!.close_to_river_or_lake_adj,
    order: 14,
  },
  diff_lot_quality: {
    rowDescription: "Lot Quality",
    subject: (_, subjectField) => (
      <BoundSelectField
        options={["above_average", "average", "below_average"].map((o) => ({ id: o, name: o }))}
        getOptionLabel={(o) => capitalCase(o.name.replace("_", " "))}
        getOptionValue={(o) => o.id}
        borderless={false}
        field={subjectField.lot_quality}
      />
    ),
    field: (field, amount) => <AdjBoundSelectField field={field!.lot_quality} amount={amount} />,
    adjustmentCoef: (adjustment) => adjustment!.lot_quality_adj,
    order: 15,
  },
  diff_spec_level_quality: {
    rowDescription: "Spec Level Quality",
    subject: (readyPlan: ReadyPlan) => capitalCase(readyPlan.spec_level ?? "Select Spec Level on Ready Plan Step"),
    field: (field, amount) => (
      <AdjBoundSelectField field={field!.spec_level} amount={amount} options={PROPERTY_SPEC_LEVELS} />
    ),
    adjustmentCoef: (adjustment) => adjustment!.spec_level_quality_adj,
    order: 16,
  },
  diff_outdoor_amenity_quality: {
    rowDescription: "Outdoor Amenity Quality",
    // Note: unknown if RP will ever contribute to this value
    subject: (_, subjectField) => "Average",
    field: (field, amount) => <AdjBoundSelectField field={field!.outdoor_amenity_quality} amount={amount} />,
    adjustmentCoef: (adjustment) => adjustment!.outdoor_amenity_quality_adj,
    order: 16,
  },
  diff_street_quality: {
    rowDescription: "Street Quality",
    subject: (_, subjectField) => (
      <BoundSelectField
        options={["above_average", "average", "below_average"].map((o) => ({ id: o, name: o }))}
        getOptionLabel={(o) => capitalCase(o.name.replace("_", " "))}
        getOptionValue={(o) => o.id}
        borderless={false}
        field={subjectField.street_quality}
      />
    ),
    field: (field, amount) => <AdjBoundSelectField field={field!.street_quality} amount={amount} />,
    adjustmentCoef: (adjustment) => adjustment!.street_quality_adj,
    order: 18,
  },
  diff_has_scenic_view: {
    rowDescription: "Scenic View",
    subject: (_, subjectField) => <BoundCheckboxField field={subjectField.has_scenic_view} checkboxOnly />,
    field: (field, amount) => <AdjBoundCheckboxField field={field!.has_scenic_view} amount={amount} />,
    adjustmentCoef: (adjustment) => adjustment!.has_scenic_view_adj,
    order: 19,
  },
  close_to_commercial: {
    rowDescription: "Close To Commercial",
    subject: (_, subjectField) => <BoundCheckboxField field={subjectField.close_to_commercial} checkboxOnly />,
    field: (field, amount) => <AdjBoundCheckboxField field={field!.close_to_commercial} amount={amount} />,
    adjustmentCoef: (adjustment) => adjustment!.close_to_commercial_adj,
    order: 20,
  },
  close_to_powerlines: {
    rowDescription: "Close To Powerlines",
    subject: (_, subjectField) => <BoundCheckboxField field={subjectField.close_to_powerlines} checkboxOnly />,
    field: (field, amount) => <AdjBoundCheckboxField field={field!.close_to_powerlines} amount={amount} />,
    adjustmentCoef: (adjustment) => adjustment!.close_to_powerlines_adj,
    order: 21,
  },
  close_to_school: {
    rowDescription: "Close to School",
    subject: (_, subjectField) => <BoundCheckboxField field={subjectField.close_to_school} checkboxOnly />,
    field: (field, amount) => <AdjBoundCheckboxField field={field!.close_to_school} amount={amount} />,
    adjustmentCoef: (adjustment) => adjustment!.close_to_school_adj,
    order: 22,
  },
};
