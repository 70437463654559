import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { getApiBaseUrl, getStage } from "src/context";
import * as FullStory from "@fullstory/browser";

/* Since Stage is meaningful in the GraphQL context as well, grab off the ReturnType vs. exporting from context.ts */
type Stage = ReturnType<typeof getStage>;
type FsWindow = { FS?: { getCurrentSession?: () => string | null } };

/** Enable third-party analytics tools */
export async function enableAnalytics(stage = getStage(), _fsWindow: FsWindow = window as FsWindow) {
  const waitForFullstoryPromise = enableFullStory(stage, _fsWindow);
  const datadogEnabled = enableDatadog(stage);
  await waitForFullstoryPromise;

  if (datadogEnabled) {
    datadogRum.startSessionReplayRecording();
  }
}

/**
 * This function enables FullStory via their @fullstory/browser package. The `init` call pulls the script at runtime,
 * so it's not deterministic as to when FullStory is actually up and running. We have a helper method that polls
 * the FS global to determine if it's fully initialized.
 */
async function enableFullStory(stage: Stage, _fsWindow: FsWindow): Promise<boolean> {
  if (stage !== "prod") {
    return false;
  }
  FullStory.init({ orgId: "P2TFT" });
  return waitForFullStoryToInit({ interval: 200, maxTimeToWait: 10_000 }, _fsWindow);
}

/**
 * This method waits for FullStory to fully initialize. The main reason we're doing this is because of the Datadog
 * issue mentioned in `enableAnalytics`. If that upstream issue is resolved, this all gets much much simpler
 */
async function waitForFullStoryToInit(
  { interval, maxTimeToWait }: { interval: number; maxTimeToWait: number },
  _fsWindow: FsWindow,
): Promise<boolean> {
  return new Promise(async (resolve) => {
    const maxIntervals = maxTimeToWait / interval;

    for (let i = 0; i < maxIntervals; i++) {
      // We're using the global FS here instead of the @fullstory/browser `getCurrentSessionURL` method because it prints
      // a console.warn statement each time we poll and the package isn't ready. This creates a lot of noise in the console
      // so we don't use it.
      if (_fsWindow.FS && _fsWindow.FS.getCurrentSession && _fsWindow.FS.getCurrentSession() !== null) {
        resolve(true);
        return;
      } else {
        await wait(interval);
      }
    }

    console.info(
      `FullStory did not initialize in ${maxTimeToWait / 1000} seconds. An ad-blocker likely blocked the request.`,
    );
    resolve(false);
  });
}

function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function enableDatadog(stage: Stage): boolean {
  if (stage === "local") {
    return false;
  }

  const commonOptions = {
    clientToken: "pub7fb2e56c517cdfb693c331b806f5e127",
    site: "datadoghq.com",
    service: "underwriting-frontend",
    env: stage,
    version: VITE_GIT_COMMIT ?? "local",
  };

  datadogRum.init({
    ...commonOptions,
    applicationId: "549c323a-193c-4713-a74f-d38fc7580b71",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    allowedTracingUrls: [getApiBaseUrl()],
    defaultPrivacyLevel: "allow",
  });

  datadogLogs.init({
    ...commonOptions,
    forwardConsoleLogs: [
      // Remember that we pay per-log ingested. You should likely avoid reporting debug-level log messages
      // unless you're actively debugging a major, global issue.
      // "debug",
      "info",
      "warn",
      "error",
    ],
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    beforeSend: (event) => {
      return !DATADOG_IGNORED_ERROR_MESSAGES.some((ignoreMessage) => ignoreMessage.test(event.message));
    },
  });

  return true;
}

const DATADOG_IGNORED_ERROR_MESSAGES = [
  /ResizeObserver loop limit exceeded/,
  /ResizeObserver loop completed with undelivered notifications/,
];
