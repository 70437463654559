import { Css } from "@homebound/beam";
import { groupBy, startCase } from "lodash";
import { Fragment } from "react";
import { mdashUnicode } from "src/components/mdash";
import { ReadyPlan } from "src/routes/cma/endpoints/reports";
import { formatNumberToString } from "src/utils";
import { getReadyPlanDisplayName } from "src/utils/mappers";

interface ReadyPlansSectionProps {
  readyPlans?: ReadyPlan[];
  showTitle?: boolean;
}

/**
 * Shows program data and options for each ready plan in underwriting report
 */
export function ReadyPlanProgramData({ readyPlans, showTitle = true }: ReadyPlansSectionProps) {
  if (!readyPlans || readyPlans.length === 0) {
    throw new Error(`No ready plans for report`);
  }

  return (
    <div
      css={{
        ...Css.df.fdc.w100.pb1.$,
        ...Css.if(!showTitle).bt.bcGray300.py2.else.if("lg").w("80%").$,
      }}
    >
      {readyPlans.map((rp) => (
        <Fragment key={rp.id}>
          {showTitle && <div css={Css.lgBd.pb1.$}>{getReadyPlanDisplayName(rp)}</div>}
          <div css={Css.df.gap7.w100.pb2.$}>
            <RPProgramData rp={rp} />
            <RPOptions rp={rp} />
          </div>
        </Fragment>
      ))}
    </div>
  );
}

function RPProgramData({ rp }: { rp: ReadyPlan }) {
  return (
    <div css={Css.df.fdc.w("35%").$}>
      <div css={Css.baseBd.$}>Program Data</div>
      {RP_PROGRAM_DATA_FIELDS.map((pdField) => {
        // program data fields are all numbers
        // fallback to 0 for legacy reports finalized prior to sqft field split
        const value = (rp[pdField] as number) ?? 0;
        const formattedValue = value === 0 ? mdashUnicode : formatNumberToString(value, true);
        const formattedPdField = startCase(pdField.replace("num_", ""));
        return (
          <div css={Css.df.jcsb.$} key={pdField}>
            <div css={Css.sm.gray700.$}>{formattedPdField}</div>
            <div css={Css.sm.$}>{formattedValue}</div>
          </div>
        );
      })}
    </div>
  );
}

// TODO: Option utils alongside updating option gql calls SC-43184
export function RPOptions({ rp, inModal = false }: { rp: ReadyPlan; inModal?: boolean }) {
  const { bp_ready_plan_options_json, bp_ready_plan_option_ids = [] } = rp;
  const groupedSelections = groupBy(
    bp_ready_plan_options_json?.optionGroups
      ?.flatMap((o) => o.options)
      ?.filter((o) => bp_ready_plan_option_ids?.includes(o.id)),
    (o) => o.type.name,
  );

  return (
    <div css={Css.df.fdc.w("65%").if(inModal).w100.$}>
      {!inModal && <div css={Css.baseBd.$}>Options</div>}
      {rp.ready_plan_id ? (
        <div css={Css.add("fontStyle", "italic").$}>Ready plan not created from Blueprint template.</div>
      ) : (
        <div css={Css.dg.gtr("auto").gtc(inModal ? "1fr" : "1fr 1fr").rg1.cg3.$}>
          {Object.entries(groupedSelections).map(([type, options], idx) => {
            return inModal ? (
              <div key={type} css={Css.pb2.bb.bcGray400.$}>
                <div css={Css.lgBd.gray700.$}>{type}</div>
                {options
                  .sort((a, b) => a.code.localeCompare(b.code))
                  .map((o) => (
                    <div key={o.id} css={Css.df.fdc.pl1.$}>
                      <div css={Css.fw6.$}>
                        - {o.code} ({o.id})
                      </div>
                      <div css={Css.pl2.$}>| {o.name}</div>
                    </div>
                  ))}
              </div>
            ) : (
              <div key={type}>
                <div css={Css.fw6.$}>{type}</div>
                {options
                  .sort((a, b) => a.code.localeCompare(b.code))
                  .map((o) => (
                    <div key={o.id}>
                      {o.displayName} ({o.id})
                    </div>
                  ))}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

// TODO: unified type/enum/const map for ready plan program data fields to be used throughout the app
//   maybe in SC-43184
type RPKey = keyof ReadyPlan;
export const RP_PROGRAM_DATA_FIELDS: RPKey[] = [
  "num_stories",
  "num_bedrooms",
  "num_baths",
  "num_half_baths",
  "num_garage_attached",
  "num_garage_port",
  "sellable_sqft",
  "sellable_basement_sqft",
  "above_ground_sqft",
  "below_ground_sqft",
];
