import { Endpoint } from "@rest-hooks/endpoint";
import { Maybe } from "src/utils";
import { api_url, auth_headers } from "src/context";
import { UnderwritingReport } from "src/routes/cma/endpoints/reports";

type FinalizeUnderwritingReportEndpointParams = {
  dpid: string;
  versionId: Maybe<number | string>;
};

/**
 * TODO: We should be able to combine all of our UnderwritingReportEndpoints into a single resource after
 *  - We've separated Finalize logic
 *  - We've made `versiondId` required. SC-48996
 *    - New reports SHOULD be able to use a `.create()` method on the new resource after that
 *    - https://resthooks.io/rest/api/createResource#members
 *  - Maybe convert to RestEndpoint https://resthooks.io/rest/api/RestEndpoint will be nice too
 *  That change should also allow us to further breakup the `UnderwritingReport` schema into smaller pieces to better
 *    handle SubjectProperty, ReadyPlan, and Comp Selection steps for better performance and maintainability.
 */
export const FinalizeUnderwritingReportEndpoint = new Endpoint(
  async ({ dpid, versionId }: FinalizeUnderwritingReportEndpointParams) => {
    const headers = {
      "content-type": "application/json",
      ...(await auth_headers()),
    };

    const res = await fetch(api_url(`v1/reports/${dpid}/versions/${versionId}/finalize`), {
      headers,
      method: "POST",
    });

    return await res.json();
  },
  { schema: { report: UnderwritingReport }, name: "FinalizeUnderwritingReportEndpoint" },
);
