import { Css, Tag, TagType, Tooltip } from "@homebound/beam";
import { Property } from "src/routes/cma/endpoints/PropertyEndpoint";
import { UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { ValuationStage } from "src/routes/cma/endpoints/reports/ValuationStage";
import { PropertyAddressHeader } from "src/routes/cma/steps/comparables/PropertyAddressHeader";
import { UnderwritingReportStatus } from "src/routes/cma/UnderwritingReportForm";
import {
  FORMAT_MONTH_DATE,
  FORMAT_MONTH_DATE_TIME,
  parseMaybeStringDate,
  parseMaybeStringISOOffsetDate,
} from "src/utils";

interface StepPageHeaderProps {
  property: Property;
  status?: UnderwritingReportStatus;
  report?: UnderwritingReport;
}

export function StepPageHeader({ property, status, report }: StepPageHeaderProps) {
  return (
    // Height set to 66 px to eliminate shifting after the report loads
    <div css={Css.df.aic.gap2.hPx(66).$}>
      <PropertyAddressHeader property={property} size="lg" />
      {report && status !== "Unsaved" && (
        <div css={Css.bl.bw2.bcGray400.pl2.df.fdc.gap1.$}>
          <div css={Css.df.gap1.$}>
            <Tooltip title="Valuation Stage" placement="left">
              <Tag text={report.valuation_stage!} type={getValuationTagType[report.valuation_stage!]} />
            </Tooltip>
            <Tag text={status!} type={getStatusTagType[status!]} />
          </div>
          <div>
            <div css={Css.baseBd.$}>{report.version_name ?? <i>Untitled</i>}</div>
            <div css={Css.xs.$}>{timeLabel(report)}</div>
          </div>
        </div>
      )}
    </div>
  );
}

function timeLabel(version: UnderwritingReport) {
  if (version.underwritten_at) {
    const dateParse = parseMaybeStringDate(version.underwritten_at);
    const newDate = new Date(dateParse!);

    return `Underwritten: ${FORMAT_MONTH_DATE.format(newDate)}`;
  }

  const dateParse = parseMaybeStringISOOffsetDate(version.updated_at);
  const newDate = new Date(dateParse!);
  return `Last updated: ${FORMAT_MONTH_DATE_TIME.format(newDate)}`;
}

const getStatusTagType: Record<UnderwritingReportStatus, TagType> = {
  Unsaved: "info",
  Draft: "info",
  Finalized: "success",
};

const getValuationTagType: Record<ValuationStage, TagType> = {
  Underwriting: "caution",
  "Asset Valuation": "neutral",
  "Clear to Close": "success",
  "Pre-Listing": "warning",
  "Post-Listing": "info",
};
