import { Button, Css, ModalBody } from "@homebound/beam";
import { ModalFooterWTip } from "src/components/outdatedReadyPlanModal/components/atoms";
import { Maybe } from "src/utils";

export function PlanTemplateMissingContent({
  planName = "this ready plan",
  onChooseNewRp,
}: {
  planName: Maybe<string>;
  onChooseNewRp: () => void;
}) {
  return (
    <>
      <ModalBody>
        <div css={Css.df.fdc.gap2.$}>
          <div>
            We're unable to confirm that the configured ready plan is in sync with Blueprint because its associated
            template is missing.
          </div>
          <div>This could be due to a number of reasons but most often indicates a stale report or ready plan.</div>
          <div>
            Please select a new Ready Plan or reach out in the #uw-readyplan-feedback channel if you're confident{" "}
            {planName} is still in use.
          </div>
        </div>
      </ModalBody>
      <ModalFooterWTip>
        <Button label={"Go to Ready Plan step"} onClick={onChooseNewRp} />
      </ModalFooterWTip>
    </>
  );
}
