import { Button, Css } from "@homebound/beam";
import { useEffect, useRef } from "react";
import { Popup, Polygon as ReactLeafletPolygon, Tooltip, useMap } from "react-leaflet";
import { SavePolygonInput } from "src/routes/admin/polygons";
import { usePolygonContext } from "src/routes/admin/polygons/PolygonContext";
import { UwLeafletPolygon } from "src/routes/admin/polygons/components/PolygonFeatureView";
import { getPolygonPositions } from "src/routes/maps/mapUtils";
import { CompFilterPolygonGeometry } from "./ComparablesStep";

type OverlayPolygonFeatureViewProps = {
  p: SavePolygonInput;
  color: string;
  polygonClickedRef: React.MutableRefObject<boolean>;
  compFilterPolygon: CompFilterPolygonGeometry | undefined;
  setCompFilterPolygon: (filter: CompFilterPolygonGeometry | undefined) => void;
};

export function OverlayPolygonFeatureView(props: OverlayPolygonFeatureViewProps) {
  const { p, color, polygonClickedRef, compFilterPolygon, setCompFilterPolygon } = props;
  const lpRef = useRef<UwLeafletPolygon>(null);
  const map = useMap();
  const { selectedPolygonId, setSelectedPolygonId, polygonVisibility } = usePolygonContext();
  const highlightedOutlineWeight = 4;
  const defaultOutlineWeight = 1;

  // handles clicks on map, polygon side pane, or drawing editor
  useEffect(() => {
    if (lpRef.current) {
      // if a new polygon is selected, center map on polygon and highlight it
      if (selectedPolygonId === p.id) {
        map.flyToBounds(lpRef.current.getBounds());
        lpRef.current.setStyle({ fillOpacity: 0.5, weight: highlightedOutlineWeight });
      } else {
        // if a polygon is deselected, remove highlight and send it behind other layers
        // the main benefit is that when clicking on the trash can icon, this enables comp filter polygon deletion
        // by moving the overlay polygon behind comp filter polygons
        if (lpRef.current.options.weight === highlightedOutlineWeight) {
          lpRef.current.setStyle({ fillOpacity: 0.2, weight: defaultOutlineWeight });
          lpRef.current.bringToBack();
          lpRef.current.closePopup();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPolygonId]);

  if (!polygonVisibility.find((pv) => pv.type === p.type)?.isVisible) return null;

  return (
    <ReactLeafletPolygon
      ref={lpRef}
      key={p.id}
      positions={getPolygonPositions(p.geometry)}
      fillColor={color}
      color={color}
      weight={defaultOutlineWeight}
      eventHandlers={{
        click: () => {
          polygonClickedRef.current = true;
          setSelectedPolygonId(p.id);
        },
      }}
      // this removes the focus outline that is added from the tooltip
      css={{ "&:focus": Css.outline0.$ }}
    >
      <Tooltip
        opacity={0.7}
        direction="center"
        css={Css.xsBd.gray900.add("boxShadow", "unset").add("backgroundColor", "unset").add("border", "none").$}
      >
        {p.name}
      </Tooltip>
      <Popup>
        <Button
          label="Add to comp filter"
          onClick={() => {
            const newFeature = getPolygonFeaturesForCompSearch(compFilterPolygon, p);
            setCompFilterPolygon(newFeature);
          }}
        />
      </Popup>
    </ReactLeafletPolygon>
  );
}

export function getPolygonFeaturesForCompSearch(
  compFilterPolygon: CompFilterPolygonGeometry | undefined,
  overlayPolygon: SavePolygonInput,
): CompFilterPolygonGeometry {
  const overlayPolygonCoordinates = overlayPolygon.geometry.coordinates;

  if (!compFilterPolygon) {
    return { geometry: { type: "MultiPolygon", coordinates: [overlayPolygonCoordinates] } };
  }

  const currentFilterCoordinates = compFilterPolygon.geometry?.coordinates ?? [];
  const newCoordinates = [...currentFilterCoordinates, overlayPolygonCoordinates];

  return { geometry: { type: "MultiPolygon", coordinates: newCoordinates } };
}
