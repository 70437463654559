import { UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { Css, Tooltip, useTestIds } from "@homebound/beam";
import {
  EstimateSection,
  EstStatRange,
  EstStatContainer,
  EstSingleStat,
} from "src/routes/cma/steps/estimate/components";
import React, { useMemo } from "react";
import { getEstRanges } from "src/routes/cma/steps/readyPlanUtils";
import { FormattedPrice } from "src/components/FormattedPrice";
import { getPlanNameAndCopies } from "src/routes/cma/steps/estimate/EstimatePage";

export function ValuationSummarySection({ report }: { report: UnderwritingReport }) {
  // Only show appreciation values when necessary
  const hasProjectAppreciation = !!(
    report.costs_and_metrics?.appreciation_time_of_delivery_appreciation &&
    report.costs_and_metrics?.appreciation_time_of_delivery_appreciation > 0
  );

  // Add up all the buildable sqft values for each Ready Plan
  const totalBuildableSqft = useMemo(() => {
    return report.ready_plans!.reduce((sum, current_item) => {
      return sum + current_item.sellable_sqft * current_item.copies;
    }, 0);
  }, [report]);

  const { weightedPricesWithAppreciation, weightedPrices } =
    getEstRanges(report.costs_and_metrics, report.ready_plans) || {};

  return (
    <EstimateSection title="Valuation Summary" headerColor="blue" forceOpen>
      <div>
        <EstStatContainer
          bold
          title={`Gross Expected Sales Price ${hasProjectAppreciation ? "(No Appreciation)" : ""}`}
          type="money"
          value={report.final_weighted_price}
        >
          <>
            {weightedPrices && <EstStatRange high={weightedPrices.high} low={weightedPrices.low} type="money" />}
            <PlanPricing report={report} />
          </>
        </EstStatContainer>
        <EstStatContainer
          title={`$ / SF ${hasProjectAppreciation ? "(No Appreciation)" : ""}`}
          type="money"
          value={(report?.final_weighted_price ?? 0) / totalBuildableSqft}
        >
          {weightedPrices && (
            <EstStatRange
              high={weightedPrices.high / totalBuildableSqft}
              low={weightedPrices.low / totalBuildableSqft}
              type="money"
            />
          )}
        </EstStatContainer>
      </div>
      {/* W appreciations */}
      <div>
        {hasProjectAppreciation && (
          <>
            <EstStatContainer
              bold
              title="Gross Expected Sales Price (W Appreciation)"
              type="money"
              value={report.costs_and_metrics?.exp_price_with_appreciation}
            >
              <>
                {weightedPricesWithAppreciation && (
                  <EstStatRange
                    high={weightedPricesWithAppreciation.high}
                    low={weightedPricesWithAppreciation.low}
                    type="money"
                  />
                )}
                <PlanPricing report={report} withAppreciation />
              </>
            </EstStatContainer>
            <EstStatContainer
              title="$ / SF (W Appreciation)"
              type="money"
              value={report.costs_and_metrics!.exp_price_with_appreciation / totalBuildableSqft}
            >
              {weightedPricesWithAppreciation && (
                <EstStatRange
                  high={weightedPricesWithAppreciation.high / totalBuildableSqft}
                  low={weightedPricesWithAppreciation.low / totalBuildableSqft}
                  type="money"
                />
              )}
            </EstStatContainer>
          </>
        )}
        <EstSingleStat
          title="Projected Appreciation"
          value={report.costs_and_metrics?.appreciation_time_of_delivery_appreciation}
          type="percentage"
        />
      </div>
    </EstimateSection>
  );
}

// Displays per-unit pricing for multi-unit dwellings
function PlanPricing({ report, withAppreciation }: { report: UnderwritingReport; withAppreciation?: boolean }) {
  const tid = useTestIds({});

  if (!report.ready_plans || !report.costs_and_metrics) return null;

  const appreciation = 1 + (withAppreciation ? report.costs_and_metrics.appreciation_time_of_delivery_appreciation : 0);
  const hasMultipleReadyPlans = report.ready_plans.length > 1;

  return (
    <>
      {report.ready_plans.map((rp) => {
        const hasMultipleCopies = rp.copies > 1;
        if (!hasMultipleCopies && !hasMultipleReadyPlans) return null;
        return (
          <div css={Css.$} key={rp.id} {...tid.multiUnitPricing}>
            <div css={Css.df.jcsb.smSb.ttc.$} {...tid.nameAndCopies}>
              <div>{getPlanNameAndCopies(rp)}</div>
              <Tooltip title={"Total"}>
                <div {...tid.totalPrice}>
                  <FormattedPrice price={rp.final_weighted_price * appreciation * rp.copies} />
                </div>
              </Tooltip>
            </div>
          </div>
        );
      })}
    </>
  );
}
