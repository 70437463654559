import { Css, LoadingSkeleton } from "@homebound/beam";
import { useEffect, useState } from "react";
import { useDLE } from "@rest-hooks/react";
import { LoadingBoundary } from "src/components/LoadingBoundary";
import { useReaction } from "src/hooks";
import { ReadyPlan } from "src/routes/cma/endpoints/reports";
import {
  BlueprintReadyPlanComputeData,
  BlueprintReadyPlanComputeDataEndpoint,
  BlueprintReadyPlanComputeDataEndpointOptions,
} from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlanComputeDataEndpoint";
import { formatNumberToString } from "src/utils";
import { formatInchesToFeet } from "./formatters";
import { V2ReadyPlanFormState } from "./ReadyPlanForm";
import { StandardErrorContainer } from "src/components/ErrorBoundary";

export interface LoadReadyPlanOptionsPreviewProps {
  devId: string;
  form: V2ReadyPlanFormState;
  onLoading: (isLoading: boolean) => void;
}

// Maps ready plan values to the preview component in a way that matches expected structure of
// BlueprintReadyPlanComputeDataEndpoint return.
export function FinalizedReadyPlanOptionsPreview({ readyPlan }: { readyPlan: ReadyPlan }) {
  const rpoPreviewData = {
    pk: () => "any-pk-is-unused",
    hashKey: 1,
    computeReadyPlanCost: {
      softCostInCents: readyPlan.soft_cost_in_cents!,
      directHardCostInCents: readyPlan.direct_hard_cost_in_cents!,
      indirectHardCostInCents: readyPlan.indirect_hard_cost_in_cents!,
      totalCostInCents:
        readyPlan.soft_cost_in_cents! + readyPlan.direct_hard_cost_in_cents! + readyPlan.indirect_hard_cost_in_cents!,
      deltas: [],
    },
    readyPlan: {
      id: readyPlan.bp_ready_plan_id!,
      name: readyPlan.bp_ready_plan_name!,
    },
    computeProgramData: {
      stories: readyPlan.num_stories,
      bedrooms: readyPlan.num_bedrooms,
      fullBaths: readyPlan.num_baths,
      halfBaths: readyPlan?.num_half_baths || 0,
      depthInInches: readyPlan.depth_in_inches,
      widthInInches: readyPlan.width_in_inches,
      garageAttached: readyPlan.num_garage_attached,
      garagePort: readyPlan.num_garage_port,
      sellableSqft: readyPlan.sellable_sqft,
      sellableAboveGroundSqft: readyPlan.above_ground_sqft,
      sellableBelowGroundSqft: readyPlan.sellable_basement_sqft,
      unfinishedBelowGroundSqft: readyPlan.below_ground_sqft,
      // Fields below are not shown in preview. Set to 0 to avoid undefined errors.
      aboveGroundSqft: 0,
      sellableBasementSqft: 0,
      belowGroundSqft: 0,
    },
  };

  return <ReadyPlanOptionsPreview data={rpoPreviewData} />;
}

export function LoadReadyPlanOptionsPreview({ form, onLoading }: LoadReadyPlanOptionsPreviewProps) {
  const [params, setParams] = useState<BlueprintReadyPlanComputeDataEndpointOptions | null>(null);

  // Blocks submission of form by setting computeResults to undefined between new fetches/cache reads
  useReaction(
    () => {
      return {
        devId: form.devId.value!,
        bp_ready_plan_id: form.bp_ready_plan_id.value!,
        options: [
          ...form.multiOptionGroups.rows.map((r) => r.selectedOptionId.value!),
          ...(form.singleOptionGroups.value ?? []),
        ],
      };
    },
    (params: BlueprintReadyPlanComputeDataEndpointOptions | null, prevParams) => {
      JSON.stringify(params) !== JSON.stringify(prevParams) && form.computeResults.set(undefined);
      setParams(params);
    },
    { fireImmediately: !form.readOnly },
    [form],
  );

  const { data, loading, error } = useDLE(BlueprintReadyPlanComputeDataEndpoint, params);

  useEffect(() => {
    onLoading(loading);
  }, [loading, onLoading]);

  useEffect(() => {
    form.computeResults.set(data);
  }, [data, form.computeResults]);

  return (
    <>
      {data && (
        <LoadingBoundary>
          <ReadyPlanOptionsPreview data={data} />
        </LoadingBoundary>
      )}
      {loading && <LoadingSkeleton rows={6} randomizeWidths />}
      {error && <StandardErrorContainer>{error.message}</StandardErrorContainer>}
    </>
  );
}

export interface ReadyPlanOptionsPreviewProps {
  data: BlueprintReadyPlanComputeData;
}

export function ReadyPlanOptionsPreview({ data }: ReadyPlanOptionsPreviewProps) {
  return (
    <>
      <ReadyPlanProgramDataPreview data={data} />
      <div css={Css.pt4.$}>
        <ReadyPlanCostDataPreview data={data} />
      </div>
    </>
  );
}

function ReadyPlanProgramDataPreview({ data }: ReadyPlanOptionsPreviewProps) {
  const program = data.computeProgramData!;

  return (
    <div>
      <div css={Css.lgBd.$}>{data.readyPlan?.name}</div>
      <div>{program.bedrooms} bedrooms</div>
      <div>
        {program.fullBaths} full, {program.halfBaths} half bathrooms
      </div>
      <div>{program.garageAttached} car garage</div>
      <div>
        {program.stories} stor{program.stories > 1 ? "ies" : "y"}
      </div>
      <div>{formatNumberToString(program.sellableSqft, true)} sellable sqft</div>
      {/* Skip showing these fields when 0 */}
      {program.sellableAboveGroundSqft > 0 && (
        <div>{formatNumberToString(program.sellableAboveGroundSqft, true)} sellable above ground sqft</div>
      )}
      {program.sellableBelowGroundSqft > 0 && (
        <div>{formatNumberToString(program.sellableBelowGroundSqft, true)} sellable below ground sqft</div>
      )}
      {program.unfinishedBelowGroundSqft > 0 && (
        <div>{formatNumberToString(program.unfinishedBelowGroundSqft, true)} unfinished below ground sqft</div>
      )}

      <div>
        {formatInchesToFeet(program.depthInInches)} x {formatInchesToFeet(program.widthInInches)}
      </div>
    </div>
  );
}

function ReadyPlanCostDataPreview({ data }: ReadyPlanOptionsPreviewProps) {
  const { computeReadyPlanCost } = data;

  if (!computeReadyPlanCost) {
    return null;
  } else if (computeReadyPlanCost?.configurationErrors && computeReadyPlanCost.configurationErrors.length > 0) {
    return (
      <div css={Css.df.fdc.gap1.$}>
        <div css={Css.fw7.$}>Configuration Invalid:</div>
        {computeReadyPlanCost.configurationErrors.map((e) => (
          <div key={e} css={Css.red500.$}>
            {e}
          </div>
        ))}
      </div>
    );
  }

  const total =
    computeReadyPlanCost.softCostInCents +
    computeReadyPlanCost.directHardCostInCents +
    computeReadyPlanCost.indirectHardCostInCents;

  return (
    <table css={Css.wPx(250).$}>
      <tbody>
        <tr>
          <td>Soft Cost</td>
          <td align="right">{formatCostFromCents(computeReadyPlanCost.softCostInCents)}</td>
        </tr>
        <tr>
          <td>Direct Hard Cost</td>
          <td align="right">{formatCostFromCents(computeReadyPlanCost.directHardCostInCents)}</td>
        </tr>
        <tr>
          <td>Indirect Hard Cost</td>
          <td align="right">{formatCostFromCents(computeReadyPlanCost.indirectHardCostInCents)}</td>
        </tr>
        <tr css={Css.bt.bcGray400.smBd.$}>
          <td>Total</td>
          <td css={Css.fontFamily("monospace").$} align="right">
            {formatCostFromCents(total)}
          </td>
        </tr>
        <tr css={Css.h6.bcGray400.smBd.$}>
          <td>Build Costs / sqft</td>
          <td css={Css.fontFamily("monospace").$} align="right">
            {formatCostFromCents(total / data.computeProgramData!.sellableSqft)}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function formatCostFromCents(cost: number): string {
  return formatNumberToString(cost / 100, false, false);
}
