import { Button, Css, Palette, useModal } from "@homebound/beam";
import toGeoJSON from "@mapbox/togeojson";
import rewind from "@mapbox/geojson-rewind";
import { Feature, FeatureCollection, Geometry, GeometryCollection, Polygon } from "geojson";
import React, { useRef, useState } from "react";
import { Maybe } from "src/utils";
import { KMLImportModal } from "./KMLImportModal";
import { UWPolygonType } from "../PolygonEndpoints";

type PolygonPropertiesType = {
  polygonType: UWPolygonType;
  polygonColor: Palette | undefined;
};

type KMLImportButtonProps = {
  onFileUpload: (
    file: File,
    polygonProperties: PolygonPropertiesType,
    onKmlImport: KMLImportButtonProps["onKmlImport"],
    saveForm: () => void,
  ) => void;
  onKmlImport: (feature: Feature) => void;
  saveForm: () => void;
  disabled?: boolean;
};

export function KMLImportButton(props: KMLImportButtonProps) {
  const { onFileUpload, onKmlImport, saveForm, disabled = false } = props;
  const { openModal } = useModal();
  const inputRef = useRef<HTMLInputElement>(null);
  const [polygonType, setPolygonType] = useState<UWPolygonType>();
  const [polygonColor, setPolygonColor] = useState<Palette | undefined>();

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (!file.name.endsWith(".kml")) {
        alert("Please upload a .kml file");
        return;
      }

      await onFileUpload(file, { polygonType: polygonType!, polygonColor }, onKmlImport, saveForm);

      // reset modal state
      setPolygonColor(undefined);
      setPolygonType(undefined);
      // clear file input value so another file can be uploaded
      inputRef.current!.value = "";
    }
  };

  return (
    <div>
      <input
        data-testid="kmlImportHiddenInput"
        css={Css.dn.$}
        ref={inputRef}
        type="file"
        accept={".kml"}
        onChange={handleFileChange}
      />
      <Button
        data-testid="kmlImportTriggerButton"
        variant="secondary"
        disabled={disabled}
        label={"Import KML"}
        onClick={() => {
          openModal({
            content: (
              <KMLImportModal
                inputRef={inputRef.current!}
                polygonType={polygonType}
                setPolygonType={setPolygonType}
                setPolygonColor={(color: Palette | undefined) => setPolygonColor(color)}
              />
            ),
          });
        }}
      />
    </div>
  );
}

type KmlFeatureProperties = { name?: string; type: UWPolygonType; color: Palette | undefined };

// A feature that can only have a type of Polygon
type KmlPolygonFeature = Feature<Polygon, KmlFeatureProperties>;

// A feature that can have a type of Polygon or GeometryCollection
type KmlPolygonGeometryCollectionFeature = Feature<Polygon | GeometryCollection, KmlFeatureProperties>;

export async function handleKMLFile(
  file: File,
  polygonProperties: PolygonPropertiesType,
  onKmlImport: KMLImportButtonProps["onKmlImport"],
  saveForm: () => void,
) {
  const reader = new FileReader();

  reader.onload = (e) => {
    const fileAsText = e.target?.result;
    const parsedKML = parseTextAsKml(fileAsText);
    if (!parsedKML) return;

    // Correct right hand rule
    const features = rewind(parsedKML, false).features as KmlPolygonGeometryCollectionFeature[];

    // Check if any empty features or incorrect geometry type and inform user
    if (
      features.length === 0 ||
      !features.map((f) => f.geometry.type).every((t) => t === "Polygon" || t === "GeometryCollection")
    ) {
      alert("Unable to parse KML file because it contains empty or incorrect geometry. Please try again.");
      return;
    }

    features.forEach((f: KmlPolygonGeometryCollectionFeature) => {
      if (f.geometry.type === "GeometryCollection") {
        // split GeometryCollection into individual polygons
        f.geometry.geometries.forEach((g: Geometry, i) => {
          if (g.type !== "Polygon") {
            alert("Unable to parse KML file because it contains non-polygon geometry. Please try again.");
            return;
          }
          const updatedFeature = formatFeature({ ...f, geometry: g }, polygonProperties);
          onKmlImport(updatedFeature);
        });
      } else {
        const updatedFeature = formatFeature(f as KmlPolygonFeature, polygonProperties);
        onKmlImport(updatedFeature);
      }
    });

    saveForm();
  };

  reader.readAsText(file);
}

function parseTextAsKml(text: Maybe<string | ArrayBuffer>): FeatureCollection | null {
  // Anything other than string means an issue with KML file
  if (typeof text !== "string") {
    alert("Unable to parse KML file. Please try again.");
    return null;
  }
  // Create xml dom object and convert to geojson based FeatureCollection
  return toGeoJSON.kml(new DOMParser().parseFromString(text, "text/xml"));
}

// Traverse imported geometry to remove any Z coordinates before setting it to the form
// (note: all kml files tested have 0 for third coordinate)
// and add polygon properties from the modal selections
function formatFeature(feature: KmlPolygonFeature, polygonProperties: PolygonPropertiesType): KmlPolygonFeature {
  const {
    geometry: { type, coordinates },
    properties: { name },
  } = feature;
  const { polygonType, polygonColor } = polygonProperties;

  if (type !== "Polygon") {
    alert("Unable to parse KML file because it contains non-polygon geometry. Please try again.");
  }

  const twoDCoords = coordinates.map((c) => c.map((c2) => c2.slice(0, 2)));

  return {
    ...feature,
    geometry: { type, coordinates: twoDCoords },
    properties: { name, type: polygonType, color: polygonColor },
  };
}
