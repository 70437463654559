import { Endpoint, Entity } from "@rest-hooks/rest";
import { api_url, auth_headers } from "src/context";
import { Maybe } from "src/utils/types";
import { PropertyImage } from "./PropertyImage";

export type PropertyType =
  | "adu"
  | "attached_home"
  | "condo"
  | "land"
  | "mobile_or_manufactured"
  | "planned_unit_development"
  | "single_family";

export class Property extends Entity {
  readonly dpid: string = "";
  readonly full_street_address: string = "";
  readonly city_name: string = "";
  readonly state: string = "";
  readonly zip_code: string = "";
  readonly lot_size: number = 0;
  readonly buildable_sqft: Maybe<number> = undefined;
  readonly property_type_simple: PropertyType | "" = "";
  readonly year_built_or_renovated: string = "";
  readonly total_sqft: Maybe<number> = undefined;
  readonly num_bedrooms: Maybe<number> = undefined;
  readonly num_baths: Maybe<number> = undefined;
  readonly num_stories: Maybe<number> = undefined;
  readonly has_pool: Maybe<boolean> = undefined;
  readonly has_scenic_view: Maybe<boolean> = undefined;
  readonly latitude: Maybe<number> = undefined;
  readonly longitude: Maybe<number> = undefined;
  readonly estimated_price: Maybe<number> = undefined;
  readonly homebound_avm: Maybe<number> = undefined;
  readonly homebound_avm_updated: Maybe<number> = undefined;
  readonly lot_frontage_feet: Maybe<number> = undefined;
  readonly lot_depth_feet: Maybe<number> = undefined;

  readonly adu_elig: Maybe<boolean> = undefined;
  readonly metro: string = "";
  readonly unit_number: Maybe<string> = undefined;
  readonly last_sold_price: Maybe<number> = undefined;
  readonly last_sold_price_adj: Maybe<number> = undefined;
  readonly last_sold_price_per_sqft: Maybe<number> = undefined;
  readonly last_sold_price_adj_per_sqft: Maybe<number> = undefined;
  readonly last_sold_date: Maybe<string> = undefined;
  readonly builder_name: Maybe<string> = undefined;
  readonly missing_lot_size: boolean = false;
  readonly finished_sqft: Maybe<number> = undefined;
  readonly garage_num_cars: Maybe<number> = undefined;
  readonly blackknight_avm: Maybe<number> = undefined;
  readonly blackknight_avm_low: Maybe<number> = undefined;
  readonly blackknight_avm_high: Maybe<number> = undefined;
  readonly day_of_reference: Maybe<string> = undefined;
  readonly top_comps_exp_price_median: Maybe<number> = undefined;
  readonly top_comps_exp_price_median_adj: Maybe<number> = undefined;
  readonly top_comps_exp_price: Maybe<number> = undefined;
  readonly top_comps_exp_price_adj: Maybe<number> = undefined;
  readonly updated_at: Maybe<string> = undefined;
  readonly property_images: PropertyImage[] | undefined = undefined;
  readonly basement_sqft: Maybe<number> = undefined;
  readonly finished_basement_sqft: Maybe<number> = undefined;
  readonly unfinished_basement_sqft: Maybe<number> = undefined;
  readonly zoning: Maybe<string> = undefined;
  readonly above_ground_sqft: Maybe<number> = undefined;

  readonly mls_new_build: Maybe<boolean> = undefined;
  readonly mls_status: Maybe<string> = undefined;
  readonly mls_list_date: Maybe<string> = undefined;
  readonly mls_list_price: Maybe<number> = undefined;
  readonly mls_list_price_orig: Maybe<number> = undefined;
  readonly mls_school_zone: Maybe<string> = undefined;
  readonly mls_listing_description: Maybe<string> = undefined;

  readonly outdoor_amenity_quality: Maybe<string> = undefined;
  readonly street_quality: Maybe<string> = undefined;
  readonly lot_quality: Maybe<string> = undefined;
  readonly close_to_major_road: Maybe<boolean> = undefined;
  readonly close_to_commercial: Maybe<boolean> = undefined;
  readonly close_to_powerlines: Maybe<boolean> = undefined;
  readonly close_to_school: Maybe<boolean> = undefined;

  // Populates property header
  readonly polygon_nearest_in_rec_geo_name: Maybe<string> = undefined; // AKA Dev Area
  readonly polygon_neighborhood_name: Maybe<string> = undefined;

  pk() {
    return this.dpid;
  }

  static key = "Property";
}

interface PropertyParams {
  dpid: string;
}

export const PropertyEndpoint = new Endpoint(
  async ({ dpid }: PropertyParams) => {
    const headers = await auth_headers();
    const res = await fetch(api_url(`/v1/properties/${dpid}`), {
      headers,
    });
    const p = await res.json();

    p.property.full_street_address = p.property.full_street_address.toLowerCase();
    p.property.city_name = p.property.city_name.toLowerCase();

    return p;
  },
  {
    schema: { property: Property },
    key: ({ dpid }) => {
      return `/v1/properties/${dpid}`;
    },
    name: "PropertyEndpoint",
  },
);
