import { useModal } from "@homebound/beam";
import { ReactNode, useCallback } from "react";
import { ConfirmationModal } from "./ConfirmationModal";

interface DeleteConfirmationModalProps {
  confirmationMessage?: ReactNode | string;
  onConfirmDelete: () => void;
  entityType: string;
}

export function DeleteConfirmationModal(props: DeleteConfirmationModalProps) {
  const { confirmationMessage, onConfirmDelete, entityType } = props;
  const { closeModal } = useModal();

  const onConfirmClick = useCallback(() => {
    onConfirmDelete();
    closeModal();
  }, [onConfirmDelete, closeModal]);

  return (
    <ConfirmationModal
      confirmationMessage={confirmationMessage ?? "Are you sure?"}
      onConfirmAction={onConfirmClick}
      title={`Delete ${entityType}`}
      label="Delete"
    />
  );
}
