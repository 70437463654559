import { Endpoint, Entity } from "@rest-hooks/rest";
import { api_url, auth_headers } from "src/context";
import { Maybe } from "src/utils";

export interface TableInfo {
  table: string;
  estimated_row_count: number;
  row_count: Maybe<number>;
  status: StatusValue;
}

export type StatusValue = "ok" | "degraded" | "error";

export class Status extends Entity {
  readonly status: StatusValue = "ok";
  readonly stage: string = "";
  readonly migration_version: string = "";
  readonly migration_history?: string | undefined = undefined;
  readonly tables: TableInfo[] = [];

  pk() {
    return "status";
  }

  static key = "Status";
}

interface StatusParams {
  exact_count: "True" | "False";
  include_migration_history: "True" | "False";
}

export const StatusEndpoint = new Endpoint(
  async ({ exact_count, include_migration_history }: StatusParams) => {
    const headers = await auth_headers();
    const res = await fetch(
      api_url(`/status?exact_count=${exact_count}&include_migration_history=${include_migration_history}`),
      {
        headers,
      },
    );
    return await res.json();
  },
  {
    key: ({ exact_count, include_migration_history }) => {
      return `/status?exact_count=${exact_count}&include_migration_history=${include_migration_history}`;
    },
    schema: Status,
    name: "StatusEndpoint",
  },
);
