import { Button, Css, Icon, ModalBody, useComputed } from "@homebound/beam";
import { capitalCase } from "change-case";
import { useState } from "react";
import { useSuspense } from "@rest-hooks/react";
import { CustomLoadingBoundary } from "src/components/LoadingBoundary";
import { ModalFooterWTip, SectionTitle } from "src/components/outdatedReadyPlanModal/components/atoms";
import { useController, useReaction } from "src/hooks";
import { SaveUnderwritingReportEndpoint, UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { BlueprintReadyPlanComputeDataEndpoint } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlanComputeDataEndpoint";
import {
  BlueprintOptionGroupsEndpoint,
  ReadyPlanOption,
} from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlanOptionsEndpoint";
import { formatNumberToString } from "src/utils";

export function PlanTemplateOutdatedContent({
  report,
  onChooseNewRp,
  devId,
  currentIncrement,
}: {
  onChooseNewRp: () => void;
  report: UnderwritingReport;
  devId: string;
  currentIncrement: string;
}) {
  const readyPlan = report.ready_plans![0];
  const [missingOptions, setMissingOptions] = useState<string[]>();

  const { optionGroups } = useSuspense(BlueprintOptionGroupsEndpoint, {
    devId,
    bp_ready_plan_id: readyPlan.bp_ready_plan_id!,
  });

  useReaction(
    () => ({
      allRPOptions: optionGroups?.flatMap((o) => o.options),
    }),
    ({ allRPOptions }: { allRPOptions: ReadyPlanOption[] }) => {
      const missingConfiguredOptions = readyPlan.bp_ready_plan_option_ids?.filter(
        (rpOptionId) => !allRPOptions?.some((aRPO) => aRPO.id === rpOptionId),
      );
      setMissingOptions(missingConfiguredOptions ?? []);
    },
    { fireImmediately: true },
    [optionGroups],
  );

  function RenderMissingOptions() {
    const missingConfiguredOptions = readyPlan.bp_ready_plan_options_json?.optionGroups
      ?.flatMap((o) => o.options)
      .filter((o) => missingOptions?.includes(o.id));

    return (
      <>
        <SectionTitle label="The following options are no longer available:" success={false} />
        <ul>
          {missingConfiguredOptions?.map(({ id, displayName, type }) => {
            return (
              <li key={id}>
                [{type.name}] {displayName}
              </li>
            );
          })}
        </ul>
        Please reconfigure your ready plan to ensure accurate pricing and comp generation.
      </>
    );
  }

  function RenderProgramDataCheck() {
    return (
      <>
        <SectionTitle label="All options accounted for" />
        <CustomLoadingBoundary fallback={<SectionTitle label="Comparing Program Data" loading />}>
          <ProgramDataCosts
            report={report}
            devId={devId}
            currentIncrement={currentIncrement}
            onChooseNewRp={onChooseNewRp}
          />
        </CustomLoadingBoundary>
      </>
    );
  }

  return (
    <>
      <ModalBody>
        <div css={Css.df.fdc.gap1.$}>
          {missingOptions?.length === 0 ? <RenderProgramDataCheck /> : <RenderMissingOptions />}
        </div>
      </ModalBody>
      {(missingOptions?.length ?? 0) > 0 && (
        <ModalFooterWTip>
          <Button label="Go To Plans" onClick={onChooseNewRp} variant="primary" />
        </ModalFooterWTip>
      )}
    </>
  );
}

function ProgramDataCosts({
  report,
  devId,
  currentIncrement,
  onChooseNewRp,
}: {
  report: UnderwritingReport;
  devId: string;
  currentIncrement: string;
  onChooseNewRp: () => void;
}) {
  const readyPlan = report.ready_plans![0];
  const { fetch } = useController();
  const computeData = useSuspense(BlueprintReadyPlanComputeDataEndpoint, {
    devId: devId,
    bp_ready_plan_id: readyPlan.bp_ready_plan_id!,
    options: readyPlan.bp_ready_plan_option_ids!,
  });

  const programDataChanges = useComputed(() => {
    const { computeProgramData: computedPD } = computeData!;
    let changedProgramData: { label: string; old: number; new: number }[] = [];
    for (const [storedKey, computedKey] of Object.entries(rpPdMap)) {
      // @ts-ignore: silence index issues from mix n match interfaces/types/classes
      const storedValue: number = readyPlan[storedKey];
      // @ts-ignore: silence index issues from mix n match interfaces/types/classes
      const newComputedValue: number = computedPD[computedKey] === null ? 0 : computedPD[computedKey];
      if (storedValue !== newComputedValue) {
        changedProgramData.push({ label: capitalCase(storedKey), old: storedValue, new: newComputedValue });
      }
    }

    return changedProgramData;
  }, [computeData, readyPlan]);

  function RenderProgramChanges() {
    return (
      <>
        {programDataChanges.length === 0 ? (
          <SectionTitle label="Program Data has not changed" />
        ) : (
          <>
            <SectionTitle label="Program Data has changed" success={false} />
            <div css={Css.df.fdc.gap1.mx5.$}>
              {programDataChanges.map(({ label, old, new: newComputedValue }) => {
                return <ProgramDataLine key={`pd-line-${label}`} old={old} newV={newComputedValue} label={label} />;
              })}
            </div>
          </>
        )}
      </>
    );
  }

  function RenderCostChanges() {
    const { directHardCostInCents, indirectHardCostInCents, softCostInCents } = computeData.computeReadyPlanCost!;
    const { sellableSqft } = computeData.computeProgramData!;
    const { direct_hard_cost_in_cents, indirect_hard_cost_in_cents, soft_cost_in_cents, hard_costs_per_sqft } =
      readyPlan;

    const newHardCostInCents = directHardCostInCents / 100 / sellableSqft!;

    return (
      <>
        <SectionTitle label="Updated Costs" />
        <div css={Css.df.fdc.gap1.mx5.$}>
          <CostLine label="Soft Cost" old={soft_cost_in_cents! / 100} newV={softCostInCents / 100} />
          <CostLine
            label="Indirect Hard Cost"
            old={indirect_hard_cost_in_cents! / 100}
            newV={indirectHardCostInCents / 100}
          />
          <CostLine
            label="Direct Hard Cost"
            old={direct_hard_cost_in_cents! / 100}
            newV={directHardCostInCents / 100}
          />
          <CostLine label="Hard Costs P/SF" old={hard_costs_per_sqft} newV={newHardCostInCents} />
        </div>
      </>
    );
  }

  // Apply the changes and refresh window
  async function updateRPCosts() {
    const updatedReadyPlan = {
      ...readyPlan,
      bp_ready_plan_template_version: currentIncrement,
      direct_hard_cost_in_cents: computeData.computeReadyPlanCost!.directHardCostInCents,
      indirect_hard_cost_in_cents: computeData.computeReadyPlanCost!.indirectHardCostInCents,
      soft_cost_in_cents: computeData.computeReadyPlanCost!.softCostInCents,
      hard_costs_per_sqft:
        computeData.computeReadyPlanCost!.directHardCostInCents / 100 / computeData.computeProgramData!.sellableSqft!,
    };
    await fetch(SaveUnderwritingReportEndpoint, {
      report: {
        ...report,
        ready_plans: [updatedReadyPlan],
      },
      versionId: report.id,
    });

    window.location.reload();
  }

  return (
    <>
      <RenderProgramChanges />
      <RenderCostChanges />
      <ModalFooterWTip>
        {programDataChanges.length > 0 ? (
          <Button label="Go To Plans" onClick={onChooseNewRp} variant="primary" />
        ) : (
          <Button label="Confirm & Apply" onClick={updateRPCosts} variant="primary" />
        )}
      </ModalFooterWTip>
    </>
  );
}

type LineItemProps = {
  label: string;
  old: number;
  newV: number;
};

// Bolded by default
const ProgramDataLine = ({ label, old, newV }: LineItemProps) => (
  <div key={label} css={Css.df.aic.jcsb.bb.bsDotted.bcGray700.$}>
    <div css={Css.smBd.$}>{label}</div>
    <div css={Css.df.aic.gap(1.5).$}>
      <div>{formatNumberToString(old, true)}</div>
      <Icon inc={2} icon="arrowRight" />
      <div>{formatNumberToString(newV, true)}</div>
    </div>
  </div>
);

// Bolds when inequality; prefixs values with $;
const CostLine = ({ label, old, newV }: LineItemProps) => (
  <div css={Css.df.aic.jcsb.bb.bsDotted.bcGray700.if(old !== newV).smBd.$}>
    <div>{label}</div>
    <div css={Css.df.aic.gap(1.5).$}>
      <div>${formatNumberToString(old, true)}</div>
      <Icon inc={2} icon="arrowRight" />
      <span>${formatNumberToString(newV, true)}</span>
    </div>
  </div>
);

// stored property-api entity -> computed blueprint-ready-plan-compute-data response
export const rpPdMap = {
  num_bedrooms: "bedrooms",
  num_baths: "fullBaths",
  num_half_baths: "halfBaths",
  num_stories: "stories",
  num_garage_attached: "garageAttached",
  num_garage_port: "garagePort",
  sellable_sqft: "sellableSqft",
  sellable_basement_sqft: "sellableBasementSqft",
  below_ground_sqft: "belowGroundSqft",
  width_in_inches: "widthInInches",
  depth_in_inches: "depthInInches",
};
