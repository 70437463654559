import { differenceInDays } from "date-fns";
import { calcFinalPrice, calcTotalAdjustments, isActOrPnd } from "src/utils/tableUtils";
import { ManualAdjustment, Property, PropertyComp } from "../../endpoints";
import { UsedAdjustmentTypes } from "../../endpoints/adj/AmenityAdjustmentEndpoint";
import { AdjType } from "./HpoCompTable";

export function getSortedAmenityAdjustments(
  usedAdjustments: UsedAdjustmentTypes[],
  comps: PropertyComp[],
): [UsedAdjustmentTypes, number][] {
  return (
    usedAdjustments
      .map((adjustment) => {
        // get the average absolute value of each amenity adjustment
        const sum = comps.reduce((acc, cur) => acc + Math.abs(cur.amenity_adjustment![adjustment]), 0);
        return [adjustment, sum / comps.length] as [UsedAdjustmentTypes, number];
      })
      // filter out where the amenity adjustment average is 0
      .filter(([_, avg]) => avg !== 0)
      // sort by the amenity adjustment average
      .sort(([_a, avgA], [_b, avgB]) => avgB - avgA)
  );
}

export interface ManualAdjustmentSummaryProps {
  desc: string;
  total: number;
  weightedNum: number;
  absTotal: number;
}

export function getSortedManualAdjustments(manualAdjs: ManualAdjustment[][], comps: PropertyComp[]) {
  return manualAdjs
    .reduce((acc, cur: ManualAdjustment[]) => {
      cur.forEach((ma: ManualAdjustment) => {
        const desc = ma.description;
        const index = acc.findIndex((a) => a.desc === desc);
        const weight = ma.amount * comps.find((c) => c.dpid_of_neighbor === ma.dpid_of_neighbor)?.weight!;
        if (index === -1) {
          acc.push({ desc, total: ma.amount, weightedNum: weight, absTotal: Math.abs(ma.amount) });
        } else {
          acc[index].total += ma.amount;
          acc[index].absTotal += Math.abs(ma.amount);
          acc[index].weightedNum += weight;
        }
      });
      return acc;
    }, [] as ManualAdjustmentSummaryProps[])
    .filter((adj) => adj.absTotal !== 0)
    .sort((a, b) => b.absTotal - a.absTotal);
}

export function getNumColsToShow(numAmenityAdj: number, numManualAdj: number) {
  const maxNumAdjShown = 5;
  const maxManualAdjShown = 2;
  const totalNumAdjustments = numManualAdj + numAmenityAdj;
  const numOtherColumns = totalNumAdjustments - maxNumAdjShown;
  const shouldShowOtherColumn = numOtherColumns > 0;

  const numManualAdjsShown = shouldShowOtherColumn ? Math.min(numManualAdj, maxManualAdjShown) : numManualAdj;
  const numAmenAdjsShown = shouldShowOtherColumn
    ? Math.min(numAmenityAdj, maxNumAdjShown - numManualAdjsShown)
    : numAmenityAdj;

  return { numManualAdjsShown, numAmenAdjsShown, shouldShowOtherColumn };
}

export function getManualWeightedNum(manualAdjAvgs: ManualAdjustmentSummaryProps[], desc: string) {
  return manualAdjAvgs.find((ma) => ma.desc === desc)!.weightedNum;
}

export function getCompWeightedAvg(
  comps: PropertyComp[],
  prop: keyof PropertyComp,
  adj: AdjType,
  useMLWeight: boolean = false,
) {
  return comps.reduce(
    (acc, cur) => acc + getCompWeightedValue(cur, prop, adj) * ((useMLWeight ? cur.ml_weight : cur.weight) || 0),
    0,
  );
}

function getCompWeightedValue(cur: PropertyComp, prop: keyof PropertyComp, adj: AdjType) {
  switch (adj) {
    case "total_adj":
      return calcTotalAdjustments(cur);
    case "final_val":
      return calcFinalPrice(cur);
    case "final_adj_psf":
      return calcFinalPrice(cur) / cur.finished_sqft;
    case "non_adj":
      // regular comp properties, not adjustments
      return Number(cur[prop])!;
    default:
      // everything else should be amenity adjustments
      if (!cur.amenity_adjustment) return 0;
      return (cur.amenity_adjustment[adj] as number) * (cur.estimated_price ?? 0);
  }
}

export function getCalculatedWeightedAvg(comps: PropertyComp[], type: string) {
  switch (type) {
    case "marketPricePerSqft":
      return calcMarketPsfWeightedAvg(comps);
    case "estimatedCurrentValue":
      return calcEstimatedCurrentValueWeightedAvg(comps);
    case "appreciation":
      return calcAppreciationWeightedAvg(comps);
    case "soldOrListedPricePerSqft":
      return calcPricePsfWeightedAvg(comps);
    case "lastSoldPrice":
      return calcLastSoldPriceWeightedAvg(comps);
    case "mlsListPrice":
      return calcMlsListPriceWeightedAvg(comps);
    default:
      return 0;
  }
}

export function calcMarketPsfWeightedAvg(comps: PropertyComp[]) {
  return comps.reduce((acc, cur) => {
    if (!cur.estimated_price || !cur.finished_sqft || !cur.weight) return acc + 0;

    return acc + (cur.estimated_price / cur.finished_sqft) * cur.weight;
  }, 0);
}

export function calcEstimatedCurrentValueWeightedAvg(comps: PropertyComp[]) {
  return comps.reduce((acc, cur) => {
    if (!cur.weight || !cur.estimated_price) return acc + 0;

    return acc + cur.estimated_price! * cur.weight;
  }, 0);
}

export function calcAppreciationWeightedAvg(comps: PropertyComp[]) {
  return comps.reduce((acc, cur) => {
    if (isActOrPnd(cur) || !cur.estimated_price || !cur.last_sold_price || !cur.weight) return acc + 0;
    return acc + (cur.estimated_price - cur.last_sold_price) * cur.weight;
  }, 0);
}

export function calcPricePsfWeightedAvg(comps: PropertyComp[]) {
  return comps.reduce((acc, cur) => {
    if (!cur.finished_sqft || !cur.weight) return acc + 0;

    const ppsf = isActOrPnd(cur)
      ? (cur.mls_list_price || 0) / cur.finished_sqft
      : (cur.last_sold_price || 0) / cur.finished_sqft;
    return acc + ppsf * cur.weight;
  }, 0);
}

export function calcLastSoldPriceWeightedAvg(comps: PropertyComp[]) {
  return comps.reduce((acc, cur) => {
    if (isActOrPnd(cur) || !cur?.last_sold_price || !cur.weight) return acc + 0;
    return acc + cur.last_sold_price * cur.weight;
  }, 0);
}

export function calcMlsListPriceWeightedAvg(comps: PropertyComp[]) {
  return comps.reduce((acc, cur) => {
    if (!isActOrPnd(cur) || !cur?.mls_list_price || !cur.weight) return acc + 0;
    return acc + cur.mls_list_price * cur.weight;
  }, 0);
}

export interface CalcOtherAdjProps {
  comp: PropertyComp;
  otherAmenityAdj: [UsedAdjustmentTypes, number][];
  otherManualAdj: ManualAdjustmentSummaryProps[];
}
export function calcOtherAdjustments(props: CalcOtherAdjProps): number {
  const { comp, otherAmenityAdj, otherManualAdj } = props;
  return getOtherManualAdjTotal(otherManualAdj, comp) + getOtherAmenityAdjTotal(otherAmenityAdj, comp);
}

function getOtherManualAdjTotal(otherManualAdj: ManualAdjustmentSummaryProps[], comp: PropertyComp) {
  return otherManualAdj.reduce((acc, other) => {
    const manualAdj = comp.manual_adjustments?.find((ma) => ma.description === other.desc);
    if (manualAdj) return acc + manualAdj.amount;
    else return acc;
  }, 0);
}

function getOtherAmenityAdjTotal(otherAmenityAdj: [UsedAdjustmentTypes, number][], comp: PropertyComp) {
  return otherAmenityAdj.reduce((acc, other) => {
    return acc + comp.amenity_adjustment![other[0]] * (comp.estimated_price ?? 0);
  }, 0);
}

export interface CalcTotalOtherAdjProps {
  comps: PropertyComp[];
  otherAmenityAdj: [UsedAdjustmentTypes, number][];
  otherManualAdj: ManualAdjustmentSummaryProps[];
}
export function calcTotalOtherAdjustments({ comps, otherAmenityAdj, otherManualAdj }: CalcTotalOtherAdjProps): number {
  return getOtherManualWeightedAvg(otherManualAdj) + getOtherAmenityWeightedAvg(otherAmenityAdj, comps);
}

function getOtherManualWeightedAvg(otherManualAdj: ManualAdjustmentSummaryProps[]) {
  return otherManualAdj.reduce((acc, other) => acc + other.weightedNum, 0);
}

function getOtherAmenityWeightedAvg(otherAmenityAdj: [UsedAdjustmentTypes, number][], comps: PropertyComp[]) {
  return otherAmenityAdj.reduce((acc, other) => {
    const sum = comps.reduce((acc, cur) => {
      return acc + cur.amenity_adjustment![other[0]] * (cur.estimated_price ?? 0) * cur.weight!;
    }, 0);
    return acc + sum;
  }, 0);
}

export const AMENITY_ADJUSTMENT_TO_NAME_MAPPER: Record<UsedAdjustmentTypes, string> = {
  above_ground_sqft_adj: "Sellable Above Ground Sqft",
  lot_size_adj: "Lot Size",
  year_built_or_renovated_adj: "Age",
  single_family_and_not_single_family_adj: "Property Type",
  num_baths_adj: "Baths",
  has_pool_adj: "Pool",
  garage_num_cars_adj: "Garages",
  num_stories_adj: "Stories",
  basement_sqft_adj: "Basement Size",
  close_to_road_rail_industrial_adj: "Rail Proximity",
  close_to_river_or_lake_adj: "Water Proximity",
  close_to_major_road_adj: "Road Proximity",
  close_to_commercial_adj: "Commercial Proximity",
  close_to_powerlines_adj: "Powerlines Proximity",
  close_to_school_adj: "School Proximity",
  has_scenic_view_adj: "Scenic View",
  finished_basement_sqft_adj: "Finished Basement",
  unfinished_basement_sqft_adj: "Unfinished Basement",
  outdoor_amenity_quality_adj: "Outdoor Amenity Quality",
  street_quality_adj: "Street Quality",
  spec_level_quality_adj: "Spec Level Quality",
  lot_quality_adj: "Lot Quality",
  num_bedrooms_adj: "Bedrooms",
  // NOTE: This is deprecated in favor of above ground sqft but may be on legacy reports
  finished_sqft_adj: "Sellable Above Ground Sqft",
};

export function calcAnnualizedAppreciationRate(property: Property): string | undefined {
  if (!property.last_sold_date || !property.last_sold_price || !property.estimated_price) return undefined;

  const daysSinceSold = differenceInDays(new Date(), new Date(property.last_sold_date));
  return ((Math.pow(property.estimated_price / property.last_sold_price, 365 / daysSinceSold) - 1) * 100).toFixed(1);
}
