import {
  EstimateSection,
  EstStatContainer,
  EstSingleStat,
  LandBasisBuyBox,
} from "src/routes/cma/steps/estimate/components";
import { BoundNumberField, Button, Css } from "@homebound/beam";
import { formatNumberToString } from "src/utils";
import React from "react";
import { EstimateFormState } from "src/routes/cma/steps/estimate/estimate-forms/EstimateForm";
import { UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { ValuationStage } from "src/routes/cma/endpoints/reports/ValuationStage";

type BuyBoxEligibilityFieldsProps = {
  report: UnderwritingReport;
  estimateFormState: EstimateFormState;
};

export function BuyBoxEligibilityFields({ report, estimateFormState }: BuyBoxEligibilityFieldsProps) {
  const { site_acquisition_bid_recommendation: siteAcqBidField } = estimateFormState.reference_property;
  const { backsolved_site_acquisition_target } = report?.costs_and_metrics ?? {};

  const formattedBidRec = `$${formatNumberToString(backsolved_site_acquisition_target!, true, true)}`;
  const isC2C = report.valuation_stage === ValuationStage.clear_to_close;

  return (
    <EstimateSection title="Buy Box Eligibility" numColumns={7}>
      <div css={Css.df.fdc.gap1.$}>
        <EstStatContainer
          bold
          highlight={!estimateFormState.readOnly}
          title="Site Acq Bid Recommendation"
          type="money"
          value={
            <BoundNumberField
              compact
              // Inherit font styles when field becomes readonly
              xss={Css.tac.color("inherit").fw("inherit").lh("inherit").add("fontSize", "inherit").$}
              readOnly={isC2C || estimateFormState.readOnly}
              type="dollars"
              labelStyle="hidden"
              label="Site Acquisition Bid Recommendation"
              field={siteAcqBidField}
              errorMsg={
                !siteAcqBidField.value
                  ? `Required. Set to ${formattedBidRec} to achieve the target investor margin.`
                  : undefined
              }
            />
          }
        >
          {!isC2C && (
            <div css={Css.df.jcsb.aic.ptPx(4).$}>
              <div css={Css.base.$}>Backsolved Site Acq Target Max Bid: {formattedBidRec}</div>
              {!estimateFormState.readOnly && (
                <div css={Css.addIn("> button", Css.px1.h3.add("fontSize", "14px").$).$}>
                  <Button
                    variant="text"
                    size="sm"
                    label="Set to Target Max Bid"
                    onClick={() => siteAcqBidField.set(backsolved_site_acquisition_target)}
                  />
                </div>
              )}
            </div>
          )}
        </EstStatContainer>
      </div>
      <div>
        <EstSingleStat title="Land Basis" value={<LandBasisBuyBox report={report} />} />
      </div>
    </EstimateSection>
  );
}
