import { ObjectConfig, ObjectState } from "@homebound/form-state";
import { otherPlanIds } from "src/routes/cma/steps/readyPlanUtils";
import { Maybe } from "src/utils/types";
import { PropertyComp } from "../../endpoints";
import {
  ReadyPlan,
  SaveCompInput,
  SaveReadyPlanInput,
  SaveUnderwritingReportInput,
  UnderwritingReport,
} from "../../endpoints/reports";

export interface ReadyPlanCompSelectionInput {
  id?: Maybe<number>;
  dpid: string;
  report_ready_plan_id: Maybe<number>;
  ready_plan_id: Maybe<string>;
  ready_plan_sub_id: Maybe<string>;
  bp_ready_plan_id?: Maybe<string>;
  bp_ready_plan_name?: Maybe<string>;
  comps?: SaveCompInput[];
  ready_plans?: SaveReadyPlanInput[];
}

export type FormInput = ReadyPlanCompSelectionInput;
export type FormState = ObjectState<FormInput>;

export const formConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  dpid: { type: "value", readOnly: true },
  report_ready_plan_id: { type: "value", readOnly: true },
  ready_plan_id: { type: "value", readOnly: true },
  ready_plan_sub_id: { type: "value", readOnly: true },
  bp_ready_plan_id: { type: "value", readOnly: true },
  bp_ready_plan_name: { type: "value", readOnly: true },
  ready_plans: {
    type: "list",
    config: {
      id: { type: "value", readOnly: true },
      bp_ready_plan_id: { type: "value", readOnly: true },
      ready_plan_id: { type: "value", readOnly: true },
      ready_plan_sub_id: { type: "value", readOnly: true },
    },
  },
  comps: {
    type: "list",
    config: {
      id: { type: "value" },
      dpid_of_neighbor: { type: "value", readOnly: true },
      user_selected_comp: { type: "value" },
      is_hb_comp: { type: "value", readOnly: true },
      auto_uw_rec: { type: "value", readOnly: true },
      comp_score: { type: "value", readOnly: true },
      selected_comp_id: { type: "value" },
      dist: { type: "value", readOnly: true },
    },
    rules: [
      ({ value }) =>
        !value.some((comp) => comp.user_selected_comp.value === true)
          ? "At least one comp must be selected"
          : undefined,
    ],
  },
};

interface MapToFormInput {
  report: UnderwritingReport;
  readyPlan: ReadyPlan;
  comps: PropertyComp[];
}

export function mapToForm({ report, readyPlan, comps }: MapToFormInput): FormInput {
  const formComps = (comps ?? []).map((c) => ({
    // unselected comps do not have an id, so we need to use the dpid_of_neighbor
    id: c.dpid_of_neighbor,
    dpid_of_neighbor: c.dpid_of_neighbor,
    is_hb_comp: c.is_hb_comp,
    auto_uw_rec: c.auto_uw_rec,
    comp_score: c.comp_score,
    user_selected_comp: c.id ? true : undefined,
    selected_comp_id: c.id,
    dist: c.dist,
  }));

  // need to keep track of other ready plans so they don't get deleted
  const ready_plans = otherPlanIds(report, readyPlan);

  return {
    id: report.id,
    dpid: report.dpid,
    report_ready_plan_id: readyPlan.id,
    ready_plan_id: readyPlan.ready_plan_id,
    ready_plan_sub_id: readyPlan.ready_plan_sub_id,
    comps: formComps,
    bp_ready_plan_id: readyPlan.bp_ready_plan_id,
    bp_ready_plan_name: readyPlan.bp_ready_plan_name,
    ready_plans,
  };
}

export function mapFormToSaveReport(formState: FormState): SaveUnderwritingReportInput {
  const {
    id,
    dpid,
    report_ready_plan_id,
    ready_plan_id,
    ready_plan_sub_id,
    bp_ready_plan_id,
    bp_ready_plan_name,
    comps,
    ready_plans,
  } = formState.value;

  // only save comps that have been changed or are selected
  const changedComps =
    comps
      ?.filter((c) => c.user_selected_comp !== undefined)
      .map((c) => ({
        dpid_of_neighbor: c.dpid_of_neighbor,
        user_selected_comp: c.user_selected_comp,
        is_hb_comp: c.is_hb_comp,
        auto_uw_rec: c.auto_uw_rec,
        comp_score: c.comp_score,
        selected_comp_id: c.selected_comp_id,
        dist: c.dist,
      })) ?? [];

  return {
    id,
    dpid,
    ready_plans: [
      ...(ready_plans ?? []),
      {
        id: report_ready_plan_id,
        bp_ready_plan_id,
        bp_ready_plan_name,
        ready_plan_id,
        ready_plan_sub_id,
        comps: changedComps,
      },
    ],
  };
}
