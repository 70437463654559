import { Css } from "@homebound/beam";
import { CircularProgress } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDLE } from "@rest-hooks/react";
import { capitalCase } from "change-case";
import { useState } from "react";
import { PropertySearchEndpoint, PropertySummary } from "./endpoints";

interface PropertySearchBoxProps {
  onPropertySelected: (property: PropertySummary) => void;
  label?: string | undefined;
}

export function PropertySearchBox({ onPropertySelected, label = "Address" }: PropertySearchBoxProps) {
  const [full_street_address_search, setStreet] = useState<string | undefined>(undefined);

  const params = full_street_address_search
    ? {
        full_street_address_search,
      }
    : null;

  const { data, loading, error } = useDLE(PropertySearchEndpoint, params);

  function loadOptions() {
    if (loading || error) {
      return [];
    }

    return data.properties ?? [];
  }

  function getEmptyText() {
    // figure out why we have no results: waiting for data, no data, or no data requested yet
    return loading ? "Loading..." : params ? "No results found." : "Input Address required";
  }

  return (
    <div css={Css.ttc.$}>
      <Autocomplete
        disablePortal
        options={loadOptions()}
        getOptionLabel={getOptionLabel}
        getOptionSelected={(o, v) => o.dpid === v.dpid}
        onInputChange={(_, v) => setStreet(v)}
        onChange={(_, v) => {
          if (v) {
            onPropertySelected(v);
          }
        }}
        noOptionsText={getEmptyText()}
        filterOptions={(f) => f}
        renderInput={(params) => (
          <TextField
            autoFocus
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
}

export function getOptionLabel(p: PropertySummary): string {
  const unit = p.unit_number ? ` Unit ${p.unit_number}` : "";
  return `${capitalCase(p.full_street_address)}${unit}, ${capitalCase(p.city_name)}, ${p.state} ${p.zip_code}`;
}
