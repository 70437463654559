import { Css, useModal } from "@homebound/beam";
import React from "react";
import SimpleImageSlider from "react-simple-image-slider";
import { PropertyImageModal } from "src/components/PropertyImageModal";
import { Property, PropertyComp, PropertyImage } from "../routes/cma/endpoints";

export interface PropertyImageViewerProps {
  images: PropertyImage[];
  height?: number;
  width?: number;
  showBullets?: boolean;
  startIndex?: number;
  clickable?: boolean;
  property: Property | PropertyComp;
}

export function PropertyImageViewer(props: PropertyImageViewerProps) {
  const { images, height, width, startIndex, clickable, property } = props;
  const { openModal } = useModal();
  const showNavs = images.length > 1;
  const showBullets = props.showBullets !== undefined ? props.showBullets : images.length > 1;

  return (
    <SimpleImageSlider
      style={{ ...Css.if(!!clickable).cursorPointer.$ }}
      width={width ?? 300}
      height={height ?? 240}
      showBullets={showBullets}
      images={images}
      showNavs={showNavs}
      navSize={40}
      navMargin={-8}
      startIndex={startIndex}
      onClick={
        clickable
          ? (startIndex) =>
              openModal({
                size: "xxl",
                content: <PropertyImageModal property={property} images={images} startIndex={startIndex} />,
              })
          : undefined
      }
    />
  );
}
