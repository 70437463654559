import { CheckboxGroup, Css, Icon, Palette, Tooltip } from "@homebound/beam";
import { AnnotationMatcher } from "@nivo/annotations/dist/types/types";
import { CartesianMarkerProps } from "@nivo/core";
import { ResponsiveScatterPlot, ScatterPlotNodeData } from "@nivo/scatterplot";
import { ScatterPlotDatum, ScatterPlotLayerId, ScatterPlotSvgProps } from "@nivo/scatterplot/dist/types/types";
import { capitalCase } from "change-case";
import React, { useMemo, useState } from "react";
import { UWLabel } from "src/components/UWLabel";
import { CompTier, PropertyComp } from "src/routes/cma/endpoints";
import { ReadyPlan } from "src/routes/cma/endpoints/reports";
import { houseSvg } from "src/routes/maps/mapIcons";
import { calculateMarkupPercentage, formatMagnitude, formatNumberToString } from "src/utils";
import { ConfidenceIntervalNode, ConfidenceIntervalProperty, CILegend, tierLegendPalette, CILegendProps } from "./";
import { RangeLabel } from "src/utils/tableUtils";

export interface ConfidenceDatum extends ScatterPlotDatum {
  x: number;
  y: number;
  address: string;
  color: Palette;
  kind: "comp" | "subject";
  weight?: string;
  mlsStatus?: string;
  tier?: CompTier;
  isHbComp?: boolean;
  isMlComp?: boolean;
  // Since we're hacking this graph to begin with our hb icon renders off center in the stacked view.
  // FIXME: Maybe reworking `houseSvg` will help but it's a bit of a trial n error rabbit hole
  chartIsStacked?: boolean;
}

export type ConfidenceIntervalProps = CILegendProps & {
  readyPlan: ReadyPlan;
  rawStreetAddress: string;
  showLegend?: boolean;
  /**
   * Stacks controls, chart, and chart legend in a 100% width flex-direction: column
   */
  stacked?: boolean;
};

export function ConfidenceInterval({
  readyPlan,
  rawStreetAddress,
  showLegend,
  stacked = false,
  ...ciLegendProps
}: ConfidenceIntervalProps) {
  const { comps } = readyPlan;
  const [visibleLayers, setVisibleLayers] = useState<ScatterPlotLayerId[]>(["markers", "annotations"]);

  // Always want nodes to render on top
  const layers: ScatterPlotLayerId[] = useMemo(() => {
    return ["grid", ...visibleLayers, "nodes"];
  }, [visibleLayers]);

  const [compsWPrices, errorComps] = useMemo(() => {
    const compsWPrices: PropertyComp[] = [];
    const errorComps: PropertyComp[] = [];

    comps?.forEach((comp) => {
      if (comp.final_adjusted_price) {
        compsWPrices.push(comp);
      } else {
        errorComps.push(comp);
      }
    });

    return [compsWPrices, errorComps];
  }, [comps]);

  const [dataPoints, propertyAnnotations, subjectPercentIncrements] = useMemo(() => {
    const subjectPropertyDataPoint: ConfidenceDatum = {
      address: capitalCase(rawStreetAddress),
      color: Palette.Blue500,
      x: readyPlan.final_weighted_price!,
      y: 0,
      kind: "subject",
    };

    // ----Build chart data points----
    let chartData: ConfidenceDatum[] = [
      subjectPropertyDataPoint,
      ...compsWPrices.map(
        ({ final_adjusted_price, full_street_address, weight, mls_status, comp_tier, is_hb_comp, auto_uw_rec }) =>
          ({
            address: capitalCase(full_street_address),
            color: mlsStatusPalette[mls_status!]?.color || mlsStatusPalette["UNK"].color,
            // Backend has guards against empty weight
            weight: `${formatNumberToString(weight! * 100, true, false)}%`,
            tier: comp_tier,
            x: final_adjusted_price,
            y: 0,
            kind: "comp",
            isHbComp: is_hb_comp,
            isMlComp: auto_uw_rec,
            chartIsStacked: stacked,
          }) as ConfidenceDatum, // FIXME: TS doesn't know that "comp" is a valid typeof kind,
      ),
    ];

    // !!!Order matters!!!
    chartData.sort((a, b) => a.x - b.x);

    // ----Build annotations----
    let annotations: AnnotationMatcher<ScatterPlotNodeData<ConfidenceDatum>>[] = [];
    let verticalIncrement = 1;

    // For loop because order matters!
    for (let idx = 0; idx <= chartData.length; idx++) {
      const con = chartData[idx];
      const isComp = con?.kind !== "subject";
      annotations.push({
        type: "dot",
        // Set size= 1 to render a small dot that is covered by all node components. Also styling it to be transparent
        // Size 0 actually causes the dot to render with the same size as the node
        size: 1,
        match: { index: idx },
        noteX: 0,
        noteY: !isComp ? 20 : verticalIncrement * -25,
        noteTextOffset: !isComp ? -15 : 8,
        noteWidth: 30,
        note: isComp
          ? `${capitalCase(con?.address || "")}, $${formatMagnitude(con?.x)}`
          : `${capitalCase(con?.address || "")}, $${formatNumberToString(con?.x, true, true)}`,
      });
      isComp && verticalIncrement++;
      verticalIncrement > 4 && (verticalIncrement = 1);
    }

    // ----Build markers----
    // Our chart min/max will be the final weighted value of the subject property +/- 25%
    const markers: CartesianMarkerProps<number>[] = [-0.25, -0.15, -0.1, -0.05, 0.05, 0.1, 0.15, 0.25].map((x) => {
      return {
        ...percentIncMarkerProps,
        lineStyle: {
          stroke: Palette.Gray900,
          strokeWidth: 1.4,
          strokeOutlineWidth: 0,
          strokeDasharray: "30",
          transform: `translateY(${stacked ? 6 : 8}%) scale(.75)`,
        },
        textStyle: {
          fill: Palette.Gray900,
          textAnchor: "middle",
          fontSize: 10,
          transform: stacked ? "translateX(1.5%) translateY(20%)" : undefined,
        },
        value: subjectPropertyDataPoint.x * (1 + x),
        legend: `${x * 100}%`,
      } as CartesianMarkerProps<number>; // FIXME: TS doesn't know that "axis" is a valid typeof `"x" | "y"`
    });
    return [chartData, annotations, markers];
  }, [compsWPrices, rawStreetAddress, readyPlan.final_weighted_price, stacked]);

  // Edge case: If any comp is outside the +/- 25% range we set the min/max of the chart to our data points
  const isWithin25 = useMemo(() => {
    const minDiff = calculateMarkupPercentage(dataPoints[0].x, readyPlan.final_weighted_price!, true) / 100;
    const maxDiff =
      calculateMarkupPercentage(dataPoints[dataPoints.length - 1].x, readyPlan.final_weighted_price!, true) / 100;

    const scaleDiff = Math.abs(minDiff) >= Math.abs(maxDiff) ? Math.abs(minDiff) : Math.abs(maxDiff);

    return scaleDiff <= 0.25;
  }, [dataPoints, readyPlan.final_weighted_price]);

  return (
    <div css={Css.py2.df.aic.bt.bcGray300.relative.if(stacked).gap2.fdc.w100.$}>
      <div
        css={
          Css.ba.br4.bcGray700.df.fdc
            .w(stacked ? "100%" : "300px")
            // NOTE: We want the functionality of checkbox group w/ better styling
            .addIn("> div:first-of-type", Css.px2.pyPx(4).bb.bcGray700.aic.if(stacked).df.gap1.$)
            .addIn("> div:first-of-type label:first-of-type", Css.gray900.$).$
        }
      >
        <CheckboxGroup
          label="Layers:"
          columns={2}
          // FIXME: Why does Beam.CheckboxGroup not take a generic anymore?
          onChange={(values) => setVisibleLayers(values as ScatterPlotLayerId[])}
          options={[
            { label: "Addresses", value: "annotations" },
            { label: "% Increments", value: "markers" },
          ]}
          values={visibleLayers}
        />
        <div css={Css.df.fdc.px2.py1.$}>
          {/* Estimate row */}
          <div css={Css.df.aic.if(!stacked).jcsb.else.gap1.$}>
            <div css={Css.if(!stacked).smBd.else.baseBd.$}>
              Expected Sale Price: ${formatMagnitude(readyPlan.final_weighted_price)}
            </div>
            <UWLabel
              label={tierLegendPalette[readyPlan?.sale_price_tier || "Yellow"].renderEmoji}
              tooltip={<CILegend {...ciLegendProps} />}
              labelTypography={!stacked ? "smBd" : "baseBd"}
            />
          </div>
          {/* Min/Max Comp Range row */}
          <div css={Css.pl2.df.fdc.if(!stacked).wsnw.$}>
            <UWLabel
              label={"Min/Max Comp Range:"}
              tooltip={"The min and max adjusted comp values"}
              labelTypography={!stacked ? "xsBd" : "smBd"}
            />
            <span css={Css.if(!stacked).xs.else.sm.$}>
              <RangeLabel
                label=""
                low={readyPlan.final_weighted_price_low ?? 0}
                high={readyPlan.final_weighted_price_high ?? 0}
                actual={readyPlan.final_weighted_price}
              />
            </span>
          </div>
          {/* ML Confidence Interval row */}
          <div css={Css.pl2.pt1.df.fdc.if(!stacked).wsnw.$}>
            <UWLabel
              label={"Confidence Interval:"}
              tooltip={"ML expected sale price range based on selected comps"}
              labelTypography={!stacked ? "xsBd" : "smBd"}
            />
            <span css={Css.if(!stacked).xs.else.sm.$}>
              <RangeLabel
                label=""
                low={readyPlan.expected_sale_price_low ?? 0}
                high={readyPlan.expected_sale_price_high ?? 0}
                actual={readyPlan.final_weighted_price}
              />
            </span>
          </div>
          {/* ML expected sale price row. Only show when defined. Some very old reports do not have it */}
          {readyPlan.ml_expected_sale_price &&
            readyPlan.ml_expected_sale_price_low &&
            readyPlan.ml_expected_sale_price_high && (
              <div css={Css.pt1.df.fdc.if(!stacked).wsnw.$}>
                <UWLabel
                  label={`ML ESP: ${formatMagnitude(readyPlan.ml_expected_sale_price)}`}
                  tooltip={"ML generated Expected Sale Price"}
                  labelTypography={!stacked ? "smBd" : "baseBd"}
                />
                <div css={Css.pl2.if(!stacked).xs.else.sm.$}>
                  <RangeLabel
                    label=""
                    low={readyPlan.ml_expected_sale_price_low}
                    high={readyPlan.ml_expected_sale_price_high}
                    actual={readyPlan.ml_expected_sale_price}
                  />
                </div>
              </div>
            )}
          {errorComps.length > 0 && (
            <div css={Css.df.absolute.z3.bottom0.red500.$}>
              Unable to calculate sales tier. Please check data for:&nbsp;
              {errorComps.map((comp, idx) => {
                return `${capitalCase(comp.full_street_address)}${idx === errorComps.length - 1 ? "." : ", "}`;
              })}
            </div>
          )}
        </div>
      </div>
      {/* NOTE: the height here must be explicitly set for the chart to render correctly */}
      <div
        css={
          Css.relative.df
            .hPx(stacked ? 220 : 200)
            .w("calc(100% - 300px)")
            .z2.if(stacked).aifs.fdc.w100.jcc.aic.$
        }
      >
        <ResponsiveScatterPlot<ConfidenceDatum>
          // Render components
          nodeComponent={ConfidenceIntervalNode}
          tooltip={ConfidenceIntervalProperty}
          // Memoized data
          xScale={{
            type: "linear",
            // +/- 25% of final weighted price or outrageous comp values
            min: isWithin25 ? readyPlan.final_weighted_price! * (1 - 0.25) : dataPoints[0].x,
            max: isWithin25 ? readyPlan.final_weighted_price! * (0.25 + 1) : dataPoints[dataPoints.length - 1].x,
          }}
          markers={subjectPercentIncrements}
          annotations={propertyAnnotations}
          // We allow users to toggle 'markers' and 'annotations' w/ checkboxGroup
          layers={layers}
          data={[
            {
              id: "property-comps",
              data: dataPoints,
            },
          ]}
          margin={
            stacked
              ? { top: 100, right: 40, bottom: 40, left: 40 }
              : // Right margin is high to accommodate edge case of VERY LONG address at max end of chart
                { top: 100, right: 180, bottom: 40, left: 22 }
          }
          {...confidenceIntervalStaticProps}
        />
        {showLegend && <ConfidenceMLSLegend stacked={stacked} />}
      </div>
    </div>
  );
}

// Easier to position and style an absolute legend than to use nivo's built in
function ConfidenceMLSLegend({ stacked = false }: { stacked?: boolean }) {
  return (
    <Tooltip title="MLS Status" placement="left">
      <div css={Css.df.ba.bcGray300.br4.jcc.aic.p1.gray700.gap2.if(stacked).else.absolute.leftPx(32).bottomPx(-10).$}>
        <div css={Css.df.gap1.$}>
          <div css={Css.br100.wPx(20).hPx(20).bgColor(Palette.Blue500).ba.bcGray700.$} />
          <div>Subject Property</div>
        </div>
        {Object.entries(mlsStatusPalette).map(([status, { color, label }]) => (
          <div css={Css.df.gap1.$} key={status}>
            <div css={Css.br100.wPx(20).hPx(20).bgColor(color).ba.bcGray700.$} />
            <div>{label}</div>
          </div>
        ))}
        <div css={Css.df.gap1.$}>
          {houseSvg(Palette.White, 20)}
          <div>HB Comp</div>
        </div>
        <div css={Css.df.gap1.$}>
          <Icon icon="star" />
          <div css={Css.df.aic.$}>ML Comp</div>
        </div>
      </div>
    </Tooltip>
  );
}

// ----Styling and Default Props----

// Subject: Palette.Blue500
const mlsStatusPalette: { [x: string]: { color: Palette; label: string } } = {
  ACT: { color: Palette.Green400, label: "Active" },
  PND: { color: Palette.Yellow400, label: "Pending" },
  SLD: { color: Palette.Red400, label: "Sold" },
  UNK: { color: Palette.Gray400, label: "Other" },
};

const percentIncMarkerProps = {
  axis: "x",
  // The scatter plot chart fits our data better while needing less work to get labelling and other events up and running.
  //   But the tradeoff is having to hack marker line|text styles a bit to get them to look right
  legendOrientation: "horizontal",
  legendPosition: "bottom-right",
};

const confidenceIntervalStaticProps = {
  theme: {
    annotations: {
      link: {
        stroke: Palette.Gray700,
        outlineWidth: 1,
      },
      outline: {
        stroke: Palette.Gray700,
        outlineColor: Palette.Gray700,
        outlineOpacity: 0,
        outlineWidth: 1,
      },
      symbol: {
        fill: Palette.Transparent,
      },
    },
    grid: {
      line: {
        stroke: Palette.Gray700,
      },
    },
  },
  enableGridX: false,
  gridYValues: [0],
  nodeSize: 30,
  blendMode: "normal",
  axisBottom: null,
  axisLeft: null,
  yScale: {
    type: "linear",
    nice: true,
  },
} as Omit<ScatterPlotSvgProps<ConfidenceDatum>, "data">; // FIXME: TS doesn't think blendMode: "normal" and type: "linear" are valid types
