import { ObjectConfig, ObjectState, required } from "@homebound/form-state";
import { SiteSoftCosts, UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { ValuationStage } from "src/routes/cma/endpoints/reports/ValuationStage";
import { ReferencePropertyInput } from "src/routes/cma/steps/subject-property/ReferencePropertyForm";
import { Maybe } from "src/utils";

export interface SaveReportCostsAndMetricsInput {
  appreciation_time_of_delivery_appreciation?: Maybe<number>;
  backsolve_investor_margin_investor_margin?: Maybe<number>;
  build_margin_homebound_markup_percent?: Maybe<number>;
  custom_costs?: Maybe<number>;
  demo_costs_base?: Maybe<number>;
  financing_costs_financing_rate?: Maybe<number>;
  financing_costs_percent_debt?: Maybe<number>;
  financing_costs_weighted_financing_rate?: Maybe<number>;
  net_sale_price_realtor_fee?: Maybe<number>;
  project_length_pre_con_months?: Maybe<number>;
  project_length_construction_months?: Maybe<number>;
  project_length_sales_period_months?: Maybe<number>;
  site_acquisition_margin_site_markup_percent?: Maybe<number>;
  site_acquisition_closing_cost_percent?: Maybe<number>;
  soft_costs_baseline_costs?: Maybe<number>;
  soft_costs_incremental_site_costs?: Maybe<number>;
}

export interface SaveEstimateFormInput {
  dpid: string;
  costs_and_metrics?: SaveReportCostsAndMetricsInput;
  reference_property?: Pick<
    ReferencePropertyInput,
    "subject_property_market_value" | "site_acquisition_bid_recommendation"
  >;
  site_costs: SiteSoftCosts[];
}

export type EstimateFormInput = SaveEstimateFormInput;
export type EstimateFormState = ObjectState<EstimateFormInput>;

export const estimateFormConfig: ObjectConfig<EstimateFormInput> = {
  dpid: { type: "value" },
  costs_and_metrics: {
    type: "object",
    config: {
      appreciation_time_of_delivery_appreciation: { type: "value", readOnly: true },
      backsolve_investor_margin_investor_margin: { type: "value", rules: [required] },
      build_margin_homebound_markup_percent: { type: "value", rules: [required] },
      custom_costs: { type: "value", readOnly: true },
      demo_costs_base: { type: "value" },
      financing_costs_financing_rate: { type: "value", rules: [required] },
      financing_costs_percent_debt: { type: "value", rules: [required] },
      financing_costs_weighted_financing_rate: { type: "value", rules: [required] },
      net_sale_price_realtor_fee: { type: "value", rules: [required] },
      project_length_pre_con_months: { type: "value", rules: [required] },
      project_length_construction_months: { type: "value", rules: [required] },
      project_length_sales_period_months: { type: "value", rules: [required] },
      site_acquisition_margin_site_markup_percent: { type: "value", rules: [required] },
      site_acquisition_closing_cost_percent: { type: "value", rules: [required] },
      soft_costs_baseline_costs: { type: "value" },
      soft_costs_incremental_site_costs: { type: "value" },
    },
  },
  reference_property: {
    type: "object",
    config: {
      subject_property_market_value: { type: "value" },
      site_acquisition_bid_recommendation: { type: "value" },
    },
  },
  site_costs: {
    type: "list",
    config: {
      cost_code: { type: "value" },
      cost_in_cents: { type: "value" },
    },
  },
};

export const miscSiteCostCode = "0505.001";

export function mapToEstimateForm(report: UnderwritingReport): EstimateFormInput {
  const { dpid, costs_and_metrics, reference_property, site_costs } = report;

  const reportInUnderwriting = report?.valuation_stage === ValuationStage.underwriting;
  const updatedCostsAndMetrics = handleCostMetricPercentFields(costs_and_metrics);

  return {
    dpid,
    costs_and_metrics: updatedCostsAndMetrics,
    reference_property,
    site_costs: site_costs || (reportInUnderwriting ? [{ cost_code: miscSiteCostCode, cost_in_cents: 0 }] : []),
  };
}

// Fields that need to be converted between decimals and percents
// They come in as decimals but need to be displayed as percents
// TODO: these can be removed once sc-21818 is completed
export const percentFields = [
  "financing_costs_weighted_financing_rate",
  "financing_costs_financing_rate",
  "financing_costs_percent_debt",
  "appreciation_time_of_delivery_appreciation",
  "backsolve_investor_margin_investor_margin",
  "land_basis",
];

// Convert decimals to percents
export function handleCostMetricPercentFields(costsAndMetrics: any, restoreDecimal = false) {
  const updatedCostsAndMetrics = { ...costsAndMetrics };
  const multiplier = restoreDecimal ? 0.01 : 100;
  Object.keys(updatedCostsAndMetrics).map((field) => {
    if (percentFields.includes(field)) {
      return (updatedCostsAndMetrics[field] *= multiplier);
    } else {
      return updatedCostsAndMetrics[field];
    }
  });
  return updatedCostsAndMetrics;
}
