import { Endpoint } from "@rest-hooks/endpoint";
import { api_url, auth_headers } from "src/context";
import { SaveCompPropertyDetailsInput } from "./AddCompPropertyDetailsForm";

interface SaveCompPropertyEndpointInput {
  dpid: string;
  versionId: string;
  uwReadyPlanId: number;
  comp: SaveCompPropertyDetailsInput;
}

export const SaveCompPropertyEndpoint = new Endpoint(
  async ({ dpid, versionId, uwReadyPlanId, comp }: SaveCompPropertyEndpointInput) => {
    const headers = {
      "content-type": "application/json",
      ...(await auth_headers()),
    };

    const res = await fetch(
      api_url(`/v1/reports/${dpid}/versions/${versionId}/ready_plans/${uwReadyPlanId}/create_comp`),
      {
        headers,
        method: "POST",
        body: JSON.stringify(comp),
      },
    );
    return await res.json();
  },
  {
    key: ({ dpid, versionId, uwReadyPlanId }) => {
      return `/v1/reports/${dpid}/versions/${versionId}/ready_plans/${uwReadyPlanId}/create_comp`;
    },
    name: "SaveCompPropertyEndpoint",
  },
);
