import { Css, Tag, TagType } from "@homebound/beam";
import React from "react";
import { ImageViewerWithMapImages } from "src/components/ClickableImage";
import { FormattedPrice } from "src/components/FormattedPrice";
import { mdash } from "src/components/mdash";
import { PropertyImageViewer } from "src/components/PropertyImageViewer";
import { FORMAT_BASIC_DATE, Maybe } from "src/utils";
import { domCell } from "src/utils/tableUtils";
import { Property, PropertyComp, PropertyImage } from "../../endpoints";
import { ReadyPlan } from "../../endpoints/reports";

interface SubjectPropertyWidgetProps {
  property: Property;
  readyPlan: ReadyPlan;
}

const cardStyle = Css.wPx(220).hPx(330).bshBasic.br8.df.fdc.oh.relative.$;
const addressStyle = Css.smSb.ttc.my1.$;
const subjectImgArray = [
  {
    // TODO: replace with images based on ready plan
    url: "https://personalization-assets-public.homebound.com/option-images/attIFxfTS8RZq9udW",
  } as PropertyImage,
];

export function SubjectPropertyWidget(props: SubjectPropertyWidgetProps) {
  const { property, readyPlan } = props;

  return (
    <div key={property.dpid} css={cardStyle}>
      <LeftTag text="Subject Property" type="info" />
      <PropertyImageViewer
        property={property}
        images={subjectImgArray}
        width={220}
        height={150}
        showBullets={false}
        clickable
      />
      <div css={Css.p2.$}>
        <div css={addressStyle}>{property.full_street_address.toLowerCase()}</div>
        <InfoLine property="SF" value={readyPlan.sellable_sqft?.toLocaleString()} />
        <InfoLine property="BR" value={readyPlan.num_bedrooms} />
        <InfoLine property="BA" value={readyPlan.num_baths} />
      </div>
    </div>
  );
}

interface PropertyWidgetProps {
  property: PropertyComp;
  weight?: Maybe<number>;
}

export function PropertyWidget(props: PropertyWidgetProps) {
  const { property, weight } = props;
  const { mls_status } = property;
  const lastSoldDate = property.last_sold_date
    ? FORMAT_BASIC_DATE.format(new Date(property.last_sold_date))
    : undefined;

  const hasImages = property.property_images && property.property_images.length > 0;

  return (
    <div key={property.dpid} css={cardStyle}>
      {weight && <RightTag text={`${Math.round(weight * 100)}%`} />}
      <MlsStatusTag mls_status={mls_status} />
      {hasImages ? (
        <PropertyImageViewer
          clickable
          property={property}
          images={property.property_images}
          width={220}
          height={150}
          showBullets={false}
        />
      ) : (
        <ImageViewerWithMapImages clickable property={property} width={220} height={150} />
      )}

      <div css={Css.p2.$}>
        <div css={addressStyle}>{property.full_street_address.toLowerCase()}</div>
        <InfoLine property="SF" value={property.finished_sqft?.toLocaleString()} />
        <InfoLine property="BR" value={property.num_bedrooms} />
        <InfoLine property="BA" value={property.num_baths} />
        <InfoLine property="Last sold" value={lastSoldDate} />
        <InfoLine property="Sold price" value={<FormattedPrice price={property.last_sold_price} />} />
        <InfoLine property="DOM" value={domCell(property)} />
      </div>
    </div>
  );
}

interface InfoLineProps {
  property: string;
  value: string | React.ReactNode;
}
function InfoLine({ property, value }: InfoLineProps) {
  return (
    <div css={Css.df.$}>
      <div css={Css.xsBd.wPx(100).$}>{property} </div>
      <div css={Css.xs.$}>{value ?? mdash}</div>
    </div>
  );
}

interface TagProps {
  text: string;
  type?: TagType | "default";
}
function LeftTag({ text, type }: TagProps) {
  // @ts-ignore
  return <Tag xss={Css.m1.absolute.z1.$} text={text} type={type} />;
}

function RightTag({ text, type }: TagProps) {
  // @ts-ignore
  return <Tag xss={Css.right1.top1.absolute.z1.$} text={text} type={type} />;
}

interface MlsStatusTagProps {
  mls_status: Maybe<string>;
}
function MlsStatusTag({ mls_status }: MlsStatusTagProps) {
  if (!mls_status) return null;

  return <LeftTag text={statusText(mls_status)} type={statusToType(mls_status)} />;
}

function statusToType(status: string) {
  switch (status) {
    case "ACT":
      return "warning";
    case "PND":
      return "caution";
    case "SLD":
      return "success";
    default:
      return "default";
  }
}

function statusText(status: string) {
  switch (status) {
    case "ACT":
      return "ACTIVE";
    case "PND":
      return "PENDING";
    case "SLD":
      return "SOLD";
    default:
      return status;
  }
}
