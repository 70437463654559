import { Endpoint, Entity } from "@rest-hooks/rest";
import * as queryString from "query-string";
import { api_url, auth_headers } from "src/context";
import { Property } from "src/routes/cma/endpoints";
import { UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { UnderwritingOpportunity } from "src/routes/opportunities/endpoints/OpportunityQueueEndpoint";
import { PagingOptions, PagingResult, SortOptions } from "src/routes/paging";
import { ReviewsFilter } from "../ReviewFilter";

interface ReportReviewQueue {
  id: number;
  underwriting_report_id: number;

  created_at: string;
  updated_at: string;
}

export interface ReportFlags {
  id: number;
  final_weighted_price_diff?: number;
  investor_margin_diff?: number;
  new_comps_available?: number;
  new_comps_available_by_rp?: { [key: string]: number };
  custom_price_updates_needed?: { [key: string]: number };
}

export class ReportReviewQueueEntry extends Entity {
  readonly queue!: ReportReviewQueue;
  readonly report!: UnderwritingReport;
  readonly report_flags?: ReportFlags;
  readonly property!: Property;
  readonly opportunity?: UnderwritingOpportunity = undefined;

  pk() {
    return `${this.queue.id}`;
  }

  static key = "ReportReviewQueueEntry";
}

type ReportReviewQueueParams = PagingOptions & SortOptions & ReviewsFilter;

export const ReportReviewQueueEndpoint = new Endpoint(
  async (filter: ReportReviewQueueParams) => {
    const headers = await auth_headers();

    const query = queryString.stringify(filter);
    const res = await fetch(api_url(`/v1/reports/review?${query}`), {
      headers,
      method: "GET",
    });
    const data = await res.json();
    return data;
  },
  {
    schema: { results: [ReportReviewQueueEntry], paging: PagingResult },
    key: (filter: ReportReviewQueueParams) => {
      const query = queryString.stringify(filter);
      return `/v1/review/report_queue?${query}`;
    },
    dataExpiryLength: 1000 * 5, // 5 seconds
    invalidIfStale: true,
    name: "ReportReviewQueueEndpoint",
  },
);
