import { Endpoint, Entity } from "@rest-hooks/rest";
import { api_url, auth_headers } from "src/context";

export class BlueprintDevelopment extends Entity {
  readonly id: string = "";
  readonly name: string = "";

  pk() {
    return this.id;
  }

  static key = "BlueprintDevelopment";
}

export const BlueprintDevelopmentsEndpoint = new Endpoint(
  async ({ metro }: { metro: string }) => {
    const headers = {
      "content-type": "application/json",
      ...(await auth_headers()),
    };
    const res = await fetch(api_url(`/v2/ready_plans/developments?metro=${metro}`), {
      method: "GET",
      headers,
    });
    return await res.json();
  },
  {
    schema: [BlueprintDevelopment],
    key: () => "/v2/ready_plans/developments",
    name: "BlueprintDevelopmentsEndpoint",
  },
);
