// TODO: SC-39535
//   - Common app wide definitions
//   - Page specific definitions
//   - Then one large storybook page to act as an UW app lexicon
type HpoTooltipKey =
  | "grossExpectedSalePrice"
  | "grossExpectedSalePriceTruncated"
  | "valueRange"
  | "confidenceRating"
  | "ppSqft"
  | "hbAvm"
  | "adjValue"
  | "estCurValue"
  | "marketPPSqft"
  | "appreciation"
  | "topMlAdjs"
  | "singleTopAnalystAdj"
  | "topAnalystAdjs"
  | "otherAdjs"
  | "totalAdjs"
  | "adjCompValue"
  | "finalWeight"
  | "mlWeight"
  | "compTier";
const tbd = "TBD: If you have strong opinions please reach us in #uw_app_feedback_and_updates";
type HpoTooltipMatrix = { [x in HpoTooltipKey]: string };
export const hpoTooltips: HpoTooltipMatrix = {
  // ---- Top ----
  grossExpectedSalePrice:
    "Estimated sale price as of the UW date (without HPA); Includes both main + ADU when applicable",
  grossExpectedSalePriceTruncated: "Estimated sale price as of the UW date (without HPA)",
  valueRange: "Min/Max range of adjusted comp values",
  confidenceRating: tbd,
  // ---- Transaction Data ----
  ppSqft:
    "$/SF at sold price (or list price for active/pending properties) prior to any adjustments (amenity or appreciation)",
  // ---- Estimated Market Value ----
  hbAvm: "Estimated market value as of the UW date based on Homebound's proprietary Automated Valuation Model",
  adjValue: "Last sold price adjusted for HPA as of the UW date",
  estCurValue:
    "Analyst estimated current market value as of the UW date based on input from the Homebound AVM, the Adjusted Value, and current market trends",
  marketPPSqft: "Estimated $/SF as of the UW date",
  appreciation: "Estimated Current Value - Sold Price",
  // ---- Adjustments ----
  topMlAdjs: "Top 3 adjustments provided by ML",
  singleTopAnalystAdj: "Top adjustment made by the analyst",
  topAnalystAdjs: "Top 3 adjustments made by the analyst",
  otherAdjs: "Remaining adjustments (refer to 'Adjustments' tab for detailed breakdown",
  totalAdjs: "Total amenity adjustments (ML + analyst)",
  // ---- Adjusted Comp Value ----
  adjCompValue: "Estimated Current Value + Total Adjustments",
  finalWeight: "Final comp weight applied by analyst",
  mlWeight: "Comp weighting % recommended by ML",
  compTier: "Comp rating based on qualification criteria (1 = Best)",
};
