import { Css, TabsWithContent, TabWithContent } from "@homebound/beam";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useDLE } from "@rest-hooks/react";
import { useState } from "react";
import { useDocumentTitle } from "src/hooks/useDocumentTitle";
import { DatabaseInfoTab } from "./DatabaseInfoTab";
import { StatusEndpoint } from "./endpoints/StatusEndpoint";
import { StatusBar } from "./StatusBar";

type TabValue = "database" | "ready_plans";

export function StatusPage() {
  useDocumentTitle("Status");

  const [tab, setTab] = useState<TabValue>("database");

  const { data, loading, error } = useDLE(StatusEndpoint, {
    exact_count: "False",
    include_migration_history: "True",
  });

  function content() {
    if (loading) {
      return <LinearProgress />;
    }

    if (error) {
      return (
        <div>
          <StatusBar status="error" />
          <div css={Css.xl2Sb.py2.$}>Currently unable to reach the API</div>
          <div css={Css.base.$}>Please try again in a few minutes.</div>
        </div>
      );
    }

    // FIXME: AP-UPGRADE: data will be undefined if we have an error.
    //  Will upgrade make this unnecessary or do we have more options in useStatefulResource?
    if (!data) {
      return null;
    }

    const tabs: TabWithContent<TabValue>[] = [
      { name: "Database", value: "database", render: () => <DatabaseInfoTab data={data} /> },
    ];

    return (
      <>
        <StatusBar status={data?.status} />

        <div css={Css.pt4.$}>
          <TabsWithContent tabs={tabs} onChange={setTab} selected={tab} />
        </div>
        <div>
          <div css={Css.xlSb.pt2.pb1.$}>Stage</div>
          <div>{data.stage}</div>
        </div>
      </>
    );
  }

  return (
    <div css={Css.py2.$}>
      <div css={Css.xl4Sb.pt2.pb4.$}>Underwriting App Status</div>
      <div css={Css.if("mdAndUp").w75.$}>{content()}</div>
    </div>
  );
}
