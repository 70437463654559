import { SubmitButton, SubmitButtonProps } from "@homebound/beam";
import { Observer } from "mobx-react";

/**
 * Wrapped in an observer to handle form changes as expected from other form based components
 * (BoundTextField, BoundSelectField, etc.)
 *
 * Also disables the button based on the form's state.
 */
export function BoundSubmitButton<T>(props: SubmitButtonProps<T>) {
  const { form, disabled, ...others } = props;

  return (
    <Observer>
      {() => <SubmitButton form={form} disabled={!form.valid || form.readOnly || disabled} {...others} />}
    </Observer>
  );
}
