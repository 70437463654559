import { Endpoint, Entity } from "@rest-hooks/rest";
import * as queryString from "query-string";
import { api_url, auth_headers } from "src/context";
import { PagingOptions, PagingResult, SortOptions } from "src/routes/paging";
import { Maybe } from "src/utils";

interface OpportunityQueue {
  opportunity_id: string;
  created_at: string;
  updated_at: string;
}

export interface UnderwritingAccount {
  id: string;
  name: string;
  dpid: Maybe<string>;
  dev_area: Maybe<string>;
  neighborhood: Maybe<string>;
  market: Maybe<string>;
  street: Maybe<string>;
  city: Maybe<string>;
  state: Maybe<string>;
  postal_code: Maybe<string>;
  country: Maybe<string>;
  created_at: string;
  updated_at: string;
}

export interface UnderwritingOpportunity {
  id: string;
  account_id: string;
  name: string;
  stage_name: string;
  opportunity_type: string;
  origin: string;
  created_at: string;
  updated_at: string;
  close_date: string;
  zestimate: number;
  asking_price: number;

  account: UnderwritingAccount;
}

export class OpportunityQueueEntry extends Entity {
  readonly queue!: OpportunityQueue;
  readonly opportunity!: UnderwritingOpportunity;

  pk() {
    return `${this.opportunity.id}`;
  }

  static key = "OpportunityQueueEntry";
}

type OpportunityQueueParams = PagingOptions & SortOptions;

export const OpportunityQueueEndpoint = new Endpoint(
  async (filter: OpportunityQueueParams) => {
    const headers = await auth_headers();

    const query = queryString.stringify(filter);
    const res = await fetch(api_url(`/v1/sfdc/opportunity_queue?${query}`), {
      headers,
    });
    return await res.json();
  },
  {
    schema: { results: [OpportunityQueueEntry], paging: PagingResult },
    key: (filter: OpportunityQueueParams) => {
      return `opportunity_queue-${queryString.stringify(filter)}`;
    },
    name: "OpportunityQueueEndpoint",
  },
);
