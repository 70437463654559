import { LoadingBoundary } from "src/components/LoadingBoundary";
import { PageHeader } from "src/components/PageHeader";
import { HpoPage } from "./HpoPage";

export function HpoStep() {
  return (
    <>
      <PageHeader title="Homebound Price Opinion" />
      <LoadingBoundary>
        <HpoPage />
      </LoadingBoundary>
    </>
  );
}
