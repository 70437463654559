import { ObjectConfig, ObjectState, required } from "@homebound/form-state";
import { groupBy } from "lodash";
import { Property } from "src/routes/cma/endpoints";
import { POSSIBLE_USED_MARKET_AMENITIES } from "src/routes/cma/endpoints/adj/AmenityAdjustmentEndpoint";
import {
  ReadyPlan,
  SaveCompPropertyInput,
  SaveManualCompAdjustmentInput,
  SaveReadyPlanInput,
  SaveUnderwritingReportInput,
  UnderwritingReport,
  UpdateSubjectPropertyAdjMetadata,
} from "src/routes/cma/endpoints/reports";
import { marketAmenityColumnRowMapper } from "src/routes/cma/steps/adjustments/AdjustmentTableUtils";
import { otherPlanIds, parseCompBuildDate } from "src/routes/cma/steps/readyPlanUtils";
import { parseMaybeStringDate } from "src/utils";
import { Maybe } from "src/utils/types";

export interface CompAdjustment {
  id: Maybe<string>;
  dpid: string;
  amount?: Maybe<number>;
  manual?: boolean;
}

export interface CompAdjustmentRow {
  id: string;
  description?: Maybe<string>;
  adjustmentsByProperty: CompAdjustment[];
}

export interface CompAdjustments {
  dpid: string;
  report_status: string; // Note: only for showing subjectPropertyPercentAdj error message in UI when not finalized
  report_ready_plan_id: Maybe<number>;
  bp_ready_plan_id: Maybe<string>;
  ready_plan_id: Maybe<string>;
  ready_plan_sub_id: Maybe<string>;
  added_standard_adjustment_rows: string[];

  adjustments: CompAdjustmentRow[];
  ready_plans: SaveReadyPlanInput[];
  subjectPropertyPercentAdj?: Maybe<number>;
  comp_properties: SaveCompPropertyInput[];
}

export type AdjustmentFormInput = CompAdjustments & { subject_property_metadata: UpdateSubjectPropertyAdjMetadata };
export type AdjustmentFormState = ObjectState<AdjustmentFormInput>;

export const adjustmentFormConfig: ObjectConfig<AdjustmentFormInput> = {
  dpid: { type: "value", readOnly: true },
  report_ready_plan_id: { type: "value", readOnly: true },
  bp_ready_plan_id: { type: "value", readOnly: true },
  ready_plan_id: { type: "value", readOnly: true },
  ready_plan_sub_id: { type: "value", readOnly: true },
  report_status: { type: "value", readOnly: true },
  added_standard_adjustment_rows: { type: "value" },
  ready_plans: {
    type: "list",
    config: {
      id: { type: "value", readOnly: true },
      bp_ready_plan_id: { type: "value", readOnly: true },
      ready_plan_id: { type: "value", readOnly: true },
      ready_plan_sub_id: { type: "value", readOnly: true },
      added_standard_adjustment_rows: { type: "value", readOnly: true },
      spec_level: { type: "value" },
    },
  },
  adjustments: {
    type: "list",
    config: {
      id: { type: "value" },
      description: { type: "value" },
      adjustmentsByProperty: {
        type: "list",
        config: {
          id: { type: "value" },
          dpid: { type: "value" },
          amount: { type: "value" },
        },
      },
    },
  },
  subject_property_metadata: {
    type: "object",
    config: {
      street_quality: { type: "value" },
      lot_quality: { type: "value" },
      has_scenic_view: { type: "value" },
      close_to_commercial: { type: "value" },
      close_to_powerlines: { type: "value" },
      close_to_school: { type: "value" },
      close_to_major_road: { type: "value" },
    },
  },
  subjectPropertyPercentAdj: { type: "value" },
  comp_properties: {
    type: "list",
    rules: [
      ({ value }) => {
        const all_weights = value.reduce((acc, row) => {
          return acc + (row.weight?.value ?? 0);
        }, 0);

        if (all_weights < 0.991 || all_weights > 1.001) {
          return `Weights must total 1, currently ${all_weights.toFixed(2)}`;
        }
      },
      ({ value }) => {
        if (value.some((v) => v.estimated_price.value === 0)) {
          return `Select or enter an estimated price for each property`;
        }
      },
    ],
    config: {
      dpid_of_neighbor: { type: "value" },
      year_built_or_renovated: { type: "value" },
      num_bedrooms: { type: "value" },
      num_baths: { type: "value" },
      num_stories: { type: "value" },
      has_pool: { type: "value" },
      garage_num_cars: { type: "value" },
      finished_sqft: { type: "value" },
      lot_size: { type: "value" },
      last_sold_price: { type: "value" },
      last_sold_date: { type: "value" },
      estimated_price: { type: "value" },
      weight: { type: "value" },
      close_to_major_road: { type: "value" },
      close_to_road_rail_industrial: { type: "value" },
      close_to_river_or_lake: { type: "value" },
      basement_sqft: { type: "value" },
      property_type_simple: { type: "value" },
      spec_level: { type: "value", rules: [required] },
      above_ground_sqft: { type: "value" },
      finished_basement_sqft: { type: "value" },
      unfinished_basement_sqft: { type: "value" },
      outdoor_amenity_quality: { type: "value" },
      street_quality: { type: "value" },
      lot_quality: { type: "value" },
      close_to_commercial: { type: "value" },
      close_to_powerlines: { type: "value" },
      close_to_school: { type: "value" },
      has_scenic_view: { type: "value" },
      builder_name: { type: "value" },
    },
  },
};

// export const validateWeights: Rule<CompAdjustments, readonly ObjectState<SaveCompPropertyInput, any>[]> = ;

interface MapToFormInput {
  report: UnderwritingReport;
  readyPlan: ReadyPlan;
  property: Property;
}

export function mapToAdjustmentForm({ report, readyPlan, property }: MapToFormInput): AdjustmentFormInput {
  const comps = readyPlan?.comps ?? [];
  const addedAdjustmentRows = readyPlan?.added_standard_adjustment_rows ?? [];
  const propertyAdjustmentValue = getPropertyAdjustmentValue();

  // Note: The property_adjustments attribute still exists in finalized legacy reports for historical purposes and needs
  //   to be manually zeroed out when versioned from a legacy report.
  function getPropertyAdjustmentValue() {
    if (readyPlan?.property_adjustments && readyPlan?.property_adjustments[0]) {
      return readyPlan?.property_adjustments[0].value;
    }
    return 0;
  }

  const existingManualAdjustments = comps.flatMap((c) => c.manual_adjustments ?? []);
  const byDesc = groupBy(existingManualAdjustments, (a) => a.description);

  const asRow: CompAdjustmentRow[] = Object.entries(byDesc).map(([description, adjs]) => {
    const adjustmentsByProperty: CompAdjustment[] = comps.map((c) => {
      const adj = adjs.find((a) => a.dpid_of_neighbor === c.dpid_of_neighbor);
      return adj
        ? { id: String(adj.id), dpid: adj.dpid_of_neighbor, amount: adj.amount * 100 } // as cents
        : {
            id: `_new_${description}_${c.id}`, // temporary placeholder id
            dpid: c.dpid_of_neighbor,
          };
    });

    return {
      id: description,
      description,
      adjustmentsByProperty,
      manual: true,
    };
  });

  const marketAmenityAdjustmentsUsed = POSSIBLE_USED_MARKET_AMENITIES.filter((possibleFeat) =>
    comps.every((pc) => pc.amenity_adjustment?.used_features.includes(possibleFeat)),
  );

  const marketAmenityRows: CompAdjustmentRow[] = marketAmenityAdjustmentsUsed
    .map((amenity) => {
      const amenityHelper = marketAmenityColumnRowMapper[amenity];
      const adjustmentsByProperty: CompAdjustment[] = comps.map((c) => {
        return {
          id: String(c.id),
          dpid: c.dpid_of_neighbor,
          amount: amenityHelper.adjustmentCoef(c.amenity_adjustment) * (c.neighbor!.estimated_price ?? 0),
        };
      });

      return {
        id: amenity,
        description: amenityHelper.rowDescription,
        order: amenityHelper.order,
        adjustmentsByProperty,
      };
    })
    .sort((a, b) => {
      return a.order - b.order;
    });

  asRow.unshift(...marketAmenityRows);

  const { ready_plan_id, ready_plan_sub_id, bp_ready_plan_id } = readyPlan;

  // need to keep track of other ready plans, so they don't get deleted
  const ready_plans = otherPlanIds(report, readyPlan);

  function yearToString(year: number | undefined): string | undefined {
    return year ? String(year) : undefined;
  }

  const comp_properties: SaveCompPropertyInput[] = comps.map((c) => {
    const { dpid, last_sold_date, ...p } = c.neighbor!;

    return {
      ...p,
      builder_name: c?.builder_name ?? p.builder_name,
      year_built_or_renovated: yearToString(parseCompBuildDate(p.year_built_or_renovated)),
      dpid_of_neighbor: dpid,
      last_sold_date: parseMaybeStringDate(last_sold_date),
      weight: c.weight,
    };
  });

  return {
    dpid: report.dpid,
    report_status: report.status,
    added_standard_adjustment_rows: addedAdjustmentRows,
    report_ready_plan_id: readyPlan.id,
    bp_ready_plan_id,
    ready_plan_id,
    ready_plan_sub_id,
    ready_plans,
    adjustments: asRow,
    comp_properties,
    subjectPropertyPercentAdj: propertyAdjustmentValue,
    subject_property_metadata: {
      street_quality: property.street_quality,
      lot_quality: property.lot_quality,
      has_scenic_view: property.has_scenic_view,
      close_to_commercial: property.close_to_commercial,
      close_to_powerlines: property.close_to_powerlines,
      close_to_school: property.close_to_school,
      close_to_major_road: property.close_to_major_road,
    },
  };
}

export function mapToAddManualAdjustmentInput(
  form: AdjustmentFormState,
  description: string,
  dpids: string[],
): SaveUnderwritingReportInput {
  const { dpid, report_ready_plan_id, ready_plan_id, ready_plan_sub_id, ready_plans, bp_ready_plan_id } = form.value;

  const manual_comp_adjustments = dpids.map((dpid_of_neighbor) => {
    return {
      dpid_of_neighbor,
      description,
      amount: 0,
      id: undefined,
    } as SaveManualCompAdjustmentInput;
  });

  return {
    dpid,
    ready_plans: [
      ...(ready_plans ?? []),
      {
        id: report_ready_plan_id,
        ready_plan_id,
        ready_plan_sub_id,
        bp_ready_plan_id,
        manual_comp_adjustments,
      },
    ],
  };
}

export function mapToDeleteManualAdjustmentInput(
  form: AdjustmentFormState,
  description: string,
  adjustmentsByProperty: CompAdjustment[],
): SaveUnderwritingReportInput {
  const { dpid, report_ready_plan_id, ready_plan_id, ready_plan_sub_id, ready_plans, bp_ready_plan_id } = form.value;

  const manual_comp_adjustments = adjustmentsByProperty
    .filter(({ id }) => !id?.startsWith("_new_"))
    .map(({ dpid, amount, id }) => {
      return {
        dpid_of_neighbor: dpid,
        description,
        amount,
        id,
        delete: true,
      } as SaveManualCompAdjustmentInput;
    });
  return {
    dpid,
    ready_plans: [
      ...(ready_plans ?? []),
      {
        id: report_ready_plan_id,
        ready_plan_id,
        ready_plan_sub_id,
        bp_ready_plan_id,
        manual_comp_adjustments,
      },
    ],
  };
}
