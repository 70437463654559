import {
  BoundSelectField,
  BoundSwitchField,
  BoundTextField,
  Button,
  Css,
  FieldGroup,
  FormLines,
  SubmitButton,
  useComputed,
} from "@homebound/beam";
import { ObjectConfig, ObjectState, required, useFormState } from "@homebound/form-state";
import { useSuspense } from "@rest-hooks/react";
import { Feature, Polygon } from "geojson";
import { Observer } from "mobx-react";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { LoadingBoundary } from "src/components/LoadingBoundary";
import { PageHeader, PageHeaderActions } from "src/components/PageHeader";
import { useController } from "src/hooks";
import { useDocumentTitle } from "src/hooks/useDocumentTitle";
import { KMLImportButton, handleKMLFile } from "src/routes/admin/polygons/components/KMLImportButton";
import { ReportMetro, UnderwritingReportMetrosEndpoint } from "src/routes/cma/endpoints/reports";
import { getPolygonColor } from "src/routes/maps/mapUtils";
import { beautifyMetro } from "src/routes/reports/ReportsPage";
import { polygonsListPath } from "src/routes/routesDef";
import { Maybe, groupBy } from "src/utils";
import { PolygonContext } from "./PolygonContext";
import {
  CreatePolygonGroupEndpoint,
  PolygonGroupEndpoint,
  PolygonListEndpoint,
  SavePolygonGroupInput,
  SavePolygonInput,
  UWPolygonType,
  UpdatePolygonGroupEndpoint,
  UwPolygonFeatureCollection,
  defaultPolygonName,
} from "./PolygonEndpoints";
import { PolygonMap } from "./PolygonMap";
import { PolygonUpdateType } from "./components/PolygonEditor";
import { PolygonPane } from "./components/PolygonPane";

export function PolygonPage() {
  useDocumentTitle("Polygon Collection");
  const { polygonCollectionId } = useParams<{ polygonCollectionId: string }>();
  return (
    <>
      <PageHeader title="Polygon Collection" />
      <LoadingBoundary useProgressBar>
        <LoadPolygon polygonCollectionId={polygonCollectionId} />
      </LoadingBoundary>
    </>
  );
}

type LoadPolygonProps = { polygonCollectionId: string };

export function LoadPolygon({ polygonCollectionId }: LoadPolygonProps) {
  const polygonEndpointParams = polygonCollectionId === "new" ? null : { polygonCollectionId };
  const { feature_collection } = useSuspense(PolygonGroupEndpoint, polygonEndpointParams);
  const { metros } = useSuspense(UnderwritingReportMetrosEndpoint);
  const [selectedPolygonId, setSelectedPolygonId] = useState<Maybe<number>>(null);
  const initialVisStates = useComputed(() => {
    return (
      Object.values(UWPolygonType).map((f) => ({
        isVisible: true,
        type: f,
      })) ?? []
    );
  }, [feature_collection]);

  const [polygonVisibility, setPolygonVisibility] = useState(initialVisStates);

  return (
    <PolygonContext.Provider
      value={{
        selectedPolygonId,
        setSelectedPolygonId,
        polygonVisibility,
        setPolygonVisibility,
        collectionName: feature_collection?.properties.name,
      }}
    >
      <PolygonForm featureCollection={feature_collection} metros={metros} />
    </PolygonContext.Provider>
  );
}

type PolygonFormProps = {
  featureCollection: UwPolygonFeatureCollection | undefined;
  metros: ReportMetro[];
};

export function PolygonForm({ featureCollection, metros }: PolygonFormProps) {
  const { fetch } = useController();
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const { setPolygonVisibility } = useContext(PolygonContext);

  const formState = useFormState({
    config: polygonFormConfig,
    init: {
      input: featureCollection,
      map: mapToPolygonForm,
      ifUndefined: {
        id: undefined,
        active: true,
        name: defaultPolygonName,
        metro: "",
        polygons: [],
      },
    },
  });

  useEffect(() => {
    const newVisStates = Object.values(UWPolygonType).map((type) => {
      const isVisible = formState.polygons.value.some((polygon) => polygon.type === type);
      return { isVisible, type };
    });
    setPolygonVisibility(newVisStates);
  }, [formState.polygons.value, setPolygonVisibility]);

  function onPolygonFeatureUpdated(polygonFeature: Feature, updateType: PolygonUpdateType) {
    const existingPolygon =
      polygonFeature.properties?.id &&
      formState.polygons.rows.find((p) => p.id.value === polygonFeature.properties?.id);

    switch (updateType) {
      case "Create":
        if (!existingPolygon) {
          addNewFeatureToFormState(polygonFeature);
        }
        break;
      case "Edit":
        existingPolygon?.geometry.set(polygonFeature.geometry as Polygon);
        break;
      case "Delete":
        existingPolygon?.value && formState.polygons.remove(existingPolygon.value);
        break;
      default:
        console.error(`${updateType} is an unknown polygon update type`);
    }
    saveForm();
  }

  function addNewFeatureToFormState(polygonFeature: Feature) {
    const newFeature = getDefaultPolygon(polygonFeature);
    formState.polygons.set([...formState.polygons.value, newFeature]);
  }

  async function saveForm() {
    if (!formState.canSave()) return;
    setSaving(true);
    const polygonInput = mapFormToPolygonInput(formState);

    let response;
    if (formState.id.value === undefined) {
      response = await fetch(CreatePolygonGroupEndpoint, { ...polygonInput });
    } else {
      response = await fetch(UpdatePolygonGroupEndpoint, { ...polygonInput });
    }

    const updatedState = mapToPolygonForm(response.feature_collection);
    formState.set({ ...updatedState });

    setSaving(false);

    // if still on "new" path, switch to the newly created polygon page
    if (history.location.pathname.includes("new")) {
      // this will update the url without reloading the page
      window.history.replaceState(null, "", `/admin/polygons/${response.feature_collection.id}`);
    }
  }

  const groupedPolygons: Record<UWPolygonType, SavePolygonInput[]> = useComputed(
    () => groupBy(formState.polygons.value, ({ type }) => type as UWPolygonType),
    [formState.polygons.value],
  );

  function renderForm() {
    return (
      <div css={Css.dg.$} data-testid="polygonForm">
        <PageHeaderActions>
          <>
            <KMLImportButton
              onFileUpload={handleKMLFile}
              onKmlImport={addNewFeatureToFormState}
              saveForm={saveForm}
              disabled={!formState.metro.value || !formState.name.value}
            />
            <SubmitButton
              label="Save Changes"
              form={formState}
              onClick={async () => {
                await saveForm();
                await fetch(PolygonListEndpoint);
                history.push(polygonsListPath);
              }}
              disabled={
                saving || !formState.canSave() || (formState.errors.length ? formState.errors.join(". ") : false)
              }
            />
            <Button
              label="Back to All Polygons"
              variant="secondary"
              onClick={async () => {
                await fetch(PolygonListEndpoint);
                history.push(polygonsListPath);
              }}
            />
          </>
        </PageHeaderActions>
        <div css={Css.df.$}>
          <div css={Css.w(`calc(100% - ${250}px)`).$}>
            <FormLines width="full">
              <FieldGroup>
                <BoundTextField
                  label="Name"
                  field={formState.name}
                  placeholder="Enter polygon collection name"
                  helperText="Dev Area polygons will inherit this name"
                  onBlur={() => {
                    formState.polygons.rows.forEach((p) => {
                      if (p.type.value === UWPolygonType.dev_area) {
                        p.name.set(formState.name.value);
                      }
                    });
                  }}
                />
                <BoundSelectField
                  label="Metro"
                  field={formState.metro}
                  options={metros ?? []}
                  getOptionLabel={(o) => beautifyMetro(o.metro).full}
                  getOptionValue={(o) => o.metro}
                  placeholder="Select metro area to display map"
                />
                <BoundSwitchField label="Active" field={formState.active} />
              </FieldGroup>
            </FormLines>
            {formState.metro.value && (
              <PolygonMap formState={formState} onPolygonFeatureUpdated={onPolygonFeatureUpdated} saveForm={saveForm} />
            )}
          </div>
          <div css={Css.wPx(250).maxh(`calc(100vh - ${200}px)`).oa.pl2.$}>
            <PolygonPane polygons={groupedPolygons} />
          </div>
        </div>
      </div>
    );
  }

  return <Observer>{() => renderForm()}</Observer>;
}

export type PolygonFormState = ObjectState<FormInput>;
type FormInput = SavePolygonGroupInput;

export const polygonFormConfig: ObjectConfig<FormInput> = {
  id: { type: "value" },
  active: { type: "value", rules: [required] },
  name: { type: "value", rules: [required] },
  metro: { type: "value", rules: [required] },
  polygons: {
    type: "list",
    rules: [({ value }) => (value.length === 0 ? "Create a polygon" : undefined), required],
    config: {
      id: { type: "value" },
      active: { type: "value", rules: [required] },
      name: { type: "value", rules: [required] },
      type: { type: "value", rules: [required] },
      color: { type: "value" },
      geometry: {
        type: "value",
        rules: [
          ({ value }) => {
            if (!value ?? value?.coordinates.length === 0) {
              return `Create a polygon`;
            }
          },
        ],
      },
    },
  },
};

function mapToPolygonForm(featureCollection: UwPolygonFeatureCollection): FormInput {
  const { properties, features } = featureCollection;

  return {
    id: featureCollection.id,
    active: properties.active,
    name: properties.name,
    metro: properties.metro,
    polygons: features.map((f) => ({
      id: f.id,
      active: f.properties.active,
      name: f.properties.name,
      type: f.properties.type,
      color: f.properties.color,
      geometry: { type: f.geometry.type, coordinates: f.geometry.coordinates },
    })),
  };
}

function mapFormToPolygonInput(formState: PolygonFormState): SavePolygonGroupInput {
  return {
    id: formState.id.value,
    active: formState.active.value,
    name: formState.name.value,
    metro: formState.metro.value,
    polygons: formState.polygons.rows.map((p) => {
      // Dev Areas should have the same name as the collection
      const polygonName = p.type.value === UWPolygonType.dev_area ? formState.name.value : p.name.value;

      return {
        id: p.id.value,
        active: p.active.value,
        name: polygonName,
        type: p.type.value,
        color: p.color.value,
        geometry: p.geometry.value,
      };
    }),
  };
}

function getDefaultPolygon(feature: Feature): SavePolygonInput {
  return {
    active: true,
    name: feature.properties?.name ?? defaultPolygonName,
    type: feature.properties?.type ?? "neighborhood",
    color: getPolygonColor({
      isActive: true,
      color: feature.properties?.color,
      type: feature.properties?.type as UWPolygonType,
    }),
    geometry: feature.geometry as Polygon,
  };
}
