import { Endpoint, Entity } from "@rest-hooks/rest";
import { api_url, auth_headers } from "src/context";

export interface UnderwritingSubjectPropertyInput {
  development_id: string;
  development_name: string;
}

export class UnderwritingSubjectProperty extends Entity {
  readonly dpid: string = "";
  readonly development_id: string = "";
  readonly development_name: string = "";

  pk() {
    return `${this.dpid}`;
  }

  static key = "UnderwritingSubjectProperty";
}

interface UnderwritingSubjectPropertyParams {
  dpid: string;
  uw_subj_property: UnderwritingSubjectPropertyInput;
}

export const SaveUnderwritingSubjectPropertyEndpoint = new Endpoint(
  async ({ dpid, uw_subj_property }: UnderwritingSubjectPropertyParams) => {
    const headers = {
      "content-type": "application/json",
      ...(await auth_headers()),
    };

    const res = await fetch(api_url(`/v2/uw_subj_properties/${dpid}`), {
      headers,
      method: "POST",
      body: JSON.stringify(uw_subj_property),
    });
    return await res.json();
  },
  {
    schema: { uw_subj_property: UnderwritingSubjectProperty },
    key: ({ dpid }) => {
      return `/v2/uw_subj_properties/${dpid}`;
    },
    name: "SaveUnderwritingSubjectPropertyEndpoint",
  },
);

export const UnderwritingSubjectPropertyEndpoint = new Endpoint(
  async ({ dpid }: { dpid: string }) => {
    const headers = await auth_headers();

    const res = await fetch(api_url(`/v2/uw_subj_properties/${dpid}`), {
      method: "GET",
      headers,
    });

    return await res.json();
  },
  {
    schema: { uw_subj_property: UnderwritingSubjectProperty },
    key: ({ dpid }) => {
      return `/v2/uw_subj_properties/${dpid}`;
    },
    name: "UnderwritingSubjectPropertyEndpoint",
  },
);
