import { IReactionDisposer, IReactionOptions, IReactionPublic, reaction } from "mobx";
import { useEffect, useRef } from "react";

/** A hook approach to using mobx's `reaction` function. */
export function useReaction<T, FireImmediately extends boolean = false>(
  expression: (r: IReactionPublic) => T,
  effect: (arg: T, prev: FireImmediately extends true ? T | undefined : T, r: IReactionPublic) => void,
  opts: IReactionOptions<T, FireImmediately> = {},
  deps: readonly any[],
): void {
  const reactionRef = useRef<IReactionDisposer>();
  useEffect(() => {
    reactionRef.current = reaction(expression, effect, opts);

    // If the deps change or component is unmounted, then dispose of the existing reaction.
    return () => reactionRef.current && reactionRef.current();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
