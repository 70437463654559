import { BlueprintProgramData } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlansEndpoint";
import { formatNumberToString, Maybe } from "src/utils";
import { formatInchesToFeet } from "../formatters";

export interface DataSummary {
  key: string;
  summary: string;
}

export function summarizeProgramData(programData: Maybe<Partial<BlueprintProgramData>>): DataSummary[] {
  if (!programData) return [];

  function formatEntry(key: string, v: number | undefined): DataSummary | undefined {
    if (v) {
      if (key.endsWith("InInches")) {
        const fv = formatInchesToFeet(v);
        const fk = key.replace("InInches", "");

        return {
          key,
          summary: `${fk}: ${fv}`,
        };
      }

      if (v >= 1000) {
        return { key, summary: `${key}: ${formatNumberToString(v, true, false)}` };
      }

      return { key, summary: `${key}: ${v}` };
    }
  }

  const summaries = Object.entries(programData).map(([k, v]) => formatEntry(k, v));
  return summaries.filter((v) => v !== undefined) as DataSummary[];
}
