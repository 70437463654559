import { FilterDefs, HasIdAndName, multiFilter, numberRangeFilter, singleFilter, toggleFilter } from "@homebound/beam";
import { NumberRangeFilterValue } from "@homebound/beam/dist/components/Filters/NumberRangeFilter";
import { useMemo } from "react";
import { propertyTypeOptions } from "src/utils/mappers";
import { CompTier, PropertyComp, PropertyType } from "../../endpoints";

export type SelectionCompFilter = {
  showCompSelections?: "selectedOnly" | "all";
};

export function runSelectedCompFilter(comps: PropertyComp[], selectedFilter: string): PropertyComp[] {
  if (selectedFilter === "selectedOnly") {
    return comps.filter((c) => !!c.id);
  }

  return comps ?? [];
}

export type CompFilter = {
  tierFilter?: CompTier[];
  lastSoldFilter?: LastSoldFilter;
  mlsStatusFilter?: MlsStatusFilter;
  yearBuiltRangeFilter?: NumberRangeFilterValue;
  propertyTypeFilter?: PropertyType[];
  buildingSizeFilter?: NumberRangeFilterValue;
  lotSizeFilter?: NumberRangeFilterValue;
  distanceFilter?: DistanceFilter;
  ignorePolygonSearchFilter?: boolean;
};

export function useCompFilter() {
  const filterDefs: FilterDefs<CompFilter> = useMemo(() => {
    const tierFilter = multiFilter<HasIdAndName<CompTier>, CompTier>({
      label: "Tiers",
      options: compTierFilterOptions,
      getOptionValue: (option) => option.id,
      getOptionLabel: (option) => option.name,
    });

    const propertyTypeFilter = multiFilter<HasIdAndName<PropertyType>, PropertyType>({
      label: "Property Type",
      options: propertyTypeOptions,
      getOptionValue: (option) => option.id,
      getOptionLabel: (option) => option.name,
    });

    const lastSoldFilter = singleFilter({
      label: "Last Sold",
      options: lastSoldOptions,
      getOptionValue: (option) => option.id,
      getOptionLabel: (option) => option.name,
    });

    const mlsStatusFilter = singleFilter({
      label: "MLS Status",
      options: mlsStatusOptions,
      getOptionValue: (option) => option.id,
      getOptionLabel: (option) => option.name,
    });

    const distanceFilter = singleFilter({
      label: "Max Distance in Miles",
      options: distanceOptions,
      getOptionValue: (option) => option.id,
      getOptionLabel: (option) => option.name,
    });

    const yearBuiltRangeFilter = numberRangeFilter({
      label: "Year Built",
      numberFormatOptions: { useGrouping: false },
    });

    const buildingSizeFilter = numberRangeFilter({
      label: "Building Sqft",
    });

    const lotSizeFilter = numberRangeFilter({
      label: "Lot Sqft",
    });

    const ignorePolygonSearchFilter = toggleFilter({
      label: "Ignore Search Polygon",
      defaultValue: false,
    });

    return {
      tierFilter,
      propertyTypeFilter,
      lastSoldFilter,
      mlsStatusFilter,
      distanceFilter,
      yearBuiltRangeFilter,
      buildingSizeFilter,
      lotSizeFilter,
      ignorePolygonSearchFilter,
    };
  }, []);
  return filterDefs;
}

const compTierFilterOptions: HasIdAndName<CompTier>[] = [
  { id: "Tier 1", name: "Tier 1" },
  { id: "Tier 2", name: "Tier 2" },
  { id: "Tier 3", name: "Tier 3" },
  { id: "Tier 4", name: "Tier 4" },
  { id: "Tier 5", name: "Tier 5" },
];

// id must be in the format of "timeframe_number"
// timeframe can be days, weeks, months, or years
// number must be an integer
type LastSoldFilter = "weeks_1" | "months_1" | "months_3" | "months_6" | "years_1" | "years_2";
const lastSoldOptions: HasIdAndName<LastSoldFilter>[] = [
  { id: "weeks_1", name: "1 week" },
  { id: "months_1", name: "1 month" },
  { id: "months_3", name: "3 months" },
  { id: "months_6", name: "6 months" },
  { id: "years_1", name: "1 year" },
  { id: "years_2", name: "2 years" },
];

type MlsStatusFilter = "active" | "notActive";
const mlsStatusOptions: HasIdAndName<MlsStatusFilter>[] = [
  { id: "active", name: "Active or Pending" },
  { id: "notActive", name: "Not Active" },
];

type DistanceFilter = 0.25 | 0.5 | 0.75 | 1;
const distanceOptions: HasIdAndName<DistanceFilter>[] = [
  { id: 0.25, name: "0.25" },
  { id: 0.5, name: "0.50" },
  { id: 0.75, name: "0.75" },
  { id: 1, name: "1" },
];
