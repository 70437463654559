import React from "react";
import { ReadyPlanOutdatedBanner } from "src/components/ReadyPlanOutdatedBanner";
import { VersionWarningBanner } from "src/components/VersionWarningBanner";
import { ActivityState } from "src/routes/cma/endpoints/reports/ActivityState";
import { useStepperContext } from "src/routes/cma/stepper";
import { hasV2ReadyPlans } from "src/routes/cma/steps/readyPlanUtils";

// Relies on useStepperContext()
export function ReportWarningBanners() {
  const { report } = useStepperContext();
  if (!report) return null;

  const inactiveOrArchivedReport = report.activity_state !== ActivityState.active;
  const v2Report = !inactiveOrArchivedReport && hasV2ReadyPlans(report);

  // NOTE: We only show one banner at a time.
  // inactive/archived reports cannot be updated making it not matter if the RP is out of date
  return (
    <>
      {inactiveOrArchivedReport ? (
        <VersionWarningBanner
          activityState={report?.activity_state}
          underwrittenAt={report?.underwritten_at}
          lastUpdated={report.updated_at}
        />
      ) : (
        v2Report && <ReadyPlanOutdatedBanner report={report} />
      )}
    </>
  );
}
