import { Css, Direction, Filters } from "@homebound/beam";
import { useState } from "react";
import { useSuspense } from "@rest-hooks/react";
import { LoadingBoundary } from "src/components/LoadingBoundary";
import { PageHeader } from "src/components/PageHeader";
import { PagingOptions, SortOptions } from "../paging";
import { Pagination } from "../reports/components/Pagination";
import { OpportunityQueueEndpoint } from "./endpoints/OpportunityQueueEndpoint";
import { OpportunitiesFilter, useOpportunitiesFilter } from "./OpportunitiesFilter";
import { OpportunitiesTable } from "./OpportunitiesTable";

export function OpportunitiesPage() {
  return (
    <>
      <PageHeader title="Opportunities" />
      <LoadingBoundary useProgressBar>
        <LoadOpportunities />
      </LoadingBoundary>
    </>
  );
}

export function LoadOpportunities() {
  const filterDefs = useOpportunitiesFilter();
  const [filter, setFilter] = useState<OpportunitiesFilter>({});
  const [settings, setSettings] = useState<PagingOptions>({ page: 1, perPage: 25 });
  const [sort, setSort] = useState<SortOptions>({ orderBy: "updated_at", direction: "DESC" });

  const opportunitiesResult = useSuspense(OpportunityQueueEndpoint, { ...filter, ...sort, ...settings });

  function onSort(orderBy: string | undefined = "updated_at", direction: Direction | undefined = "DESC") {
    setSort({ orderBy, direction });
    setSettings({ page: 1, perPage: settings.perPage });
  }

  function onFilterChange(filter: OpportunitiesFilter) {
    setFilter(filter);
    setSettings({ page: 1, perPage: settings.perPage });
  }

  return (
    <>
      <Filters<OpportunitiesFilter> filter={filter} filterDefs={filterDefs} onChange={onFilterChange} />
      {opportunitiesResult.results.length > 0 ? (
        <div>
          <Pagination label="Opportunities" setSettings={setSettings} paging={opportunitiesResult.paging} />
          <OpportunitiesTable opportunities={opportunitiesResult.results} onSort={onSort} />
        </div>
      ) : (
        <NoOpportunitiesFound />
      )}
    </>
  );
}

function NoOpportunitiesFound() {
  return (
    <div data-testid="no-opportunities-found" css={Css.py2.$}>
      <div css={Css.h2.lgSb.pb6.$}>No Underwriting Opportunities Found</div>
      <img src="/images/empty_street.svg" alt="" />
    </div>
  );
}
