import { useController as restUseController } from "@rest-hooks/react";
import { useAlertContext } from "src/components/AlertContext";

// Wrapper for rest-hooks useController that shows an alert on error
export const useController: typeof restUseController = function () {
  const { fetch, ...rest } = restUseController();
  const { showError } = useAlertContext();

  async function wrappedFetch(endpoint: any, ...args: any) {
    const res = await fetch(endpoint, ...args);

    // handle chalice Error messages
    if (res.hasOwnProperty("Code") && res.hasOwnProperty("Message")) {
      showError(res.Message);
      console.error(res.Message); // for DataDog
      throw new Error(res.Code);
    }

    return res;
  }

  return { fetch: wrappedFetch, ...rest } as any;
};
