import { Css } from "@homebound/beam";
import { useSuspense } from "@rest-hooks/react";
import { PropertyEndpoint } from "src/routes/cma/endpoints";
import { AppreciationPrediction } from "src/routes/cma/steps/estimate/components/AppreciationPrediction";
import {
  OfferOutcomeEstFormState as outcomeFormState,
  OfferOutcomeForm,
} from "src/routes/cma/steps/estimate/estimate-forms/OfferOutcomeForm";
import { ReadyPlan, UnderwritingReport } from "../../endpoints/reports";
import { EstimateFormState } from "./estimate-forms/EstimateForm";
import {
  AcquisitionDetailsSection,
  ConfidenceIntervalSections,
  EstimateSection,
  IMSensitivitySection,
  RPDetailSection,
  ValuationSummarySection,
} from "src/routes/cma/steps/estimate/components";
import {
  BuyBoxEligibilityFields,
  CostFields,
  CycleTimeFields,
  ReturnsAndMarginFields,
  RevenueAndCostFields,
} from "src/routes/cma/steps/estimate/estimate-forms";
import { getReadyPlanDisplayName } from "src/utils/mappers";
import { pluralize } from "src/utils";

interface LoadEstimateReportProps {
  estimateFormState: EstimateFormState;
  outcomeFormState: outcomeFormState;
  report: UnderwritingReport;
}

export function LoadEstimateReport({ outcomeFormState, report, estimateFormState }: LoadEstimateReportProps) {
  const { property } = useSuspense(PropertyEndpoint, { dpid: report.dpid });

  return (
    <div css={Css.px3.pt1.dg.gap2.gtc("repeat(10, 1fr)").w100.maxwPx(1440).$}>
      <ValuationSummarySection report={report} />
      <AcquisitionDetailsSection report={report} property={property} />
      <RPDetailSection report={report} />
      <RevenueAndCostFields report={report} estimateFormState={estimateFormState} />
      <ReturnsAndMarginFields report={report} estimateFormState={estimateFormState} />
      <CostFields report={report} estimateFormState={estimateFormState} />
      <BuyBoxEligibilityFields report={report} estimateFormState={estimateFormState} />
      <CycleTimeFields estimateFormState={estimateFormState} />
      <ConfidenceIntervalSections report={report} />
      <IMSensitivitySection report={report} property={property} />
      {report.appreciations && report.final_weighted_price && (
        <EstimateSection title="Appreciation" initOpen={false} numColumns={10}>
          <AppreciationPrediction
            appreciations={report.appreciations}
            updated_at={report.updated_at}
            final_weighted_price={report.final_weighted_price}
          />
        </EstimateSection>
      )}
      <EstimateSection title="SFDC Opportunity" headerColor="blue" numColumns={10}>
        <OfferOutcomeForm formState={outcomeFormState} />
      </EstimateSection>
    </div>
  );
}

// TODO: Maybe move to util mapper?
export function getPlanNameAndCopies(rp: ReadyPlan) {
  return `${getReadyPlanDisplayName(rp)}, ${rp.copies} ${pluralize(rp.copies, "copy", "copies")}`;
}
