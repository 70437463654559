import { createContext, useContext } from "react";
import { Maybe } from "src/utils";
import { UWPolygonType } from "./PolygonEndpoints";

type polygonVisibility = {
  isVisible: boolean;
  type: UWPolygonType;
};

type PolygonContextProps = {
  selectedPolygonId: Maybe<number>;
  setSelectedPolygonId: (id: Maybe<number>) => void;
  polygonVisibility: polygonVisibility[];
  setPolygonVisibility: (visibility: polygonVisibility[]) => void;
  collectionName?: Maybe<string>;
};

export const PolygonContext = createContext<PolygonContextProps>({
  selectedPolygonId: null,
  setSelectedPolygonId: () => {},
  polygonVisibility: [],
  setPolygonVisibility: () => {},
  collectionName: undefined,
});

export function usePolygonContext() {
  return useContext(PolygonContext);
}
