import { BoundCheckboxField, Css } from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { FlatDataList } from "src/components/FlatDataList";
import { mdashUnicode } from "src/components/mdash";
import { PropertyImageViewer } from "src/components/PropertyImageViewer";
import { PropertyComp } from "src/routes/cma/endpoints";
import { parseCompBuildDate } from "src/routes/cma/steps/readyPlanUtils";
import { mapUrl, streetViewUrl } from "src/routes/maps/MapsApiEndpoint";
import { mapPropertyTypeName } from "src/utils/mappers";
import { formatNumberToString } from "src/utils/numbers";
import { Maybe } from "src/utils/types";
import { Property, PropertyImage } from "../../endpoints";
import { SaveCompInput } from "../../endpoints/reports";
import { CompTierTag } from "./CompTierTag";
import { PropertyAddressHeader } from "./PropertyAddressHeader";

interface LoadPropertyCompCardProps extends PropertyDetailsProps {
  mapsApiKey: string;
}

export function LoadPropertyCompCard({ comp, compForm, mapsApiKey }: LoadPropertyCompCardProps) {
  const streetViewImage = { url: streetViewUrl(comp.neighbor!, mapsApiKey) } as PropertyImage;
  const mapImage = { url: mapUrl(comp.neighbor!, mapsApiKey) } as PropertyImage;

  const propertyImages = comp.neighbor?.property_images ?? [];
  const cardImages = [streetViewImage, mapImage, ...propertyImages];

  return <PropertyCompCard comp={comp} compForm={compForm} images={cardImages} />;
}

interface PropertyCompCardProps extends PropertyDetailsProps {
  images: PropertyImage[];
}

export function PropertyCompCard({ comp, compForm, images }: PropertyCompCardProps) {
  return (
    <div css={Css.br8.p1.w100.df.$}>
      {images.length > 0 && (
        <div css={Css.pr2.$}>
          <PropertyImageViewer property={comp} images={images} />
        </div>
      )}
      <PropertyDetails comp={comp} compForm={compForm} />
    </div>
  );
}

interface PropertyDetailsProps {
  comp: PropertyComp;
  compForm: ObjectState<SaveCompInput>;
}

function PropertyDetails({ comp, compForm }: PropertyDetailsProps) {
  const p = comp.neighbor!;
  const dist = comp.dist ? formatNumberToString(comp.dist, false) : mdashUnicode;

  return (
    <div>
      <div css={Css.df.jcsb.$}>
        <div css={Css.df.gap2.$}>
          <PropertyAddressHeader property={p} />
          <div css={Css.ptPx(4).$}>
            <CompTierTag compTier={comp.comp_tier} />
          </div>
        </div>
        <div css={Css.pt1.pr1.$}>
          <div css={Css.add("transform", "scale(1.75)").$}>
            <BoundCheckboxField checkboxOnly field={compForm.user_selected_comp} />
          </div>
        </div>
      </div>
      <div css={Css.pt2.bb.bcGray400.pb1.$}>
        <div css={Css.baseMd.pb1.$}> {mapPropertyTypeName(p.property_type_simple)}</div>
        <FlatDataList
          items={[
            { name: "Beds", value: p.num_bedrooms },
            { name: "Baths", value: p.num_baths },
            { name: "Car Garage", value: p.garage_num_cars },
            { name: "Finished sqft", value: p.finished_sqft },
            { name: "stories", singular: "story", value: p.num_stories, truncate: false },
            { name: "Pool", value: p.has_pool, hide: p.has_pool !== true },
          ]}
          lineXss={Css.pr2.sm.$}
        />
        <div css={Css.pt1.$}>
          <FlatDataList
            items={[
              `${formatYearBuilt(p.year_built_or_renovated)} year built`,
              { name: "Lot sqft", value: p.lot_size },
            ]}
            lineXss={Css.pr2.sm.$}
          />
        </div>
      </div>
      {p.last_sold_date && <PropertySalesInfo property={p} />}
      {p.mls_listing_description && (
        <>
          <div css={Css.pt2.baseMd.$}>Description</div>
          <div css={Css.py1.$}>{p.mls_listing_description}</div>
        </>
      )}
      <div css={Css.df.pt1.aife.jcsb.$}>
        <div>
          <div css={Css.baseMd.pb1.$}>Site Acquisition Cost</div>
          <div css={Css.base.$}>{formatPrice(p.homebound_avm)}</div>
        </div>
        <div css={Css.baseMd.$}>{dist} mi</div>
      </div>
    </div>
  );
}

interface PropertySalesInfoProps {
  property: Property;
}

function PropertySalesInfo({ property }: PropertySalesInfoProps) {
  return (
    <div css={Css.pt1.$}>
      <div css={Css.baseMd.pb1.$}>
        Last sold <span css={Css.sm.pl2.$}>{property.last_sold_date}</span>
      </div>
      {property.last_sold_price && (
        <div css={Css.base.$}>
          {formatPrice(property.last_sold_price)}
          {property.last_sold_price_adj && (
            <span css={Css.sm.pl2.$}> ({formatPrice(property.last_sold_price_adj)} adj.)</span>
          )}
        </div>
      )}
    </div>
  );
}

function formatYearBuilt(yearDate: Maybe<string>): number | string {
  return parseCompBuildDate(yearDate) ?? mdashUnicode;
}

function formatPrice(price: Maybe<number>): string {
  return price ? "$" + formatNumberToString(price, true, true) : mdashUnicode;
}
