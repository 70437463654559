import { Endpoint } from "@rest-hooks/endpoint";
import { api_url, auth_headers } from "src/context";
import { ReportCta } from "src/routes/cma/endpoints/reports/ctas/ReportCta";
import { Maybe } from "src/utils";

type CtaEndpointInput = {
  cta_id: number;
};

export type ResolveCtaInput = CtaEndpointInput & {
  resolution_action_name: string;
  resolution_note?: Maybe<string>;
};

export const ReopenCtaEndpoint = new Endpoint(
  async (body: CtaEndpointInput) => {
    const headers = {
      "content-type": "application/json",
      ...(await auth_headers()),
    };
    const res = await fetch(api_url(`/v1/reports/ctas/reopen`), {
      headers,
      method: "POST",
      body: JSON.stringify(body),
    });
    return await res.json();
  },
  {
    schema: ReportCta,
    name: "ReopenCtaEndpoint",
  },
);

export const ResolveCtaEndpoint = new Endpoint(
  async (body: ResolveCtaInput) => {
    const headers = {
      "content-type": "application/json",
      ...(await auth_headers()),
    };
    const res = await fetch(api_url(`/v1/reports/ctas/resolve`), {
      headers,
      method: "POST",
      body: JSON.stringify(body),
    });
    return await res.json();
  },
  {
    schema: ReportCta,
    name: "ResolveCtaEndpoint",
  },
);
