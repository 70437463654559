import { Icon, Palette, useTestIds } from "@homebound/beam";
import React, { PropsWithChildren } from "react";
import { Css } from "src/Css";

export interface LoadingProps {
  loading?: boolean;
}

export interface LoadingDivProps extends LoadingProps {
  id?: string;
  fullWidth?: boolean;
}

export function LoadingDiv(props: PropsWithChildren<LoadingDivProps>) {
  const { id = "loading", children, fullWidth = false, loading } = props;
  const tid = useTestIds({ "data-testid": id });
  const aria = loading ? { "aria-label": "Loading..." } : {};

  return (
    <div
      css={{
        ...Css.relative.$,
        ...(fullWidth ? Css.w100.$ : Css.dib.$),
        ...(!children ? Css.w3.h3.$ : undefined),
      }}
      {...tid}
      {...aria}
    >
      <div
        css={{
          ...Css.dn.jcc.aic.absolute.h3.w3
            .top("50%")
            .left("50%")
            .mlPx(-12)
            .mtPx(-12)
            .add("animation", "600ms loadingSpinnerDelay, 600ms linear 0s infinite normal loadingSpinner").$,
          ...(loading ? Css.df.addIn("& + *", Css.add("opacity", loadingOpacity).$).$ : {}),
        }}
        {...tid.loader}
      >
        <Icon icon="refresh" color={Palette.Gray700} />
      </div>
      {children}
    </div>
  );
}

export const loadingOpacity = 0.25;
