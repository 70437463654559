import { mdashUnicode } from "src/components/mdash";
import { formatNumberToString } from "src/utils";

export interface FormattedPercentageProps {
  percentage: number | undefined;
  decimals?: 0 | 2;
}

export function FormattedPercentage({ percentage, decimals = 2 }: FormattedPercentageProps) {
  function fmt(n: number) {
    const trim = decimals === 0 ? true : false;
    return formatNumberToString(n * 100, trim, trim);
  }

  if (!percentage) {
    return <span>{mdashUnicode}</span>;
  }

  return <span>{fmt(percentage)}%</span>;
}
