import { Css, HbLoadingSpinner, HB_QUIPS_FLAVOR, LoadingSkeleton, LoadingSkeletonProps } from "@homebound/beam";
import { LinearProgress } from "@material-ui/core";
import { Suspense } from "react";
import { ErrorBoundary } from "./ErrorBoundary";

interface LoadingBoundaryProps {
  children: React.ReactNode;
  useProgressBar?: boolean;
  loadingQuips?: string[];
}

interface GridLoadingBoundaryProps {
  children: React.ReactNode;
  gridLoadingProps: LoadingSkeletonProps;
}

export function CustomLoadingBoundary({
  children,
  fallback,
}: {
  children: React.ReactNode;
  fallback: React.ReactNode;
}) {
  const fallbackWrapped = <div>{fallback}</div>;
  return (
    <Suspense fallback={fallbackWrapped}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Suspense>
  );
}

export function LoadingBoundary({ children, useProgressBar, loadingQuips }: LoadingBoundaryProps) {
  const fallback = (
    <div css={Css.pt6.mx2.$}>{useProgressBar ? <LinearProgress /> : <HbUWSpinner loadingQuips={loadingQuips} />}</div>
  );

  return (
    <Suspense fallback={fallback}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Suspense>
  );
}

export function GridLoadingBoundary({ children, gridLoadingProps }: GridLoadingBoundaryProps) {
  return (
    <Suspense fallback={<LoadingSkeleton {...gridLoadingProps} />}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Suspense>
  );
}

export function HbUWSpinner({ loadingQuips }: { loadingQuips?: string[] }) {
  return <HbLoadingSpinner extraQuips={loadingQuips ?? [...UNDERWRITING_QUIPS, ...HB_QUIPS_FLAVOR]} extraQuipsOnly />;
}

const UNDERWRITING_QUIPS = [
  "Something wrong? Something awesome! Let us know in #uw_app_feedback_and_updates...",
  "Sit tight! We're doing lots of math...",
];
