import { Css } from "@homebound/beam";
import { FieldState } from "@homebound/form-state";
import { NumberParser } from "@internationalized/number";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Observer } from "mobx-react";
import { useMemo } from "react";
import { formatNumberToString, Maybe } from "src/utils";
import { MaybeHighLow, RangeLabel } from "src/utils/tableUtils";

export interface BoundPriceSelectFieldProps {
  field: FieldState<number | null | undefined>;
  last_sold_price_adj: Maybe<number>;
  homebound_avm: Maybe<number>;
  homebound_avm_low: Maybe<number>;
  homebound_avm_high: Maybe<number>;
  last_sold_price_adj_high: Maybe<number>;
  last_sold_price_adj_low: Maybe<number>;
}

type OptionType =
  | string
  | {
      label: string;
      value: number;
      range?: MaybeHighLow;
    };

export function BoundSelectPriceField({
  field,
  last_sold_price_adj,
  homebound_avm,
  homebound_avm_low,
  homebound_avm_high,
  last_sold_price_adj_high,
  last_sold_price_adj_low,
}: BoundPriceSelectFieldProps) {
  const formatOptions: Intl.NumberFormatOptions = { style: "currency", currency: "USD", minimumFractionDigits: 0 };
  const numberParser = new NumberParser("en-US", formatOptions);

  const options = useMemo(() => {
    const options: OptionType[] = [];

    if (homebound_avm) {
      options.push({
        label: "HB AVM",
        value: homebound_avm,
        range:
          homebound_avm_low && homebound_avm_high ? { low: homebound_avm_low, high: homebound_avm_high } : undefined,
      });
    }

    if (last_sold_price_adj) {
      options.push({
        label: "Sold Price Adj",
        value: last_sold_price_adj,
        range:
          last_sold_price_adj_low && last_sold_price_adj_high
            ? { low: last_sold_price_adj_low, high: last_sold_price_adj_high }
            : undefined,
      });
    }

    return options;
  }, [
    homebound_avm,
    homebound_avm_high,
    homebound_avm_low,
    last_sold_price_adj,
    last_sold_price_adj_high,
    last_sold_price_adj_low,
  ]);

  function labelForPrice(p: number | null | undefined): string {
    function opEq(o: OptionType, v: Maybe<number>) {
      if (typeof o === "string") {
        return v && o === formatNumberToString(v, true, true);
      }

      return o.value === v;
    }

    const o = options.find((o) => opEq(o, p));
    return o && typeof o !== "string" ? o.label : "Custom";
  }

  function formatPrice(n: number): string {
    return formatNumberToString(n, true, true);
  }

  function getOptionLabel(o: OptionType): string {
    return typeof o === "string" ? o : formatPrice(o.value);
  }

  function getOptionSelected(o: OptionType, v: OptionType): boolean {
    if (typeof o === "string") {
      if (typeof v === "string") {
        return o === v;
      }
      return false;
    }

    if (typeof v === "string") {
      return false;
    }

    return o.value === v.value;
  }

  function getValue() {
    return field.value ? formatPrice(field.value) : undefined;
  }

  function onPriceChange(v: OptionType | null) {
    function isExistingPriceString(p: string): boolean {
      if (field.value !== undefined && field.value !== null) {
        return formatPrice(field.value) === p;
      }

      return false;
    }

    if (v) {
      if (typeof v === "string") {
        // When the field is blurred we'll get back the formatted price string
        // we don't want to set it if it's the same as the existing value to avoid
        // truncation, and then future match issues.
        if (!isExistingPriceString(v)) {
          // not the existing value, a custom price the user entered
          const n = numberParser.parse(v);
          if (!isNaN(n)) {
            field.set(n);
            field.maybeAutoSave();
          }
        }
      } else {
        // price selected from the list
        field.set(v.value);
        field.maybeAutoSave();
      }
    }
  }

  return (
    <Observer>
      {() => (
        <Autocomplete
          freeSolo
          autoSelect
          defaultValue={getValue()}
          css={Css.w100.$}
          options={options}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          renderInput={(params) => {
            return (
              <div>
                <div css={Css.smMd.gray600.$}>{labelForPrice(field.value)}</div>
                <TextField
                  {...params}
                  label=""
                  hiddenLabel
                  onFocus={() => {
                    field.focus();
                  }}
                  onBlur={() => {
                    field.blur();
                  }}
                  error={field.value === 0}
                />
              </div>
            );
          }}
          renderOption={(option, _) => <PriceOption option={option} />}
          onChange={(_, v) => onPriceChange(v)}
          disabled={field.readOnly}
          aria-readonly={field.readOnly}
        />
      )}
    </Observer>
  );
}

interface PriceOptionProps {
  option: OptionType;
}
function PriceOption({ option }: PriceOptionProps) {
  if (typeof option === "string") {
    return <span>{option}</span>;
  }

  const { range: { high, low } = {}, value, label } = option;

  return (
    <div css={Css.df.fdc.mwPx(100).$}>
      <div>{formatNumberToString(value, true, true)}</div>
      <div css={Css.smMd.gray600.$}>{label}</div>
      {high && low && (
        <div css={Css.smMd.gray600.$}>
          <RangeLabel high={high} low={low} actual={value} />
        </div>
      )}
    </div>
  );
}
