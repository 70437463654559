import { FilterDefs, HasIdAndName, multiFilter } from "@homebound/beam";
import { useMemo } from "react";

export type OpportunitiesFilter = {
  origin?: string[];
  market?: string[];
  stage?: string[];
};

export function useOpportunitiesFilter() {
  const filterDefs: FilterDefs<OpportunitiesFilter> = useMemo(() => {
    const origins: string[] = ["iBuyer", "MLS", "Lead-Generated"];
    const stages: string[] = ["Inspection Period", "Underwriting"];
    const markets: string[] = [
      "Austin",
      "Bahamas",
      "Boulder County",
      "Dallas",
      "Houston",
      "Lee County",
      "Los Angeles",
      "North Bay Area",
      "Santa Rosa",
      "South Bay Area",
      "Southwest Florida",
      "Outside Current Markets",
    ];

    const origin = multiFilter({
      label: "Origin",
      options: createFilterOptions(origins),
      getOptionValue: (option) => option.id,
      getOptionLabel: (option) => option.name,
    });

    const market = multiFilter({
      label: "Market",
      options: createFilterOptions(markets),
      getOptionValue: (option) => option.id,
      getOptionLabel: (option) => option.name,
    });

    const stage_name = multiFilter({
      label: "Stage",
      options: createFilterOptions(stages),
      getOptionValue: (option) => option.id,
      getOptionLabel: (option) => option.name,
    });

    return {
      origin,
      market,
      stage_name,
    };
  }, []);

  return filterDefs;
}

function createFilterOptions(options: string[]): HasIdAndName[] {
  return options.map((id) => ({ id, name: id }));
}
