import { Avatar, Tooltip } from "@material-ui/core";
import { Icon } from "src/components/Icon";
import { Css, Palette } from "src/Css";
import { LoadingDiv, LoadingProps } from "./LoadingDiv";

export interface AvatarCircleProps extends LoadingProps {
  name?: string;
  avatar?: string;
  hideTooltip?: boolean;
  size?: AvatarSize;
  // Determine whether this avatar circle is within an AvatarSelector
  parent?: "selector";
}

export enum AvatarSize {
  small = 25,
  large = 36,
  tiny = 18,
}

// Select a color in case there is no avatar image yet
const avatarColors: Array<Palette> = [Palette.Grass, Palette.Orange, Palette.Wine, Palette.Stone, Palette.Yellow];

export function AvatarCircle(props: AvatarCircleProps) {
  const { loading, name, avatar, size = AvatarSize.large } = props;

  // We want to make sure the user always sees the same color for their avatar if they
  // don't have an image set. This is probably a better job for a library though.
  const avatarBackground = name ? avatarColors[name.length % avatarColors.length] : Palette.LightGray;

  // We determine whether or not to  show a default avatar icon based on the truthiness of this variable
  const initials = name
    ?.split(" ")
    .map((e) => e[0])
    .join("");

  // const iconStyle =
  //   props.parent === "selector"
  //     ? Css.brPill.bgWhite.lightGray.add("fontSize", "40px").$
  //     : Css.brPill.bgWhite.lightGray.add("fontSize", "28px").$;

  const avatarComponent = (
    <Avatar
      data-testid="avatar"
      src={avatar}
      alt={name}
      imgProps={{ loading: "lazy" }}
      css={{
        ...Css.sm.$,
        // Use !important to override the .Mui-Avatar of 40px/40px
        height: `${size}px !important`,
        width: `${size}px !important`,
        objectFit: "fill",
        backgroundColor: avatarBackground,
        [`.${avatarCircleHoverTarget} &`]: {
          position: "relative",
          // overlay an inset border that doesn't affect content layout
          "&:before": {
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: `1px solid ${Palette.MidGray}`,
            borderRadius: "50%",
            transition: ["border-width", "border-color"].map((x) => `${x} 150ms linear`).join(", "),
          },
        },
        [`.${avatarCircleHoverTarget}:hover &:before`]: {
          border: `2px solid ${Palette.Primary}`,
        },
      }}
    >
      {initials || <Icon icon={"userCircle"} /* css={iconStyle} */ />}
    </Avatar>
  );

  return (
    <LoadingDiv loading={loading}>
      {props.hideTooltip ? (
        avatarComponent
      ) : (
        <Tooltip title={props.name || "Unassigned"} placement="bottom-start">
          {avatarComponent}
        </Tooltip>
      )}
    </LoadingDiv>
  );
}

export const avatarCircleHoverTarget = "hoverAvatarCircle";
