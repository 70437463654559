import { Css } from "@homebound/beam";
import { useEffect, useState } from "react";
import { MapContainer, Marker } from "react-leaflet";
import { Map } from "leaflet";
import { MapBaseLayers } from "src/routes/maps/MapBaseLayers";
import { Maybe } from "src/utils/types";

interface PropertyLocationDetails {
  dpid: string;
  latitude: Maybe<number>;
  longitude: Maybe<number>;
}

interface MiniMapProps {
  property: PropertyLocationDetails;
  height?: number;
  zoom?: number;
}

export function MiniMap({ property, height = 200, zoom = 15 }: MiniMapProps) {
  const { latitude, longitude } = property;
  const [map, setMap] = useState<Maybe<Map>>(undefined);

  useEffect(() => {
    // leaflet map is immutable after creation, so need to change the lat/lon directly
    if (map && latitude && longitude) {
      map.panTo([latitude, longitude]);
    }
  }, [map, latitude, longitude]);

  if (!latitude || !longitude) {
    return null;
  }

  return (
    <MapContainer
      key={property.dpid}
      id={property.dpid}
      css={Css.hPx(height).$}
      center={[latitude, longitude]}
      zoom={zoom}
      scrollWheelZoom={false}
      ref={setMap}
    >
      <MapBaseLayers />
      <Marker position={[latitude, longitude]}></Marker>
    </MapContainer>
  );
}
