import { ReadyPlan, UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { getReadyPlanDisplayName } from "src/utils/mappers";
import { Button, Css, ModalBody, ModalHeader, useModal } from "@homebound/beam";
import { RP_PROGRAM_DATA_FIELDS, RPOptions } from "src/components/ReadyPlanProgramData";
import { EstimateSection } from "src/routes/cma/steps/estimate/components/EstimateSection";
import React from "react";
import { mdashUnicode } from "src/components/mdash";
import { formatNumberToString } from "src/utils";
import { groupBy, startCase } from "lodash";
import { hasV2ReadyPlans } from "src/routes/cma/steps/readyPlanUtils";

export function RPDetailSection({ report }: { report: UnderwritingReport }) {
  const hasBpRp = hasV2ReadyPlans(report);
  return report.ready_plans?.map((rp) => (
    <EstimateSection
      key={`rp-${rp.id}-details-section`}
      title={`RP Details - ${getReadyPlanDisplayName(rp)}`}
      initOpen={false}
      numColumns={10}
    >
      <div css={Css.df.gap2.$}>
        <RPProgramDetails rp={rp} />
        <RPOptionsLite rp={rp} hasBpRp={hasBpRp} />
      </div>
    </EstimateSection>
  ));
}

export function PrintRPDetailSection({ report }: { report: UnderwritingReport }) {
  const hasBpRp = hasV2ReadyPlans(report);
  const width = report.ready_plans?.length === 1 ? "100%" : `${100 / report.ready_plans!.length}%`;
  return (
    <EstimateSection key={`rp-details-section`} title={`A&D Details`} initOpen={true} numColumns={10}>
      <div css={Css.df.gap2.$}>
        {report.ready_plans?.map((rp) => {
          const { bp_ready_plan_options_json, bp_ready_plan_option_ids = [] } = rp;
          const groupedSelections = groupBy(
            bp_ready_plan_options_json?.optionGroups
              ?.filter((og) => og.isMultiOptionGroup)
              ?.sort((a, b) => a.order - b.order)
              ?.flatMap((o) => o.options)
              ?.filter((o) => bp_ready_plan_option_ids?.includes(o.id)),
            (o) => o.type.name,
          );
          return (
            <div css={Css.df.fdc.w(width).$}>
              <div css={Css.baseBd.$}>{getReadyPlanDisplayName(rp)}</div>
              <div css={Css.dg.gtc("repeat(1, 1fr)").add("gridColumnGap", "12px").add("gridRowGap", "4px").$}>
                <div>
                  {Object.entries(groupedSelections).map(([type, options]) => (
                    <div key={type} css={Css.df.jcsb.aic.$}>
                      <div css={Css.fw6.$}>{type}</div>
                      <div>{options[0].name}</div>
                    </div>
                  ))}
                  {/* Specify showing spec level for legacy reports that have them. Spec level will always be on a BP rp */}
                  {!hasBpRp && rp.spec_level && (
                    <div css={Css.df.jcsb.$}>
                      <div css={Css.sm.gray700.$}>Spec Level</div>
                      <div css={Css.sm.$}>{startCase(rp.spec_level)}</div>
                    </div>
                  )}
                  {["lot_size", "sellable_sqft", "sellable_basement_sqft"].map((pdField) => {
                    // program data fields are all numbers
                    // fallback to 0 for legacy reports finalized prior to sqft field split
                    const value = (rp[pdField as keyof ReadyPlan] as number) ?? 0;
                    const formattedValue = value === 0 ? mdashUnicode : formatNumberToString(value, true);
                    const formattedPdField = startCase(pdField.replace("num_", ""));
                    return value === 0 ? null : (
                      <div css={Css.df.jcsb.$} key={pdField}>
                        <div css={Css.sm.gray700.$}>{formattedPdField}</div>
                        <div css={Css.sm.$}>{formattedValue}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </EstimateSection>
  );
}

function RPProgramDetails({
  rp,
  width = "35%",
  title = "Program Data",
}: {
  rp: ReadyPlan;
  width?: string;
  title?: string;
}) {
  return (
    <div css={Css.df.fdc.w(width).$}>
      <div css={Css.baseBd.$}>{title}</div>
      <div css={Css.dg.gtc("repeat(2, 1fr)").add("gridColumnGap", "12px").add("gridRowGap", "4px").$}>
        {RP_PROGRAM_DATA_FIELDS.map((pdField) => {
          // program data fields are all numbers
          // fallback to 0 for legacy reports finalized prior to sqft field split
          const value = (rp[pdField] as number) ?? 0;
          const formattedValue = value === 0 ? mdashUnicode : formatNumberToString(value, true);
          const formattedPdField = startCase(pdField.replace("num_", ""));
          return (
            <div css={Css.df.jcsb.$} key={pdField}>
              <div css={Css.sm.gray700.$}>{formattedPdField}</div>
              <div css={Css.sm.$}>{formattedValue}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function RPOptionsLite({ rp, hasBpRp }: { rp: ReadyPlan; hasBpRp: boolean }) {
  const { openModal } = useModal();
  const { bp_ready_plan_options_json, bp_ready_plan_option_ids = [] } = rp;
  const groupedSelections = groupBy(
    bp_ready_plan_options_json?.optionGroups
      ?.filter((og) => og.isMultiOptionGroup)
      ?.flatMap((o) => o.options)
      ?.filter((o) => bp_ready_plan_option_ids?.includes(o.id))
      .sort((a, b) => a.code.localeCompare(b.code)),
    (o) => o.type.name,
  );

  return (
    <div css={Css.df.fdc.w("65%").$}>
      <div css={Css.df.gap2.aic.$}>
        <div css={Css.baseBd.$}>Options</div>
        {hasBpRp && (
          <Button
            size="sm"
            variant="text"
            label={"View All"}
            onClick={() => {
              openModal({
                content: (
                  <>
                    <ModalHeader>{getReadyPlanDisplayName(rp)} Options</ModalHeader>
                    <ModalBody>
                      <RPOptions rp={rp} inModal />
                    </ModalBody>
                  </>
                ),
                size: "lg",
              });
            }}
          />
        )}
      </div>
      {rp.ready_plan_id ? (
        <div css={Css.add("fontStyle", "italic").$}>Ready plan not created from Blueprint template.</div>
      ) : (
        <div css={Css.dg.gtr("auto").gtc("1fr 1fr").rg1.cg3.$}>
          {Object.entries(groupedSelections).map(([type, options]) => (
            <div key={type}>
              <div css={Css.fw6.$}>{type}</div>
              {options.map((o) => (
                <div key={o.id}>
                  {o.name} ({o.id})
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
