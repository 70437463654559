import {
  Button,
  ButtonMenu,
  Css,
  Direction,
  Filters,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useComputed,
  useGridTableApi,
  useModal,
  useSnackbar,
} from "@homebound/beam";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useSuspense } from "@rest-hooks/react";
import { LoadingBoundary } from "src/components/LoadingBoundary";
import { PageHeader, PageHeaderActions } from "src/components/PageHeader";
import { useController } from "../../hooks/useController";
import { PagingOptions, Sortable, SortOptions } from "../paging";
import { Pagination } from "../reports/components/Pagination";
import { ReportReviewQueueEndpoint } from "./endpoints/ReportReviewEndpoint";
import { UpdateReportReviewsEndpoint } from "./endpoints/UpdateReportReviewsEndpoint";
import { GenerateValuationReportsForm } from "./GenerateValuationReportsForm";
import { ReviewsFilter, useReviewsFilter } from "./ReviewFilter";
import { ReviewTable, Row } from "./ReviewTable";

export function ReviewPage() {
  return (
    <>
      <PageHeader title="Report Review" />
      <LoadingBoundary useProgressBar>
        <LoadReviews />
      </LoadingBoundary>
    </>
  );
}

export function LoadReviews() {
  const filterDefs = useReviewsFilter();
  const [filter, setFilter] = useState<ReviewsFilter>({});

  const [settings, setSettings] = useState<PagingOptions>({ page: 1, perPage: 25 });
  const [sort, setSort] = useState<SortOptions>({ orderBy: "updated_at", direction: "DESC" });

  function onSort(orderBy: string | undefined = "updated_at", direction: Direction | undefined = "DESC") {
    setSort({ orderBy, direction });
    setSettings({ page: 1, perPage: settings.perPage });
  }

  function onFilterChange(filter: ReviewsFilter) {
    setFilter(filter);
    setSettings({ page: 1, perPage: settings.perPage });
  }

  const { openModal } = useModal();

  const { invalidate } = useController();

  async function onReportsCreated(reportsCreated: number) {
    if (reportsCreated > 0) {
      await invalidate(ReportReviewQueueEndpoint, { ...sort, ...settings });
    }
  }

  return (
    <>
      <PageHeaderActions>
        <Button
          label="Generate Reports"
          onClick={() => {
            openModal({ content: <GenerateValuationReportsForm onReportsCreated={onReportsCreated} /> });
          }}
        />
      </PageHeaderActions>
      <Filters<ReviewsFilter> filter={filter} filterDefs={filterDefs} onChange={onFilterChange} />
      <LoadingBoundary useProgressBar>
        <LoadReviewsTable filter={filter} sort={sort} settings={settings} setSettings={setSettings} onSort={onSort} />
      </LoadingBoundary>
    </>
  );
}

interface LoadReviewsTableProps extends Sortable {
  filter: ReviewsFilter;
  sort: SortOptions;
  settings: PagingOptions;
  setSettings: Dispatch<SetStateAction<PagingOptions>>;
}

function LoadReviewsTable({ filter, sort, settings, setSettings, onSort }: LoadReviewsTableProps) {
  const reviews = useSuspense(ReportReviewQueueEndpoint, { ...sort, ...settings, ...filter });
  const { fetch, invalidate } = useController();
  const { openModal } = useModal();

  const tableApi = useGridTableApi<Row>();
  const selectedReviews = useComputed(() => tableApi.getSelectedRowIds("data"), [tableApi]);

  async function onDeleteConfirm(reviewIds: string[]) {
    await fetch(UpdateReportReviewsEndpoint, { delete: { reviewIds } });
    await invalidate(ReportReviewQueueEndpoint, { ...sort, ...settings });
  }

  async function onFinalizeConfirm(reviewIds: string[]) {
    await fetch(UpdateReportReviewsEndpoint, { finalize: { reviewIds } });
    await invalidate(ReportReviewQueueEndpoint, { ...sort, ...settings });
  }

  const actionMenuItems = useMemo(
    () => [
      {
        label: "Delete Reports",
        icon: "trash",
        disabled: !selectedReviews.length,
        onClick: () => {
          openModal({ content: <DeleteReviewsModal reviewIds={selectedReviews} onConfirm={onDeleteConfirm} /> });
        },
      },
      {
        label: "Finalize Reports",
        icon: "thumbsUp",
        disabled: !selectedReviews.length,
        onClick: () => {
          openModal({ content: <FinalizeReviewsModal reviewIds={selectedReviews} onConfirm={onFinalizeConfirm} /> });
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedReviews, tableApi],
  );

  return reviews.results.length > 0 ? (
    <>
      <div css={Css.df.pt2.aic.jcsb.$}>
        <ButtonMenu trigger={{ label: "Actions" }} items={actionMenuItems} />
        <Pagination label="Reports" setSettings={setSettings} paging={reviews.paging} />
      </div>
      <ReviewTable tableApi={tableApi} reviews={reviews.results} onSort={onSort} />
    </>
  ) : (
    <NoReviewsFound />
  );
}

function NoReviewsFound() {
  return (
    <div data-testid="no-reviews-found" css={Css.py3.$}>
      <div css={Css.h2.lgSb.pb6.$}>No Reports Need Review</div>
      <img src="/images/empty_reviews.svg" alt="No Reviews Found" />
    </div>
  );
}

interface DeleteReviewsModalProps {
  reviewIds: string[];
  onConfirm: (reviewIds: string[]) => Promise<void>;
}

function DeleteReviewsModal({ reviewIds, onConfirm }: DeleteReviewsModalProps) {
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();

  return (
    <>
      <ModalHeader>Delete {reviewIds.length} reviews?</ModalHeader>
      <ModalBody>This will delete the selected generated report versions and cannot be undone.</ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          label="Delete"
          variant="danger"
          onClick={async () => {
            await onConfirm(reviewIds);
            triggerNotice({ icon: "success", message: `${reviewIds.length} Report versions successfully deleted` });
            closeModal();
          }}
        />
      </ModalFooter>
    </>
  );
}

function FinalizeReviewsModal({ reviewIds, onConfirm }: DeleteReviewsModalProps) {
  const { closeModal } = useModal();
  const { triggerNotice } = useSnackbar();

  return (
    <>
      <ModalHeader>Finalize {reviewIds.length} reviews?</ModalHeader>
      <ModalBody>
        <>
          <p>This will finalize the selected generated report versions and cannot be undone.</p>
          <p css={Css.pt2.$}>Please ensure no reports are flagged for Comp Price Review.</p>
        </>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button
          label="Finalize"
          variant="primary"
          onClick={async () => {
            await onConfirm(reviewIds);
            triggerNotice({ icon: "success", message: `${reviewIds.length} Report versions successfully finalized` });
            closeModal();
          }}
        />
      </ModalFooter>
    </>
  );
}
