import { AuthViewProps } from "@homebound/auth-components";
import { BeamProvider, Css, PreventBrowserScroll, RightPaneLayout, ScrollableParent } from "@homebound/beam";
import React from "react";
import { AlertContextProvider } from "src/components/AlertContext";
import { BlueprintProvider } from "src/context/BlueprintContext";
import { GlobalNav } from "src/routes/layout/GlobalNav";

export interface PageLayoutProps extends Pick<AuthViewProps, "user" | "setIsAuthenticated"> {
  children?: React.ReactNode;
}

/** Wraps the application pages (passed as children) with our logged-in-user layout/nav. */
export function PageLayout({ user, children }: PageLayoutProps) {
  return (
    <AlertContextProvider>
      <BeamProvider>
        <BlueprintProvider>
          <PreventBrowserScroll>
            <GlobalNav user={user} />
            <RightPaneLayout>
              <ScrollableParent tagName="main" xss={Css.px3.$}>
                {children}
              </ScrollableParent>
            </RightPaneLayout>
          </PreventBrowserScroll>
        </BlueprintProvider>
      </BeamProvider>
    </AlertContextProvider>
  );
}
