import {
  BoundSelectField,
  BoundSwitchField,
  BoundTextField,
  Button,
  ButtonModal,
  Css,
  Icon,
  IconButton,
  Palette,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { capitalCase } from "change-case";
import { Observer } from "mobx-react";
import { useMemo } from "react";
import { getPolygonColor, polygonTypeOptions } from "src/routes/maps/mapUtils";
import { SavePolygonInput, UWPolygonType } from "../PolygonEndpoints";
import { ColorPickerGrid } from "./ColorPickerGrid";
import { PolygonUpdateType } from "./PolygonEditor";
import { UwLeafletPolygon } from "./PolygonFeatureView";
import { usePolygonContext } from "../PolygonContext";

type PolygonPopupProps = {
  canEdit: boolean;
  lpRef: React.RefObject<UwLeafletPolygon>;
  polygonFormState: ObjectState<SavePolygonInput>;
  onPolygonChange: (layer: UwLeafletPolygon, updateType: PolygonUpdateType) => void;
  saveForm: () => void;
  toggleEdit: () => void;
};

export function PolygonPopUp(props: PolygonPopupProps) {
  const { canEdit, lpRef, polygonFormState, onPolygonChange, saveForm, toggleEdit } = props;
  const { collectionName } = usePolygonContext();

  const color = useMemo(
    () =>
      getPolygonColor({
        isActive: polygonFormState.value.active,
        color: polygonFormState.color.value,
        type: polygonFormState.value.type,
      }),
    [polygonFormState.color.value, polygonFormState.value.active, polygonFormState.value.type],
  );

  function renderPopup() {
    return (
      <div css={Css.dg.gap1.maxwPx(150).$}>
        {canEdit ? (
          <>
            <BoundTextField
              label="Name"
              autoFocus
              field={polygonFormState.name}
              placeholder="Enter polygon name"
              compact
              disabled={
                polygonFormState.type.value === UWPolygonType.dev_area
                  ? "Dev area polygon names cannot be edited"
                  : false
              }
            />
            <BoundSelectField
              label="Type"
              field={polygonFormState.type}
              options={polygonTypeOptions}
              getOptionLabel={(o) => o.name}
              getOptionValue={(o) => o.id}
              // changes color based on type if one is not already set
              onBlur={() => !polygonFormState.color.value && setPolygonColor(lpRef, polygonFormState, color)}
              onSelect={(type) => {
                polygonFormState.type.set(type);
                if (type === UWPolygonType.dev_area) {
                  polygonFormState.name.set(collectionName);
                }
              }}
              placeholder="Select polygon type"
              compact
            />
            <div css={Css.df.pt1.jcsb.$}>
              <div css={Css.sm.$}>Active</div>
              <BoundSwitchField
                label="Active"
                compact
                labelStyle="hidden"
                field={polygonFormState.active}
                onChange={() => {
                  polygonFormState.active.set(!polygonFormState.active.value);
                  const updatedColor = getPolygonColor({
                    isActive: polygonFormState.active.value,
                    color: polygonFormState.color.value,
                    type: polygonFormState.type.value,
                  });
                  updatePolygonMapColor(lpRef, updatedColor);
                }}
              />
            </div>
          </>
        ) : (
          <div css={Css.gray700.dg.gap3.sm.$}>
            <div css={Css.dg.gapPx(4).$}>
              <div>Name</div>
              <div css={Css.gray900.fw5.$}>{polygonFormState.value.name}</div>
            </div>
            <div css={Css.dg.gapPx(4).$}>
              <div>Type</div>
              <div css={Css.gray900.fw5.$}>
                {polygonFormState.value.type && capitalCase(polygonFormState.value.type)}
              </div>
            </div>
            <div css={Css.gray900.fw5.$}>{polygonFormState.value.active ? "Active" : "Inactive"}</div>
          </div>
        )}
        <div css={Css.df.jcsb.pt1.$}>
          <div css={Css.df.gap1.aic.$}>
            <Button
              label={canEdit ? "Save" : "Edit"}
              variant={canEdit ? "primary" : "secondary"}
              icon={canEdit ? "cloudSave" : "pencil"}
              onClick={() => {
                if (canEdit) {
                  // save changes and disable feature editing
                  polygonFormState.commitChanges();
                  lpRef.current?.pm.disable();
                  saveForm();
                } else {
                  // enable feature editing
                  lpRef.current?.pm.enable({ allowSelfIntersection: false });
                }
                toggleEdit();
              }}
              disabled={canEdit && !polygonFormState.valid}
              size="sm"
            />
            {canEdit ? (
              <ButtonModal
                trigger={{ icon: "palette", color }}
                disabled={polygonFormState.active.value === false && "Polygon is inactive"}
                content={
                  <ColorPickerGrid
                    initialColor={color}
                    setColor={(color) => setPolygonColor(lpRef, polygonFormState, color)}
                  />
                }
              />
            ) : (
              <Icon icon="circle" color={color} />
            )}
            <IconButton
              icon={"trash"}
              onClick={() => {
                lpRef.current?.remove();
                lpRef.current && onPolygonChange(lpRef.current, "Delete");
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  return <Observer>{() => renderPopup()}</Observer>;
}

function setPolygonColor(
  lpRef: React.RefObject<UwLeafletPolygon>,
  polygonFormState: ObjectState<SavePolygonInput>,
  color: Palette,
) {
  polygonFormState.color.set(color);
  updatePolygonMapColor(lpRef, color);
}

function updatePolygonMapColor(lpRef: React.RefObject<UwLeafletPolygon>, color: Palette) {
  lpRef.current?.setStyle({ fillColor: color, color });
}
