import { Entity } from "@rest-hooks/rest";
import { ReportCtaLevel } from "src/routes/cma/endpoints/reports/ctas/ReportCtaLevel";
import { ReportCtaStatus } from "src/routes/cma/endpoints/reports/ctas/ReportCtaStatus";
import { ReportCtaTrigger } from "src/routes/cma/endpoints/reports/ctas/ReportCtaTrigger";
import { Maybe } from "src/utils";

type CtaTrackedValuesBase<T, V> = {
  type: T;
  current: V;
  initial: V;
  resolved: V | null;
};

export type ThresholdValues = {
  threshold: number;
  delta: number;
  value: number;
  unit: string;
};

export type RangeCtaValues = {
  thresholdHigh: number;
  thresholdLow: number;
  value: number;
  unit: string;
};

export type CtaTrackedThresholdValue = CtaTrackedValuesBase<"threshold", ThresholdValues>;
export type CtaTrackedRangeValue = CtaTrackedValuesBase<"range", RangeCtaValues>;
export type CtaTrackedEnumValue = CtaTrackedValuesBase<"enum", string>;

export class ReportCta extends Entity {
  readonly id: number = 0;
  readonly underwriting_report_id: number = 0;
  readonly underwriting_report_ready_plan_id: number = 0;
  readonly status: ReportCtaStatus = ReportCtaStatus.open;
  readonly trigger_name: ReportCtaTrigger = ReportCtaTrigger.low_confidence;
  readonly level: ReportCtaLevel = ReportCtaLevel.underwriting_report_id;
  readonly level_value: number = 0;
  readonly resolution_user_id?: Maybe<number> = undefined;
  readonly resolution_action_name?: Maybe<string> = undefined;
  readonly resolution_note?: Maybe<string> = undefined;
  readonly created_at: string = "";
  readonly updated_at: string = "";

  // "None" type is server default
  readonly tracked_values:
    | CtaTrackedThresholdValue
    | CtaTrackedEnumValue
    | CtaTrackedRangeValue
    | CtaTrackedValuesBase<"none", null> = {
    type: "none",
    current: null,
    initial: null,
    resolved: null,
  };

  pk() {
    return `${this.id}`;
  }

  static key = "ReportCta";
}
