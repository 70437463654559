import { parse as dateFnsParse, parseISO as dateFnsParseIso } from "date-fns";
import { Maybe } from "src/utils/types";

/**
 * monthDateTime = `November 10, 4:57 PM`
 *
 * monthDate = `November 10`
 *
 * basicDate = `11/10/2020`
 */
type DateLabelOptions = "monthDate" | "basicDate" | "monthDateTime";

/**
 *   Formats To: `November 10, 4:57 PM`
 *
 *   Using:
 *
 *     month: "long",
 *     day: "numeric",
 *     hour: "numeric",
 *     minute: "numeric",
 */
export const FORMAT_MONTH_DATE_TIME = new Intl.DateTimeFormat("en-US", {
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
});

export const FORMAT_MONTH_DATE = new Intl.DateTimeFormat("en-US", {
  month: "long",
  day: "numeric",
});

export const FORMAT_MONTH_DAY_YEAR = new Intl.DateTimeFormat("en-US", {
  month: "short",
  day: "numeric",
  year: "numeric",
});

export const FORMAT_BASIC_DATE = new Intl.DateTimeFormat("en-US");

// IE underwritten_at
export function parseMaybeStringDate(date: Maybe<string>, format = "yyyy-MM-dd") {
  if (!date) return undefined;

  return dateFnsParse(date, format, new Date());
}

function parseMaybeStringISODate(date: Maybe<string>, additionalDigits?: 0 | 1 | 2) {
  if (!date) return undefined;

  return dateFnsParseIso(date, { additionalDigits });
}

/**
 * Parses iso date with timezone.
 * Use with any records in DB of type `timestamp with time zone`.
 * IE: updated_at
 */
export function parseMaybeStringISOOffsetDate(date: Maybe<string>, additionalDigits?: 0 | 1 | 2) {
  if (!date) return undefined;

  return parseMaybeStringISODate(date);
}

export function maybeStringToDateLabel(
  maybeDateString: Maybe<string>,
  uwDateLabelFormat: DateLabelOptions = "basicDate",
) {
  if (!maybeDateString) return undefined;

  let parsedDate: Date | undefined;
  // TODO: TZ offset can also be -
  if (maybeDateString.includes("Z") || maybeDateString.includes("+")) {
    parsedDate = parseMaybeStringISOOffsetDate(maybeDateString);
  } else {
    parsedDate = parseMaybeStringDate(maybeDateString);
  }

  return formatters[uwDateLabelFormat].format(parsedDate);
}

const formatters: Record<DateLabelOptions, Intl.DateTimeFormat> = {
  monthDate: FORMAT_MONTH_DATE,
  basicDate: FORMAT_BASIC_DATE,
  monthDateTime: FORMAT_MONTH_DATE_TIME,
};

// Endpoint uses date in yyyy-mm-dd format and formState uses Date() for BoundDateField
export function convertDateToString(date: Date | string): string {
  return typeof date === "string" ? date : date.toISOString().split("T")[0];
}
