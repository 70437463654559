import { Css } from "@homebound/beam";
import { Datum, ResponsiveLine, Serie } from "@nivo/line";
import { Maybe } from "src/utils/types";

type JSONValue = string | number | boolean | JSONObject;

export interface JSONObject {
  [x: string]: JSONValue;
}

export interface AppreciationPredictionProps {
  appreciations?: Maybe<JSONObject>;
  updated_at: string;
  final_weighted_price?: Maybe<number>;
}

function format_percentile_data(
  array_appreciations: Array<JSONObject>,
  percentile_key: string,
  house_value: number,
): Datum[] {
  return array_appreciations.map((appreciation): Datum => {
    return {
      x: appreciation.day as string,
      y: ((appreciation.rates as JSONObject)[percentile_key] as number) * house_value + house_value,
    };
  });
}

function reformat_appreciation(
  appreciations: Maybe<JSONObject>,
  house_value: Maybe<number>,
  updated_at: string,
): Serie[] {
  if (appreciations && house_value && updated_at) {
    const updated_at_first_month = updated_at.slice(0, 8) + "01";
    const array_appreciations: JSONObject[] | { day: string; rates: JSONValue }[] = [];
    Object.keys(appreciations).forEach((k) => {
      if (k >= updated_at_first_month) {
        array_appreciations.push({
          day: k,
          rates: appreciations[k as any],
        });
      }
    });
    array_appreciations.sort((a, b) => (a.day > b.day ? 1 : -1));

    const result = ["20", "50", "80"].map((percentile_key: string): Serie => {
      return {
        id: `percentile_${percentile_key}`,
        data: format_percentile_data(array_appreciations, percentile_key, house_value),
      };
    });

    return result;
  } else {
    return [];
  }
}

export function AppreciationPrediction({
  appreciations,
  updated_at,
  final_weighted_price,
}: AppreciationPredictionProps) {
  return (
    <div css={Css.hPx(450).$}>
      <ResponsiveLine
        data={reformat_appreciation(appreciations, final_weighted_price, updated_at)}
        margin={{ top: 50, right: 110, bottom: 75, left: 80 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        yFormat=" >$,.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -45,
          legend: "", // date
          legendOffset: 65,
          legendPosition: "middle",
        }}
        axisLeft={{
          format: ".3s",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "House Forecast Value",
          legendOffset: -65,
          legendPosition: "end",
        }}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
          {
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
}
