import { HasIdAndName } from "@homebound/beam";
import { PropertyType } from "src/routes/cma/endpoints";
import { ReadyPlan } from "src/routes/cma/endpoints/reports";
import { ValuationStage } from "src/routes/cma/endpoints/reports/ValuationStage";

export const propertyTypeOptions: HasIdAndName<PropertyType>[] = [
  {
    id: "adu",
    name: "ADU",
  },
  {
    id: "attached_home",
    name: "Attached Home",
  },
  {
    id: "condo",
    name: "Condo",
  },
  {
    id: "land",
    name: "Land",
  },
  {
    id: "mobile_or_manufactured",
    name: "Mobile or Manufactured",
  },
  {
    id: "planned_unit_development",
    name: "Planned Unit Development",
  },
  {
    id: "single_family",
    name: "Single Family",
  },
];

export function mapPropertyTypeName(type: string): string {
  const pt = propertyTypeOptions.find((t) => t.id === type);
  return pt ? pt.name : type;
}

export function mapPropertyCompTypeName(type: string): string {
  const types: Record<string, string> = {
    all_comps: "All Comps",
    new_builds: "New Builds",
    new_build_sales: "New Build Sales",
  };

  return types[type];
}

export function mapReadyPlanId(id: string): string {
  return id.split("_").join(" ");
}

export function getReadyPlanDisplayName(rp: ReadyPlan): string | "Main" | "Adu" {
  if (rp.ready_plan_sub_id === "main_adu") {
    return "Main";
  }

  if (rp.ready_plan_sub_id === "adu") {
    return "Adu";
  }

  if (rp.ready_plan_id) {
    return rp.ready_plan_id?.split("_").join(" ") || "";
  }

  if (rp.bp_ready_plan_name) {
    return rp.bp_ready_plan_name;
  }

  return "";
}

// This is to help handle different logic in the app for whether a property has closed or not
export const valuationStageToListingStatus: Record<ValuationStage, string> = {
  [ValuationStage.underwriting]: "Pre-Closing",
  [ValuationStage.clear_to_close]: "Pre-Closing",
  [ValuationStage.pre_listing]: "Post-Closing",
  [ValuationStage.post_listing]: "Post-Closing",
  [ValuationStage.asset_valuation]: "Post-Closing",
};
