// organize-imports-ignore
import "src/globalThis";
import { CssReset, defaultStyle, setGridTableDefaults } from "@homebound/beam";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { Amplify } from "@aws-amplify/core";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { App } from "./App";
import { amplifyConfig, getStage } from "./context";
import * as serviceWorker from "./serviceWorker";
import { BlueprintTheme } from "./theme/Themes";
import { CacheProvider } from "@rest-hooks/react";
import { mdash } from "./components/mdash";
import { configure } from "mobx";
import { enableAnalytics } from "src/utils";
const stage = getStage();

void enableAnalytics(stage);

Amplify.configure(amplifyConfig(stage));

// Amplify.Logger.LOG_LEVEL = "DEBUG";

// disable mobx action warnings
configure({ enforceActions: "never" });

// Apply grid table defaults for empty cells
setGridTableDefaults({ style: { ...defaultStyle, emptyCell: mdash } });

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Router>
    <QueryParamProvider ReactRouterRoute={Route}>
      <ThemeProvider theme={BlueprintTheme}>
        <CssBaseline />
        <CssReset />
        <CacheProvider>
          <App />
        </CacheProvider>
      </ThemeProvider>
    </QueryParamProvider>
  </Router>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
