import { GoogleCallback, AuthenticatedRoute, AuthViewProps, UnauthenticatedRoute } from "@homebound/auth-components";
import { Redirect, Route, Switch } from "react-router-dom";
import { NotFound } from "./components/NotFound";
import { PolygonPage } from "./routes/admin/polygons/PolygonPage";
import { PolygonsListPage } from "./routes/admin/polygons/PolygonsListPage";
import { SignIn } from "./routes/auth/SignIn";
import { CmaPage } from "./routes/cma/CmaPage";
import { OpportunitiesPage } from "./routes/opportunities/OpportunitiesPage";
import { ReportsPage } from "./routes/reports/ReportsPage";
import { ReviewPage } from "./routes/review/ReviewPage";
import {
  cmaPath,
  cmaPathRouteMatchPath,
  opportunitiesPath,
  polygonPath,
  polygonsListPath,
  printEstimatePage,
  reportsPath,
  reviewPath,
} from "./routes/routesDef";
import { StatusPage } from "./routes/status/StatusPage";
import { PrintEstimatePage } from "src/routes/cma/PrintEstimatePage";
export type ProjectRouteProps = { projectId: string };

export function Routes({ authProps }: { authProps: AuthViewProps }) {
  return (
    <Switch>
      <Redirect exact from="/" to={cmaPath} />

      <AuthenticatedRoute path={reviewPath} exact component={ReviewPage} authProps={authProps} />
      <AuthenticatedRoute path={opportunitiesPath} exact component={OpportunitiesPage} authProps={authProps} />
      <AuthenticatedRoute path={cmaPathRouteMatchPath} component={CmaPage} authProps={authProps} />
      <AuthenticatedRoute path={cmaPath} component={CmaPage} authProps={authProps} />
      <AuthenticatedRoute path={reportsPath} exact component={ReportsPage} authProps={authProps} />
      <AuthenticatedRoute path={printEstimatePage} exact component={PrintEstimatePage} authProps={authProps} />
      <AuthenticatedRoute path="/status" exact component={StatusPage} authProps={authProps} />
      <AuthenticatedRoute path={polygonsListPath} exact component={PolygonsListPage} authProps={authProps} />
      <AuthenticatedRoute path={polygonPath} exact component={PolygonPage} authProps={authProps} />
      <UnauthenticatedRoute path="/signIn" exact component={SignIn} authProps={authProps} />
      <UnauthenticatedRoute path="/auth/gcallback" exact component={GoogleCallback} authProps={authProps} />
      <Route component={NotFound} />
    </Switch>
  );
}
