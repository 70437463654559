import {
  column,
  Css,
  GridCellContent,
  GridColumn,
  GridDataRow,
  GridTable,
  Icon,
  numericColumn,
  simpleHeader,
} from "@homebound/beam";
import { CollapsibleGroup } from "src/components/CollapsibleGroup";
import { formatNumberToString, Maybe } from "src/utils";
import { Status, StatusValue, TableInfo } from "./endpoints/StatusEndpoint";
import { statusColor, statusIconName } from "./StatusBar";

interface DatabaseInfoTabProps {
  data: Status;
}

export function DatabaseInfoTab({ data }: DatabaseInfoTabProps) {
  return (
    <>
      <div>
        <div css={Css.xlSb.pb2.$}>Table Status</div>
        <StatusTable tables={data.tables} />
      </div>
      <div>
        <div css={Css.xlSb.pt2.pb1.$}>Latest Migration</div>
        <div>{data.migration_version}</div>
        {data.migration_history && (
          <div css={Css.pt2.$}>
            <CollapsibleGroup label="Migration History">
              <pre>{data.migration_history}</pre>
            </CollapsibleGroup>
          </div>
        )}
      </div>
    </>
  );
}

interface StatusTableProps {
  tables: TableInfo[];
}

export function StatusTable({ tables }: StatusTableProps) {
  return (
    <div>
      <GridTable
        columns={columns}
        rows={createRows(tables)}
        sorting={{ on: "client", initial: ["table-column", "ASC"] }}
      />
    </div>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: TableInfo };
type Row = HeaderRow | DataRow;

const columns: GridColumn<Row>[] = [
  column<Row>({
    id: "status-column",
    header: "Status",
    data: ({ status }) => statusCell(status),
    w: "75px",
  }),
  column<Row>({
    id: "table-column",
    header: "Table",
    data: ({ table }) => table,
  }),
  numericColumn<Row>({
    id: "count-column",
    header: "est. row count",
    data: ({ estimated_row_count }) => numberCell(estimated_row_count),
  }),
];

function createRows(comps: TableInfo[]): GridDataRow<Row>[] {
  return [simpleHeader, ...(comps.map((t) => ({ kind: "data" as const, id: t.table, data: t })) || [])];
}

function numberCell(price: Maybe<number>, trim: boolean = true, prefix: string = ""): GridCellContent {
  return {
    content: price ? `${prefix}${formatNumberToString(price, trim, trim)}` : undefined,
    value: price,
  };
}

function statusCell(status: StatusValue): GridCellContent {
  return {
    content: (
      <div css={Css.add("color", statusColor(status)).$}>
        <Icon inc={3} icon={statusIconName(status)} />
      </div>
    ),
    value: status,
  };
}
