import { Button, ModalBody, ModalFooter, ModalHeader, useModal } from "@homebound/beam";
import { Tooltip } from "@material-ui/core";
import { Observer } from "mobx-react";
import { Fragment, useEffect } from "react";
import NavigationPrompt from "react-router-navigation-prompt";
import { Css } from "src/Css";
import { useTestIds } from "src/hooks";
import { SubmittingInitialState, useSubmitting } from "src/hooks/useSubmitting";
import { useUnload } from "src/hooks/useUnload";
import { ObjectState } from "src/utils/formState";

export interface FormActionsProps<F> {
  mode: "create" | "read" | "update";
  formState: ObjectState<F>;
  entityType?: string;
  entityName?: string;
  onSave: () => Promise<unknown>;
  onCancel: () => void;
  onEdit?: () => void;
  editDisableReason?: string;
  onDelete?: () => void;
  pretendSaveSubmissionState?: SubmittingInitialState;
  deleteDisabledReason?: string;
  deleteMessage?: string;
  secondaryButton?: "cancel" | "delete" | "none";
  /** Defaults to "Create" or "Save", but you can override i.e. if you really want "Add". */
  primaryLabel?: string;
  reverseOrder?: boolean;
  /** defaults to true. Form will call formState.commitChanges() when onSave resolves */
  autoCommitChanges?: boolean;
}

/** Whether the user is reading, creating, updating an entity. */
export type FormMode = "create" | "update" | "read";

/** Provides edit/save/delete/cancel buttons for a FormState-driven form. */
export function FormActions<F>(props: FormActionsProps<F>) {
  const {
    mode,
    onSave = async () => {},
    onCancel = () => {},
    onEdit = () => {},
    onDelete,
    entityName = "the entry",
    entityType = "entry",
    formState,
    pretendSaveSubmissionState,
    deleteDisabledReason,
    deleteMessage,
    reverseOrder = false,
    secondaryButton,
    primaryLabel,
    editDisableReason = "",
    autoCommitChanges = true,
  } = props;
  const { openModal, closeModal } = useModal();
  const saveSubmission = useSubmitting(pretendSaveSubmissionState);
  const [, editTooltipId] = useTestIds("formActions", ["editTooltip"]);

  // We use a function b/c this gets passed to NavigationPrompt that probably needs the latest
  // value from our formState (i.e. after formState.commitChanges()) w/o waiting for mobx to reactively
  // through an updated props value.
  const isDirty = () => formState.dirty;

  // Setting up events to listen for browser navigation to add confirmation if `promptOnLeave` is true.
  // Unfortunately we need to handle Browser routing and React routing separately.
  // `useUnload` hooks into native browser prompts. This handles full page reloads that React-Router cannot hook into.
  useUnload((e: BeforeUnloadEvent) => {
    if (isDirty()) {
      e.preventDefault();
      e.returnValue = "";
    }
  });

  // We check the .dirty via a proxy so need to be reactive
  return (
    <Observer>
      {() => {
        const primaryBtn =
          mode === "read" ? (
            <Tooltip title={editDisableReason} {...editTooltipId}>
              <span>
                <Button
                  variant="secondary"
                  label="Edit"
                  data-testid="edit"
                  onClick={onEdit}
                  disabled={!!editDisableReason}
                />
              </span>
            </Tooltip>
          ) : (
            <Button
              label={primaryLabel ?? (mode === "create" ? "Create" : "Save")}
              onClick={async () => {
                if (formState.canSave()) {
                  const promise = onSave();
                  saveSubmission.begin(promise);
                  // If the promise resolves successful, mark the form state
                  await promise;
                  autoCommitChanges && formState.commitChanges();
                }
              }}
              data-testid="save"
              // Should we check `formState.valid` here?
              // It seems intuitive that "if the form isn't valid, don't allow clicking Save,
              // but right now form fields only show errors if they are touched, and we have
              // a lot of tests that use "click Save" as a way of doing "touch all fields".
              // ^ UW App does not do this but keeping info here in case it comes up later
              disabled={saveSubmission.submitting || !isDirty() || !formState.valid}
            />
          );

        const secondaryBtn =
          secondaryButton === "none" ? (
            <Fragment />
          ) : secondaryButton === "delete" || (mode === "read" && onDelete) ? (
            <Tooltip title={deleteDisabledReason || ""} placement="right">
              <span>
                <Button
                  variant="danger"
                  label="Delete"
                  disabled={!!deleteDisabledReason}
                  data-testid="deleteBtn"
                  onClick={() =>
                    openModal({
                      content: (
                        <>
                          <ModalHeader>{`Delete ${entityType}`}</ModalHeader>
                          <ModalBody>
                            {deleteMessage || `This will delete ${entityName} and cannot be undone.`}
                          </ModalBody>
                          <ModalFooter>
                            <Button variant="tertiary" label="Cancel" onClick={closeModal} />
                            <Button
                              label="Delete"
                              variant="danger"
                              data-testid="confirmDelete"
                              onClick={() => {
                                onDelete && onDelete();
                                closeModal();
                              }}
                            />
                          </ModalFooter>
                        </>
                      ),
                    })
                  }
                />
              </span>
            </Tooltip>
          ) : (
            (secondaryButton === "cancel" || mode !== "read") && (
              <Button variant="tertiary" label="Cancel" data-testid="cancel" onClick={onCancel} />
            )
          );

        return (
          <Fragment>
            <div css={Css.df.aic.gap1.$}>
              {reverseOrder ? (
                <Fragment>
                  {primaryBtn} {secondaryBtn}
                </Fragment>
              ) : (
                <Fragment>
                  {secondaryBtn} {primaryBtn}
                </Fragment>
              )}
            </div>

            {/* React Router implementation of confirmation dialog. */}
            <NavigationPrompt when={isDirty}>
              {({ onConfirm, onCancel }) => (
                <ConfirmLeaveModal onConfirm={onConfirm} onCancel={onCancel} formState={formState} />
              )}
            </NavigationPrompt>
          </Fragment>
        );
      }}
    </Observer>
  );
}

interface ConfirmLeaveModalProps {
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
  formState: ObjectState<any>;
}

function ConfirmLeaveModal({ onConfirm, onCancel, formState }: ConfirmLeaveModalProps) {
  const { openModal, closeModal } = useModal();
  // useEffect to immediately open the modal.
  useEffect(() => {
    openModal({
      content: (
        <>
          <ModalHeader>Leave page?</ModalHeader>
          <ModalBody>
            <p>All changes you've made will be lost.</p>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="tertiary"
              label="Continue Editing"
              onClick={() => {
                closeModal();
                onCancel();
              }}
            />
            <Button
              label="Leave"
              variant="danger"
              onClick={() => {
                onConfirm();
                formState.revertChanges();
                closeModal();
              }}
            />
          </ModalFooter>
        </>
      ),
    });

    return () => closeModal();
    // If it ain't broke dont fix it
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
}
