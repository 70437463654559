import { useCallback, useState } from "react";

export interface SubmittingState {
  submitting: boolean;
  error: string | undefined;
  begin: (operation: Promise<unknown>) => void;
  clearError: () => void;
}

export type SubmittingInitialState = Pick<SubmittingState, "submitting" | "error">;

/** Helps track status of a server-side submissions. */
export function useSubmitting(initialState?: SubmittingInitialState): SubmittingState {
  const [submitting, setSubmitting] = useState<boolean>(initialState?.submitting ?? false);
  const [error, setError] = useState<string | undefined>(initialState?.error ?? undefined);

  const begin = useCallback(async (operation: Promise<unknown>) => {
    setSubmitting(true);
    setError(undefined);
    try {
      await operation;
    } catch (e: any) {
      console.log(e);
      setError(e.message);
    } finally {
      setSubmitting(false);
    }
  }, []);

  const clearError = useCallback(() => setError(undefined), []);

  return { submitting, error, begin, clearError };
}
