import { Css, Icon, Tooltip } from "@homebound/beam";
import { useParams } from "react-router";
import { useSuspense } from "@rest-hooks/react";
import { LoadingBoundary } from "src/components/LoadingBoundary";
import { PageHeader } from "src/components/PageHeader";
import { useComputed } from "src/hooks";
import { PropertyEndpoint } from "../../endpoints";
import { UnderwritingReportEndpoint } from "../../endpoints/reports/UnderwritingReportEndpoint";
import { findPlan } from "../readyPlanUtils";
import { CompAdjustmentTable } from "./AdjustmentTable";

export function AdjustmentsStep() {
  const { dpid, versionId, readyPlanId } = useParams<{ dpid: string; versionId: string; readyPlanId: string }>();

  return (
    <>
      <PageHeader
        title="Comp Adjustments"
        right={
          <Tooltip title="Updates made in the table are saved globally to property attributes and used in future reporting.">
            <div css={Css.pr3.$}>
              <Icon icon="infoCircle" />
            </div>
          </Tooltip>
        }
      />
      <LoadingBoundary>
        <LoadAdjustments dpid={dpid} versionId={versionId} readyPlanId={readyPlanId} key={readyPlanId} />
      </LoadingBoundary>
    </>
  );
}

interface LoadAdjustmentsProps {
  dpid: string;
  versionId: string;
  readyPlanId: string;
}

export function LoadAdjustments({ dpid, versionId, readyPlanId }: LoadAdjustmentsProps) {
  const { property } = useSuspense(PropertyEndpoint, { dpid });
  const { report } = useSuspense(UnderwritingReportEndpoint, { dpid, versionId });
  const plan = useComputed(() => findPlan(report, readyPlanId), [report, readyPlanId]);

  return <CompAdjustmentTable property={property} report={report} readyPlan={plan!} />;
}
