import { Endpoint, Entity } from "@rest-hooks/rest";
import { api_url, auth_headers } from "src/context";
import { Maybe } from "src/utils";

export class ReportCtaTrigger extends Entity {
  readonly id: number = 0;
  readonly name: string = "";
  readonly label: string = "";
  readonly description: Maybe<string> = undefined;

  pk() {
    return `cta-trigger-${this.id}`;
  }

  static key = "ReportCtaTrigger";
}

export class ReportCtaResolution extends ReportCtaTrigger {
  readonly sort_order: Maybe<number> = null;
  pk() {
    return `cta-resolution-${this.id}`;
  }

  static key = "ReportCtaResolution";
}

export const CtaTriggerAndResolutionsEndpoint = new Endpoint(
  async () => {
    const headers = await auth_headers();
    const res = await fetch(api_url(`/v1/reports/ctas/triggers-and-resolutions`), {
      headers,
    });
    return await res.json();
  },
  {
    schema: {
      triggers: [ReportCtaTrigger],
      resolutions: [ReportCtaResolution],
    },
    key: () => `/v1/reports/ctas/triggers-and-resolutions`,
    name: "CtaTriggerAndResolutionsEndpoint",
  },
);
