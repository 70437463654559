import { ObjectState } from "@homebound/form-state";
import { Polygon as LeafletPolygon, Point } from "leaflet";
import { useEffect, useMemo, useRef } from "react";
import { Popup, Polygon as ReactLeafletPolygon, useMap } from "react-leaflet";
import { useToggle } from "src/hooks";
import { SavePolygonInput, defaultPolygonName } from "src/routes/admin/polygons/PolygonEndpoints";
import { getPolygonColor, getPolygonPositions } from "src/routes/maps/mapUtils";
import { Maybe } from "src/utils";
import { usePolygonContext } from "../PolygonContext";
import { PolygonUpdateType } from "./PolygonEditor";
import { PolygonPopUp } from "./PolygonPopup";

interface PolygonFeatureViewProps {
  polygonFormState: ObjectState<SavePolygonInput>;
  onPolygonChange: (layer: UwLeafletPolygon, updateType: PolygonUpdateType) => void;
  saveForm: () => void;
}

export type UwLeafletPolygon = LeafletPolygon & { uwPolygonId?: Maybe<number> };

export function PolygonFeatureView({ polygonFormState, onPolygonChange, saveForm }: PolygonFeatureViewProps) {
  const lpRef = useRef<UwLeafletPolygon>(null);
  const [canEdit, toggleEdit] = useToggle(false);
  const map = useMap();
  const { polygonVisibility, selectedPolygonId, setSelectedPolygonId } = usePolygonContext();

  const highlightedOutlineWeight = 4;
  const defaultOutlineWeight = 2;

  const color = useMemo(
    () =>
      getPolygonColor({
        isActive: polygonFormState.value.active,
        color: polygonFormState.color.value,
        type: polygonFormState.value.type,
      }),
    [polygonFormState.color.value, polygonFormState.value.active, polygonFormState.value.type],
  );

  useEffect(() => {
    if (lpRef.current) {
      // add uwPolygonId to the leaflet polygon so it can be used to identify the polygon
      lpRef.current.uwPolygonId = polygonFormState.value.id;
      // Fired when Edit Mode is disabled and a layer is edited and its coordinates have changed.
      lpRef.current.on(
        "pm:update",
        ({ target }) => {
          polygonFormState.commitChanges();
          onPolygonChange(target, "Edit");
        },
        lpRef.current,
      );
    }
  }, [onPolygonChange, polygonFormState]);

  useEffect(() => {
    if (lpRef.current) {
      // hide/show polygons based on type
      if (!polygonVisibility.find((pv) => pv.type === polygonFormState.value.type)?.isVisible) {
        lpRef.current.remove();
      } else {
        map.addLayer(lpRef.current!);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygonVisibility]);

  // Open popup and enable editing on newly created polygon or polygon with default name
  useEffect(() => {
    if (polygonFormState.value.id !== undefined && polygonFormState.name.value === defaultPolygonName) {
      lpRef.current?.openPopup();
      lpRef.current?.pm.enable({ allowSelfIntersection: false });
      !canEdit && toggleEdit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygonFormState.value.id]);

  // handle polygon selection
  useEffect(() => {
    if (lpRef.current) {
      if (selectedPolygonId === polygonFormState.value.id) {
        map.flyTo(lpRef.current.getCenter());
        lpRef.current.openPopup();
      } else {
        // if polygon is highlighted, remove the highlight
        lpRef.current.options.weight === highlightedOutlineWeight && unHighlightPolygon(lpRef.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPolygonId]);

  useEffect(() => {
    // when map is clicked, clear the selected polygon id
    map.on("click", () => setSelectedPolygonId(null));
  }, [map, setSelectedPolygonId]);

  function highlightPolygon(uwPolygon: UwLeafletPolygon) {
    uwPolygon.bringToFront();
    uwPolygon.setStyle({ fillOpacity: 0.5, weight: highlightedOutlineWeight });
  }

  function unHighlightPolygon(uwPolygon: UwLeafletPolygon) {
    uwPolygon.setStyle({ fillOpacity: 0.2, weight: defaultOutlineWeight });
    uwPolygon.bringToBack();
  }

  function resetPolygon(uwPolygon: UwLeafletPolygon) {
    unHighlightPolygon(uwPolygon);
    uwPolygon.pm.disable();
    // revert unsaved changes
    polygonFormState.revertChanges();
    lpRef.current?.setStyle({ fillColor: color, color });
  }

  return (
    <ReactLeafletPolygon
      ref={lpRef}
      positions={getPolygonPositions(polygonFormState.value.geometry)}
      fillColor={color}
      color={color}
      weight={2}
      eventHandlers={{
        click: () => {
          setSelectedPolygonId(polygonFormState.value.id);
          // turn off if in edit mode on click (this can happen for polygons that have the default name)
          canEdit && toggleEdit();
        },
        popupopen: () => {
          if (lpRef.current) {
            highlightPolygon(lpRef.current);
          }
        },
        popupclose: () => {
          // disable editing when the popup is closed
          polygonFormState.valid && canEdit && toggleEdit();
          if (lpRef.current) {
            resetPolygon(lpRef.current);
          }
        },
      }}
    >
      <Popup minWidth={150} offset={new Point(0, -50)}>
        <PolygonPopUp
          canEdit={canEdit}
          lpRef={lpRef}
          polygonFormState={polygonFormState}
          toggleEdit={toggleEdit}
          onPolygonChange={onPolygonChange}
          saveForm={saveForm}
        />
      </Popup>
    </ReactLeafletPolygon>
  );
}
