import {
  Accordion,
  AccordionList,
  AccordionProps,
  Button,
  Css,
  Icon,
  Palette,
  Tag,
  useRightPane,
  useTestIds,
} from "@homebound/beam";
import { capitalCase } from "change-case";
import { groupBy, partition } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { UWLabel } from "src/components/UWLabel";
import { PropertyComp } from "src/routes/cma/endpoints";
import {
  ReadyPlan,
  ReportCtaLevel,
  ReportCtaResolution,
  ReportCtaStatus,
  ReportCtaTrigger,
  UnderwritingReport,
} from "src/routes/cma/endpoints/reports";
import { OptionWithMenuLabel, ResolveCtaCard } from "src/routes/reports/components/cta/ResolveCtaCard";
import { Maybe } from "src/utils";
import { getReadyPlanDisplayName } from "src/utils/mappers";

type CtaPaneContentProps = {
  report: UnderwritingReport;
  triggerOptions: ReportCtaTrigger[];
  resolutionOptions: ReportCtaResolution[];
};

export function CtaPaneContent(props: CtaPaneContentProps) {
  const { report, triggerOptions, resolutionOptions } = props;
  const testId = useTestIds(props, "cta-pane-content");
  const location = useLocation();
  const [initialPath] = useState(location.pathname);
  const { closeRightPane } = useRightPane();

  const { report_ctas: allReportCtas, ready_plans } = report;

  const resolutionFieldOptions = buildResolutionOptions(resolutionOptions);

  const rpGroupedCtas = groupBy(allReportCtas, (cta) =>
    getReadyPlanName(ready_plans, cta.underwriting_report_ready_plan_id),
  );

  // Build accordion list grouping by RP
  // We currently do not have any report level triggers
  const accordionList = Object.entries(rpGroupedCtas).map(([rpName, ctas]) => {
    // Get comps specific to this RP
    const thisRpComps = ready_plans?.find((rp) => rp.id === ctas[0].underwriting_report_ready_plan_id)?.comps;
    const allRpOpenCtaCount = ctas.filter((cta) => cta.status === ReportCtaStatus.open).length;

    // Separate CTAs relevant to whole ready plan and comp specific ctas
    const [rpSpecificCtas, compSpecificCtas] = partition(
      ctas,
      (cta) => cta.level === ReportCtaLevel.underwriting_report_ready_plan_id,
    );
    const groupedCompCtas = groupBy(compSpecificCtas, (cta) => cta.level_value);

    const openRpSpecificCtasCount = rpSpecificCtas.filter((rpCta) => rpCta.status === ReportCtaStatus.open).length;

    return {
      title: <AccordionTitle title={rpName} openCount={allRpOpenCtaCount} />,
      // FIXME: defaultExpanded straight up broken, beam needs an update.
      //  accordionList is overwriting defaultExpanded prop by spreading received props first onto child accordions
      defaultExpanded: true,
      topBorder: false,
      xss: Css.bcGray300.bb.$,
      children: (
        <>
          {/* Always render rp specific ctas on top and opened */}
          {rpSpecificCtas.length > 0 && (
            <Accordion
              {...testId["rp-specific-accordion"]}
              compact
              title={<AccordionTitle title="Ready Plan" openCount={openRpSpecificCtasCount} compact />}
            >
              <div css={Css.df.fdc.gap2.$}>
                {rpSpecificCtas.map((rpCta) => (
                  <ResolveCtaCard
                    {...testId}
                    key={rpCta.id}
                    reportCta={rpCta}
                    resolutionFieldOptions={resolutionFieldOptions}
                    // Impossible for it NOT to exist
                    ctaTrigger={triggerOptions.find((to) => to.name === rpCta.trigger_name)!}
                  />
                ))}
              </div>
            </Accordion>
          )}
          <AccordionList
            compact
            {...testId["comp-specific-accordion-list"]}
            accordions={Object.entries(groupedCompCtas).map(([compId, compCtas]) => {
              const openCompCtasCount = compCtas.filter((compCta) => compCta.status === ReportCtaStatus.open).length;
              const prettyCompAddress = capitalCase(getCompAddress(thisRpComps, compId));
              return {
                title: <AccordionTitle title={prettyCompAddress} openCount={openCompCtasCount} compact />,
                topBorder: false,
                compact: true,
                children: (
                  <div css={Css.df.fdc.gap2.$}>
                    {compCtas.map((compCta) => (
                      <ResolveCtaCard
                        {...testId}
                        key={compCta.id}
                        reportCta={compCta}
                        resolutionFieldOptions={resolutionFieldOptions}
                        // Impossible for it NOT to exist
                        ctaTrigger={triggerOptions.find((to) => to.name === compCta.trigger_name)!}
                      />
                    ))}
                  </div>
                ),
              };
            })}
          />
        </>
      ),
    };
  });

  // Close the right pane if user navigates anywhere
  useEffect(() => {
    initialPath !== location.pathname && closeRightPane();
  }, [closeRightPane, initialPath, location.pathname]);

  return (
    <div
      {...testId}
      css={
        Css.os.relative.z0
          .h("calc(100% - 64px)")
          // offset to account for CMApage padding
          .df.fdc.pr2.pb2.mt2.addIn("> div:last-of-type", Css.bn.$).$ // AccordionList hardcode bottom border if compact
      }
    >
      <div css={Css.sticky.z1.top0.bgWhite.df.jcsb.aic.hPx(48).pb2.addIn("> button:first-of-type", Css.fdrr.pr0.$).$}>
        <UWLabel tooltip={CTA_HELPER_TEXT} icon="helpCircle" label={"CTAs"} labelTypography="lgBd" />
        <Button variant="textSecondary" icon="chevronRight" label={"Close"} onClick={() => closeRightPane()} />
      </div>
      <AccordionList {...testId["cta-accordion-list"]} accordions={accordionList as AccordionProps[]} />
    </div>
  );
}

function AccordionTitle({
  title,
  openCount,
  compact = false,
}: {
  title: string;
  openCount: number;
  compact?: boolean;
}) {
  return (
    <div css={Css.df.jcsb.$}>
      <div>{title}</div>
      <div>
        {openCount > 0 ? (
          <Tag type="warning" text={`${openCount} Open`} />
        ) : (
          <Icon inc={compact ? 2 : 3} icon="checkCircleFilled" color={Palette.Green600} />
        )}
      </div>
    </div>
  );
}

// Adds a "Requires notes" menuLabel to `dismissed` and `other` resolution options
function buildResolutionOptions(resolutionOptions: ReportCtaResolution[]): OptionWithMenuLabel[] {
  return resolutionOptions
    .filter((o) => !!o.sort_order)
    .map((res) => {
      const menuLabel =
        res.name === "dismissed" || res.name === "other" ? (
          <span>
            {res.label} <div css={Css.df.tinyBd.red500.add("fontStyle", "italic").$}>Requires notes</div>
          </span>
        ) : (
          res.label
        );
      return {
        value: res.name,
        label: res.label,
        menuLabel: menuLabel,
      };
    });
}

function getCompAddress(comps: Maybe<PropertyComp[]>, compId: string) {
  const compAddress = comps?.find((comp) => comp.id?.toString() === compId)?.full_street_address || "Address not found";
  return capitalCase(compAddress);
}

function getReadyPlanName(readyPlans: ReadyPlan[] | undefined, rpId: number) {
  const theReadyPlan = readyPlans?.find((rp) => rp.id === rpId);

  return theReadyPlan ? getReadyPlanDisplayName(theReadyPlan) : "Ready Plan not found";
}

const CTA_HELPER_TEXT =
  "Please note that once a Call to Action (CTA) within a unique ready plan or comparable property selection has been resolved, we will not notify you of it again, even if triggered. For this reason, we encourage reviewing CTAs during underwriting as needed and resolving them prior to finalizing your report.";
