import { BaseFilter, Filter, Icon, Palette, TestIds, TextField } from "@homebound/beam";
import { ReactElement } from "react";

export type SearchBoxFilterProps<V> = {
  label?: string;
  defaultValue?: V | undefined;
};

/**
 * Text box filter that works with other out-of-the-box beam Filter defs.
 * Filter object is updated while typing so expect needing to implement a debounce alongside it if used to build an
 * expensive query.
 */
export function searchBoxFilter(props: SearchBoxFilterProps<string>): (key: string) => Filter<string> {
  return (key) => new SearchBoxFilter(key, props);
}

class SearchBoxFilter extends BaseFilter<string, SearchBoxFilterProps<string>> implements Filter<string> {
  get hideLabelInModal() {
    return true;
  }

  render(value: string | undefined, setValue: (value: string | undefined) => void, tid: TestIds): ReactElement {
    return (
      <TextField
        label="Search"
        labelStyle="hidden"
        compact
        value={value}
        onChange={setValue}
        placeholder={this.label}
        clearable
        startAdornment={<Icon icon="search" color={Palette.Gray700} />}
        {...this.testId(tid)}
      />
    );
  }
}
