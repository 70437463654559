import { Endpoint, Entity } from "@rest-hooks/rest";
import FileSaver from "file-saver";
import { api_url, auth_headers } from "src/context";
import { Maybe } from "src/utils/types";
import { AmenityAdjustment } from "./adj/AmenityAdjustmentEndpoint";
import { Property, PropertyType } from "./PropertyEndpoint";
import { PropertyImage } from "./PropertyImage";

export interface ManualAdjustment {
  id: Maybe<number>;
  dpid_of_neighbor: string;
  description: string;
  amount: number;
}

export type CompTier = "Tier 1" | "Tier 2" | "Tier 3" | "Tier 4" | "Tier 5";
// TODO: Cleanup types
//  Why would neighbor ever be undefined???
export class PropertyComp extends Entity {
  id: Maybe<string> = undefined;
  user_selected_comp: Maybe<boolean> = undefined;

  readonly dpid: string = "";
  readonly dpid_of_neighbor: string = "";
  readonly dist: Maybe<number> = undefined;
  readonly comp_type: string = "";
  readonly ml_weight: Maybe<number> = undefined;
  readonly weight: Maybe<number> = undefined;
  readonly comp_tier: CompTier = "Tier 5";
  readonly comp_score: Maybe<number> = undefined;
  readonly has_pool: Maybe<boolean> = undefined;
  readonly num_stories: Maybe<number> = undefined;
  readonly num_bedrooms: Maybe<number> = undefined;
  readonly num_baths: Maybe<number> = undefined;
  readonly garage_num_cars: Maybe<number> = undefined;
  readonly year_built_or_renovated: string = "";
  readonly above_ground_sqft: Maybe<number> = undefined;
  readonly finished_sqft: number = 0;
  readonly lot_size: number = 0;
  readonly last_sold_date: Maybe<string> = undefined;
  readonly last_sold_price: Maybe<number> = undefined;
  readonly last_sold_price_adj: Maybe<number> = undefined;
  readonly last_sold_price_adj_per_sqft: Maybe<number> = undefined;
  readonly homebound_avm: Maybe<number> = undefined;
  readonly estimated_price: Maybe<number> = undefined;
  readonly full_street_address: string = "";
  readonly city_name: string = "";
  readonly state: string = "";
  readonly zip_code: string = "";
  readonly unit_number: Maybe<string> = undefined;
  readonly latitude: Maybe<number> = undefined;
  readonly longitude: Maybe<number> = undefined;
  readonly property_type_simple: PropertyType | "" = "";
  readonly close_to_major_road: boolean = false;
  readonly close_to_road_rail_industrial: boolean = false;
  readonly close_to_river_or_lake: boolean = false;
  readonly basement_sqft: Maybe<number> = undefined;
  readonly finished_basement_sqft: Maybe<number> = undefined;
  readonly unfinished_basement_sqft: Maybe<number> = undefined;
  readonly spec_level: Maybe<string> = undefined;

  readonly mls_status: Maybe<string> = undefined;
  readonly mls_list_date: Maybe<string> = undefined;
  readonly mls_list_price: Maybe<number> = undefined;

  readonly neighbor: Property | undefined = undefined;
  readonly manual_adjustments?: ManualAdjustment[] = undefined;
  readonly amenity_adjustment: AmenityAdjustment | undefined = undefined;
  readonly property_images: PropertyImage[] | undefined = undefined;
  readonly final_adjusted_price: Maybe<number> = undefined;

  readonly is_hb_comp: boolean | undefined = undefined;
  // ML auto underwriting recommended comp selection
  readonly auto_uw_rec: boolean | undefined = undefined;
  readonly builder_name: Maybe<string> = undefined;

  readonly last_sold_price_adj_high: Maybe<number> = undefined;
  readonly last_sold_price_adj_low: Maybe<number> = undefined;
  readonly homebound_avm_high: Maybe<number> = undefined;
  readonly homebound_avm_low: Maybe<number> = undefined;

  pk() {
    return `${this.dpid}-${this.dpid_of_neighbor}`;
  }

  static key = "PropertyComp";
}

interface PropertyCompParams {
  dpid: string;
}

export const PropertyCompEndpoint = new Endpoint(
  async ({ dpid }: PropertyCompParams) => {
    const headers = await auth_headers();
    const res = await fetch(api_url(`/v1/comps/${dpid}`), {
      headers,
    });
    return await res.json();
  },
  {
    schema: { comps: [PropertyComp] },
    key: ({ dpid }) => {
      return `/v1/comps/${dpid}`;
    },
    name: "PropertyCompEndpoint",
  },
);

export async function downloadExportCsv(dpid: string, filename: string) {
  const headers = await auth_headers();
  const res = await fetch(api_url(`/v1/comps/${dpid}/export`), {
    headers,
  });

  const blob = await res.blob();
  FileSaver.saveAs(blob, filename);
}
