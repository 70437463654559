import { Endpoint, Entity } from "@rest-hooks/rest";
import { api_url, auth_headers } from "src/context";

export interface CreateValuationReportsEndpointParams {
  metros: string[];
}

export class CreateValuationReportsEndpointResponse extends Entity {
  readonly reports_created: number = 0;

  pk() {
    return `cvrer-${this.reports_created}`;
  }

  static key = "CreateValuationReportsEndpointResponse";
}

export const CreateValuationReportsEndpoint = new Endpoint(
  async (params: CreateValuationReportsEndpointParams) => {
    const headers = {
      "content-type": "application/json",
      ...(await auth_headers()),
    };

    const res = await fetch(api_url(`/v1/reports/valuation`), {
      headers,
      method: "POST",
      body: JSON.stringify(params),
    });

    const data = await res.json();
    return data;
  },
  {
    schema: CreateValuationReportsEndpointResponse,
    name: "CreateValuationReportsEndpoint",
  },
);
