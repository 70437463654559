import { AuthViewProps, HomeboundUser, signOut } from "@homebound/auth-components";
import { Icon, NavLink, Palette } from "@homebound/beam";
import { Fade, Menu, MenuItem } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AvatarCircle } from "src/components/AvatarCircle";
import { HomeboundIcon } from "src/components/HomeboundLogo";
import { api_url, getStage } from "src/context";
import { Css, px } from "src/Css";
import { useTestIds } from "src/hooks";
import { cmaPath, opportunitiesPath, reportsPath, reviewPath } from "src/routes/routesDef";
import { navLink } from "src/theme";

interface GlobalNavProps extends Pick<AuthViewProps, "user"> {}

export function GlobalNav({ user }: GlobalNavProps) {
  const location = useLocation();
  const [navId, navLinkId, homeLinkId] = useTestIds("globalNav", ["link", "helpLink", "homeLink"]);

  const toolbarLinks: {
    // What is shown as a label
    label: string;
    // Which path or paths will activate the label
    path: string | string[];
    // What path to redirect the user when clicking on the label
    value?: string;
  }[] = [
    { label: "Opportunities", path: opportunitiesPath, value: opportunitiesPath },
    { label: "CMA", path: cmaPath, value: cmaPath },
    { label: "Reports", path: reportsPath, value: reportsPath },
    { label: "Review", path: reviewPath, value: reviewPath },
  ];

  return (
    <nav
      css={Css.bgBlack.fs0.w100.sticky.top0.z999.h(px(topNavHeight)).df.aic.addIn("@media print", Css.dn.$).$}
      {...navId}
    >
      <div css={Css.mx3.$}>
        <Link to={cmaPath} {...homeLinkId}>
          <HomeboundIcon width={45} color={Palette.White} />
        </Link>
      </div>
      <div css={Css.df.aic.mx1.fg1.gap2.$}>
        {toolbarLinks.map(({ label, path, value = Array.isArray(path) ? path[0] : path }) => (
          <NavLink
            key={value}
            href={value}
            label={label}
            active={
              Array.isArray(path)
                ? path.some((p) => location.pathname.startsWith(p))
                : location.pathname.startsWith(path)
            }
            variant="global"
            {...navLinkId}
          />
        ))}
      </div>
      {getStage() === "local" && (
        <div>
          <a href={api_url("/__profiler")} target="_main">
            <div css={Css.yellow500.pr2.$}>
              <Icon icon="bolt" />
            </div>
          </a>
        </div>
      )}
      <div>
        <Link to="/status">
          <div css={Css.gray400.$}>
            <Icon icon="infoCircle" />
          </div>
        </Link>
      </div>
      <div css={Css.ml2.mr3.$}>
        <ProfileMenu user={user} />
      </div>
    </nav>
  );
}

interface ProfileMenuProps {
  user?: HomeboundUser;
}

function ProfileMenu({ user }: ProfileMenuProps) {
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null);
  const menuIsOpen = Boolean(menuAnchor);

  function handleMenuOpen(event: any) {
    setMenuAnchor(event.currentTarget);
  }

  function handleMenuClose() {
    setMenuAnchor(null);
  }

  return (
    <Fragment>
      {user && (
        <button
          aria-controls="profile-menu"
          aria-haspopup="true"
          onClick={handleMenuOpen}
          css={Css.df.aic.jcc.h(px(topNavHeight)).w(px(topNavHeight)).$}
        >
          <AvatarCircle avatar={user.picture} name={user.name} hideTooltip />
        </button>
      )}
      <Menu
        id="profile-menu"
        anchorEl={menuAnchor}
        transformOrigin={{ vertical: 65, horizontal: -30 }}
        keepMounted
        open={menuIsOpen}
        onClose={handleMenuClose}
        TransitionComponent={Fade}
        PaperProps={{ style: { borderRadius: "8px" } }}
      >
        <div css={Css.df.fdc.jcsb.w("232px").$}>
          <MenuItem className={navLink} component="a" href="https://myaccount.google.com/personal-info" target="_blank">
            Profile
            <Icon icon="linkExternal" xss={Css.add("marginLeft", "auto").$} />
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClose();
              signOut().catch(console.error);
            }}
            css={Css.bt.bGray100.$}
          >
            Sign Out
          </MenuItem>
        </div>
      </Menu>
    </Fragment>
  );
}

export const topNavHeight = 48;
