import { Icon, IconButton, IconKey, Palette } from "@homebound/beam";
import { Snackbar, SnackbarContent } from "@material-ui/core";
import { ReactNode } from "react";
import { createPortal } from "react-dom";
import { Css } from "src/Css";

export enum AlertLevel {
  Error = "Error",
  Warning = "Warning",
  Info = "Info",
  Success = "Success",
}

const { Error, Warning, Info, Success } = AlertLevel;

export interface AlertProps {
  open: boolean;
  dismissable?: boolean;
  onDismiss: () => void;
  message: ReactNode;
  level: AlertLevel;
  allowClickAway?: boolean;
}

interface AlertStylesValue {
  icon: IconKey;
  color: Palette;
  bgColor: Palette;
  iconColor: Palette;
}

const AlertStyles: Record<AlertLevel, AlertStylesValue> = {
  [Error]: {
    color: Palette.White,
    bgColor: Palette.Red700,
    icon: "errorCircle",
    iconColor: Palette.White,
  },
  [Warning]: {
    color: Palette.Gray800,
    bgColor: Palette.Yellow100,
    icon: "errorCircle",
    iconColor: Palette.Yellow600,
  },
  [Info]: {
    color: Palette.Gray800,
    bgColor: Palette.Blue200,
    icon: "infoCircle",
    iconColor: Palette.Gray800,
  },
  [Success]: {
    color: Palette.White,
    bgColor: Palette.Green800,
    icon: "checkCircle",
    iconColor: Palette.White,
  },
};

export function Alert(props: AlertProps) {
  const { open, dismissable = true, onDismiss, message, level = Info } = props;
  const { color, icon, iconColor, bgColor } = AlertStyles[level];

  const autoHideIfSuccess = level === Success ? 3500 : undefined;

  return createPortal(
    <Snackbar
      open={open}
      onClose={(_, reason) => {
        // onClose is called on either timeout-expired (technically only used for success alerts) or click-outside-the-alert.
        // Per UX, we want to ignore click-outside-the-alerts and force the user to click close, so only call onDismiss if this
        // was from a success-related timeout.
        if (reason === "timeout") {
          onDismiss();
        }
      }}
      autoHideDuration={autoHideIfSuccess}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      data-testid="Alert"
      css={Css.add("position", undefined).$}
    >
      {/* Requires `important` on SnackbarContent css as the MuiSnackbar styles appear to have more specificity. */}
      <SnackbarContent
        css={Css.bgColor(bgColor).w("97vw").add({ borderRadius: "8px", flexWrap: "nowrap" }).important.$}
        message={
          <div css={Css.df.jcfe.aic.$}>
            <Icon icon={icon} color={iconColor} />
            <div css={Css.color(color).ml1.$}>{message}</div>
          </div>
        }
        action={
          dismissable && (
            <IconButton
              data-testid="cancel"
              onClick={onDismiss}
              icon="x"
              color={color === Palette.White ? Palette.White : Palette.Gray700}
            />
          )
        }
      />
    </Snackbar>,
    document.body,
  );
}
