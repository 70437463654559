import { getStage, Stage } from "./context";

export function getSfdcBaseUrl(stage: Stage = getStage()): string {
  if (stage === "prod") {
    return "https://homebound.lightning.force.com/";
  }

  return "https://homebound--partialsb.sandbox.lightning.force.com/";
}

export function getSfdcOpportunityUrl(opportunityId: string): string {
  return `${getSfdcBaseUrl()}lightning/r/Opportunity/${opportunityId}/view`;
}
