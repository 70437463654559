import { Css, Icon, Palette, Tooltip } from "@homebound/beam";
import { mdashUnicode } from "src/components/mdash";
import { formatNumberToString } from "src/utils";
import React from "react";
import { hasV2ReadyPlans } from "src/routes/cma/steps/readyPlanUtils";
import { ValuationStage } from "src/routes/cma/endpoints/reports/ValuationStage";
import { UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { shouldUseOldCostComputations } from "src/routes/cma/steps/estimate/components";

export function LandBasisBuyBox({ report }: { report: UnderwritingReport }) {
  const hasBpRp = hasV2ReadyPlans(report);
  const isC2C = report.valuation_stage === ValuationStage.clear_to_close;
  const landBasis = report.costs_and_metrics?.land_basis;
  const reportBeforeCutoff = shouldUseOldCostComputations(report);

  // Land basis calculation relies on BP cost data to be accurate
  if (!hasBpRp && !isC2C && !reportBeforeCutoff) {
    return (
      <Tooltip title={"Land Basis unavailable when report stage is not 'clear to close' if legacy ready plan is used"}>
        <div data-testid="landBasisHiddenIcon" css={Css.df.aic.jcc.baseBd.$}>
          <Icon icon="infoCircle" />
        </div>
      </Tooltip>
    );
  } else if (!landBasis) {
    return mdashUnicode;
  }

  let eligibilityColor, eligibilityText;
  switch (true) {
    case landBasis < 0.35:
      eligibilityColor = Palette.Green500;
      eligibilityText = "FITS BUY BOX";
      break;
    case landBasis <= 0.45 && landBasis >= 0.35:
      eligibilityColor = Palette.Yellow500;
      eligibilityText = "EXPANDED BUY BOX";
      break;
    default:
      eligibilityColor = Palette.Red500;
      eligibilityText = "INELIGIBLE";
  }
  return (
    <Tooltip title={"< 35% = fits buy box, > 35% = expanded buy box limit, > 45% = ineligible"}>
      <div data-testid="landBasisBuyBox" css={Css.df.fdc.aic.jcc.$}>
        <div data-testid="landBasisPercent" css={Css.baseBd.$}>
          {formatNumberToString(landBasis * 100) + "%"}
        </div>
        <div data-testid="eligibilityText" css={Css.bt.tac.bc(eligibilityColor).color(eligibilityColor).$}>
          {eligibilityText}
        </div>
      </div>
    </Tooltip>
  );
}
