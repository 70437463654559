import {
  Button,
  ButtonModal,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Palette,
  SelectField,
  useModal,
} from "@homebound/beam";
import { polygonTypeOptions } from "src/routes/maps/mapUtils";
import { UWPolygonType } from "../PolygonEndpoints";
import { useState } from "react";
import { ColorPickerGrid } from "./ColorPickerGrid";

type KMLImportModalProps = {
  inputRef: HTMLInputElement;
  polygonType: UWPolygonType | undefined;
  setPolygonType: (polygonType: UWPolygonType | undefined) => void;
  setPolygonColor: (color: Palette | undefined) => void;
};

export function KMLImportModal(props: KMLImportModalProps) {
  const { inputRef, polygonType, setPolygonType, setPolygonColor } = props;
  const { closeModal } = useModal();
  const [selectedPolygonType, setSelectedPolygonType] = useState<UWPolygonType | undefined>(polygonType);

  function resetModal() {
    closeModal();
    setPolygonType(undefined);
    setPolygonColor(undefined);
  }

  return (
    <div data-testid="kmlImportModal">
      <ModalHeader>Import Polygons From KML File</ModalHeader>
      <ModalBody>
        <div css={Css.df.fdc.gap2.$}>
          <SelectField
            label="Polygon Type"
            placeholder="Select polygon type"
            options={polygonTypeOptions}
            value={selectedPolygonType}
            onSelect={(v) => {
              setPolygonType(v);
              setSelectedPolygonType(v);
            }}
          />
          <div css={Css.df.fdc.gray700.$}>
            <label>Color</label>
            <ButtonModal
              trigger={{ label: "Polygon Color", icon: "palette" }}
              content={
                <ColorPickerGrid
                  setColor={(color) => {
                    setPolygonColor(color);
                  }}
                />
              }
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={resetModal} />
        <Button
          data-testid="kmlImportButton"
          disabled={!selectedPolygonType && "Select a polygon type"}
          label="Select KML File"
          onClick={() => {
            inputRef && inputRef.click();
            closeModal();
          }}
        />
      </ModalFooter>
    </div>
  );
}
