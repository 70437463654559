import {
  BoundNumberField,
  Checkbox,
  column,
  Css,
  GridColumn,
  GridDataRow,
  GridStyle,
  GridTable,
  RowStyles,
  simpleHeader,
} from "@homebound/beam";
import { condensedStyle, GridStyleDef } from "@homebound/beam/dist/components/Table/TableStyles";
import { NumberField } from "@homebound/beam/dist/inputs/NumberField";
import { FieldState, ObjectConfig, ObjectState } from "@homebound/form-state";
import _ from "lodash";
import { runInAction } from "mobx";
import { Observer } from "mobx-react";
import { CollapsibleGroup } from "src/components/CollapsibleGroup";
import { Maybe } from "src/utils";
import { Property } from "../../endpoints";
import { ReadyPlanOption, ReadyPlanOptions } from "../../endpoints/ReadyPlanEligibilityEndpoint";
import { UnderwritingReport } from "../../endpoints/reports/UnderwritingReportEndpoint";
import { UnderwritingReportInput } from "../../UnderwritingReportForm";
import { ReferencePropertyInput } from "../subject-property/ReferencePropertyForm";

interface ReadyPlanSelectionFormProps {
  formState: FormState;
}

export function ReadyPlanSelectionForm({ formState }: ReadyPlanSelectionFormProps) {
  function renderForm() {
    return (
      <div data-testid="ready-plan-v1-selection">
        <GridTable
          columns={createColumns(formState.ready_plan_id.value)}
          rows={createRows({ formState, eligible: true })}
          style={style}
          rowStyles={createRowStyles(formState)}
        />
        <div css={Css.pt4.$}>
          <CollapsibleGroup label="Additional Plans">
            <h1 css={Css.pb2.$}>These plans don't seem to be a good fit, but they can be selected.</h1>
            <GridTable
              columns={createColumns(formState.ready_plan_id.value)}
              rows={createRows({ formState, eligible: false })}
              style={style}
              rowStyles={createRowStyles(formState)}
            />
          </CollapsibleGroup>
        </div>
      </div>
    );
  }

  return <Observer>{() => renderForm()}</Observer>;
}

interface GroupedReadyPlanAdjustment {
  ready_plan_id: string;
  sub_plans: ObjectState<ReadyPlanAdjustment>[];
  eligible: Maybe<boolean>;
  formIsReadOnlyMode: boolean;
}

type HeaderRow = { kind: "header" };
type ParentRow = { kind: "rp"; data: GroupedReadyPlanAdjustment };
type DataRow = { kind: "sp"; data: ObjectState<ReadyPlanAdjustment> };
type Row = HeaderRow | ParentRow | DataRow;

interface CreateRowsProps {
  formState: FormState;
  eligible: boolean;
}

function createRows({ formState, eligible }: CreateRowsProps): GridDataRow<Row>[] {
  const options = mapOptions(formState, eligible);

  function makeDataRows() {
    return options
      .filter((o) => o.eligible === eligible)
      .map((o) => {
        return {
          id: o.ready_plan_id,
          kind: "rp" as const,
          data: o,
          children: o.sub_plans.map((s) => ({
            id: `${o.ready_plan_id}-${s.ready_plan_sub_id.value}`,
            kind: "sp" as const,
            data: s,
          })),
        };
      });
  }

  return [simpleHeader, ...makeDataRows()];
}

function mapOptions(formState: FormState, eligible: boolean): GroupedReadyPlanAdjustment[] {
  const by_id = _.groupBy(formState.ready_plan_adjustments.rows, (r) => r.ready_plan_id.value);
  const formIsReadOnlyMode = formState.readOnly;
  const plans = Object.keys(by_id).map((k) => {
    const sub_plans = by_id[k];
    const eligible = sub_plans[0].eligible.value;
    return { ready_plan_id: k, eligible, sub_plans, formIsReadOnlyMode };
  });

  return plans;
}

function createColumns(selectedReadyPlan: Maybe<string>): GridColumn<Row>[] {
  const calculateFAR = (row: ObjectState<ReadyPlanAdjustment>) => {
    const build = row.sellable_sqft.value;
    const lot = row.lot_size.value;

    if (build && lot) {
      return (build / lot) * 100;
    }

    return undefined;
  };

  return [
    column<Row>({
      header: "",
      rp: (row) => (
        <Checkbox
          checkboxOnly
          disabled={row.formIsReadOnlyMode}
          selected={row.ready_plan_id === selectedReadyPlan}
          onChange={() => {}}
          label={""}
        />
      ),
      sp: (_row) => undefined,
      w: "50px",
    }),
    column<Row>({
      header: "Ready Plan",
      rp: (row) => ({ content: <div css={Css.smMd.$}>{row.ready_plan_id}</div>, value: row.ready_plan_id }),
      sp: (row) => ({
        content: <div css={Css.sm.$}>{row.ready_plan_sub_id.value}</div>,
        value: row.ready_plan_sub_id.value,
      }),
      w: "200px",
    }),
    numberField("Beds", (row) => row.num_bedrooms, selectedReadyPlan),
    numberField("Baths", (row) => row.num_baths, selectedReadyPlan, 1),
    numberField("Garage Cars", (row) => row.num_garage_attached, selectedReadyPlan, 1),
    numberField("Stories", (row) => row.num_stories, selectedReadyPlan, 1),
    numberField("Subject Lot Size", (row) => row.lot_size, selectedReadyPlan),
    column<Row>({
      header: { content: "Sellable Sqft", tooltip: "Sellable Above Ground Sqft + Sellable Below Ground Sqft" },
      rp: (_row) => undefined,
      sp: (row) => {
        return {
          content: (
            <BoundNumberField
              compact
              field={row.sellable_sqft}
              label={"Sellable Sqft"}
              // After sqft split this is now a computed field
              // above_ground_sqft + sellable_basement_sqft
              readOnly={true}
              numFractionDigits={undefined}
            />
          ),
          value: row.sellable_sqft.value,
        };
      },
    }),
    column<Row>({
      header: { content: "Abv. Sqft", tooltip: "Above Ground Sellable Sqft" },
      rp: (_row) => undefined,
      sp: (row) => {
        return {
          content: (
            <BoundNumberField
              compact
              field={row.above_ground_sqft}
              label={"Abv. Sqft"}
              onChange={(nv) => {
                row.above_ground_sqft.set(nv);
                row.sellable_sqft.set((nv ?? 0) + (row.sellable_basement_sqft.value ?? 0));
              }}
            />
          ),
          value: row.above_ground_sqft.value,
        };
      },
    }),
    column<Row>({
      header: { content: "Blw. Sqft", tooltip: "Below Ground Sellable Sqft" },
      rp: (_row) => undefined,
      sp: (row) => {
        return {
          content: (
            <BoundNumberField
              compact
              field={row.sellable_basement_sqft}
              label={"Blw. Sqft"}
              onChange={(nv) => {
                row.sellable_basement_sqft.set(nv);
                row.sellable_sqft.set((nv ?? 0) + (row.above_ground_sqft.value ?? 0));
              }}
            />
          ),
          value: row.sellable_basement_sqft.value,
        };
      },
    }),
    numberField(
      { content: "UnFin. Blw. Sqft", tooltip: "Unfinished Below Ground Sqft" },
      (row) => row.below_ground_sqft,
      selectedReadyPlan,
    ),

    numberField("Hard Costs psqft", (row) => row.hard_costs_per_sqft, selectedReadyPlan, 2),
    numberField("Copies", (row) => row.copies, selectedReadyPlan),

    column<Row>({
      header: "FAR",
      rp: (row) => undefined,
      sp: (row) => ({
        content: (
          <Observer>
            {() => <NumberField type="percent" label="FAR" value={calculateFAR(row)} onChange={() => {}} readOnly />}
          </Observer>
        ),
        value: calculateFAR(row),
      }),
    }),
  ];
}

type RowToFieldF = (row: ObjectState<ReadyPlanAdjustment>) => FieldState<Maybe<number>>;

function numberField(
  label: string | { content: string; tooltip: string },
  rowField: RowToFieldF,
  selectedReadyPlan: Maybe<string>,
  numFractionDigits?: number,
) {
  return column<Row>({
    header: label,
    rp: (_row) => undefined,
    sp: (row) => {
      const f = rowField(row);
      return {
        content: (
          <BoundNumberField
            compact
            field={f}
            label={typeof label === "string" ? label : label.content}
            readOnly={selectedReadyPlan !== row.ready_plan_id.value || f.readOnly}
            numFractionDigits={numFractionDigits}
          />
        ),
        value: f.value,
      };
    },
  });
}

const style: GridStyle | GridStyleDef = {
  ...condensedStyle,
  emptyCell: <></>,
  headerCellCss: Css.bgGray100.smBd.gray700.$,
};

function createRowStyles(formState: FormState): RowStyles<Row> {
  const onClickFn = formState.readOnly
    ? undefined
    : (row: GridDataRow<ParentRow>) => {
        runInAction(() => {
          formState.ready_plan_id.set(row.data.ready_plan_id);
        });
      };

  return {
    rp: {
      onClick: onClickFn,
      cellCss: (row) => (row.data.ready_plan_id === formState.ready_plan_id.value ? Css.bgGreen200.$ : Css.bgGray200.$),
    },
    sp: {
      cellCss: (row) =>
        row.data.ready_plan_id.value === formState.ready_plan_id.value ? Css.bgGreen100.$ : Css.bgGray100.$,
    },
  };
}

export interface ReadyPlanAdjustment {
  ready_plan_id?: Maybe<string>;
  ready_plan_sub_id?: Maybe<string>;
  lot_size?: Maybe<number>;
  sellable_sqft?: Maybe<number>;
  num_bedrooms?: Maybe<number>;
  num_baths?: Maybe<number>;
  num_garage_attached: Maybe<number>;
  num_stories: Maybe<number>;
  hard_costs_per_sqft?: Maybe<number>;
  copies: Maybe<number>;
  property_type?: Maybe<string>;
  eligible: Maybe<boolean>;
  above_ground_sqft?: Maybe<number>;
  sellable_basement_sqft?: Maybe<number>;
  below_ground_sqft?: Maybe<number>;
  spec_level?: Maybe<string>;
}

export interface SaveReadyPlanInput {
  ready_plan_id?: Maybe<string>;
  ready_plan_adjustments?: Maybe<ReadyPlanAdjustment[]>;
}

export type FormInput = ReferencePropertyInput & SaveReadyPlanInput;
export type FormState = ObjectState<FormInput>;

export const formConfig: ObjectConfig<FormInput> = {
  dpid: { type: "value", readOnly: true },
  metro: { type: "value", readOnly: true },
  buildable_sqft: { type: "value" },
  lot_size: { type: "value" },
  zoning: { type: "value" },
  adu_elig: { type: "value" },
  ready_plan_id: { type: "value" },
  ready_plan_adjustments: {
    type: "list",
    config: {
      ready_plan_id: { type: "value" },
      ready_plan_sub_id: { type: "value" },
      spec_level: { type: "value" },
      num_bedrooms: { type: "value" },
      num_baths: { type: "value" },
      num_garage_attached: { type: "value" },
      num_stories: { type: "value" },
      hard_costs_per_sqft: { type: "value" },
      lot_size: { type: "value" },
      sellable_sqft: { type: "value" },
      copies: { type: "value" },
      property_type: { type: "value" },
      above_ground_sqft: { type: "value" },
      sellable_basement_sqft: { type: "value" },
      below_ground_sqft: { type: "value" },
      eligible: { type: "value", readOnly: true },
    },
  },
};

interface ReadyPlanFormInputData {
  property: Property;
  report: UnderwritingReport;
  options: ReadyPlanOptions;
}

export function mapToForm({ property, report, options }: ReadyPlanFormInputData): FormInput {
  const ref = report.reference_property;

  const ready_plan_id = report.ready_plans?.length ? report.ready_plans[0].ready_plan_id : undefined;

  const showOptions: ReadyPlanAdjustment[] = mapReadyPlans(
    options.ready_plans.filter((rp) => rp.ready_plan_id !== ready_plan_id),
  );

  const selectedReadyPlans = (report.ready_plans ?? []).map((r) => ({ ...r, eligible: true }));
  const ready_plan_adjustments = [...selectedReadyPlans, ...showOptions];
  ready_plan_adjustments.forEach((adj) => (adj.copies = adj.copies ?? 1));
  return {
    dpid: property.dpid,
    metro: property.metro,
    buildable_sqft: ref?.buildable_sqft ?? property.buildable_sqft!,
    lot_size: ref?.lot_size ?? property.lot_size,
    zoning: ref?.zoning ?? property.zoning,
    ready_plan_id,
    ready_plan_adjustments,
    adu_elig: ref?.adu_elig ?? property.adu_elig,
  };
}

export function mapFormToReadyPlanSaveReportInput(formState: FormState, spec_level: string): UnderwritingReportInput {
  const { dpid, ready_plan_adjustments, ready_plan_id } = formState.value;

  const plans = (ready_plan_adjustments ?? [])
    .filter((rp) => rp.ready_plan_id === ready_plan_id)
    .map((rp) => ({ ...rp, spec_level }));

  return {
    dpid,
    ready_plans: plans,
  };
}

export function mapReadyPlans(plans: ReadyPlanOption[]): ReadyPlanAdjustment[] {
  return plans.map((s) => ({
    ready_plan_id: s.ready_plan_id,
    ready_plan_sub_id: s.sub_ready_plan_id,
    sellable_sqft: s.sellable_sqft,
    hard_costs_per_sqft: s.hard_costs_psf,
    copies: s.copies,
    lot_size: s.lot_size,
    num_bedrooms: s.bed,
    num_baths: s.bath,
    num_garage_attached: s.num_garage_attached,
    num_stories: s.num_stories,
    property_type: s.property_type,
    eligible: s.eligible,
    above_ground_sqft: s.above_ground_sqft,
    sellable_basement_sqft: s.sellable_basement_sqft,
    below_ground_sqft: s.below_ground_sqft,
  }));
}
