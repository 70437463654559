import {
  BoundDateField,
  BoundNumberField,
  BoundSelectField,
  BoundSwitchField,
  BoundTextAreaField,
  BoundTextField,
  Button,
  Css,
  FormLines,
} from "@homebound/beam";
import { useFormState } from "@homebound/form-state";
import { comparer } from "mobx";
import { Observer } from "mobx-react";
import { useEffect, useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { useSuspense } from "@rest-hooks/react";
import { LoadingBoundary } from "src/components/LoadingBoundary";
import { PageHeader } from "src/components/PageHeader";
import { useController, useReaction } from "src/hooks";
import { Property, PropertyEndpoint, PropertySummary } from "src/routes/cma/endpoints";
import {
  LatestDpidReportEndpoint,
  SaveUnderwritingReportEndpoint,
  UnderwritingReport,
  UnderwritingReportEndpoint,
} from "src/routes/cma/endpoints/reports";
import { PropertySearchBox } from "src/routes/cma/PropertySearchBox";
import { StepperActions, useStepperContext } from "src/routes/cma/stepper";
import { createCmaSubjectPropertyUrl, DEFAULT_UNDEFINED_VERSION_ID } from "src/routes/routesDef";
import { propertyTypeOptions } from "src/utils/mappers";
import { reportIsReadOnly } from "src/utils/reports";
import { MiniMap } from "../comparables/add/MiniMap";
import {
  mapReferencePropertyFormToSaveReportInput,
  mapToReferencePropertyForm,
  referencePropertyFormConfig,
  ReferencePropertyFormState,
} from "./ReferencePropertyForm";

export function SubjectPropertyStep() {
  const { dpid, versionId } = useParams<{ dpid: string; versionId: string }>();
  const { fetch } = useController();

  const history = useHistory();
  const query = useQuery();
  const oppId = query.get("oppId");

  // Find a report for selected property if it exists else navigate to page create one new report
  // NOTE: This will go away once subject property step is removed
  async function handlePropertySelect(propResult: PropertySummary) {
    const { dpid } = propResult;
    const { report } = await fetch(LatestDpidReportEndpoint, { dpid });

    if (report) {
      history.push(`${createCmaSubjectPropertyUrl({ dpid, versionId: report.id, oppId })}`);
    } else {
      history.push(`${createCmaSubjectPropertyUrl({ dpid, oppId })}`);
    }
  }

  return (
    <>
      <PageHeader title="Set Subject Property" />
      <div css={Css.w50.$}>
        <PropertySearchBox onPropertySelected={handlePropertySelect} />
      </div>
      {dpid && versionId && (
        <div css={Css.pt4.$}>
          <LoadingBoundary>
            <LoadSubjectPropertyReport dpid={dpid} versionId={versionId} />
          </LoadingBoundary>
        </div>
      )}
    </>
  );
}

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

interface LoadSubjectPropertyReportProps {
  dpid: string;
  versionId: string;
}

export function LoadSubjectPropertyReport({ dpid, versionId }: LoadSubjectPropertyReportProps) {
  const propertyResult = useSuspense(PropertyEndpoint, { dpid });
  const reportsResult = useSuspense(UnderwritingReportEndpoint, { dpid, versionId });

  return <SubjectPropertyForm property={propertyResult.property} report={reportsResult.report} />;
}

interface SubjectPropertyFormProps {
  property: Property;
  report: UnderwritingReport;
}

export function SubjectPropertyForm({ property, report }: SubjectPropertyFormProps) {
  const { disableCurrentStep, goToNextStep } = useStepperContext();
  const { fetch } = useController();
  const history = useHistory();
  const { versionId: urlVersionId } = useParams<{ versionId: string }>();
  const query = useQuery();
  const oppId = query.get("oppId");

  const formState = useFormState({
    config: referencePropertyFormConfig,
    init: {
      input: { report, property },
      map: mapToReferencePropertyForm,
    },
    readOnly: reportIsReadOnly(report),
  });

  // updates the url to match the report id if using search box to pull up a property with a report
  useEffect(() => {
    const reportVersionId = report?.id && report.id.toString();
    if (reportVersionId && reportVersionId !== urlVersionId) {
      history.replace(createCmaSubjectPropertyUrl({ dpid: report.dpid, versionId: reportVersionId }));
    }
  }, [report, urlVersionId, history]);

  useReaction(
    () => formState.valid,
    (enabled, prev) => {
      disableCurrentStep({ disabled: !enabled });
    },
    { equals: comparer.shallow, fireImmediately: true },
    [formState],
  );

  async function saveForm() {
    const formInput = mapReferencePropertyFormToSaveReportInput(formState);
    const reportInput = oppId
      ? {
          ...formInput,
          sfdc_opportunities: [{ dpid: formInput.dpid, opportunity_id: oppId, stage_name: "Underwriting" }],
        }
      : formInput;

    const result = await fetch(SaveUnderwritingReportEndpoint, {
      report: reportInput,
      versionId: report?.id || DEFAULT_UNDEFINED_VERSION_ID,
    });

    // Adds report to the report page
    // TODO: can't invalidate this way now that report list is paginated
    //invalidate(UnderwritingReportListEndpoint);
    return result && result.report;
  }

  async function onNextStep() {
    const savedReport = await saveForm();
    goToNextStep({ report: savedReport, shouldRegenerateSteps: false });
  }

  function renderForm() {
    return (
      <div data-testid="subjectPropertyForm">
        <>
          <div css={Css.xlSb.pb2.$}>Review Property Details</div>
          <div css={Css.df.w100.$}>
            <div css={Css.w50.$}>
              <MiniMap height={500} zoom={17} property={property} />
            </div>
            <div css={Css.ml2.$}>
              <FormLines compact>
                <div css={Css.dg.gtc("1fr 1fr").gap2.$}>
                  <BoundNumberField label="Lot Size" field={formState.lot_size} />
                  <BoundNumberField label="Max Buildable Sqft" field={formState.buildable_sqft} />
                  <BoundTextField label="Zoning" field={formState.zoning} clearable />
                  <BoundSwitchField label="Adu Elig" field={formState.adu_elig} />
                  <BoundNumberField label="Lot Frontage ft." field={formState.lot_frontage_feet} />
                  <BoundNumberField label="Lot Depth ft." field={formState.lot_depth_feet} />
                  <BoundSelectField
                    label="Property Type"
                    field={formState.property_type_simple}
                    options={propertyTypeOptions}
                    getOptionLabel={(o) => o.name}
                    getOptionValue={(o) => o.id}
                  />
                  <BoundSwitchField
                    data-testid="isInFloodplain"
                    label="Is In Floodplain"
                    field={formState.is_in_floodplain}
                  />
                  <BoundDateField useYearPicker label="MLS Date Listed" field={formState.mls_date_listed} />
                  {formState.mls_list_price.value && (
                    <BoundNumberField
                      label="MLS List Price"
                      field={formState.mls_list_price}
                      type="dollars"
                      numFractionDigits={0}
                      readOnly
                    />
                  )}
                </div>
                <BoundTextAreaField label="MLS URL" field={formState.mls_url} />
                {formState.mls_listing_description.value && (
                  <BoundTextAreaField label="MLS Description" field={formState.mls_listing_description} readOnly />
                )}
              </FormLines>
            </div>
          </div>
        </>
        <StepperActions>
          <Button
            label="Save &amp; Continue"
            onClick={onNextStep}
            disabled={formState.valid ? false : <FormValidationMessage formState={formState} />}
          />
        </StepperActions>
      </div>
    );
  }

  return <Observer>{() => renderForm()}</Observer>;
}

function FormValidationMessage({ formState }: { formState: ReferencePropertyFormState }) {
  return <span>{formState.errors.map((e) => e).join(", ")}</span>;
}
