import { Css } from "@homebound/beam";
import { useMemo } from "react";
import { SavePolygonInput, UWPolygonType } from "src/routes/admin/polygons";
import { PolygonPane } from "src/routes/admin/polygons/components/PolygonPane";

type CompsMapPolygonPaneProps = {
  overlayPolygons: SavePolygonInput[];
};

export function CompsMapPolygonPane(props: CompsMapPolygonPaneProps) {
  const { overlayPolygons } = props;
  const panelPolygons: { [key in UWPolygonType]: SavePolygonInput[] } = useMemo(() => {
    const polygons = {} as any;
    for (const p of overlayPolygons) {
      if (polygons.hasOwnProperty(p.type)) {
        polygons[p.type!].push(p);
      } else {
        polygons[p.type!] = [p];
      }
    }
    return polygons;
  }, [overlayPolygons]);

  return (
    <>
      {Object.keys(panelPolygons).length > 0 && (
        <div css={Css.wPx(250).maxhPx(600).oa.$}>
          <div css={Css.mb2.smBd.$}>Overlay Polygons</div>
          <PolygonPane polygons={panelPolygons} />
        </div>
      )}
    </>
  );
}
