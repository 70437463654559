import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import { useLeafletContext } from "@react-leaflet/core";
import { Layer, LayerGroup, stamp } from "leaflet";
import { useEffect } from "react";
import { useMap } from "react-leaflet";
import { PolygonUpdateType } from "./components/PolygonEditor";
import { UwLeafletPolygon } from "./components/PolygonFeatureView";

type UwEditControlProps = {
  onPolygonChange: (layer: UwLeafletPolygon, updateType: PolygonUpdateType) => void;
};

export function UwEditControl({ onPolygonChange }: UwEditControlProps) {
  const map = useMap();
  const context = useLeafletContext();

  async function onDrawCreate(layer: UwLeafletPolygon) {
    const container = (context.layerContainer || context.map) as LayerGroup;
    const ids = container.getLayers().map((l: Layer) => stamp(l));
    // Don't add layer if it's a copy
    if (ids.includes(stamp(layer))) {
      return;
    }

    await onPolygonChange(layer, "Create");
    const drawLayers = map.pm.getGeomanDrawLayers();
    const geomanLayers = map.pm.getGeomanLayers();
    // remove the draw layer from the geoman layers
    // otherwise this layer prevents the polygon from being editable
    // @ts-ignore - pm is a property on the geoman layers
    geomanLayers.find((l) => stamp(l) === stamp(drawLayers[0]))?.pm.remove();
  }

  useEffect(() => {
    if (map && map.pm) {
      map.pm.addControls({
        position: "topright",
        drawCircle: false,
        drawCircleMarker: false,
        drawRectangle: false,
        drawText: false,
        drawMarker: false,
        drawPolyline: false,
        dragMode: false,
        cutPolygon: false,
        rotateMode: false,
        oneBlock: true,
        editMode: false,
        removalMode: false,
      });
      map.on("pm:create", ({ layer }) => {
        // we only allow drawing polygons
        onDrawCreate(layer as UwLeafletPolygon);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
