import { BoundSelectField, Css, Icon } from "@homebound/beam";
import { getSfdcOpportunityUrl } from "src/context.sfdc";
import { OfferOutcomeEstFormState } from "src/routes/cma/steps/estimate/estimate-forms/OfferOutcomeForm";

interface PropertySFDCSectionProps {
  offerOutcomeForm: OfferOutcomeEstFormState;
}

export function PropertySFDCSection({ offerOutcomeForm }: PropertySFDCSectionProps) {
  return (
    <>
      <div css={Css.gap2.py2.pb2.pt0.$}>
        <div data-testid="sfdcOppAndStage" css={Css.df.aic.gap2.mt1.$}>
          <SfdcOpportunityAndStage offerOutcomeForm={offerOutcomeForm} />
        </div>
      </div>
    </>
  );
}

function SfdcOpportunityAndStage({ offerOutcomeForm }: { offerOutcomeForm: OfferOutcomeEstFormState }) {
  const opps = offerOutcomeForm.sfdc_opportunities.rows;
  const sfdcOpportunityOptions = opps.map((opp) => ({
    id: opp.opportunity_id.value,
    name: opp.stage_name.value,
  }));

  if (opps.length === 0) {
    return (
      <div css={Css.red500.$}>
        <div>No SFDC opportunities were found for this address.</div>
        <div>
          Please confirm that an opportunity exists and ensure that the SFDC Account name matches this property's
          address.
        </div>
      </div>
    );
  }
  return (
    <div css={Css.wPx(380).df.gap2.$}>
      <BoundSelectField
        label="Opportunity Id and Stage"
        field={offerOutcomeForm.opportunity_id}
        options={sfdcOpportunityOptions}
        getOptionLabel={(o) => `${o.id} (${o.name})`}
        getOptionValue={(o) => o.id!}
        placeholder="Select Opportunity"
      />
      <div css={Css.df.asfe.pb1.$}>
        <a
          target="_blank"
          rel="noreferrer"
          data-testid="sfdcLink"
          href={getSfdcOpportunityUrl(offerOutcomeForm.opportunity_id.value!)}
        >
          <Icon icon="linkExternal" />
        </a>
      </div>
    </div>
  );
}
