import { Button, Css, useRightPaneContext } from "@homebound/beam";
import React from "react";
import { useFetch, useSuspense } from "@rest-hooks/react";
import { GridLoadingBoundary } from "src/components/LoadingBoundary";
import { CtaTriggerAndResolutionsEndpoint, UnderwritingReportEndpoint } from "src/routes/cma/endpoints/reports";
import { CtaPaneContent } from "src/routes/reports/components/cta/CtaPaneContent";

type ShowCtaPaneButtonProps = { dpid: string; versionId: string; openCount: number; hasResolvedCta: boolean };

export function ShowCtaPaneButton(props: ShowCtaPaneButtonProps) {
  // Preload triggers and resolutions to ensure smooth right pane opening
  useFetch(CtaTriggerAndResolutionsEndpoint, null);
  const { openInPane, isRightPaneOpen, closePane } = useRightPaneContext();
  const { openCount, hasResolvedCta, ...rest } = props;
  const hasOpenCtas = openCount > 0;

  function handleCtaPane() {
    if (isRightPaneOpen) {
      closePane();
    } else {
      openInPane({
        content: (
          <GridLoadingBoundary gridLoadingProps={{}}>
            <LoadCtaDetailPane {...rest} />
          </GridLoadingBoundary>
        ),
      });
    }
  }

  // Only need pane if a user has ctas or has resolved them previously
  if (!hasOpenCtas && !hasResolvedCta) {
    return null;
  }

  return (
    <div css={Css.df.aic.fw4.addIn("button:first-of-type", Css.fdrr.gap1.if(hasOpenCtas).red500.else.green600.$).$}>
      <Button
        label={hasOpenCtas && openCount}
        icon="flag"
        tooltip={hasOpenCtas ? `${openCount} CTA${openCount > 1 ? "s" : ""} need attention` : <AllCtaResolvedNode />}
        aria-label={hasOpenCtas ? `${openCount} CTA${openCount > 1 ? "s" : ""} need attention` : "All Cta Resolved"}
        variant={"tertiary"}
        onClick={handleCtaPane}
      />
    </div>
  );
}

export function LoadCtaDetailPane({ dpid, versionId }: Pick<ShowCtaPaneButtonProps, "dpid" | "versionId">) {
  // Directly using the report resource instead of as a prop allows observer and forms to work correctly and give instant feedback
  const { report } = useSuspense(UnderwritingReportEndpoint, { dpid, versionId });
  const { triggers, resolutions } = useSuspense(CtaTriggerAndResolutionsEndpoint);
  return <CtaPaneContent report={report} resolutionOptions={resolutions} triggerOptions={triggers} />;
}

const AllCtaResolvedNode = () => (
  <span role={"img"} aria-label="High Confidence">
    All open CTAs have been resolved 🎉
  </span>
);
