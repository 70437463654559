import { Css } from "@homebound/beam";
import { mdashUnicode } from "src/components/mdash";
import { formatNumberToString, Maybe } from "src/utils";

export interface FormattedPriceProps {
  price: Maybe<number>;
  colorCoded?: boolean;
}

/**
 * Display a formatted price, with optional color coding.
 */
export function FormattedPrice({ price, colorCoded }: FormattedPriceProps) {
  function fmt(n: number) {
    return formatNumberToString(n, true, true);
  }

  if (!price) {
    return <span>{mdashUnicode}</span>;
  }

  const css = colorCoded ? (price > 0 ? Css.green600 : Css.red600) : Css;
  const formattedPrice = price > 0 ? `$${fmt(price)}` : `($${fmt(price * -1)})`;

  return <span css={css.$}>{formattedPrice}</span>;
}
