import { Css, useModal } from "@homebound/beam";
import { useState } from "react";
import { useSuspense } from "@rest-hooks/react";
import { Property, PropertyComp, PropertyImage } from "src/routes/cma/endpoints";
import { MapsApiEndpoint, mapUrl, streetViewUrl } from "src/routes/maps/MapsApiEndpoint";
import { PropertyImageModal } from "./PropertyImageModal";
import { PropertyImageViewer, PropertyImageViewerProps } from "./PropertyImageViewer";

interface PropertyImageProps {
  images: PropertyImage[];
  property: Property | PropertyComp;
}

interface ClickableImageProps extends PropertyImageProps {
  maxWidth?: number;
}

function ClickableImage({ images, property, maxWidth }: ClickableImageProps) {
  const { openModal } = useModal();
  const [image, setImage] = useState(images[0].url);

  function loadFallBack() {
    setImage("/images/fallback.jpeg");
  }

  return (
    <img
      src={image}
      onClick={() =>
        openModal({
          size: "xxl",
          content: <PropertyImageModal property={property} images={images} />,
        })
      }
      css={Css.cursorPointer.objectCover.maxwPx(maxWidth ?? 260).hPx(150).$}
      alt=""
      onError={loadFallBack}
    />
  );
}

interface ClickableImageWithMapImagesProps extends useMapImagesProps {
  maxWidth?: number;
}

export function ClickableImageWithMapImages(props: ClickableImageWithMapImagesProps) {
  const { property, initialImage = "streetView", maxWidth } = props;
  const propertyImages = property.property_images ?? [];
  const mapViewImages = useMapImages({ property, initialImage });

  return <ClickableImage images={[...mapViewImages, ...propertyImages]} property={property} maxWidth={maxWidth} />;
}

interface ImageViewerWithMapImagesProps extends useMapImagesProps, Omit<PropertyImageViewerProps, "images"> {}
export function ImageViewerWithMapImages(props: ImageViewerWithMapImagesProps) {
  const { property, initialImage = "streetView", ...others } = props;
  const propertyImages = property.property_images ?? [];
  const mapViewImages = useMapImages({ property, initialImage });

  return (
    <PropertyImageViewer
      images={[...mapViewImages, ...propertyImages]}
      showBullets={false}
      property={property}
      {...others}
    />
  );
}

interface useMapImagesProps {
  property: Property | PropertyComp;
  initialImage?: "streetView" | "overheadView";
}
function useMapImages(props: useMapImagesProps) {
  const { api_key } = useSuspense(MapsApiEndpoint);

  const { property, initialImage = "streetView" } = props;
  const streetViewImage = { url: streetViewUrl(property, api_key) } as PropertyImage;
  const mapImage = { url: mapUrl(property, api_key) } as PropertyImage;
  const mapViewImages = initialImage === "streetView" ? [streetViewImage, mapImage] : [mapImage, streetViewImage];

  return mapViewImages;
}
