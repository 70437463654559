import { Endpoint, Entity } from "@rest-hooks/rest";
import { api_url, auth_headers } from "src/context";
import { Maybe } from "src/utils/types";
import { ReferencePropertyInput } from "../steps/subject-property/ReferencePropertyForm";

export interface ReadyPlanOption {
  ready_plan_id: string;
  sub_ready_plan_id: string;
  hard_costs_psf: number;
  copies: number;
  is_adu: boolean;
  bed: number;
  bath: number;
  min_lot_size: number;
  lot_size: number;
  sellable_sqft: number;
  num_garage_attached: number;
  num_stories: number;
  property_type: string;
  above_ground_sqft: number;
  sellable_basement_sqft: number;
  below_ground_sqft: number;
  eligible?: Maybe<boolean>;
  spec_level?: Maybe<string>;
}

export class ReadyPlanOptions extends Entity {
  readonly dpid: string = "";
  readonly ready_plans: ReadyPlanOption[] = [];
  readonly ineligible_plans?: Maybe<ReadyPlanOption[]>;

  pk() {
    return `rpo-${this.dpid}`;
  }

  static key = "ReadyPlanOptions";
}

export interface ReadyPlanEligInput extends ReferencePropertyInput {
  include_ineligible_plans?: boolean;
}

export const ReadyPlanOptionsEndpoint = new Endpoint(
  async (input: ReadyPlanEligInput) => {
    const headers = {
      "content-type": "application/json",
      ...(await auth_headers()),
    };

    const { buildable_sqft, ...rest } = input;
    const params = {
      ...rest,
      max_property_buildable_sqft: buildable_sqft,
    };

    const res = await fetch(api_url(`/app_ready_plan_elig`), {
      headers,
      method: "POST",
      body: JSON.stringify(params),
    });
    return (await res.json())[0];
  },
  {
    schema: ReadyPlanOptions,
    dataExpiryLength: 500, // TODO: not totally sure why this is needed
    key: (input: ReadyPlanEligInput) => JSON.stringify(input),
    name: "ReadyPlanOptionsEndpoint",
  },
);
