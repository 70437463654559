import { createTheme } from "@material-ui/core";
import { Css, Palette } from "src/Css";
import { FontSansSerif } from "./fonts/Fonts";

export function spacing(index: number) {
  return index * 8;
}

// Certain `a` tags in the app we want to opt-out of the `a:visited` behavior
// and always be the same non-visited blue.
export const navLink = "navLink";

// Used by LoadingDiv but needs imported globally so we put it here.
const loadingSpinnerKeyframes = {
  "@keyframes loadingSpinner": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
  "@keyframes loadingSpinnerDelay": {
    "0%": { opacity: 0 },
    "50%": { opacity: 0 },
    "100%": { opacity: 1.0 },
  },
};

export const BlueprintTheme = {
  themeName: "Blueprint",
  ...createTheme({
    palette: {
      primary: {
        main: Palette.Black,
        contrastText: Palette.White,
      },
      secondary: {
        main: Palette.White,
        contrastText: Palette.Black,
      },
      text: {
        primary: Palette.Black,
        secondary: Palette.MidGray,
      },
      background: {
        default: Palette.White,
        paper: Palette.White,
      },
      error: {
        light: Palette.LightRed,
        main: Palette.Red,
        dark: Palette.DarkRed,
        contrastText: Palette.White,
      },
    },
    props: {
      // default properties
      MuiButton: {
        variant: "contained",
      },
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    shape: {
      borderRadius: 25,
    },
    typography: {
      fontFamily: FontSansSerif,
      h1: Css.xl2Em.black.$,
      h2: Css.xl2Em.lightGray.$,
      h3: Css.lgEm.lightGray.$,
      h4: Css.base.lightGray.$,
      subtitle1: Css.base.midGray.$,
      subtitle2: Css.sm.midGray.$,
      // we only support h1-h4.  h5,h6 you should style directly
      body1: Css.base.black.$,
      // MUI will use this for the root <body> tag: https://material-ui.com/components/css-baseline/
      body2: Css.add({ fontFamily: FontSansSerif }).sm.black.$,
      // TODO: caption, overline, subtitle1, subtitle2
      button: {
        // disable default theme uppercasing text
        textTransform: "none",
      },
    },
    // Spacing function defined above
    spacing,
    overrides: {
      MuiCssBaseline: {
        "@global": {
          // Setting this to ignore because the @font-face isn't allowed but should be
          // @ts-ignore
          "@font-face": [],
          ...loadingSpinnerKeyframes,
          // TODO Will go away with Beam CssReset
          [`a:not(.${navLink})`]: { color: Palette.Blue700 },
          [`a:visited:not(.${navLink})`]: { color: Palette.Blue500 },
          // TODO Comes from the Beam CssReset
          svg: {
            display: "block",
            verticalAlign: "middle",
          },
        },
      },
      MuiAutocomplete: {
        popper: {
          width: "auto !important",
          maxWidth: "400px",
        },
      },
      MuiRadio: {
        // Make checked radio buttons black instead of white.
        root: {
          "&.Mui-checked": {
            color: `${Palette.Black} !important`,
          },
        },
      },
      MuiCheckbox: {
        root: {
          "& .fa-check-square, & .fa-square": Css.base.important.$,
          // Make checked checkboxes gray (unchecked) & black (checked) instead of white.
          "&.MuiCheckbox-colorSecondary": Css.gray400.$,
          "&.Mui-checked": Css.black.important.$,
          // Make the checkboxes bigger. They're already the right color (MidGray).
          "& svg": Css.w(3).h(3).$,
        },
      },
      MuiFormControlLabel: {
        // Fix the font of checkbox labels.
        label: Css.sm.midGray.$,
        // Make the selected labels black.
        root: {
          "& .Mui-checked + span": Css.black.$,
          // For some reason there is a default marginLeft of -11px, however for our
          // large custom radio & checkbox icons, this is too far and they get clipped,
          // so bring it back a bit.
          marginLeft: 0 - spacing(1),
        },
      },
      MuiFormLabel: {
        // Customize the color of the form field label.
        root: {
          ...Css.sm.$,
          "&$focused": Css.midGray.$,
        },
      },
      MuiInputLabel: {
        // Customize the font size/color of our form field placeholder.
        root: Css.sm.midGray.$,
        // We show "optional" for non-required fields, so hide the asterisk for required fields.
        asterisk: {
          display: "none",
        },
        shrink: {
          // Overrides the "scale" value as it was too small for 12px font size. Keeps translate the same value.
          // 12px * scale(.83) + ~10px.
          transform: "translate(0, 1.5px) scale(.83)",
        },
      },
      MuiInputBase: {
        // Make the placeholder show up as black by turning off the 50% opacity.
        root: {
          "& input::placeholder": {
            color: Palette.MidGray,
            opacity: 1,
          },
          "& textarea::placeholder": {
            color: Palette.MidGray,
            opacity: 1,
          },
        },
        input: Css.sm.$,
      },
      MuiFormHelperText: { root: Css.tinyEm.$ },
      MuiInput: {
        // Customize the underline color when unfocused. Note that when focused, MuiInput
        // already uses the text.primary color, which is our Black, so doesn't need overridden.
        underline: {
          "&:before": {
            borderBottomColor: Palette.MidGray,
          },
        },
      },
      MuiIcon: {
        // Render invalid text fields with a red X.
        colorError: {
          color: Palette.DarkRed,
        },
      },
      MuiLink: {
        root: {
          color: Palette.Primary,
          cursor: "pointer",
          "& .fa-angle-right": Css.lgEm.important.$,
        },
      },
      MuiMenuItem: {
        // Make select box items the right font/color.
        root: Css.base.black.$,
      },
      MuiButtonBase: {
        root: {
          "&$disabled": {
            cursor: "not-allowed",
            pointerEvents: "auto",
          },
        },
      },
      MuiButton: {
        root: {
          ...Css.sm.$,
          boxShadow: "none",
          padding: "0.5rem 1rem",
          minWidth: "120px",
        },
        contained: {
          // Primary and secondary buttons use same border
          border: `2px solid ${Palette.Black}`,
          "&.Mui-disabled": {
            border: `2px solid transparent`,
          },
        },
        outlined: {
          // Primary and secondary buttons use same border
          border: `2px solid ${Palette.Black}`,
        },
        text: {
          color: Palette.MidGray,
          // Give a dummy/invisible border so that we align better with contained/outlined buttons when side-by-side.
          border: "2px solid transparent",
          // Re-override the padding to make it match contained/outlined (MUI has a smaller padding for text).
          padding: "0.5rem 1rem",
          "&:hover": {
            // Keep the background white, the default MuiButton.root:hover is black.
            backgroundColor: Palette.White,
          },
        },
        sizeLarge: Css.lgEm.$,
      },
      MuiTextField: {
        root: {
          "& input": Css.sm.important.$,
        },
      },
      MuiTabs: {
        root: {
          minHeight: 0,
        },
        indicator: {
          backgroundColor: Palette.Primary,
          transition: "none",
        },
      },
      MuiTab: {
        root: {
          minHeight: "0 !important",
          minWidth: "0 !important",
          "&$selected": {
            color: Palette.Primary,
          },
          borderBottomStyle: "solid",
          borderBottomWidth: "2px",
          borderBottomColor: Palette.LightGray,
        },
      },
      MuiDialog: {
        // minHeight and maxWidth as shown in mocks, may need to confirm with Stumpy.
        paper: {
          minHeight: "240px",
        },
        paperWidthSm: {
          maxWidth: "528px",
        },
        paperWidthMd: {
          maxWidth: "800px",
        },
      },
      MuiDialogTitle: {
        root: {
          alignItems: "flex-start",
          display: "flex",
          justifyContent: "space-between",
          padding: `${spacing(3)}px ${spacing(3)}px ${spacing(1)}px`,
        },
      },
      MuiDialogContent: {
        root: Css.pt0.$,
      },
      MuiDialogContentText: {
        root: Css.sm.midGray.$,
      },
      MuiDialogActions: {
        root: {
          justifyContent: "flex-start",
          padding: spacing(3),
        },
      },
      MuiPickersDatePickerRoot: {
        toolbar: {
          "& button:hover .MuiPickersToolbarText-toolbarTxt": Css.black.$,
        },
      },
      MuiDivider: {
        inset: { marginLeft: "60px" },
      },
      MuiTooltip: {
        tooltip: {
          whiteSpace: "pre-line",
        },
      },
    } as any,
  }),
};
