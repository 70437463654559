import { FilterDefs, multiFilter } from "@homebound/beam";
import { useMemo } from "react";
import { useSuspense } from "@rest-hooks/react";
import { UnderwritingReportCollaboratorsEndpoint } from "src/routes/cma/endpoints/reports/UnderwritingReportEndpoint";

export type ReportVersionsFilter = {
  collaborator_id?: number[];
  status?: string;
};

export function useReportVersionsFilter() {
  const collaboratorsResult = useSuspense(UnderwritingReportCollaboratorsEndpoint);

  const filterDefs: FilterDefs<ReportVersionsFilter> = useMemo(() => {
    const collaborator_id = multiFilter({
      label: "Editor",
      options: collaboratorsResult?.collaborators ?? [],
      getOptionValue: (option) => option.id,
      getOptionLabel: (option) => {
        const parts = option.email.split("@");
        return parts[0];
      },
    });
    return { collaborator_id };
  }, [collaboratorsResult]);

  return filterDefs;
}
