import { Css, Icon, IconKey } from "@homebound/beam";
import { StatusValue } from "./endpoints/StatusEndpoint";

interface StatusBarProps {
  status: StatusValue;
}

export function StatusBar({ status }: StatusBarProps) {
  function text() {
    if (status === "ok") {
      return "All Systems Go!";
    }
    if (status === "error") {
      return "Something is not right.";
    }

    return "System may be degraded.";
  }

  const color = statusColor(status);
  const iconName = statusIconName(status);

  return (
    <div css={Css.df.p2.aic.add("backgroundColor", color).white.$}>
      <Icon inc={4} icon={iconName} /> <span css={Css.pl2.xl2Sb.$}>{text()}</span>
    </div>
  );
}

export function statusIconName(status: StatusValue): IconKey {
  if (status === "ok") {
    return "checkCircle";
  }
  if (status === "error") {
    return "errorCircle";
  }
  return "infoCircle";
}

export function statusColor(status: StatusValue) {
  if (status === "ok") {
    return "#2ECC40";
  }
  if (status === "error") {
    return "#FF4136 ";
  }
  return "#FF851B";
}
