import { currentAuthToken } from "@homebound/auth-components";
import _ from "lodash";
import { qaServerEnvironment } from "./utils";

export type Stage = "local" | "dev" | "qa" | "prod";

export function getStage(windowLocation = window.location): Stage {
  // Guess stage based on the current window.location.hostname
  const hostname = windowLocation.hostname;
  if (hostname.includes("dev-homebound")) {
    return "dev";
  } else if (hostname.includes("qa-homebound")) {
    return "qa";
  } else if (hostname.includes("homebound")) {
    return "prod";
  } else {
    return "local";
  }
}

export async function auth_headers(): Promise<Record<string, string>> {
  const token = await currentAuthToken();
  const Authorization = token ? `Bearer ${token}` : undefined;
  if (Authorization) {
    return { Authorization };
  }
  return {};
}

export function api_url(path: string): string {
  const sep = path.startsWith("/") ? "" : "/";
  return `${getApiBaseUrl()}${sep}${path}`;
}

export function getApiBaseUrl(stage: Stage = getStage()): string {
  switch (stage) {
    case "local":
      return "VITE_PROPERTY_API_ENDPOINT" in global && VITE_PROPERTY_API_ENDPOINT
        ? VITE_PROPERTY_API_ENDPOINT
        : "http://localhost:8000";
    case "dev":
      return "https://property-api.dev-homebound.com/api";
    case "qa":
      return `https://${qaServerEnvironment().subDomain}.qa-homebound.com/api`;
    case "prod":
      return "https://property-api.homebound.com/api";
  }
}

// Include `aws.cognito.signin.user.admin` so that we can access user attributes like their profile picture:
// https://stackoverflow.com/a/53151260/355031
const scope = ["phone", "email", "openid", "aws.cognito.signin.user.admin"];

export function amplifyConfig(stage: Stage) {
  function stageConfig() {
    if (stage === "prod") {
      return {
        Auth: {
          userPoolId: "us-west-2_Sud41U2uX",
          userPoolWebClientId: "6jbhcfnuji5mor48dg2hg37hvg",
          oauth: { domain: "homebound-internal-prod.auth.us-west-2.amazoncognito.com", scope },
        },
      };
    }

    return {
      Auth: {
        userPoolId: "us-west-2_u4KsZS7DF",
        userPoolWebClientId: "47jne7hh7uao4il33eq1kftr5c",
        oauth: { domain: "homebound-internal-dev.auth.us-west-2.amazoncognito.com", scope },

        cookieStorage: {
          // use whole domain for preview branches to reuse auth
          domain: stage === "local" ? "localhost" : `.${stage}-homebound.com`,
        },
      },
    };
  }

  const { origin } = window.location;

  return _.merge({}, stageConfig(), {
    Auth: {
      region: "us-west-2",
      oauth: {
        scope: ["phone", "email", "openid"],
        redirectSignIn: origin + "/auth/gcallback",
        redirectSignOut: origin + "/",
        responseType: "code",
      },
    },
  });
}
