import { Entity } from "@rest-hooks/rest";

export class PagingResult extends Entity {
  readonly page: number = 0;
  readonly perPage: number = 0;
  readonly totalResults: number = 0;
  readonly pages: number = 0;
  readonly hasNext: boolean = false;
  readonly hasPrev: boolean = false;
  readonly resultRange: [number, number] = [0, 0];

  pk() {
    return `${this.page}-${this.perPage}-${this.totalResults}-${this.resultRange}`;
  }

  static key = "PagingResult";
}
