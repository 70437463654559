import { useRouteMatch } from "react-router-dom";
import { cmaPathRouteMatchPath, cmaPathRouteReadyPlanMatchPath } from "src/routes/routesDef";
import { CmaStep } from "./CmaStep";

interface StepRouteMatch {
  dpid: string;
  versionId: string;
  currentStep: string;
}

interface MatchParams {
  currentStep: string;
  dpid: string;
  versionId: string;
  readyPlanId?: string;
}

// Pull out the current step and dpid from the route
export function useStepRouteMatch(): StepRouteMatch {
  const match = useRouteMatch<MatchParams>([cmaPathRouteReadyPlanMatchPath, cmaPathRouteMatchPath]);

  const {
    params: { currentStep = "", dpid, versionId, readyPlanId },
  } = match || { params: {} as MatchParams };

  if (currentStep === CmaStep.comparables || currentStep === CmaStep.adjustments) {
    return { dpid, versionId, currentStep: `${currentStep}-${readyPlanId}` };
  }

  return {
    dpid,
    versionId,
    currentStep,
  };
}
