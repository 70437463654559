import { HasIdAndName } from "@homebound/beam";

export enum ValuationStage {
  underwriting = "Underwriting",
  clear_to_close = "Clear to Close",
  asset_valuation = "Asset Valuation",
  pre_listing = "Pre-Listing",
  post_listing = "Post-Listing",
}

// We're not allowing a user to change a report to "Clear to Close" from the dropdown.
export const valuationStageOptions: HasIdAndName<ValuationStage>[] = [
  { id: ValuationStage.underwriting, name: "Underwriting" },
  { id: ValuationStage.asset_valuation, name: "Asset Valuation" },
  { id: ValuationStage.pre_listing, name: "Pre-Listing" },
  { id: ValuationStage.post_listing, name: "Post-Listing" },
];
