import { Css, useComputed } from "@homebound/beam";
import { LatLngTuple } from "leaflet";
import { MapContainer } from "react-leaflet";
import { MapBaseLayers } from "src/routes/maps/MapBaseLayers";
import { PolygonEditor, PolygonUpdateType } from "./components/PolygonEditor";
import { ObjectState } from "@homebound/form-state";
import { SavePolygonGroupInput } from "./PolygonEndpoints";
import { Feature } from "geojson";
import { getMapBoundsFromCoordsOrMetro } from "src/routes/maps/mapUtils";

type PolygonMapProps = {
  formState: ObjectState<SavePolygonGroupInput>;
  onPolygonFeatureUpdated: (feature: Feature, updateType: PolygonUpdateType) => void;
  saveForm: () => void;
};

export function PolygonMap(props: PolygonMapProps) {
  const { formState, onPolygonFeatureUpdated, saveForm } = props;

  const [latitudeCenter, longitudeCenter, bounds] = useComputed(() => {
    const flatCoords = formState.polygons.rows.map((p) => p.geometry.value?.coordinates[0]).flat() as LatLngTuple[];
    return getMapBoundsFromCoordsOrMetro(flatCoords, formState.metro.value);
  }, [formState.metro.value]);

  return (
    <div data-testid="polygonMap">
      <MapContainer
        css={Css.vh75.$}
        center={[latitudeCenter, longitudeCenter]}
        scrollWheelZoom={false}
        zoom={bounds ? undefined : 11}
        bounds={bounds}
        boundsOptions={{ padding: [100, 100] }}
        key={`${formState.metro.value}`}
        preferCanvas={true}
      >
        <MapBaseLayers />
        <PolygonEditor
          polygonsFormState={formState.polygons}
          onPolygonFeatureUpdated={onPolygonFeatureUpdated}
          saveForm={saveForm}
        />
      </MapContainer>
    </div>
  );
}
