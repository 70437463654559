import { Css } from "@homebound/beam";
import React, { useEffect } from "react";
import { useDLE } from "@rest-hooks/react";
import { UnderwritingReport, UnderwritingReportEndpoint } from "src/routes/cma/endpoints/reports";
import { useParams } from "react-router";
import { Property, PropertyEndpoint } from "src/routes/cma/endpoints";
import { useFormState } from "@homebound/form-state";
import { estimateFormConfig, mapToEstimateForm } from "src/routes/cma/steps/estimate/estimate-forms/EstimateForm";
import { PropertyAddressHeader } from "src/routes/cma/steps/comparables/PropertyAddressHeader";
import {
  AcquisitionDetailsSection,
  IMSensitivitySection,
  PrintRPDetailSection,
  ValuationSummarySection,
} from "src/routes/cma/steps/estimate/components";
import {
  BuildCostFields,
  CycleTimeFields,
  FeesCostFields,
  FinancingCostFields,
  LotCostFields,
  ReturnsAndMarginFields,
  RevenueAndCostFields,
} from "src/routes/cma/steps/estimate/estimate-forms";
import { HbUWSpinner } from "src/components/LoadingBoundary";
import { StandardErrorContainer } from "src/components/ErrorBoundary";

export function PrintEstimatePage() {
  const { dpid, versionId } = useParams<{ dpid: string; versionId: string }>();
  const {
    data: { report },
    loading: reportLoading,
    error: reportError,
  } = useDLE(UnderwritingReportEndpoint, {
    dpid,
    versionId,
    shouldGenerateMlSaleConfidence: true,
  });
  const {
    data: { property },
    loading: propertyLoading,
    error: propertyError,
  } = useDLE(PropertyEndpoint, { dpid });

  if (propertyLoading || reportLoading) {
    return <HbUWSpinner />;
  }

  if (reportError || propertyError) {
    return (
      <StandardErrorContainer>
        <div css={Css.df.fdc.gap1.$}>
          {reportError?.message && <div>{reportError?.message}</div>}
          {propertyError?.message && <div>{propertyError?.message}</div>}
        </div>
      </StandardErrorContainer>
    );
  }

  return (
    <>
      <PropertyAddressHeader property={property!} size="lg" />
      {report && property && <EstimateReportView report={report} property={property} />}
    </>
  );
}

interface EstimateReportViewProps {
  report: UnderwritingReport;
  property: Property;
}

function EstimateReportView({ report, property }: EstimateReportViewProps) {
  // A single RP report will render cycle times alongside RP details
  const hasSingleRP = report.ready_plans!.length === 1;

  // Most estimate sections expect a form but we are hardcoding it to be readonly for this view
  const estimateFormState = useFormState({
    config: estimateFormConfig,
    init: {
      input: report,
      map: (report) => mapToEstimateForm(report),
    },
    readOnly: true,
  });

  // Open print window on render
  useEffect(() => {
    window.print();
  });

  return (
    <div css={Css.px3.pt1.dg.gap1.gtc("repeat(2, 60% 40%)").w100.px0.$}>
      <div css={Css.df.fdc.gap("inherit").$}>
        <div css={Css.df.gap("inherit").$}>
          <ValuationSummarySection report={report} />
          <AcquisitionDetailsSection report={report} property={property} />
        </div>
        <div css={Css.df.gap("inherit").w100.$}>
          <PrintRPDetailSection report={report} />
          {hasSingleRP && <CycleTimeFields estimateFormState={estimateFormState} />}
        </div>
        <div css={Css.df.w100.gap("inherit").$}>
          {/* 27% is sweet spot to keep title in line without hacking main component */}
          {!hasSingleRP && (
            <div css={Css.w("27%").$}>
              <CycleTimeFields estimateFormState={estimateFormState} />
            </div>
          )}
          <IMSensitivitySection report={report} property={property} />
        </div>
      </div>
      <div css={Css.df.fdc.gapPx(4).$}>
        <RevenueAndCostFields report={report} estimateFormState={estimateFormState} />
        <LotCostFields report={report} estimateFormState={estimateFormState} />
        <BuildCostFields report={report} estimateFormState={estimateFormState} />
        <FeesCostFields report={report} estimateFormState={estimateFormState} />
        <FinancingCostFields report={report} estimateFormState={estimateFormState} />
        <ReturnsAndMarginFields report={report} estimateFormState={estimateFormState} />
      </div>
    </div>
  );
}
