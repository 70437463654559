import { Auth, CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import React, { useState } from "react";
import GoogleButton from "react-google-button";

export function LoginButton() {
  const [disabled, setDisabled] = useState(false);

  return (
    <GoogleButton
      type="light"
      disabled={disabled}
      onClick={() => {
        setDisabled(true);
        // Setting provider allows us to self-host the login button
        Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
      }}
    />
  );
}
