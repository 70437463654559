import { Button, Css, Stepper } from "@homebound/beam";
import { partition } from "lodash";
import React, {
  createContext,
  PropsWithChildren,
  ReactPortal,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { Link, useHistory } from "react-router-dom";
import { useController } from "@rest-hooks/react";
import {
  ReportCtaStatus,
  UnderwritingReport,
  UnderwritingReportEndpoint,
  UnderwritingReportVersionsEndpoint,
} from "src/routes/cma/endpoints/reports";
import { OpenArchiveModalButton } from "src/routes/reports/components/ArchiveReportConfirmationModal";
import { ShowCtaPaneButton } from "src/routes/reports/components/cta/LoadCtaDetailPane";
import { OpenVersionsDrawerButton } from "src/routes/reports/components/ReportVersionsTable";
import { ReportWarningBanners } from "src/routes/reports/components/ReportWarningBanners";
import { reportIsReadOnly } from "src/utils/reports";
import { pathForStep } from "./pathForStep";
import { StepPageHeader } from "./StepPageHeader";
import { generateSteps, useStepData } from "./useStepData";
import { useStepRouteMatch } from "./useStepRouteMatch";
import { generatePath } from "react-router";
import { printEstimatePage } from "src/routes/routesDef";

export function StepperContextProvider({ children }: PropsWithChildren<unknown>) {
  const history = useHistory();
  const { invalidate } = useController();
  const [currentStepInvalid, setCurrentStepInvalid] = useState(false);

  const { currentStep, dpid, versionId } = useStepRouteMatch();
  const { steps, property, report } = useStepData(dpid, versionId, currentStep);

  const goToFirstStep = useCallback(
    () => history.push(pathForStep(dpid, versionId, steps[0])),
    [dpid, versionId, history, steps],
  );

  const goToStep = useCallback(
    (stepValue: string) => {
      return history.push(pathForStep(dpid, versionId, stepValue));
    },
    [dpid, versionId, history],
  );

  const goToNextStep = useCallback(
    (context?: NextStepContext | undefined) => {
      let searchSteps = steps;
      if (context?.report && context?.shouldRegenerateSteps) {
        searchSteps = generateSteps(context.report);
      }
      const stepConfigCurrentKeyIndex = searchSteps.findIndex((sc) => sc.value === currentStep);

      const nextStep = searchSteps[stepConfigCurrentKeyIndex + 1];
      if (!nextStep) {
        throw new Error("there is no next step!");
      }

      const updatedVersionId = context?.report?.id ? context?.report?.id.toString() : versionId;
      const path = pathForStep(dpid, updatedVersionId, nextStep);
      // force refresh of report between pages
      invalidate(UnderwritingReportVersionsEndpoint, dpid ? { dpid } : null);
      invalidate(UnderwritingReportEndpoint, dpid ? { dpid, versionId: updatedVersionId } : null).then(() => {
        history.push(path);
      });
    },
    [currentStep, dpid, versionId, steps, history, invalidate],
  );

  const onChangeStep = useCallback(
    async ({ selectedDpid, stepValue }: { selectedDpid?: string; stepValue?: string }) => {
      // determine dpid from value passed or from URL
      const theDpid = selectedDpid || dpid || undefined;
      if (!theDpid) {
        return goToFirstStep();
      }

      if (theDpid) {
        // force refresh of report between pages
        // This reduces performance when switching between steps
        // but addresses bugs when going from sp -> rp and rp -> comps
        // TODO: only invalidate when necessary or find another way to handle bugginess
        invalidate(UnderwritingReportVersionsEndpoint, dpid ? { dpid } : null);
        invalidate(UnderwritingReportEndpoint, { dpid: theDpid, versionId });
      }

      // no stepValue and dpid -> go to next step
      if (!stepValue && theDpid) {
        return goToNextStep();
      }

      // stepValue and dpid -> go to specific step
      if (stepValue && theDpid) {
        return goToStep(stepValue);
      }
    },
    [dpid, goToFirstStep, invalidate, goToNextStep, goToStep, versionId],
  );

  const disableCurrentStep = useCallback(
    async ({ disabled }: { disabled: boolean }) => {
      setCurrentStepInvalid(disabled);
    },
    [setCurrentStepInvalid],
  );

  const onChange = (stepValue: string) => {
    onChangeStep({ stepValue });
  };

  // DOM ref and DOM node to use with our StepperActions component portal
  const stepperActionsRef = useRef<HTMLDivElement | null>(null);
  const stepperActionsEl = useMemo(() => document.createElement("div"), []);

  // Allows us to set up our DOM element to be used via portal before browser paint to avoid any potential flicker
  useLayoutEffect(() => {
    stepperActionsRef.current!.appendChild(stepperActionsEl);
  }, [stepperActionsEl]);

  const contextValue = useMemo(
    () => ({ stepperActionsEl, disableCurrentStep, goToNextStep, report }),
    [stepperActionsEl, disableCurrentStep, goToNextStep, report],
  );

  // Override disabled state if current step was marked invalid
  const renderedSteps = useMemo(() => {
    return steps.map((step) => {
      const disabled = (step.value !== currentStep && currentStepInvalid) || step.disabled;
      return { ...step, disabled };
    });
  }, [steps, currentStep, currentStepInvalid]);

  const isSavedReport = report && report.status !== "Unsaved";
  const [openCta, resolvedCta] = partition(report?.report_ctas || [], (cta) => cta.status === ReportCtaStatus.open);

  return (
    <StepperContext.Provider value={contextValue}>
      <div css={Css.w100.$}>
        {property && (
          <>
            <ReportWarningBanners />
            <div css={Css.df.aic.jcsb.py2.$}>
              <StepPageHeader property={property} status={report?.status} report={report} />
              <div css={Css.df.aic.gap1.$}>
                {isSavedReport && (
                  <ShowCtaPaneButton
                    dpid={dpid}
                    versionId={versionId}
                    openCount={openCta.length}
                    hasResolvedCta={resolvedCta.length > 0}
                  />
                )}
                <OpenArchiveModalButton report={report} />
                {isSavedReport && <OpenVersionsDrawerButton property={property} versionId={report.id} size={"md"} />}
              </div>
            </div>
          </>
        )}
        {children}
      </div>
      <div
        css={
          Css.df.jcsb.aic.gap3.fixed.z999.bottom0.left0.right0.bgGray100.px4
            .hPx(stepperBarHeight)
            .oxa.boxShadow("0px 0px 32px rgba(201, 201, 201, 0.75)").$
        }
      >
        <Stepper steps={renderedSteps} currentStep={currentStep} onChange={onChange} />
        <div ref={stepperActionsRef} css={Css.addIn("> div", Css.df.aic.gap2.$).$} />
      </div>
    </StepperContext.Provider>
  );
}

interface NextStepContext {
  report?: UnderwritingReport;
  shouldRegenerateSteps?: boolean;
}

interface StepperContextProps {
  stepperActionsEl: HTMLDivElement | null;
  goToNextStep: (context?: NextStepContext | undefined) => void;
  disableCurrentStep: ({ disabled }: { disabled: boolean }) => Promise<void>;
  report?: UnderwritingReport;
}

export const StepperContext = createContext<StepperContextProps>({
  stepperActionsEl: null,
  goToNextStep: () => {},
  disableCurrentStep: async () => {},
});

export function useStepperContext() {
  return useContext(StepperContext);
}

export function StepperActions({ children }: { children: JSX.Element }): ReactPortal | JSX.Element {
  const { stepperActionsEl, report } = useStepperContext();

  // a fallback for tests should headerActionsEl not exist so tests can find the elements it needs
  if (!stepperActionsEl) {
    return children;
  }

  if (!reportIsReadOnly(report)) {
    return createPortal(children, stepperActionsEl);
  } else if (report?.status === "Finalized") {
    return createPortal(<PrintFinalizedReportButton report={report} />, stepperActionsEl);
  }
  return <></>;
}

function PrintFinalizedReportButton({ report }: { report: UnderwritingReport }) {
  const reportPath = generatePath(printEstimatePage, { dpid: report.dpid, versionId: report.id!.toString() });
  return (
    <Link to={reportPath} target="_blank" referrerPolicy="no-referrer">
      <Button label={"Print Report Summary"} onClick={() => undefined} />
    </Link>
  );
}

export const stepperBarHeight = 80;
