import { Css } from "@homebound/beam";
import { FlatDataList } from "src/components/FlatDataList";
import { mapPropertyTypeName } from "src/utils/mappers";
import { Property } from "../../endpoints";
import { PropertyAddressHeader } from "./PropertyAddressHeader";

interface ReferencePropertyCardProps {
  property: Property;
}

export function ReferencePropertyCard({ property }: ReferencePropertyCardProps) {
  const { property_type_simple, buildable_sqft } = property;

  return (
    <div css={Css.br8.pt1.w100.df.$}>
      <div>
        <PropertyAddressHeader property={property} />
        <div css={Css.pt2.$}>
          <div css={Css.smMd.$}> {mapPropertyTypeName(property_type_simple)}</div>
          <FlatDataList
            items={[
              { name: "Beds", value: undefined }, // TODO: could add RP info here
              { name: "Baths", value: undefined },
              { name: "buildable sqft", value: buildable_sqft },
            ]}
            lineXss={Css.pr2.sm.$}
          />
        </div>
      </div>
    </div>
  );
}
