import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { currentUser, HomeboundUser } from "@homebound/auth-components";
import { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { PageLayout } from "src/routes/layout/PageLayout";
import { Routes } from "./Routes";

export function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<HomeboundUser>();

  useEffect(() => {
    onLoad().catch(console.error);
  }, []);

  async function onLoad() {
    const { data: user } = await currentUser();
    setUser(user);
    setIsAuthenticated(user !== undefined);
    setIsAuthenticating(false);
    if (user) {
      datadogRum.setUser({ id: user.email, email: user.email, name: user.name });
      datadogLogs.setUser({ id: user.email, email: user.email, name: user.name });
    }
  }

  const authProps = { isAuthenticated, setIsAuthenticated, user, setUser };
  const routes = <Routes authProps={authProps} />;

  if (isAuthenticating) {
    return <div />;
  } else if (isAuthenticated) {
    return (
      <Route>
        <PageLayout {...{ user, setIsAuthenticated }}>{routes}</PageLayout>
      </Route>
    );
  } else {
    return routes;
  }
}
