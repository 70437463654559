import { Button, Css } from "@homebound/beam";
import { useParams } from "react-router";
import { useSuspense } from "@rest-hooks/react";
import { LoadingBoundary } from "src/components/LoadingBoundary";
import { PageHeader } from "src/components/PageHeader";
import { ReadyPlanConfigSelectionV2 } from "src/routes/cma/steps/ready-plan/v2/ReadyPlanConfigSelectionV2";
import { BooleanParam, useQueryParams } from "use-query-params";
import { Property, PropertyEndpoint } from "../../endpoints";
import { UnderwritingReport, UnderwritingReportEndpoint } from "../../endpoints/reports/UnderwritingReportEndpoint";
import { hasV2ReadyPlans } from "../readyPlanUtils";
import { LoadReadyPlanSelectOptions } from "./ReadyPlanDetails";

export function ReadyPlanStep() {
  const { dpid, versionId } = useParams<{ dpid: string; versionId: string }>();

  return (
    <>
      <PageHeader title="Ready Plan Selection" />
      <LoadingBoundary>
        <LoadReadyPlan dpid={dpid} versionId={versionId} />
      </LoadingBoundary>
    </>
  );
}

interface LoadReadyPlanProps {
  dpid: string;
  versionId: string;
}

export function LoadReadyPlan({ dpid, versionId }: LoadReadyPlanProps) {
  const propertyResult = useSuspense(PropertyEndpoint, { dpid });
  const reportsResult = useSuspense(UnderwritingReportEndpoint, { dpid, versionId });

  return <ReadyPlanSelector property={propertyResult.property} report={reportsResult.report} />;
}

interface ReadyPlanSelectorProps {
  property: Property;
  report: UnderwritingReport;
}

export function ReadyPlanSelector({ property, report }: ReadyPlanSelectorProps) {
  const [{ useV2 = hasV2ReadyPlans(report) }, setFlags] = useQueryParams({ useV2: BooleanParam });

  function bpVersionSwitcher() {
    return (
      <div css={Css.my2.$}>
        <Button
          variant="tertiary"
          label={useV2 ? "Use Legacy Ready Plan" : "Use Blueprint Ready Plan"}
          onClick={() => setFlags({ useV2: !useV2 })}
        />
      </div>
    );
  }

  return (
    <>
      {bpVersionSwitcher()}
      {useV2 ? <ReadyPlanV2Selection report={report} /> : <ReadyPlanV1Selection property={property} report={report} />}
    </>
  );
}

export function ReadyPlanV1Selection({ property, report }: ReadyPlanSelectorProps) {
  return <LoadReadyPlanSelectOptions property={property} report={report} />;
}

export function ReadyPlanV2Selection({ report }: { report: UnderwritingReport }) {
  return (
    <div data-testid="ready-plan-v2-selection" css={Css.w100.$}>
      <ReadyPlanConfigSelectionV2 report={report} />
    </div>
  );
}
