import {
  BoundDateField,
  BoundNumberField,
  BoundSelectField,
  BoundTextField,
  Button,
  ButtonMenu,
  column,
  Css,
  GridDataRow,
  GridStyle,
  GridTable,
  IconButton,
  MenuItem,
  ModalProps,
  RowStyles,
  ScrollableContent,
  ScrollableParent,
  simpleHeader,
  Tooltip,
  useModal,
} from "@homebound/beam";
import { condensedStyle, GridStyleDef } from "@homebound/beam/dist/components/Table/TableStyles";
import { ObjectState, useFormState } from "@homebound/form-state";
import { comparer } from "mobx";
import { Observer } from "mobx-react";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { DeleteConfirmationModal } from "src/components/DeleteConfirmationModal";
import { FormattedPrice } from "src/components/FormattedPrice";
import { Icon } from "src/components/Icon";
import { HbUWSpinner } from "src/components/LoadingBoundary";
import { mdashUnicode } from "src/components/mdash";
import { UWLabel } from "src/components/UWLabel";
import { useComputed, useController, useReaction } from "src/hooks";
import { Property, PropertyComp } from "src/routes/cma/endpoints";
import { POSSIBLE_USED_MARKET_AMENITIES } from "src/routes/cma/endpoints/adj/AmenityAdjustmentEndpoint";
import {
  ReadyPlan,
  SaveCompPropertyInput,
  SaveUnderwritingReportEndpoint,
  SaveUnderwritingReportInput,
  UnderwritingReport,
  UnderwritingReportEndpoint,
} from "src/routes/cma/endpoints/reports";
import { RecalcReadyPlanCompWeightsEndpoint } from "src/routes/cma/endpoints/reports/RecalcReadyPlanCompWeightsEndpoint";
import { StepperActions, useStepperContext } from "src/routes/cma/stepper/StepperContext";
import { AddCommonAdjustmentSelectField } from "src/routes/cma/steps/adjustments/AddCommonAdjustmentSelectField";
import {
  adjustmentFormConfig,
  AdjustmentFormInput,
  AdjustmentFormState,
  CompAdjustment,
  CompAdjustmentRow,
  mapToAddManualAdjustmentInput,
  mapToAdjustmentForm,
  mapToDeleteManualAdjustmentInput,
} from "src/routes/cma/steps/adjustments/AdjustmentTableForm";
import {
  AdjustedFormPropertyVal,
  mapAllAdjustmentsToSaveReportInput,
  marketAmenityColumnRowMapper,
  zeroWeights,
} from "src/routes/cma/steps/adjustments/AdjustmentTableUtils";
import { BoundSelectPriceField } from "src/routes/cma/steps/adjustments/BoundPriceSelectField";
import { formatNumberToString, Maybe } from "src/utils";
import { propertyTypeOptions } from "src/utils/mappers";
import { reportIsReadOnly } from "src/utils/reports";
import {
  calcFinalPrice,
  calcTotalAdjustments,
  calcTotalAmenityAdjustments,
  calcTotalManualAdjustments,
  isNegativeNumber,
  isPositiveNumber,
  price,
  sortComps,
} from "src/utils/tableUtils";
import { PropertyHeader } from "./PropertyHeader";

export interface CompAdjustmentTableProps {
  property: Property;
  report: UnderwritingReport;
  readyPlan: ReadyPlan;
}

interface FormControlsProps {
  onSave: () => void;
  saveProgress: () => void;
  allowRecalculate: boolean;
  errorMessage: string;
}

interface RowField {
  id: string;
  value: (p: PropertyComp, os: ObjectState<SaveCompPropertyInput> | undefined) => Maybe<number | string> | ReactNode;
  subject?: (r: UnderwritingReport, rp: ReadyPlan) => Maybe<number | string>;
}

interface TotalRowField {
  id: string;
  calcTotal: (p: PropertyComp) => Maybe<number>;
}

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: RowField };
type ManualAdjustmentRow = { kind: "manualAdj"; data: ObjectState<CompAdjustmentRow> };
type MarketAdjustmentRow = { kind: "marketAdj"; data: ObjectState<CompAdjustmentRow> };
type PropertyAdjRow = { kind: "propAdj"; data: ObjectState<AdjustmentFormInput> };
type NewCompAdjRow = { kind: "newCompAdj" };
type TotalRow = { kind: "total"; data: TotalRowField };
type Row = HeaderRow | DataRow | MarketAdjustmentRow | ManualAdjustmentRow | NewCompAdjRow | PropertyAdjRow | TotalRow;

interface CreateColumnsInput {
  property: Property;
  report: UnderwritingReport;
  readyPlan: ReadyPlan;
  comps: PropertyComp[];
  adjustmentFormState: AdjustmentFormState;
  updateCommonAdjustments: (adjAttribute: string, remove?: boolean) => Promise<void>;
  updateManualAdjustment: (description: Maybe<string>, adjustmentsByProperty?: CompAdjustment[]) => Promise<void>;
  openModal: (props: ModalProps) => void;
  recalcWeights: () => Promise<void>;
}

interface RowHeaderProps {
  rowId: string;
}

export function CompAdjustmentTable({ property, report, readyPlan }: CompAdjustmentTableProps) {
  const { fetch, invalidate } = useController();
  const { openModal } = useModal();
  const { goToNextStep, disableCurrentStep } = useStepperContext();
  const [saving, setSaving] = useState(false); // updating

  const initialPropertyAdjVal = useMemo(() => {
    return readyPlan.property_adjustments?.[0]?.value ?? 0;
  }, [readyPlan]);
  const input = useComputed(() => ({ report, readyPlan, property }), [report, readyPlan, property]);
  const comps = useComputed(() => {
    return (readyPlan.comps ?? []).sort(sortComps);
  }, [readyPlan]);

  const adjustmentFormState = useFormState({
    config: adjustmentFormConfig,
    init: {
      input,
      map: mapToAdjustmentForm,
    },
    readOnly: reportIsReadOnly(report),
    addRules({ subjectPropertyPercentAdj, report_status }) {
      subjectPropertyPercentAdj.rules.push(() => {
        return report_status.value !== "Finalized" && subjectPropertyPercentAdj.value !== 0
          ? "Property % Adjustment has been deprecated and must be 0 before finalizing report"
          : undefined;
      });
    },
  });

  async function saveProgress() {
    setSaving(true);
    await Promise.all([
      // Protect users from themselves
      disableCurrentStep({ disabled: true }),
      // Invalidate any previous MlSaleConfidence requests to ensure recalculated when loading est page
      invalidate(UnderwritingReportEndpoint, {
        dpid: report.dpid,
        versionId: report.id!.toString(),
        shouldGenerateMlSaleConfidence: true,
      }),
    ]);
    const reportInput = mapAllAdjustmentsToSaveReportInput(adjustmentFormState, readyPlan);
    await fetch(SaveUnderwritingReportEndpoint, {
      report: reportInput,
      versionId: report.id,
    });
    await disableCurrentStep({ disabled: false });
    setSaving(false);
  }

  // disable forward stepper progress if form has errors
  useReaction(
    () => adjustmentFormState.valid,
    (valid, _prev) => {
      disableCurrentStep({ disabled: !valid });
    },
    { equals: comparer.shallow },
    [adjustmentFormState],
  );

  async function onSave() {
    adjustmentFormState.dirty && (await saveProgress());
    goToNextStep();
  }

  async function updateCommonAdjustments(adjAttribute: string, remove = false) {
    remove
      ? (adjustmentFormState.added_standard_adjustment_rows.value =
          adjustmentFormState.added_standard_adjustment_rows.value.filter((adj) => adj !== adjAttribute))
      : adjustmentFormState.added_standard_adjustment_rows.value.push(adjAttribute);

    await saveProgress();
  }

  async function updateManualAdjustment(description: Maybe<string>, adjustmentsByProperty?: CompAdjustment[]) {
    if (!description) return;

    const neighborDpids = adjustmentFormState.comp_properties.value.map((c) => c.dpid_of_neighbor);

    let reportInput: SaveUnderwritingReportInput;

    if (adjustmentsByProperty) {
      reportInput = mapToDeleteManualAdjustmentInput(adjustmentFormState, description, adjustmentsByProperty);
    } else {
      reportInput = mapToAddManualAdjustmentInput(adjustmentFormState, description, neighborDpids);
    }

    await fetch(SaveUnderwritingReportEndpoint, { report: reportInput, versionId: report.id });
  }

  async function recalcWeights() {
    setSaving(true);
    const input = {
      dpid: report.dpid,
      uwReadyPlanId: readyPlan.id,
      versionId: report.id!.toString(),
    };

    await fetch(RecalcReadyPlanCompWeightsEndpoint, input);
    setSaving(false);
  }

  const columns = useComputed(
    () =>
      createColumns({
        property,
        report,
        readyPlan,
        comps,
        adjustmentFormState,
        updateManualAdjustment,
        openModal,
        recalcWeights,
        updateCommonAdjustments,
      }),
    [input],
  );

  const rows = useComputed(
    () => createRows(adjustmentFormState, initialPropertyAdjVal),
    [input, initialPropertyAdjVal],
  );

  // We're only able to set form to readonly mode on the first fetch, so we're just putting it behind a loader
  function renderForm() {
    return (
      <ScrollableParent>
        <ScrollableContent>
          {saving ? <HbUWSpinner /> : <GridTable columns={columns} rows={rows} style={style} rowStyles={rowStyles} />}
          <FormControls
            saveProgress={saveProgress}
            onSave={onSave}
            allowRecalculate={!adjustmentFormState.valid || !adjustmentFormState.dirty}
            errorMessage={adjustmentFormState.errors
              .map((e) => e.replace(/^comp_properties: |^subjectPropertyPercentAdj: /, ""))
              .join("\n")}
          />
        </ScrollableContent>
      </ScrollableParent>
    );
  }

  return <Observer>{() => renderForm()}</Observer>;
}

// There's some kind of issue displaying form element errors for "group" errors
// like this, i.e. setting errorMessage on all the weight fields causes odd
// focus issues, so, just reporting any weight total errors in a div for now.
function FormControls({ onSave, saveProgress, allowRecalculate, errorMessage }: FormControlsProps) {
  return (
    <>
      {errorMessage && (
        <div css={Css.mt1.red500.df.jcfe.$}>
          <Icon icon="errorCircle" />
          <div data-testid="errorMessage" css={Css.pl1.$}>
            {errorMessage}
          </div>
        </div>
      )}
      <StepperActions>
        <>
          <Button
            endAdornment={<Icon icon="cloudSave" />}
            label={"Save & Recalculate"}
            labelInFlight={"Recalculating..."}
            onClick={saveProgress}
            disabled={allowRecalculate}
            variant="secondary"
          />
          <Button label="Save &amp; Continue" onClick={onSave} disabled={errorMessage} />
        </>
      </StepperActions>
    </>
  );
}

// Note: The `borderless={false}` on our input fields overrides the default styling that gets applied when they're inside GridTable
function createColumns({
  property,
  report,
  readyPlan,
  comps,
  adjustmentFormState,
  updateManualAdjustment,
  openModal,
  recalcWeights,
  updateCommonAdjustments,
}: CreateColumnsInput) {
  const addedAdjustmentRows = adjustmentFormState.added_standard_adjustment_rows.value;

  const propertyColumns = comps.map((c) => {
    const p = c.neighbor!;
    const compPropRow = adjustmentFormState.comp_properties.rows.find(
      (r) => r.value.dpid_of_neighbor === c.dpid_of_neighbor,
    );

    return column<Row>({
      header: () => ({
        content: (
          <PropertyHeader property={p} compTier={c.comp_tier} isHbComp={c.is_hb_comp} isMlComp={c.auto_uw_rec} />
        ),
        css: BORDER_RIGHT_COMP,
      }),
      data: (row) => ({
        content: row.value(c, compPropRow),
        css: BORDER_RIGHT_COMP,
      }),
      marketAdj: (row) => {
        const f = row.adjustmentsByProperty.rows.find((a) => a.dpid.value === c.dpid_of_neighbor);
        const content =
          !f || !compPropRow ? (
            <div>{mdashUnicode}</div>
          ) : (
            marketAmenityColumnRowMapper[row.id.value].field(compPropRow, f!.amount.value)
          );

        return {
          content,
          css: BORDER_RIGHT_COMP,
        };
      },
      manualAdj: (row) => {
        const f = row.adjustmentsByProperty.rows.find((a) => a.dpid.value === c.dpid_of_neighbor);
        const readOnly = row.description.value === undefined;
        // Check that comp property has value for this adjustment
        const content =
          !f || !compPropRow ? (
            <div>{mdashUnicode}</div>
          ) : (
            <div css={Css.w50.$}>
              <BoundNumberField
                borderless={false}
                xss={
                  Css.tal.xs.if(isPositiveNumber(f.amount.value)).green600.if(isNegativeNumber(f.amount.value)).red600.$
                }
                type="cents"
                field={f.amount}
                readOnly={readOnly || compPropRow.readOnly}
              />
            </div>
          );

        return {
          content,
          css: BORDER_RIGHT_COMP,
        };
      },
      newCompAdj: () => ({
        content: <></>,
        css: BORDER_RIGHT_COMP,
      }),
      propAdj: () => ({
        content: <></>,
        css: BORDER_RIGHT_COMP,
      }),
      total: (data) => ({
        content: () => {
          const colorCoded = data.id !== "Final Price";
          return <FormattedPrice price={data.calcTotal(c)} colorCoded={colorCoded} />;
        },
        css: BORDER_RIGHT_COMP,
      }),
      mw: "300px",
      align: "right",
    });
  });

  return [
    // description column
    column<Row>({
      header: () => <></>,
      data: (row) => <RowHeader rowId={row.id} />,
      marketAdj: (row) => <RowHeader rowId={row.description.value ?? ""} />,
      manualAdj: (row) => (
        <div css={Css.w100.$}>
          <BoundTextField borderless={false} xss={Css.base.$} field={row.description} />
        </div>
      ),
      // TODO: If we can combine this CommonAdjSelectField, with the Add manual field, and remove rows into a single modal it will be much better UX.
      //   Can still use the adj form data
      newCompAdj: () => (
        <div css={Css.w100.$}>
          <AddCommonAdjustmentSelectField
            borderless={false}
            addManualAdjustment={updateManualAdjustment}
            addCommonAdjustmentRow={updateCommonAdjustments}
            currentAddedAdjustments={addedAdjustmentRows}
            disabled={adjustmentFormState.readOnly}
          />
        </div>
      ),
      propAdj: () => <div css={Css.baseMd.$}>Property % Adjustment</div>,
      total: (_data, { row }) => <RowHeader rowId={row.id} />,
      w: "270px",
      sticky: "left",
    }),
    // trash icon column
    column<Row>({
      header: () => <></>,
      marketAdj: (row) => ({
        content: addedAdjustmentRows.includes(row.id.value) ? (
          <IconButton
            icon="trash"
            onClick={() =>
              openModal({
                content: (
                  <DeleteConfirmationModal
                    onConfirmDelete={async () => await updateCommonAdjustments(row.id.value, true)}
                    entityType={`${row.description.value}`}
                    confirmationMessage="Are you sure you want to delete this row?"
                  />
                ),
              })
            }
          />
        ) : (
          <></>
        ),
        revealOnRowHover: true,
      }),
      data: (row) => ({
        content: () => {
          if (row.id === "Weight") {
            const items: MenuItem[] = [
              {
                label: "Zero",
                onClick: () => {
                  zeroWeights(adjustmentFormState);
                },
              },
              {
                label: "Recalculate",
                onClick: () => {
                  recalcWeights();
                },
              },
            ];
            return (
              <ButtonMenu trigger={{ icon: "verticalDots" }} items={items} disabled={adjustmentFormState.readOnly} />
            );
          }
        },
        revealOnRowHover: true,
      }),
      manualAdj: (row) => ({
        content: (
          <IconButton
            icon="trash"
            onClick={() =>
              openModal({
                content: (
                  <DeleteConfirmationModal
                    onConfirmDelete={() =>
                      updateManualAdjustment(row.description.value, row.adjustmentsByProperty.value)
                    }
                    entityType={`${row.description.value}`}
                    confirmationMessage="Are you sure you want to delete this row?"
                  />
                ),
              })
            }
          />
        ),
        revealOnRowHover: true,
      }),
      newCompAdj: () => <></>,
      propAdj: () => <></>,
      total: () => <></>,
      w: "50px",
      sticky: "left",
    }),
    // subject property column
    column<Row>({
      header: () => ({
        content: <PropertyHeader property={property} isSubjectProperty />,
        css: BORDER_RIGHT,
      }),
      marketAdj: (row) => ({
        content: marketAmenityColumnRowMapper[row.id.value] ? (
          marketAmenityColumnRowMapper[row.id.value].subject(readyPlan, adjustmentFormState.subject_property_metadata)
        ) : (
          <></>
        ),
        css: BORDER_RIGHT,
      }),
      data: (row) => ({
        content: row.subject ? row.subject(report, readyPlan) : () => <></>,
        css: BORDER_RIGHT,
      }),
      manualAdj: () => ({
        content: <></>,
        css: BORDER_RIGHT,
      }),
      newCompAdj: () => ({
        content: <></>,
        css: BORDER_RIGHT,
      }),
      propAdj: (fs) => ({
        content: (
          <div css={Css.wPx(60).$}>
            <Observer>
              {() => (
                <BoundNumberField
                  borderless={false}
                  field={fs.subjectPropertyPercentAdj}
                  type="percent"
                  numFractionDigits={2}
                />
              )}
            </Observer>
          </div>
        ),
        css: BORDER_RIGHT,
      }),
      // SUBJECT
      total: (row) => {
        let content = <></>;
        if (row.id === "Final Price") {
          const { final_weighted_price, copies } = readyPlan;
          content = (
            <Tooltip title={"Weighted Sale Price without appreciation"}>
              <div css={Css.df.fdc.gap1.smBd.if(copies > 1).xsBd.$}>
                <span>
                  <FormattedPrice price={final_weighted_price} />
                  <span css={Css.xs.$}>{copies > 1 && " each"}</span>
                </span>
                {copies > 1 && (
                  <span>
                    <FormattedPrice price={final_weighted_price * copies} />
                    <span css={Css.xs.$}> for {copies} copies</span>
                  </span>
                )}
              </div>
            </Tooltip>
          );
        }
        return {
          content,
          css: BORDER_RIGHT,
        };
      },
      mw: "300px",
      sticky: "left",
    }),
    ...propertyColumns,
  ];
}

function rowFields(): RowField[] {
  return [
    {
      id: "dpid",
      value: (p) => p.neighbor!.dpid,
    },
    {
      id: "Property Type",
      value: (p, os) => {
        function PropertySelectField() {
          return (
            <BoundSelectField
              borderless={false}
              label="Property Type"
              field={os!.property_type_simple}
              options={propertyTypeOptions}
              getOptionLabel={(o) => o.name}
              getOptionValue={(o) => o.id}
            />
          );
        }

        // Our exception to market amenity based row logic
        return p.amenity_adjustment?.used_features.includes("single_family_and_not_single_family") ? (
          <AdjustedFormPropertyVal price={price(p) * p.amenity_adjustment?.single_family_and_not_single_family_adj}>
            <PropertySelectField />
          </AdjustedFormPropertyVal>
        ) : (
          <PropertySelectField />
        );
      },
      subject: (_, rp) => {
        return rp.ready_plan_sub_id ?? rp.property_type;
      },
    },
    {
      id: "Last Sold Price",
      value: (p, os) => {
        return os ? (
          <div css={Css.w50.$}>
            <BoundNumberField borderless={false} field={os!.last_sold_price} />
          </div>
        ) : undefined;
      },
    },
    {
      id: "Sold Date",
      value: (p, os) =>
        os ? (
          <div css={Css.$}>
            <BoundDateField useYearPicker borderless={false} field={os!.last_sold_date} compact iconLeft />
          </div>
        ) : undefined,
    },
    {
      id: "Builder Name",
      value: (p, os) =>
        os ? (
          <div css={Css.$}>
            <BoundTextField borderless={false} field={os!.builder_name} compact />
          </div>
        ) : undefined,
    },
    {
      id: "Homebound AVM",
      value: (p) => <FormattedPrice price={p.neighbor!.homebound_avm} />,
    },
    {
      id: "Last Sold Price Adj.",
      value: (p) => <FormattedPrice price={p.neighbor!.last_sold_price_adj} />,
    },
    {
      id: "Distance",
      value: ({ dist }) => (dist ? `${formatNumberToString(dist, false, false)} mi` : mdashUnicode),
    },
    {
      id: "Estimated Price",
      value: (p, os) => {
        return os ? (
          <BoundSelectPriceField
            field={os!.estimated_price}
            last_sold_price_adj={p.neighbor!.last_sold_price_adj}
            homebound_avm={p.neighbor!.homebound_avm}
            homebound_avm_low={p.homebound_avm_low}
            homebound_avm_high={p.homebound_avm_high}
            last_sold_price_adj_high={p.last_sold_price_adj_high}
            last_sold_price_adj_low={p.last_sold_price_adj_low}
          />
        ) : undefined;
      },
    },
    {
      id: "Amenity Adjustment",
      value: (p) => <FormattedPrice price={calcTotalAmenityAdjustments(p)} colorCoded />,
    },
    {
      id: "Weight",
      value: (p, os) => {
        return os ? (
          <div css={Css.w50.$}>
            <BoundNumberField borderless={false} field={os!.weight} numFractionDigits={2} />
          </div>
        ) : undefined;
      },
    },
  ];
}

function adjustmentRows(formState: AdjustmentFormState): ObjectState<CompAdjustmentRow>[] {
  return formState.adjustments.rows.map((a) => a);
}

function createRows(adjustmentFormState: AdjustmentFormState, initialPropertyAdjVal: number): GridDataRow<Row>[] {
  const mainFields: GridDataRow<Row>[] = rowFields().map((f, i) => ({
    kind: "data" as const,
    id: `${i}-${f.id}`,
    data: f,
  }));

  // Get adjustmentFields, then separate market adjustment rows from manual
  const adjustmentFields: GridDataRow<Row>[] = adjustmentRows(adjustmentFormState).map((f) => ({
    kind: POSSIBLE_USED_MARKET_AMENITIES.includes(f.id.value) ? ("marketAdj" as const) : ("manualAdj" as const),
    id: f.id.value,
    data: f,
  }));

  const newManualAdjustmentRow = [{ kind: "newCompAdj" as const, id: "newCompAdj", data: {} }];

  // Note: The property_adjustments attribute still exists in finalized legacy reports for historical purposes and needs
  //   to be manually zeroed out when versioned from a legacy report.
  const propertyAdjustmentRow: GridDataRow<Row>[] =
    initialPropertyAdjVal === 0 ? [] : [{ kind: "propAdj" as const, id: "propAdj", data: adjustmentFormState }];

  const allAdjustmentFields = [...adjustmentFields, ...newManualAdjustmentRow];

  const totalFields: GridDataRow<Row>[] = [
    {
      kind: "total" as const,
      id: "Total Manual Adjustments",
      data: {
        calcTotal: (p) => calcTotalManualAdjustments(p),
        id: "Total Manual Adjustments",
      },
    },
    {
      kind: "total" as const,
      id: "Total Adjustments",
      data: {
        calcTotal: (p) => calcTotalAdjustments(p),
        id: "Total Adjustments",
      },
    },
    {
      kind: "total" as const,
      id: "Final Price",
      data: {
        calcTotal: (p) => calcFinalPrice(p),
        id: "Final Price",
      },
    },
  ];

  mainFields.splice(10, 0, ...totalFields, ...propertyAdjustmentRow);
  mainFields.splice(8, 0, ...allAdjustmentFields);

  return [simpleHeader, ...mainFields];
}

// Darker
const BORDER_RIGHT = Css.br.bcGray500.$;
// Lighter
const BORDER_RIGHT_COMP = Css.br.bcGray300.$;

function RowHeader({ rowId }: RowHeaderProps) {
  const tips: Record<string, ReactNode> = {
    "Final Price": "Estimated Price + Amenity Adjustments + Manual Adjustments",
    "Estimated Price": (
      <div css={Css.df.fdc.gap1.$}>
        <div>Defaults to Last Sold Price Adj if available, else Homebound AVM.</div>
        <div>
          Ranges used in calculating these values are <span css={Css.add("fontStyle", "italic").fw7.$}>only</span> saved
          when the comp is first added to the report.
        </div>
      </div>
    ),
    "Finished Sqft.": "Combined above & below total sellable sqft when applicable",
    Baths: "Bath adjustments use combined full and half bath count when applicable to match methodology for comps data",
  };

  function withToolTip() {
    const tip = tips[rowId];

    return <UWLabel labelTypography="baseMd" label={rowId} tooltip={tip} />;
  }

  return withToolTip();
}

const style: GridStyle | GridStyleDef = {
  ...condensedStyle,
  // Overwrite forced white bg from condensed but keep rest
  cellCss: {
    ...condensedStyle.cellCss,
    ...Css.bgColor("inherit").$,
  },
  rootCss: Css.addIn(
    "& > div:nth-of-type(2)", // grid div
    Css.addIn("& > div:nth-of-type(odd)", Css.bgWhite.$) // odd rows
      .addIn("& > div:nth-of-type(even)", Css.bgGray100.$).$, // even rows
  ).$,
  emptyCell: <></>,
  headerCellCss: Css.bgWhite.mhPx(412).$,
};

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {
    cellCss: ({ data }) => {
      return heightById[data.id] ? Css.hPx(heightById[data.id]).$ : Css.hPx(48).$;
    },
  },
  marketAdj: { cellCss: Css.hPx(48).$ },
  manualAdj: { cellCss: Css.hPx(48).$ },
  newCompAdj: { cellCss: Css.hPx(48).$ },
  propAdj: { cellCss: Css.hPx(48).$ },
  total: {
    cellCss: ({ data }) => {
      return data.id === "Final Price" ? Css.hPx(56).smBd.$ : Css.hPx(36).$;
    },
  },
};

// Height is 48px for rows with inline editing
const heightById: Record<string, number> = {
  "Estimated Price": 70,
  // 36px for rows without inline editing
  dpid: 36,
  "Homebound AVM": 36,
  "Last Sold Price Adj.": 36,
  Distance: 36,
  "Amenity Adjustment": 36,
};
