import { BoundDateField, BoundRichTextField, BoundSelectField, Css, HasIdAndName } from "@homebound/beam";
import { ObjectConfig, ObjectState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { LoadingBoundary } from "src/components/LoadingBoundary";
import { UnderwritingReport } from "src/routes/cma/endpoints/reports/UnderwritingReportEndpoint";
import { ValuationStage, valuationStageOptions } from "src/routes/cma/endpoints/reports/ValuationStage";
import { PropertySFDCSection } from "src/routes/cma/steps/estimate/estimate-forms/PropertySFDCSection";
import { UnderwritingReportInput } from "src/routes/cma/UnderwritingReportForm";
import { Maybe, parseMaybeStringDate } from "src/utils";

interface OfferOutcomeFormProps {
  formState: OfferOutcomeEstFormState;
}

export function OfferOutcomeForm({ formState }: OfferOutcomeFormProps) {
  return (
    <LoadingBoundary>
      <LoadOfferOutcomeForm formState={formState} />
    </LoadingBoundary>
  );
}

export function LoadOfferOutcomeForm({ formState }: OfferOutcomeFormProps) {
  function renderForm() {
    const isC2C = formState.valuation_stage.value === ValuationStage.clear_to_close;

    return (
      <div data-testid="offerOutcomeForm">
        <PropertySFDCSection offerOutcomeForm={formState} />
        <div css={Css.df.gap2.pt2.bt.bcGray300.fdc.$}>
          <div css={Css.df.fdc.gap2.fg1.$}>
            <div css={Css.df.gap2.$}>
              <BoundSelectField
                data-testid="valuationStage"
                getOptionValue={(o) => o.id}
                getOptionLabel={(o) => o.name}
                options={
                  isC2C ? [{ id: ValuationStage.clear_to_close, name: "Clear to Close" }] : valuationStageOptions
                }
                field={formState.valuation_stage}
                readOnly={isC2C || formState.readOnly}
              />
              <BoundDateField field={formState.underwritten_at} label={"Date Of Underwriting"} />
              <BoundSelectField
                field={formState.offer_outcome}
                options={offer_outcome_options}
                getOptionValue={undefined}
                getOptionLabel={undefined}
              />
            </div>
          </div>
          <div css={Css.df.fdc.flexGrow(2).fdr.jcc.$}>
            <BoundRichTextField fullWidth label="Additional Outcome Notes" field={formState.offer_outcome_notes} />
          </div>
        </div>
      </div>
    );
  }

  return <Observer>{() => renderForm()}</Observer>;
}

const offer_outcome_options: HasIdAndName[] = [
  { id: "Proceed", name: "Proceed" },
  { id: "DQ", name: "DQ" },
];

export interface OfferOutcomeEstFormInput extends Omit<UnderwritingReportInput, "underwritten_at"> {
  underwritten_at?: Maybe<Date>;
}
export type OfferOutcomeEstFormState = ObjectState<OfferOutcomeEstFormInput>;

export const offerOutcomeEstFormConfig: ObjectConfig<OfferOutcomeEstFormInput> = {
  dpid: { type: "value" },
  offer_outcome: { type: "value" },
  offer_outcome_notes: { type: "value" },
  underwritten_at: { type: "value" },
  opportunity_id: { type: "value" },
  sfdc_opportunities: {
    type: "list",
    config: {
      dpid: { type: "value", readOnly: true },
      opportunity_id: { type: "value" },
      stage_name: { type: "value" },
    },
  },
  valuation_stage: { type: "value" },
};

export function mapOutcomeForm(report: UnderwritingReport): OfferOutcomeEstFormInput {
  const oppId = report.sfdc_opportunities?.length === 1 ? report.sfdc_opportunities[0].opportunity_id : undefined;

  return {
    dpid: report.dpid,
    offer_outcome: report.offer_outcome,
    offer_outcome_notes: report.offer_outcome_notes,
    underwritten_at: report?.underwritten_at ? parseMaybeStringDate(report.underwritten_at) : new Date(),
    sfdc_opportunities: report?.sfdc_opportunities ?? [],
    valuation_stage: report.valuation_stage,
    opportunity_id: report.opportunity_id ?? oppId,
  };
}
