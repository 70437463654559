import { Step } from "@homebound/beam";
import {
  cmaPath,
  createCmaAdjustmentsUrl,
  createCmaComparablesUrl,
  createCmaEstimateUrl,
  createCmaHpoUrl,
  createCmaReadyPlanUrl,
  createCmaSubjectPropertyUrl,
} from "src/routes/routesDef";
import { CmaStep } from "./CmaStep";

export function pathForStep(dpid: string, versionId: string, step: Step | string): string {
  if (!dpid) {
    return cmaPath;
  }

  const stepValue = typeof step === "string" ? step : step.value;

  if (stepValue === CmaStep.subject_property) {
    return createCmaSubjectPropertyUrl({ dpid, versionId });
  }

  if (stepValue === CmaStep.ready_plan) {
    return createCmaReadyPlanUrl(dpid, versionId);
  }

  if (stepValue === CmaStep.estimate) {
    return createCmaEstimateUrl(dpid, versionId);
  }

  if (stepValue === CmaStep.hpo) {
    return createCmaHpoUrl(dpid, versionId);
  }

  const parts = stepValue.split("-");
  const readyPlanId = parts.splice(1).join("-");

  if (parts[0] === "comps") {
    return createCmaComparablesUrl(dpid, versionId, readyPlanId);
  }

  if (parts[0] === "adjustments") {
    return createCmaAdjustmentsUrl(dpid, versionId, readyPlanId);
  }

  // If we don't know the step, go to the first page I guess?
  return createCmaSubjectPropertyUrl({ dpid, versionId });
}
