import { createContext, PropsWithChildren, ReactNode, useContext, useState } from "react";
import { Alert, AlertLevel, AlertProps } from "src/components/Alert";

export interface AlertContextProps {
  showError: (message: ReactNode) => void;
  showWarning: (message: ReactNode) => void;
  showSuccess: (message: ReactNode) => void;
  showInfo: (message: ReactNode) => void;
}

export const AlertContext = createContext<AlertContextProps>({
  showError: () => {},
  showWarning: () => {},
  showSuccess: () => {},
  showInfo: () => {},
});

export function AlertContextProvider({ children }: PropsWithChildren<{}>) {
  const [alertState, setAlertState] = useState<AlertProps>({} as AlertProps);
  const onAlertDismiss = () => setAlertState({ message: "", open: false } as AlertProps);
  const commonAlertProps: Pick<AlertProps, "open" | "onDismiss"> = { open: true, onDismiss: onAlertDismiss };
  const globalAlertContext: AlertContextProps = {
    showError: (message) => setAlertState({ ...commonAlertProps, message, level: AlertLevel.Error }),
    showWarning: (message) => setAlertState({ ...commonAlertProps, message, level: AlertLevel.Warning }),
    showSuccess: (message) => setAlertState({ ...commonAlertProps, message, level: AlertLevel.Success }),
    showInfo: (message) => setAlertState({ ...commonAlertProps, message, level: AlertLevel.Info }),
  };

  // Expose our api to baseOnError which is called outside of React contexts
  window.bpAlert = globalAlertContext;

  return (
    <AlertContext.Provider value={globalAlertContext}>
      {children}
      <Alert {...alertState} />
    </AlertContext.Provider>
  );
}

export function useAlertContext() {
  return useContext(AlertContext);
}

declare global {
  interface Window {
    bpAlert: AlertContextProps;
  }
}
