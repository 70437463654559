import { FilterDefs, HasIdAndName, multiFilter, singleFilter } from "@homebound/beam";
import { useMemo } from "react";
import { useSuspense } from "@rest-hooks/react";
import {
  UnderwritingReportCollaboratorsEndpoint,
  UnderwritingReportMetrosEndpoint,
} from "src/routes/cma/endpoints/reports";
import { searchBoxFilter } from "src/components/SearchBoxFilter";

export type ReportsFilter = {
  collaborator_id?: number[];
  status?: string;
  metro?: string[];
  stage_name?: string[];
  address?: string;
  zip_code?: string;
};

export function useReportsFilter() {
  const collaboratorsResult = useSuspense(UnderwritingReportCollaboratorsEndpoint);
  const metrosResult = useSuspense(UnderwritingReportMetrosEndpoint);

  const filterDefs: FilterDefs<ReportsFilter> = useMemo(() => {
    const statusOptions = [
      { id: "Draft", name: "Draft" },
      { id: "Finalized", name: "Finalized" },
    ];

    const stageOptions: HasIdAndName[] = [
      "Clear to Close",
      "Closed Won",
      "Closed Lost",
      "Closed - Disqualified",
      "Contract & Negotiation",
      "Inspection Period",
      "Ready for Offer",
      "Terms Reviewed",
      "Trying to Reach",
      "Under Contract",
      "Underwriting",
    ].map((id) => ({ id, name: id }));

    const collaborator_id = multiFilter({
      label: "Editor",
      options: collaboratorsResult?.collaborators ?? [],
      getOptionValue: (option) => option.id,
      getOptionLabel: (option) => {
        const parts = option.email.split("@");
        return parts[0];
      },
    });

    const status = singleFilter({
      label: "Status",
      options: statusOptions,
      getOptionValue: (option) => option.id,
      getOptionLabel: (option) => option.name,
    });

    const address = searchBoxFilter({
      label: "Address",
    });

    const zip_code = searchBoxFilter({
      label: "Zip Code",
    });

    const metro = multiFilter({
      label: "Metro",
      options: metrosResult.metros ?? [],
      getOptionValue: (option) => option.metro,
      getOptionLabel: (option) => option.metro,
    });

    const stage_name = multiFilter({
      label: "Opp Stage",
      options: stageOptions,
      getOptionValue: (option) => option.id,
      getOptionLabel: (option) => option.name,
    });

    return { collaborator_id, status, metro, stage_name, address, zip_code };
  }, [collaboratorsResult, metrosResult]);

  return filterDefs;
}
