import L from "leaflet";

export type MapIconColors = "gold" | "grey" | "red" | "blue" | "green" | "orange";

// NOTE: Source colors and marker pngs from: https://github.com/pointhi/leaflet-color-markers
const mapColorToCode: Record<MapIconColors, string> = {
  gold: "#FFD326",
  grey: "#7B7B7B",
  red: "#CB2B3E",
  blue: "#2A81CB",
  green: "#2AAD27",
  orange: "#CB8427",
};

export function mapMarkerIcon(color: MapIconColors) {
  return new L.Icon({
    iconUrl: `/images/map/marker-icon-2x-${color}.png`,
    shadowUrl: "/images/map/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });
}

export function mapMarkerImage(type: MapIconColors) {
  return <img alt={`${type} icon`} src={`/images/map/marker-icon-${type}.png`} height={14} width={14} />;
}

export function houseLeafletIcon(color: MapIconColors) {
  return L.divIcon({
    html: `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path
        d="M80 212v236a16 16 0 0016 16h96V328a24 24 0 0124-24h80a24 24 0 0124 24v136h96a16 16 0 0016-16V212"
        fill="${mapColorToCode[color]}"
        stroke="#3D3D3D"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="10"
      />
      <path
        d="M480 256L266.89 52c-5-5.28-16.69-5.34-21.78 0L32 256M400 179V64h-48v69"
        fill="${mapColorToCode[color]}"
        stroke="#3D3D3D"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="10"
      />
    </svg>
    `,
    className: "",
    iconSize: [32, 32],
    iconAnchor: [18, 16],
    tooltipAnchor: [-2, 26],
    popupAnchor: [-2, -12],
  });
}

// Also used in adjustment table PropertyHeader.tsx to denote a hb comp
export function houseSvg(color: string, size?: number, strokeWidth: number = 10) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...(size ? { width: size, height: size } : {})}>
      <path
        d="M80 212v236a16 16 0 0016 16h96V328a24 24 0 0124-24h80a24 24 0 0124 24v136h96a16 16 0 0016-16V212"
        fill={color}
        stroke="#3D3D3D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
      <path
        d="M480 256L266.89 52c-5-5.28-16.69-5.34-21.78 0L32 256M400 179V64h-48v69"
        fill={color}
        stroke="#3D3D3D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
}

// the html is a copy of the path for the Beam starFilled icon
export function starFilledLeafletIcon(color: MapIconColors) {
  return L.divIcon({
    html: `
     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path 
        d="M11.0513 3.69095C11.3506 2.76968 12.654 2.76968 12.9534 3.69095L14.4722 8.36485C14.6061 8.77685 14.99 9.05579 15.4232 9.0558L20.3377 9.05599C21.3064 9.05603 21.7091 10.2956 20.9255 10.865L16.9497 13.7538C16.5992 14.0084 16.4526 14.4598 16.5864 14.8718L18.1049 19.5458C18.4042 20.4671 17.3498 21.2332 16.5661 20.6638L12.5901 17.7753C12.2396 17.5207 11.765 17.5207 11.4146 17.7753L7.43853 20.6638C6.65483 21.2332 5.6004 20.4671 5.89971 19.5458L7.4182 14.8718C7.55205 14.4598 7.4054 14.0084 7.05494 13.7538L3.07914 10.865C2.29548 10.2956 2.69823 9.05603 3.66692 9.05599L8.58142 9.0558C9.01461 9.05579 9.39854 8.77685 9.53242 8.36486L11.0513 3.69095Z" 
        fill="${mapColorToCode[color]}"
      />
      <path 
        d="M11.0513 3.69095C11.3506 2.76968 12.654 2.76968 12.9534 3.69095L14.4722 8.36485C14.6061 8.77685 14.99 9.05579 15.4232 9.0558L20.3377 9.05599C21.3064 9.05603 21.7091 10.2956 20.9255 10.865L16.9497 13.7538C16.5992 14.0084 16.4526 14.4598 16.5864 14.8718L18.1049 19.5458C18.4042 20.4671 17.3498 21.2332 16.5661 20.6638L12.5901 17.7753C12.2396 17.5207 11.765 17.5207 11.4146 17.7753L7.43853 20.6638C6.65483 21.2332 5.6004 20.4671 5.89971 19.5458L7.4182 14.8718C7.55205 14.4598 7.4054 14.0084 7.05494 13.7538L3.07914 10.865C2.29548 10.2956 2.69823 9.05603 3.66692 9.05599L8.58142 9.0558C9.01461 9.05579 9.39854 8.77685 9.53242 8.36486L11.0513 3.69095Z" 
        fill="${mapColorToCode[color]}"  
      />
     </svg>
    `,
    className: "",
    iconSize: [40, 40],
    iconAnchor: [18, 16],
    tooltipAnchor: [-2, 26],
    popupAnchor: [-2, -12],
  });
}
