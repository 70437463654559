import { useEffect, useState } from "react";
import { SelectField } from "@homebound/beam";
import { getReadyPlanDisplayName } from "src/utils/mappers";
import { ConfidenceInterval } from "src/components/confidenceIntervalChart";
import { EstimateSection } from "./EstimateSection";
import { ReadyPlan, UnderwritingReport } from "src/routes/cma/endpoints/reports";

export function ConfidenceIntervalSections({ report }: { report: UnderwritingReport }) {
  const [chartReadyPlan, setChartReadyPlan] = useState<ReadyPlan | null>();

  useEffect(() => {
    if (report.ready_plans) {
      setChartReadyPlan(report.ready_plans[0]);
    }
  }, [report]);

  return (
    <EstimateSection title="Confidence Intervals" numColumns={10}>
      <div>
        <div>
          <SelectField
            compact
            readOnly={report.ready_plans?.length === 1}
            labelStyle="inline"
            value={chartReadyPlan?.id}
            onSelect={(o) => setChartReadyPlan(report.ready_plans?.find((rp) => rp.id === o))}
            getOptionValue={(o) => o.id}
            getOptionLabel={(o) => o.name}
            options={
              report.ready_plans?.map((rp) => {
                return {
                  id: rp.id,
                  name: getReadyPlanDisplayName(rp),
                };
              }) || []
            }
            label="Ready Plan"
          />
        </div>
        {chartReadyPlan && (
          <ConfidenceInterval
            showLegend
            stacked
            rawStreetAddress={"subject property"}
            readyPlan={chartReadyPlan}
            reportStatus={report.status}
            finalizedDate={report.underwritten_at}
            activityState={report.activity_state}
            lastUpdated={report.updated_at}
          />
        )}
      </div>
    </EstimateSection>
  );
}
