import { Endpoint } from "@rest-hooks/endpoint";
import { api_url, auth_headers } from "src/context";
import { ActivityState } from "src/routes/cma/endpoints/reports/ActivityState";
import { ValuationStage } from "src/routes/cma/endpoints/reports/ValuationStage";
import { BlueprintReadyPlanOptionGroup } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlanOptionsEndpoint";
import { Maybe } from "src/utils";
import { Lot } from "../PropCoEndpoint";
import { maybePatchComps, SFDCOpportunity, UnderwritingReport } from "./UnderwritingReportEndpoint";

interface SaveUnderwritingReportEndpointParams {
  report: SaveUnderwritingReportInput;
  versionId: Maybe<number | string>;
}

export interface SaveUnderwritingReportInput {
  id?: Maybe<number>;
  dpid: string;
  status?: Maybe<string>;

  reference_property?: SaveReportReferencePropertyInput;
  ready_plans?: Maybe<SaveReadyPlanInput[]>;

  offer_outcome?: Maybe<string>;
  offer_outcome_notes?: Maybe<string>;
  underwritten_at?: Maybe<Date | string>;

  version_name?: Maybe<string>;
  activity_state?: Maybe<ActivityState>;
  sfdc_opportunities?: SFDCOpportunity[];
  valuation_stage?: Maybe<ValuationStage>;

  subject_property_metadata?: UpdateSubjectPropertyAdjMetadata;
  ml_generate_sale_price_confidence?: Maybe<boolean>;
}

// Subset of property
// Could really be anything on Property but this subset is for adjustments table/form
export interface UpdateSubjectPropertyAdjMetadata {
  street_quality?: Maybe<string>;
  lot_quality?: Maybe<string>;
  has_scenic_view?: Maybe<boolean>;
  close_to_commercial?: Maybe<boolean>;
  close_to_powerlines?: Maybe<boolean>;
  close_to_school?: Maybe<boolean>;
  close_to_major_road?: Maybe<boolean>;
}

export interface SaveReportReferencePropertyInput {
  property_type_simple?: Maybe<string>;
  buildable_sqft?: Maybe<number>;
  lot_size?: Maybe<number>;
  zoning?: Maybe<string>;
  adu_elig?: Maybe<boolean>;
  lot_frontage_feet?: Maybe<number>;
  lot_depth_feet?: Maybe<number>;
  subject_property_market_value?: Maybe<number>;
  site_acquisition_bid_recommendation?: Maybe<number>;
  mls_url?: Maybe<string>;
  mls_date_listed?: Maybe<Date | string>;
  is_in_floodplain?: Maybe<boolean>;
}

export interface SaveManualCompAdjustmentInput {
  id: Maybe<string>;
  dpid_of_neighbor: string;
  description: string;
  amount: number;
  delete?: boolean;
}

export interface SaveManualPropertyAdjustmentInput {
  id?: Maybe<number>;
  description: string;
  value_type: string;
  value: number;
}

export interface SaveReadyPlanInput {
  id?: Maybe<number>;
  ready_plan_id?: Maybe<string>;
  ready_plan_sub_id?: Maybe<string>;

  bp_ready_plan_id?: Maybe<string>;
  bp_ready_plan_name?: Maybe<string>;
  bp_ready_plan_sub_id?: Maybe<string>;
  // the template version created rp was based on. Must be aligned prior to finalizing report
  bp_ready_plan_template_version?: Maybe<string>;
  // selected ids
  bp_ready_plan_option_ids?: Maybe<string[]>;
  // selected OPTION json
  bp_ready_plan_options_json?: Maybe<{ optionGroups?: BlueprintReadyPlanOptionGroup[] }>;
  lot_size?: Maybe<number>;
  sellable_sqft?: Maybe<number>;
  num_bedrooms?: Maybe<number>;
  num_baths?: Maybe<number>;
  hard_costs_per_sqft?: Maybe<number>;
  property_type?: Maybe<string>;
  comps?: Maybe<SaveCompInput[]>;
  manual_comp_adjustments?: SaveManualCompAdjustmentInput[];
  property_adjustments?: Maybe<SaveManualPropertyAdjustmentInput[]>;
  num_half_baths?: Maybe<number>;
  num_stories?: Maybe<number>;
  num_garage_attached?: Maybe<number>;
  num_garage_port?: Maybe<number>;
  width_in_inches?: Maybe<number>;
  depth_in_inches?: Maybe<number>;
  direct_hard_cost_in_cents?: Maybe<number>;
  indirect_hard_cost_in_cents?: Maybe<number>;
  soft_cost_in_cents?: Maybe<number>;
  spec_level?: Maybe<string>;
  // AKA sellable above ground sqft
  // Mapped from `sellableAboveGroundSqft` in computeProgramData
  above_ground_sqft?: Maybe<number>;
  // Mapped from `sellableBelowGroundSqft` in computeProgramData
  sellable_basement_sqft?: Maybe<number>;
  // AKA unfinished below ground sqft
  // Mapped from `unfinishedBelowGroundSqft` in computeProgramData
  below_ground_sqft?: Maybe<number>;

  added_standard_adjustment_rows?: Maybe<string[]>;
}

export interface SaveCompInput {
  id?: Maybe<string>;
  dpid_of_neighbor: string;
  user_selected_comp?: Maybe<boolean>;
  selected_comp_id?: Maybe<string>;
  dist?: Maybe<number>;
  is_hb_comp?: Maybe<boolean>;
  auto_uw_rec?: Maybe<boolean>;
  comp_score?: Maybe<number>;
}

export interface SaveCompPropertyInput {
  dpid_of_neighbor: string;
  year_built_or_renovated: Maybe<string>;
  builder_name: Maybe<string>;
  num_bedrooms: Maybe<number>;
  num_baths: Maybe<number>;
  num_stories: Maybe<number>;
  has_pool: Maybe<boolean>;
  has_scenic_view?: Maybe<boolean>;
  garage_num_cars: Maybe<number>;
  finished_sqft: Maybe<number>;
  lot_size: Maybe<number>;
  last_sold_price: Maybe<number>;
  last_sold_date: Maybe<Date>;
  estimated_price: Maybe<number>;
  weight?: Maybe<number>;

  close_to_road_rail_industrial?: Maybe<boolean>;
  close_to_river_or_lake?: Maybe<boolean>;
  close_to_major_road?: Maybe<boolean>;
  close_to_commercial?: Maybe<boolean>;
  close_to_powerlines?: Maybe<boolean>;
  close_to_school?: Maybe<boolean>;
  basement_sqft?: Maybe<number>;
  finished_basement_sqft?: Maybe<number>;
  unfinished_basement_sqft?: Maybe<number>;
  property_type_simple?: Maybe<string>;
  above_ground_sqft?: Maybe<number>;

  spec_level?: Maybe<string>;
  outdoor_amenity_quality?: Maybe<string>;
  street_quality?: Maybe<string>;
  lot_quality?: Maybe<string>;
}

export const SaveUnderwritingReportEndpoint = new Endpoint(
  async ({ report, versionId }: SaveUnderwritingReportEndpointParams) => {
    const path = `/v1/reports/${report.dpid}/versions/${versionId}`;

    const headers = {
      "content-type": "application/json",
      ...(await auth_headers()),
    };
    const res = await fetch(api_url(path), {
      headers,
      method: "POST",
      body: JSON.stringify(report),
    });
    return maybePatchComps(await res.json());
  },
  {
    schema: { report: UnderwritingReport },
    key: ({ report, versionId }) => {
      return `/v1/reports/${report.dpid}/versions/${versionId}`;
    },
    name: "SaveUnderwritingReportEndpoint",
  },
);

interface NewReportVersionDetails {
  version_name?: Maybe<string>;
  valuation_stage: Maybe<ValuationStage>;
  lot?: Lot;
}

export type SaveUnderwritingReportVersionInput = {
  dpid: string;
  versionId: Maybe<number>;
  newReportDetails: NewReportVersionDetails;
};

export type UpdateUnderwritingReportVersionInput = {
  dpid: string;
  versionId: Maybe<number | string>;
  changes: Pick<SaveUnderwritingReportInput, "version_name"> & {
    archive_report?: boolean;
  };
};

export const CreateNewUnderwritingReportVersionEndpoint = new Endpoint(
  async ({ dpid, versionId, newReportDetails }: SaveUnderwritingReportVersionInput) => {
    const headers = {
      "content-type": "application/json",
      ...(await auth_headers()),
    };
    const res = await fetch(api_url(`/v1/reports/${dpid}/versions/${versionId}/new`), {
      headers,
      method: "POST",
      body: JSON.stringify(newReportDetails),
    });

    return await res.json();
  },
  {
    schema: { report: UnderwritingReport },
    name: "CreateNewUnderwritingReportVersionEndpoint",
  },
);

// Success is 204. No schema/key needed
export const UpdateUnderwritingReportVersionEndpoint = new Endpoint(
  async ({ dpid, versionId, changes }: UpdateUnderwritingReportVersionInput) => {
    const headers = {
      "content-type": "application/json",
      ...(await auth_headers()),
    };

    return await fetch(api_url(`/v1/reports/${dpid}/versions/${versionId}`), {
      headers,
      method: "PUT",
      body: JSON.stringify(changes),
    });
  },
  {
    name: "UpdateUnderwritingReportVersionEndpoint",
  },
);
