import { Css, GridCellContent } from "@homebound/beam";
import { differenceInDays } from "date-fns";
import { mdashUnicode } from "src/components/mdash";
import { UWLabel } from "src/components/UWLabel";
import { Property, PropertyComp } from "src/routes/cma/endpoints";
import { calculateMarkupPercentage, formatNumberToString, isDefined } from "src/utils";
import { Maybe } from "src/utils/types";

export interface MaybeHighLow {
  high: Maybe<number>;
  low: Maybe<number>;
}

export function uwLabelPriceCell(price: Maybe<number>, range: MaybeHighLow, trim: boolean = true): GridCellContent {
  return uwNumberCell(price, range, trim, "$");
}

/**
 * outputs w/ colored percents:
 *
 * Range: $444,444 - $777,777 (-20% / 20%)
 */
export function RangeLabel({
  low,
  high,
  actual,
  label = "Range: ",
}: {
  low: number;
  high: number;
  actual: number;
  label?: string;
}) {
  return (
    <div css={Css.df.gap1.$}>
      <span>
        {label}${formatNumberToString(low, true, true)}&nbsp;-&nbsp;${formatNumberToString(high, true, true)}
      </span>
      <span>
        (<span css={Css.red400.$}>{calculateMarkupPercentage(low, actual, true)}%</span>
        &nbsp;/&nbsp;
        <span css={Css.green500.$}>{calculateMarkupPercentage(high, actual, true)}%</span>)
      </span>
    </div>
  );
}

export function uwNumberCell(
  price: Maybe<number>,
  range: MaybeHighLow,
  trim: boolean = true,
  prefix: string = "",
  suffix: string = "",
): GridCellContent {
  // if price is undefined or null
  if (price == null) return { content: undefined, value: undefined };

  return {
    content: (
      <UWLabel
        labelTypography="xs"
        label={`${prefix}${formatNumberToString(price, trim, trim)}${suffix}`}
        tooltip={range?.low && range?.high && <RangeLabel high={range.high} low={range.low} actual={price} />}
      />
    ),
    value: price,
  };
}

export function priceCell(price: Maybe<number>, trim: boolean = true): GridCellContent {
  return numberCell(price, trim, "$");
}

export function percentageCell(num: Maybe<number>, trim: boolean = true): GridCellContent {
  const percent = num ? num * 100 : undefined;
  return numberCell(percent, trim, "", "%");
}

export function numberCell(
  price: Maybe<number>,
  trim: boolean = true,
  prefix: string = "",
  suffix: string = "",
): GridCellContent {
  // if price is undefined or null
  if (price == null) return { content: undefined, value: undefined };

  return {
    content: `${prefix}${formatNumberToString(price, trim, trim)}${suffix}`,
    value: price,
  };
}

export function addressCell(address?: Maybe<string>, unit?: Maybe<string>, rightBorder?: boolean): GridCellContent {
  return {
    content: address ? (
      <div css={Css.ttc.$}>{`${address.toLowerCase()}${unit ? `, Unit ${unit?.toLowerCase()}` : ""}`}</div>
    ) : undefined,
    value: address,
    ...(rightBorder && { css: Css.br.bcGray500.$ }),
  };
}

export function domCell(property?: Property | PropertyComp) {
  if (!property) return mdashUnicode;

  if (!property?.mls_list_date) {
    return mdashUnicode;
  }
  // if status is pending or active, then DOM is today - list date
  if (property.mls_status === "ACT" || property?.mls_status === "PND") {
    return differenceInDays(new Date(), new Date(property.mls_list_date));
  }
  // otherwise, DOM is sold date - list date if the sold date is after the list date
  return property?.last_sold_date && property.mls_list_date < property?.last_sold_date
    ? differenceInDays(new Date(property.last_sold_date), new Date(property.mls_list_date))
    : mdashUnicode;
}

/*
 * Table Calculations
 */

export function isPositiveNumber(n: Maybe<number>) {
  if (n && n > 0) return true;
  return false;
}

export function isNegativeNumber(n: Maybe<number>) {
  if (n && n < 0) return true;
  return false;
}

export function formatNum(n: Maybe<number>) {
  return isDefined(n) ? formatNumberToString(n, true) : undefined;
}

export function price(p: PropertyComp): number {
  return p.estimated_price || p.neighbor!.estimated_price!;
}

export function calcTotalAmenityAdjustments(p: PropertyComp): number {
  const adj = p.amenity_adjustment;
  return adj ? adj.amenity_adjustment * price(p) : 0;
}

export function calcTotalManualAdjustments(p: PropertyComp): number {
  return (
    p.manual_adjustments?.reduce((acc, cur) => {
      const amt = cur.amount ?? 0;
      return acc + amt;
    }, 0) ?? 0
  );
}

export function calcTotalAdjustments(p: PropertyComp): number {
  return calcTotalAmenityAdjustments(p) + calcTotalManualAdjustments(p);
}

export function calcFinalPrice(p: PropertyComp): number {
  return price(p) + calcTotalAdjustments(p);
}

export function sortComps(a: PropertyComp, b: PropertyComp): number {
  return a.neighbor!.full_street_address.localeCompare(b.neighbor!.full_street_address);
}

export function isActOrPnd(p: Property | PropertyComp | undefined): Maybe<boolean> {
  if (!p?.mls_status) return undefined;
  return p.mls_status === "ACT" || p.mls_status === "PND";
}
