import { Css, ModalBody, ModalHeader } from "@homebound/beam";
import { useState } from "react";
import { ReadyPlan } from "src/routes/cma/endpoints/reports";
import { Property, PropertySummary } from "../../../endpoints";
import { PropertySearchBox } from "../../../PropertySearchBox";
import { CompFilter } from "../CompFilter";
import { AddCompPropertyDetailsForm } from "./AddCompPropertyDetailsForm";
import { MiniMap } from "./MiniMap";
import { CompFilterPolygonGeometry } from "src/routes/cma/steps/comparables/ComparablesStep";

export interface AddCompModalContentProps {
  subjectProperty: Property;
  compDpids: string[];
  versionId: string;
  dpid: string;
  readyPlan: ReadyPlan;
  compFilter: CompFilter;
  compFilterPolygon?: CompFilterPolygonGeometry | undefined;
}

export function AddCompModalContent({
  subjectProperty,
  compDpids,
  versionId,
  dpid,
  readyPlan,
  compFilter,
  compFilterPolygon,
}: AddCompModalContentProps) {
  const [newComp, setNewComp] = useState<PropertySummary | undefined>(undefined);

  return (
    <>
      <ModalHeader>Add a Comp</ModalHeader>
      <ModalBody>
        <div css={Css.mb4.$}>
          <PropertySearchBox label="Search &amp; auto-fill" onPropertySelected={(p) => setNewComp(p)} />
        </div>
        {newComp && (
          <div css={Css.pb2.$}>
            <MiniMap property={newComp} />
          </div>
        )}
        <AddCompPropertyDetailsForm
          dpid={dpid}
          versionId={versionId}
          subjectProperty={subjectProperty}
          newComp={newComp}
          compDpids={compDpids}
          readyPlan={readyPlan}
          compFilter={compFilter}
          compFilterPolygon={compFilterPolygon}
        />
      </ModalBody>
    </>
  );
}
