import { UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { ActivityState } from "src/routes/cma/endpoints/reports/ActivityState";
import { Maybe } from "./types";

export function reportIsReadOnly(report: Maybe<UnderwritingReport>): boolean {
  if (report === undefined || report === null) {
    return false;
  }

  return report.activity_state !== ActivityState.active || report.status === "Finalized";
}
