import {
  Button,
  column,
  condensedStyle,
  Css,
  GridColumn,
  GridDataRow,
  GridTable,
  ScrollableContent,
  simpleHeader,
  Tooltip,
} from "@homebound/beam";
import { Icon } from "src/components/Icon";
import { getSfdcOpportunityUrl } from "src/context.sfdc";
import { PropertyEndpoint, PropertySearchEndpoint } from "src/routes/cma/endpoints";
import {
  OpportunityQueueEntry,
  UnderwritingOpportunity,
} from "src/routes/opportunities/endpoints/OpportunityQueueEndpoint";
import { Sortable } from "src/routes/paging";
import { createCmaSubjectPropertyUrl } from "src/routes/routesDef";
import { maybeStringToDateLabel } from "src/utils/dates";
import { OpenVersionsDrawerButton } from "../reports/components/ReportVersionsTable";
import { useDLE } from "@rest-hooks/react";

interface OpportunitiesTableProps extends Sortable {
  opportunities: OpportunityQueueEntry[];
}

export function OpportunitiesTable({ opportunities, onSort }: OpportunitiesTableProps) {
  return (
    <ScrollableContent>
      <GridTable
        columns={columns}
        rows={createRows(opportunities)}
        style={{ ...condensedStyle, inlineEditing: true }}
        sorting={{ on: "server", value: ["updated_at", "DESC"], onSort }}
        stickyHeader
      />
    </ScrollableContent>
  );
}

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: OpportunityQueueEntry };
type Row = HeaderRow | DataRow;

const columns: GridColumn<Row>[] = [
  column<Row>({
    header: "Opportunity",
    data: ({ opportunity: { name, id, account } }) => {
      return {
        content: (
          <div css={Css.df.aic.$}>
            <a css={Css.pr1.$} target="_blank" rel="noreferrer" href={getSfdcOpportunityUrl(id)}>
              <Icon icon="linkExternal" />
            </a>
            <Tooltip title={account.name}>{name}</Tooltip>
          </div>
        ),
        value: name,
      };
    },
    w: 2,
  }),
  column<Row>({
    header: "Stage",
    data: ({ opportunity: { stage_name } }) => stage_name,
  }),
  column<Row>({
    header: "Origin",
    data: ({ opportunity: { origin } }) => origin,
  }),
  column<Row>({
    header: "Dev Area",
    data: ({
      opportunity: {
        account: { dev_area },
      },
    }) => dev_area,
  }),
  column<Row>({
    header: "Market",
    data: ({
      opportunity: {
        account: { market },
      },
    }) => market,
  }),
  column<Row>({
    header: "Close Date",
    data: ({ opportunity: { close_date } }) => close_date,
    serverSideSortKey: "close_date",
  }),
  column<Row>({
    header: "Updated",
    data: ({ queue: { updated_at } }) => {
      return { content: maybeStringToDateLabel(updated_at, "monthDateTime"), value: updated_at };
    },
    serverSideSortKey: "updated_at",
  }),
  column<Row>({
    header: "Action",
    data: ({ opportunity }) => {
      const dpid = opportunity.account.dpid;
      if (opportunity.stage_name === "Underwriting") {
        if (!dpid) {
          // if the opportunity doesn't have a dpid, search for the Property by street address
          // before showing the start report button
          return <PropertySearchButton streetAddress={opportunity.account.street || ""} oppId={opportunity.id} />;
        }
        return <StartReportButton testId={`start-cma-button-${dpid}`} path={createCmaSubjectPropertyUrl({ dpid })} />;
      } else if (opportunity.stage_name === "Inspection Period") {
        if (!dpid) {
          return <PropertySearchVersionButton opportunity={opportunity} />;
        }
        return <OpenVersionsButton dpid={dpid} opportunity={opportunity} />;
      }
    },
    w: "150px",
  }),
];

function createRows(entries: OpportunityQueueEntry[]): GridDataRow<Row>[] {
  return [simpleHeader, ...entries.map((e) => ({ kind: "data" as const, id: e.opportunity.id, data: e }))];
}

interface PropertySearchButtonProps {
  streetAddress: string;
  oppId?: string;
}

function PropertySearchButton({ streetAddress, oppId }: PropertySearchButtonProps) {
  const {
    data: { properties },
    loading,
  } = useDLE(PropertySearchEndpoint, {
    full_street_address_search: streetAddress,
  });
  if (loading) return <div>loading...</div>;

  // if there's only one result, use that dpid
  const dpid = properties && properties.length === 1 ? properties[0].dpid : undefined;
  // if there's a dpid, use it, otherwise pass oppId to the CMA page
  const path = dpid ? createCmaSubjectPropertyUrl({ dpid }) : `/cma?oppId=${oppId}`;
  const testId = dpid ? `start-cma-button-${dpid}` : `start-cma-button-no-dpid`;

  return <StartReportButton testId={testId} path={path} />;
}

interface StartReportButtonProps {
  path: string;
  testId: string;
  label?: string;
}

function StartReportButton({ path, testId, label = "Create Report" }: StartReportButtonProps) {
  return <Button data-testid={testId} label={label} variant="secondary" size="sm" onClick={path} />;
}

interface OpenVersionsButtonProps {
  dpid: string;
  opportunity: UnderwritingOpportunity;
}

function OpenVersionsButton({ dpid, opportunity }: OpenVersionsButtonProps) {
  const {
    data: { property },
    loading,
  } = useDLE(PropertyEndpoint, { dpid });
  if (loading) return <div>loading...</div>;
  return property ? (
    <Tooltip title="Open versions table to create a clear to close report for this property">
      <OpenVersionsDrawerButton property={property} size="sm" />
    </Tooltip>
  ) : (
    <PropertySearchButton streetAddress={opportunity.account.street || ""} oppId={opportunity.id} />
  );
}

function PropertySearchVersionButton({ opportunity }: { opportunity: UnderwritingOpportunity }) {
  const {
    data: { properties },
    loading,
  } = useDLE(PropertySearchEndpoint, {
    full_street_address_search: opportunity.account.street || "",
  });
  if (loading) return <div>loading...</div>;

  const dpid = properties && properties.length === 1 ? properties[0].dpid : undefined;

  if (dpid) {
    return <OpenVersionsButton dpid={dpid} opportunity={opportunity} />;
  } else {
    return (
      <Tooltip title="This opportunity is missing a dpid and the property could not be found. Search for it on the CMA page">
        <StartReportButton path={`/cma?oppId=${opportunity.id}`} testId="c2c-no-dpid" label="Find Property" />
      </Tooltip>
    );
  }
}
