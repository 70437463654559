import {
  actionColumn,
  BoundTextField,
  Button,
  Chip,
  ChipTypes,
  column,
  Css,
  Filters,
  GridDataRow,
  GridTable,
  IconButton,
  RowStyles,
  simpleHeader,
  SuperDrawerHeader,
  Tooltip,
  useModal,
  useSuperDrawer,
} from "@homebound/beam";
import { ObjectState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import React, { useMemo, useState } from "react";
import { useHistory } from "react-router";
import { useSuspense } from "@rest-hooks/react";
import { Icon } from "src/components/Icon";
import { GridLoadingBoundary } from "src/components/LoadingBoundary";
import { useComputed, useController } from "src/hooks";
import { Property } from "src/routes/cma/endpoints";
import { Lot, PropCoEndpoint } from "src/routes/cma/endpoints/PropCoEndpoint";
import {
  UnderwritingReport,
  UnderwritingReportVersionsEndpoint,
  UpdateUnderwritingReportVersionEndpoint,
  UpdateUnderwritingReportVersionInput,
} from "src/routes/cma/endpoints/reports";
import { ValuationStage } from "src/routes/cma/endpoints/reports/ValuationStage";
import { PropertyAddressHeader } from "src/routes/cma/steps/comparables/PropertyAddressHeader";
import { NewReportVersionModal } from "src/routes/reports/components/NewReportVersionModal";
import { PropertyValuationGraph } from "src/routes/reports/components/PropertyValuationGraph";
import { ReportVersionsFilter, useReportVersionsFilter } from "src/routes/reports/components/ReportVersionsFilter";
import {
  UnderwritingReportVersionInput,
  VersionFormState,
  versionsFormConfig,
} from "src/routes/reports/components/ReportVersionsForm";
import { beautifyMetro } from "src/routes/reports/ReportsPage";
import {
  createCmaEstimateUrl,
  createCmaHpoUrl,
  createCmaReadyPlanUrl,
  createCmaSubjectPropertyUrl,
} from "src/routes/routesDef";
import { formatMagnitude, formatReportCollaborators, Maybe, maybeStringToDateLabel } from "src/utils";
import { useFormState } from "src/utils/formState";

type HeaderRow = { kind: "header" };
type DataRow = { kind: "data"; data: ObjectState<UnderwritingReportVersionInput> };
type Row = HeaderRow | DataRow;

interface LoadReportVersionsTableProps {
  dpid: string;
  versionId?: Maybe<number>;
  propertyAddress: string;
}

interface ReportVersionsTableProps {
  propertyAddress: string;
  versions: UnderwritingReport[];
  latestVersionForLot: UnderwritingReport | undefined;
  c2cLot: Lot | undefined;
  versionId?: Maybe<number>;
}

interface ReportVersionsTableDrawerContentProps {
  property: Property;
  versionId?: Maybe<number>;
}

interface OpenVersionsDrawerButtonProps {
  property: Property;
  versionId?: Maybe<number>;
  size: "sm" | "md";
}

export function LoadReportVersionsTable({ dpid, versionId, propertyAddress }: LoadReportVersionsTableProps) {
  const { versions } = useSuspense(UnderwritingReportVersionsEndpoint, { dpid });

  const { c2cLot, latestVersionForLot } = getC2CReportEntities(versions);

  return (
    <>
      <ReportVersionsTable
        versions={versions}
        versionId={versionId}
        propertyAddress={propertyAddress}
        latestVersionForLot={latestVersionForLot}
        c2cLot={c2cLot}
      />
    </>
  );
}

/**
 * Returns the lot that is ready for clear to close and the latest report version with the same opportunity id as the lot
 * @param versions - all the reports for this property
 */
function getC2CReportEntities(versions: UnderwritingReport[]): {
  latestVersionForLot: UnderwritingReport | undefined;
  c2cLot: Lot | undefined;
} {
  // Find all the opportunity ids for this property
  // There will typically only be one opportunity id, but it's possible there could be more than one
  const opportunityIds = versions.map((v) => v.opportunity_id).filter((id): id is string => !!id);
  const uniqueOpportunityIds = [...new Set(opportunityIds)];

  let c2cLot: Lot | undefined = undefined;
  let latestVersionForLot: UnderwritingReport | undefined = undefined;

  const c2cLotOpportunityId = uniqueOpportunityIds.find((oppId: string) => {
    // query propco for each opportunity id to see if it's ready for clear to close
    const readyForClearToCloseLot = Getc2cLot(oppId);
    if (readyForClearToCloseLot) {
      c2cLot = readyForClearToCloseLot;
      return true;
    }
    return false;
  });

  // if there is a lot that is ready for clear to close
  if (c2cLot) {
    // find the most recently updated report with an opportunity matching that lot
    latestVersionForLot = versions
      .filter((v) => v.opportunity_id === c2cLotOpportunityId)
      .sort((a, b) => {
        // Prioritize 'Finalized' status
        if (a.status === "Finalized" && b.status !== "Finalized") {
          return -1;
        }
        if (b.status === "Finalized" && a.status !== "Finalized") {
          return 1;
        }

        return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
      })[0];
  }

  return { c2cLot, latestVersionForLot };
}

/**
 * Queries the propco app for a lot and returns the lot if it is ready for clear to close
 */
function Getc2cLot(opportunityId: string): Lot | undefined {
  const { lot } = useSuspense(PropCoEndpoint, { opportunityId });
  // TODO: show a message to let the user know that a lot couldn't be found for this opportunity id?
  // This could mean that the query didn't work or a lot doesn't exist for this opportunity id
  if (!lot) return undefined;

  if (lot.primeEstimateReadyForClearToCloseReport) {
    return lot;
  }
  return undefined;
}

export function ReportVersionsTable(props: ReportVersionsTableProps) {
  const { versions, versionId, propertyAddress, latestVersionForLot, c2cLot } = props;
  const { fetch } = useController();
  const { openModal, closeModal } = useModal();
  const filterDefs = useReportVersionsFilter();
  const [filter, setFilter] = useState<ReportVersionsFilter>({});
  const history = useHistory();
  const { closeDrawer } = useSuperDrawer();

  // We'll declare versions the simpler type to avoid some TS headaches related to ready_plans config props being a mile long
  const input = useComputed(() => ({ versions: versions as UnderwritingReportVersionInput[] }), [versions]);

  const versionFormState = useFormState({
    config: versionsFormConfig,
    init: { input },
  });

  function updateVersion({ dpid, versionId, changes }: UpdateUnderwritingReportVersionInput) {
    fetch(UpdateUnderwritingReportVersionEndpoint, { dpid, versionId, changes });
  }

  function goToReport(path: string) {
    history.push(path);
    closeDrawer();
  }

  const parentVersionId = versions.find((v) => v.id === versionId)?.parent_id;

  const columns = createColumns(parentVersionId, updateVersion, goToReport);
  const rows = createRows(versionFormState ?? [], filter);

  // check to see if a clear to close version exists for this property
  const hasClearToCloseVersion = useMemo(
    () => versions.some((v) => v.valuation_stage === ValuationStage.clear_to_close),
    [versions],
  );

  function openNewVersionModal({ isC2C }: { isC2C: boolean }) {
    openModal({
      size: "lg",
      content: (
        <NewReportVersionModal
          c2cLot={c2cLot}
          initialBaseVersionId={isC2C ? latestVersionForLot!.id : versionId}
          latestVersionForLotId={latestVersionForLot?.id}
          currentPageVersionId={versionId}
          propertyAddress={propertyAddress}
          valuation_stage={isC2C ? ValuationStage.clear_to_close : undefined}
          versions={versions}
          onSuccess={async (newReport, goToBPReadyPlanStep: boolean) => {
            const { dpid, id } = newReport;
            closeModal();
            if (goToBPReadyPlanStep) {
              // go to ready plan page and default to Blueprint ready plan selector
              goToReport(createCmaReadyPlanUrl(dpid, id!.toString(), goToBPReadyPlanStep));
            } else {
              // go to subject property page
              goToReport(createCmaSubjectPropertyUrl({ dpid, versionId: id!.toString() }));
            }
          }}
        />
      ),
    });
  }

  return (
    <>
      <div css={Css.mx1.my2.df.gap2.$} data-testid="versionTableButtons">
        <Filters<ReportVersionsFilter> filterDefs={filterDefs} filter={filter} onChange={setFilter} />
        <Button variant="primary" label="New" icon="plus" onClick={() => openNewVersionModal({ isC2C: false })} />
        <Button
          variant="primary"
          label="Create Clear to Close Report from BP Estimate"
          onClick={() => openNewVersionModal({ isC2C: true })}
          disabled={
            (hasClearToCloseVersion && "Clear to close report already exists") ||
            (!latestVersionForLot && "Final estimate is not ready, contact procurement")
          }
        />
      </div>
      {/* subtract chart height */}
      <div css={Css.w100.h("calc(100% - 370px)").$}>
        <GridTable
          stickyHeader
          activeRowId={`data_${versionId}`}
          as="virtual"
          columns={columns}
          rows={rows}
          rowStyles={createRowStyles(goToReport)}
          sorting={{ on: "client", initial: ["underwriting-date-column", "DESC"] }}
        />
      </div>
      <PropertyValuationGraph versions={versions} />
    </>
  );
}

// using a fn to create style to take advantage of onClick so drawer can close and navigate to report
function createRowStyles(onRowClick: (arg: string) => void): RowStyles<Row> {
  return {
    header: {},
    data: {
      onClick: ({
        data: {
          value: { dpid, status, id },
        },
      }) => {
        const path =
          status === "Finalized"
            ? createCmaEstimateUrl(dpid, id!.toString())
            : createCmaSubjectPropertyUrl({ dpid, versionId: id!.toString() });
        onRowClick(path);
      },
    },
  };
}

function createColumns(
  parentVersionId: Maybe<number>,
  updateVersion: (props: UpdateUnderwritingReportVersionInput) => void,
  goToReport: (path: string) => void,
) {
  return [
    column<Row>({
      id: "parent-marker",
      header: "Parent",
      clientSideSort: false,
      data: (row) => {
        if (parentVersionId === row.id.value) {
          return (
            <Tooltip title="This report is the direct parent of the current report">
              <Icon icon="projectItem" color="primary" />
            </Tooltip>
          );
        }
        return " ";
      },
      w: "75px",
    }),
    column<Row>({
      id: "version-name-column",
      header: "Version Name",
      clientSideSort: false,
      wrapAction: false,
      mw: "150px",
      data: (row) => {
        return (
          <Tooltip title={row.version_name.value} disabled={!row.version_name.value}>
            <BoundTextField
              field={row.version_name}
              placeholder={`Add version name`}
              label={`Version Name Entry Field For ${row.id.value}`}
              onBlur={async () =>
                updateVersion({
                  dpid: row.dpid.value,
                  versionId: row.id.value,
                  changes: {
                    version_name: row.version_name.value,
                  },
                })
              }
            />
          </Tooltip>
        );
      },
    }),
    column<Row>({
      id: "finalized-column",
      header: "Finalized",
      clientSideSort: true,
      data: (row) => (row.status.value === "Finalized" ? "Yes" : "No"),
      w: "75px",
    }),
    column<Row>({
      id: "activity-state-column",
      header: "Activity",
      align: "center",
      data: (row) => ({
        content: () => (
          <Observer>
            {() => (
              <div>
                <Chip
                  text={row.activity_state.value}
                  type={
                    row.activity_state.value === "Active"
                      ? ChipTypes.success
                      : row.activity_state.value === "Inactive"
                        ? ChipTypes.caution
                        : ChipTypes.neutral
                  }
                />
              </div>
            )}
          </Observer>
        ),
        value: row.activity_state.value,
      }),
      w: "120px",
    }),
    column<Row>({
      id: "valuation-stage-column",
      header: "Valuation Stage",
      data: (row) => row.valuation_stage.value,
      w: "160px",
    }),
    column<Row>({
      id: "underwriting-date-column",
      header: "Underwriting Date",
      data: (row) => {
        if (!row?.underwritten_at) return "N/A";
        return {
          content: () => maybeStringToDateLabel(row.underwritten_at.value),
          value: row.underwritten_at.value,
        };
      },
      w: "120px",
    }),
    column<Row>({
      id: "final-weighted-price-column",
      header: "Est. Price",
      data: (row) => {
        return {
          content: () => formatMagnitude(row.final_weighted_price.value),
          value: row.final_weighted_price.value,
        };
      },
      w: "100px",
    }),
    column<Row>({
      id: "appreciated-final-weighted-price-column",
      header: "Est. Price W/ Appreciation",
      data: (row) => {
        return {
          content: () => formatMagnitude(row.final_weighted_price_with_appreciation.value),
          value: row.final_weighted_price_with_appreciation.value,
        };
      },
      w: "100px",
    }),
    actionColumn<Row>({
      id: "hpo-column",
      header: "HPO",
      data: (row) => (
        <IconButton
          icon="document"
          onClick={() => goToReport(createCmaHpoUrl(row.dpid.value, row.id.value!.toString()))}
        />
      ),
      wrapAction: false,
      w: "80px",
    }),
    column<Row>({
      id: "editors-column",
      clientSideSort: false,
      header: "Editors",
      data: (row) => formatReportCollaborators(row.collaborators.value),
      w: "150px",
    }),
  ];
}

function createRows(versionFormState: VersionFormState, filter: ReportVersionsFilter): GridDataRow<Row>[] {
  // Collaborators filter client-side
  function filterRowCollabs(v: ObjectState<UnderwritingReportVersionInput>) {
    if (!filter.collaborator_id) return true;
    return v.collaborators.value.some((c) => filter.collaborator_id?.includes(c.id));
  }

  const versionRows = versionFormState.versions.rows.filter(filterRowCollabs).map((c) => c);

  return [
    simpleHeader,
    ...versionRows.map((c) => {
      return {
        name: c.id.value,
        kind: "data" as const,
        id: `${c.id.value}`,
        data: c,
      };
    }),
  ];
}

const ReportVersionsTableDrawerContent = ({ property, versionId }: ReportVersionsTableDrawerContentProps) => {
  return (
    <GridLoadingBoundary gridLoadingProps={{ rows: 5, columns: 4 }}>
      <SuperDrawerHeader hideControls>
        {property && (
          <div css={Css.df.fdc.jcsb.$}>
            <PropertyAddressHeader property={property} size="lg" />
            <div css={Css.sm.mt1.$}>
              <b>Metro:</b> <i>{beautifyMetro(property.metro).full}</i>
            </div>
          </div>
        )}
      </SuperDrawerHeader>
      <LoadReportVersionsTable
        dpid={property!.dpid!}
        versionId={versionId}
        propertyAddress={property!.full_street_address}
      />
    </GridLoadingBoundary>
  );
};

export function OpenVersionsDrawerButton({ property, versionId, size }: OpenVersionsDrawerButtonProps) {
  const { openInDrawer } = useSuperDrawer();

  return (
    <Button
      label=""
      onClick={() => {
        openInDrawer({
          content: <ReportVersionsTableDrawerContent property={property} versionId={versionId} />,
        });
      }}
      icon="templates"
      size={size}
      tooltip={versionId && "View all versions"}
      aria-label="View all versions"
      variant="tertiary"
    />
  );
}
