import { CheckboxGroup, Css, RichTextField, TabsWithContent } from "@homebound/beam";
import { CheckboxGroupProps } from "@homebound/beam/dist/inputs/CheckboxGroup";
import React, { useState } from "react";
import { useParams } from "react-router";
import { useSuspense } from "@rest-hooks/react";
import { ConfidenceInterval } from "src/components/confidenceIntervalChart";
import { FormattedPercentage } from "src/components/FormattedPercentage";
import { ReadyPlanProgramData } from "src/components/ReadyPlanProgramData";
import { UWLabel } from "src/components/UWLabel";
import { Property, PropertyComp, PropertyEndpoint } from "src/routes/cma/endpoints";
import {
  ReadyPlan,
  UnderwritingReport,
  UnderwritingReportEndpoint,
} from "src/routes/cma/endpoints/reports/UnderwritingReportEndpoint";
import { MapsApiEndpoint } from "src/routes/maps/MapsApiEndpoint";
import { hpoTooltips } from "src/utils";
import { getReadyPlanDisplayName } from "src/utils/mappers";
import { Legend } from "../comparables/Legend";
import { HpoCompTable } from "./HpoCompTable";
import { PropertyWidget, SubjectPropertyWidget } from "./PropertyWidget";
import { StaticMap } from "./StaticMap";

export function HpoPage() {
  const { dpid, versionId } = useParams<{ dpid: string; versionId: string }>();
  const { property } = useSuspense(PropertyEndpoint, { dpid });
  const { report } = useSuspense(UnderwritingReportEndpoint, { dpid, versionId });
  useSuspense(MapsApiEndpoint);

  const [selectedTab, setSelectedTab] = useState(getReadyPlanDisplayName(report.ready_plans![0]));
  const [visibleExtraInfo, setVisibleExtraInfo] = useState(["confidence"]);

  if (!report.ready_plans) return <div>No ready plans found for this report</div>;
  if (!report.ready_plans[0].comps) return <div>No comps found for this report</div>;

  const propertyAdjustment = getReadyPlanAdjustment(report.ready_plans![0]);

  return (
    <>
      <div css={Css.my2.gap4.base.df.$}>
        <UWLabel
          tooltip={hpoTooltips.grossExpectedSalePrice}
          label="Gross Expected Sale Price: "
          value={report.final_weighted_price}
        />
        <UWLabel
          label="$/SF: "
          value={
            report?.final_weighted_price && report.ready_plans.every((rp) => rp.sellable_sqft > 0)
              ? report?.final_weighted_price / report.ready_plans.reduce((acc, rp) => acc + rp.sellable_sqft, 0)
              : null
          }
        />

        {report.ready_plans.length === 1 && (
          <UWLabel label="Ready Plan: " value={<span css={Css.ttc.$}>{selectedTab}</span>} />
        )}
        {/* TODO: Note: We expect this HPA data to be placed elsewhere or brought back */}
        {/*<div>*/}
        {/*  <span css={Css.baseBd.$}>Sale Price w/ HPA: </span>*/}
        {/*  <FormattedPrice value={report.final_weighted_price_with_appreciation} />*/}
        {/*</div>*/}
        {/* this is only shown here when there is a single ready plan and the adjustment is > 0 */}
        {report.ready_plans.length === 1 && propertyAdjustment !== 0 && (
          <UWLabel
            label="Property Adjustment: "
            value={<FormattedPercentage percentage={propertyAdjustment / 100} />}
          />
        )}
        {report.ready_plans.length === 1 && (
          <ExtraInfoCheckboxGroup onChange={setVisibleExtraInfo} values={visibleExtraInfo} />
        )}
      </div>
      {report.ready_plans.length > 1 ? (
        <TabsWithContent
          tabs={createTabs(report.ready_plans, property, report)}
          onChange={(t) => setSelectedTab(t)}
          selected={selectedTab}
        />
      ) : (
        <>
          {visibleExtraInfo.includes("confidence") && (
            <ConfidenceInterval
              rawStreetAddress={property.full_street_address}
              readyPlan={report.ready_plans[0]}
              reportStatus={report.status}
              finalizedDate={report.underwritten_at}
              activityState={report.activity_state}
              lastUpdated={report.updated_at}
            />
          )}
          {visibleExtraInfo.includes("programData") && (
            <ReadyPlanProgramData readyPlans={report.ready_plans} showTitle={false} />
          )}
          <HpoMainContent
            legendToTop={visibleExtraInfo.includes("confidence")}
            property={property}
            comps={report.ready_plans[0].comps!}
            report={report}
            readyPlan={report.ready_plans[0]}
          />
        </>
      )}
    </>
  );
}

function createTabs(readyPlans: ReadyPlan[], property: Property, report: UnderwritingReport) {
  return readyPlans.map((rp) => {
    return {
      name: getReadyPlanDisplayName(rp),
      value: getReadyPlanDisplayName(rp),
      render: () => <HpoReadyPlanSummary property={property} report={report} readyPlan={rp} />,
    };
  });
}

interface HpoReadyPlanSummaryProps {
  property: Property;
  readyPlan: ReadyPlan;
  report: UnderwritingReport;
}

function HpoReadyPlanSummary(props: HpoReadyPlanSummaryProps) {
  const [visibleExtraInfo, setVisibleExtraInfo] = useState(["confidence"]);
  const { property, readyPlan, report } = props;
  const comps = readyPlan.comps ?? [];
  const propertyAdjustment = getReadyPlanAdjustment(readyPlan);
  const labelSuffix =
    readyPlan.property_type === "main_adu" || readyPlan.property_type === "adu"
      ? ` (${getReadyPlanDisplayName(readyPlan)})`
      : "";

  return (
    <>
      <div css={Css.mb2.mr2.sm.gap2.df.$}>
        <UWLabel
          labelTypography="smBd"
          tooltip={hpoTooltips.grossExpectedSalePriceTruncated}
          label={`Expected Sale Price${labelSuffix}: `}
          value={readyPlan.final_weighted_price}
        />
        <UWLabel
          labelTypography="smBd"
          label={`$/SF${labelSuffix}: `}
          value={readyPlan.final_weighted_price / readyPlan.sellable_sqft}
        />
        {propertyAdjustment !== 0 && (
          <UWLabel
            labelTypography="smBd"
            label="Property Adjustment: "
            value={<FormattedPercentage percentage={propertyAdjustment / 100} />}
          />
        )}
        {readyPlan.copies > 1 && (
          <UWLabel labelTypography="smBd" label="Copies: " value={<span>{readyPlan.copies}</span>} />
        )}
        <ExtraInfoCheckboxGroup onChange={setVisibleExtraInfo} values={visibleExtraInfo} />
      </div>
      {visibleExtraInfo.includes("confidence") && (
        <ConfidenceInterval
          reportStatus={report.status}
          finalizedDate={report.underwritten_at}
          rawStreetAddress={property.full_street_address}
          readyPlan={readyPlan}
          activityState={report.activity_state}
          lastUpdated={report.updated_at}
        />
      )}
      {visibleExtraInfo.includes("programData") && <ReadyPlanProgramData readyPlans={[readyPlan]} showTitle={false} />}
      <HpoMainContent
        legendToTop={visibleExtraInfo.includes("confidence")}
        property={property}
        comps={comps}
        report={report}
        readyPlan={readyPlan}
      />
    </>
  );
}

interface HpoMainContentProps {
  property: Property;
  comps: PropertyComp[];
  report: UnderwritingReport;
  readyPlan: ReadyPlan;
  legendToTop?: boolean;
}

function HpoMainContent(props: HpoMainContentProps) {
  const { property, report, readyPlan, legendToTop } = props;

  const comps = props.comps.sort((a, b) => b.weight! - a.weight!) ?? [];
  const compsWithLocations = comps.filter((c) => c.latitude !== null && c.longitude !== null);
  const numColumns = Math.ceil((comps.length + 1) / 2);

  return (
    <>
      <div css={Css.df.hPx(700).$}>
        <div css={Css.df.fdc.w100.br4.ba.bcGray100.mwPx(500).$}>
          {legendToTop && <Legend content={propertyStatusMarkers} />}
          <div css={Css.df.hPx(660).$}>
            <StaticMap property={property} comps={compsWithLocations} compSearchGeometry={report.comp_search_polygon} />
          </div>
          {!legendToTop && <Legend content={propertyStatusMarkers} />}
        </div>
        <div css={Css.dg.gtc(`repeat(${numColumns}, 1fr)`).add("gridAutoFlow", "row").gap1.hPx(700).px4.$}>
          <SubjectPropertyWidget property={property} readyPlan={readyPlan} />
          {comps.map((comp: PropertyComp) => (
            <PropertyWidget key={comp.dpid_of_neighbor} property={comp} weight={comp.weight} />
          ))}
        </div>
      </div>
      <div css={Css.oxs.my2.$}>
        <HpoCompTable comps={comps} readyPlan={readyPlan} property={property} />
      </div>
      {report?.offer_outcome_notes && (
        <RichTextField value={report.offer_outcome_notes} onChange={() => {}} label="Valuation Notes" readOnly />
      )}
    </>
  );
}

/**
 * Returns the property percent adjustment for a ready plan, or 0 if there is none.
 * @param {ReadyPlan} readyPlan
 * @returns {number}
 */
function getReadyPlanAdjustment(readyPlan: ReadyPlan): number {
  return (
    (readyPlan.property_adjustments && readyPlan.property_adjustments[0] && readyPlan.property_adjustments[0].value) ??
    0
  );
}

// FIXME: Tech debt single source of truth on map marker colors
const propertyStatusMarkers: Record<string, string> = {
  blue: "Subject Property",
  green: "Active",
  gold: "Pending",
  red: "Sold",
  grey: "Unknown Status",
  homebound: "HB Comp",
  mlRec: "ML Comp",
};

const VisibleExtraInfoOptions = [
  {
    label: "Confidence Intervals",
    value: "confidence",
  },
  {
    label: "Ready Plan Program Data",
    value: "programData",
  },
];

// Wrapped checkboxGroup for styling
function ExtraInfoCheckboxGroup(props: Omit<CheckboxGroupProps, "options" | "label" | "columns">) {
  return (
    <div css={Css.addIn("> div:first-of-type", Css.df.aic.gap1.$).$}>
      <CheckboxGroup columns={2} label="Show:" options={VisibleExtraInfoOptions} {...props} />
    </div>
  );
}
