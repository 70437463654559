import { ListFieldState } from "@homebound/form-state";
import { Feature } from "geojson";
import { FeatureGroup as LeafletFeatureGroup } from "leaflet";
import { useRef } from "react";
import { FeatureGroup } from "react-leaflet";
import { SavePolygonInput } from "src/routes/admin/polygons/PolygonEndpoints";
import { UwLeafletPolygon, PolygonFeatureView } from "./PolygonFeatureView";
import { UwEditControl } from "../UwEditControl";

interface PolygonEditorProps {
  polygonsFormState: ListFieldState<SavePolygonInput>;
  onPolygonFeatureUpdated: (feature: Feature, updateType: PolygonUpdateType) => void;
  saveForm: () => void;
}

export type PolygonUpdateType = "Create" | "Edit" | "Delete";

// Note: A key is required on the surrounding MapContainer to force a re-render when the compFilterPolygon changes.
// Otherwise multiple copies of the polygons will be drawn on the map when editing or drawing additional polygons.
export function PolygonEditor(props: PolygonEditorProps) {
  const { polygonsFormState, onPolygonFeatureUpdated, saveForm } = props;
  const fgRef = useRef<LeafletFeatureGroup>(null);

  function onPolygonChange(layer: UwLeafletPolygon, updateType: PolygonUpdateType) {
    const layerGeoJson = layer.toGeoJSON();
    layerGeoJson.properties.id = layer.uwPolygonId;
    onPolygonFeatureUpdated(layerGeoJson, updateType);
  }

  return (
    <FeatureGroup ref={fgRef}>
      <UwEditControl onPolygonChange={onPolygonChange} />
      {polygonsFormState.rows.map((p, i) => (
        <PolygonFeatureView
          key={p.value.id ?? i}
          polygonFormState={p}
          onPolygonChange={onPolygonChange}
          saveForm={saveForm}
        />
      ))}
    </FeatureGroup>
  );
}
