import { createContext, ReactPortal, useContext } from "react";
import { createPortal } from "react-dom";
interface ConfigSelectionContextProps {
  configPreviewEl: HTMLDivElement | null;
}

export const ConfigSelectionContext = createContext<ConfigSelectionContextProps>({
  configPreviewEl: null,
});

export function ConfigPreview({ children }: { children: JSX.Element }): ReactPortal | JSX.Element {
  const { configPreviewEl } = useConfigPreviewContext();
  return configPreviewEl ? createPortal(children, configPreviewEl) : children;
}

export function useConfigPreviewContext() {
  return useContext(ConfigSelectionContext);
}
