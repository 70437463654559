import { FilterDefs, multiFilter } from "@homebound/beam";
import { useMemo } from "react";
import { useSuspense } from "@rest-hooks/react";
import { UnderwritingReportMetrosEndpoint } from "../cma/endpoints/reports";

export type ReviewsFilter = {
  metro?: string[];
};

export function useReviewsFilter() {
  const metrosResult = useSuspense(UnderwritingReportMetrosEndpoint);

  const filterDefs: FilterDefs<ReviewsFilter> = useMemo(() => {
    const metro = multiFilter({
      label: "Metro",
      options: metrosResult.metros ?? [],
      getOptionValue: (option) => option.metro,
      getOptionLabel: (option) => option.metro,
    });

    return { metro };
  }, [metrosResult]);

  return filterDefs;
}
