import { Css } from "@homebound/beam";
import { ActivityState } from "src/routes/cma/endpoints/reports/ActivityState";
import { Maybe, parseMaybeStringDate, parseMaybeStringISOOffsetDate } from "src/utils";

interface VersionWarningBannerProps {
  lastUpdated: string;
  underwrittenAt?: Maybe<string>;
  activityState?: ActivityState;
}

// Not using date util formatter because we want to format each a little differently
export function VersionWarningBanner({ lastUpdated, underwrittenAt, activityState }: VersionWarningBannerProps) {
  let displayedDate: string;
  if (underwrittenAt) {
    const parsed = parseMaybeStringDate(underwrittenAt);
    const monthDay = parsed!.toDateString();
    displayedDate = monthDay;
  } else {
    const parsed = parseMaybeStringISOOffsetDate(lastUpdated);
    const parsedDate = new Date(parsed!);
    const [monthDay, time] = [parsedDate.toDateString(), parsedDate.toLocaleTimeString()];
    displayedDate = `${monthDay}, ${time}`;
  }

  return (
    <div
      css={
        Css.ba.br4.bcGray300.fs0.w100.hPx(48).my2.pl3.df.aic.if(activityState === ActivityState.inactive).bgYellow200
          .else.bgGray200.$
      }
    >
      Viewing {activityState} version from {displayedDate}. Switch to an active version to make changes.
    </div>
  );
}
