import { Entity } from "@rest-hooks/rest";
import { Endpoint } from "@rest-hooks/endpoint";
import { api_url, auth_headers } from "src/context";
import { ReadyPlanOption } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlanOptionsEndpoint";
import { BlueprintProgramData } from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlansEndpoint";

export interface BlueprintReadyPlanOptionDelta {
  softCostInCents: number;
  directHardCostInCents: number;
  indirectHardCostInCents: number;
  totalCostInCents: number;
  readyPlanOption: Pick<ReadyPlanOption, "id" | "name" | "type" | "code">;
}

export interface BlueprintReadyPlanCostData {
  softCostInCents: number;
  directHardCostInCents: number;
  indirectHardCostInCents: number;
  totalCostInCents: number;
  configurationErrors?: string[];
  deltas: BlueprintReadyPlanOptionDelta[];
}

export class BlueprintReadyPlanComputeData extends Entity {
  readonly computeProgramData: BlueprintProgramData | undefined = undefined;
  readonly computeReadyPlanCost: BlueprintReadyPlanCostData | undefined = undefined;
  readonly readyPlan: { id: string; name: string } | undefined = undefined;
  readonly hashKey: number = 0;

  pk() {
    return `${this.hashKey}`;
  }

  static key = "BlueprintReadyPlanComputeData";
}

export interface BlueprintReadyPlanComputeDataEndpointOptions {
  devId: string;
  bp_ready_plan_id: string;
  options: string[];
}

export const BlueprintReadyPlanComputeDataEndpoint = new Endpoint(
  async ({ devId, bp_ready_plan_id, options }: BlueprintReadyPlanComputeDataEndpointOptions) => {
    const headers = await auth_headers();

    const res = await fetch(api_url(`/v2/ready_plans/developments/${devId}/ready_plans/${bp_ready_plan_id}/compute`), {
      method: "POST",
      headers: {
        "content-type": "application/json",
        ...headers,
      },
      body: JSON.stringify({ options }),
    });
    return await res.json();
  },
  {
    schema: BlueprintReadyPlanComputeData,
    name: "BlueprintReadyPlanComputeDataEndpoint",
  },
);
