import { ObjectConfig, ObjectState, required } from "@homebound/form-state";
import { convertDateToString, parseMaybeStringDate } from "src/utils";
import { Maybe } from "src/utils/types";
import { Property } from "../../endpoints";
import { ReferenceProperty, UnderwritingReport } from "../../endpoints/reports";
import { UnderwritingReportInput } from "../../UnderwritingReportForm";

export interface ReferencePropertyFormProps {
  report: UnderwritingReport;
  property: Property;
  onCancel: () => void;
  onSave: () => void;
}

export interface ReferencePropertyInput {
  dpid: string;
  metro: string;
  property_type_simple?: Maybe<string>;
  buildable_sqft?: Maybe<number>;
  lot_size?: Maybe<number>;
  zoning?: Maybe<string>;
  adu_elig?: Maybe<boolean>;
  lot_frontage_feet?: Maybe<number>;
  lot_depth_feet?: Maybe<number>;
  subject_property_market_value?: Maybe<number>;
  site_acquisition_bid_recommendation?: Maybe<number>;
  mls_url?: Maybe<string>;
  mls_date_listed?: Maybe<string>;
  is_in_floodplain?: Maybe<boolean>;
}

export interface ReferencePropertyFormInput extends Omit<ReferencePropertyInput, "mls_date_listed"> {
  mls_date_listed?: Maybe<Date>;
  mls_list_price?: Maybe<number>;
  mls_listing_description?: Maybe<string>;
}
export type ReferencePropertyFormState = ObjectState<ReferencePropertyFormInput>;

export const referencePropertyFormConfig: ObjectConfig<ReferencePropertyFormInput> = {
  dpid: { type: "value", readOnly: true },
  metro: { type: "value", readOnly: true },
  property_type_simple: { type: "value" },
  buildable_sqft: { type: "value", rules: [required] },
  lot_size: { type: "value", rules: [required] },
  zoning: { type: "value" },
  adu_elig: { type: "value" },
  lot_frontage_feet: { type: "value" },
  lot_depth_feet: { type: "value" },
  is_in_floodplain: { type: "value" },
  mls_url: { type: "value" },
  mls_date_listed: { type: "value" },
  mls_list_price: { type: "value" },
  mls_listing_description: { type: "value" },
};

interface ReferencePropertyFormInputData {
  property: Property;
  report: UnderwritingReport;
}

export function mapToReferencePropertyForm({
  property,
  report,
}: ReferencePropertyFormInputData): ReferencePropertyFormInput {
  const ref = report?.reference_property;

  return {
    dpid: property.dpid,
    metro: property.metro,
    buildable_sqft: ref?.buildable_sqft ?? property.buildable_sqft!,
    lot_size: ref?.lot_size ?? property.lot_size,
    zoning: ref?.zoning ?? property.zoning,
    adu_elig: ref?.adu_elig ?? property.adu_elig,
    lot_depth_feet: ref?.lot_depth_feet ?? property.lot_depth_feet,
    lot_frontage_feet: ref?.lot_frontage_feet ?? property.lot_frontage_feet,
    property_type_simple: ref?.property_type_simple ?? property.property_type_simple,
    mls_url: ref?.mls_url,
    mls_date_listed: getListDate(ref, property),
    mls_list_price: property.mls_list_price,
    mls_listing_description: property.mls_listing_description,
    is_in_floodplain: ref?.is_in_floodplain,
  };
}

function getListDate(ref?: ReferenceProperty, property?: Property) {
  const mlsListDate = ref?.mls_date_listed || property?.mls_list_date;
  return mlsListDate ? parseMaybeStringDate(mlsListDate) : undefined;
}

export function mapReferencePropertyFormToSaveReportInput(
  formState: ReferencePropertyFormState,
): UnderwritingReportInput {
  const {
    zoning,
    adu_elig,
    dpid,
    lot_size,
    buildable_sqft,
    lot_frontage_feet,
    lot_depth_feet,
    property_type_simple,
    mls_url,
    is_in_floodplain,
  } = formState.value;

  const mls_date_listed = formState.mls_date_listed.value
    ? convertDateToString(formState.mls_date_listed.value)
    : undefined;

  return {
    dpid,
    reference_property: {
      lot_size,
      buildable_sqft,
      zoning,
      adu_elig,
      lot_frontage_feet,
      lot_depth_feet,
      property_type_simple,
      mls_url,
      mls_date_listed,
      is_in_floodplain,
    },
  };
}
