import { Endpoint } from "@rest-hooks/endpoint";
import { api_url, auth_headers } from "src/context";
import { maybePatchComps, UnderwritingReport } from "./UnderwritingReportEndpoint";

export interface RecalcReadyPlanCompWeightsEndpointParams {
  dpid: string;
  versionId: string;
  uwReadyPlanId: number;
}

export const RecalcReadyPlanCompWeightsEndpoint = new Endpoint(
  async ({ dpid, versionId, uwReadyPlanId }: RecalcReadyPlanCompWeightsEndpointParams) => {
    const headers = {
      "content-type": "application/json",
      ...(await auth_headers()),
    };

    const res = await fetch(
      api_url(`/v1/reports/${dpid}/versions/${versionId}/ready_plans/${uwReadyPlanId}/recalc_weights`),
      {
        headers,
        method: "POST",
      },
    );
    return maybePatchComps(await res.json());
  },
  {
    schema: { report: UnderwritingReport },
    key: ({ dpid, versionId, uwReadyPlanId }) => {
      return `/v1/reports/${dpid}/versions/${versionId}/ready_plans/${uwReadyPlanId}/recalc_weights`;
    },
    name: "RecalcReadyPlanCompWeightsEndpoint",
  },
);
