import { Button, Css } from "@homebound/beam";
import { ReactNode, useEffect, useRef, useState } from "react";

export interface CollapsibleGroupProps {
  label?: string;
  initOpen?: boolean;
  children: ReactNode;
}

export function CollapsibleGroup({ children, label, initOpen = false }: CollapsibleGroupProps) {
  const collapseRef = useRef<HTMLDivElement>(null);
  const [showForm, setShowForm] = useState(initOpen);

  const prefix = showForm ? "Hide" : "Show";

  useEffect(() => {
    showForm && collapseRef.current?.scrollIntoView?.({ behavior: "auto", block: "center" });
  }, [showForm]);

  return (
    <div>
      <Button
        variant="text"
        size="sm"
        icon={showForm ? "chevronDown" : "chevronRight"}
        onClick={() => setShowForm(!showForm)}
        label={`${prefix} ${label ?? ""}`}
      />
      <div ref={collapseRef} css={Css.pt2.$}>
        {showForm && children}
      </div>
    </div>
  );
}
