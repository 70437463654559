import { Icon, Palette } from "@homebound/beam";
import { ScatterPlotNodeProps } from "@nivo/scatterplot/dist/types/types";
import { animated } from "@react-spring/web";
import { MouseEvent, useCallback, useMemo } from "react";
import { houseSvg } from "src/routes/maps/mapIcons";
import { ConfidenceDatum } from "./";

// Slightly modified version of default scatter plot node
// Wraps standard circle svg with an additional text into a <g> tag with events attached to g
// minor styling
// https://github.com/plouc/nivo/blob/2671ca4df614190f597bb63030bb794d3106c36e/packages/scatterplot/src/Nodes.tsx#L43
export function ConfidenceIntervalNode({
  node,
  style,
  blendMode,
  isInteractive,
  onMouseEnter,
  onMouseMove,
  onMouseLeave,
  onClick,
}: ScatterPlotNodeProps<ConfidenceDatum>) {
  const handleMouseEnter = useCallback((event: MouseEvent) => onMouseEnter?.(node, event), [node, onMouseEnter]);
  const handleMouseMove = useCallback((event: MouseEvent) => onMouseMove?.(node, event), [node, onMouseMove]);
  const handleMouseLeave = useCallback((event: MouseEvent) => onMouseLeave?.(node, event), [node, onMouseLeave]);
  const handleClick = useCallback((event: MouseEvent) => onClick?.(node, event), [node, onClick]);

  const useHomeIcon = useMemo(() => node.data.isHbComp, [node.data.isHbComp]);
  const useMlIcon = useMemo(() => node.data.isMlComp, [node.data.isMlComp]);
  const inStackedView = useMemo(() => node.data.chartIsStacked, [node.data.chartIsStacked]);

  return (
    <animated.g
      onMouseEnter={isInteractive ? handleMouseEnter : undefined}
      onMouseMove={isInteractive ? handleMouseMove : undefined}
      onMouseLeave={isInteractive ? handleMouseLeave : undefined}
      onClick={isInteractive ? handleClick : undefined}
    >
      {useHomeIcon ? (
        <g transform={`translate(${node.x - 20}, ${inStackedView ? -2 : 5})`}>{houseSvg(node.data.color, 40)}</g>
      ) : useMlIcon ? (
        <g transform={`translate(${node.x - 24}, ${inStackedView ? -6 : 5})`}>
          <Icon icon="starFilled" inc={6} color={node.data.color} />
        </g>
      ) : (
        <animated.circle
          cx={style.x}
          cy={style.y}
          r={style.size.to((size: number) => size / 2)}
          fill={node.data.color || style.color}
          stroke={Palette.Gray700}
          style={{ mixBlendMode: blendMode }}
        />
      )}
      <animated.text fontSize={12} dy={useHomeIcon ? "-.2em" : ".3em"} textAnchor={"middle"} x={style.x} y={style.y}>
        {node.data.weight || ""}
      </animated.text>
    </animated.g>
  );
}
