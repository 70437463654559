import { Button, Css, Filters } from "@homebound/beam";
import { useSuspense } from "@rest-hooks/react";
import { LatLngTuple } from "leaflet";
import { useMemo, useState } from "react";
import { MapContainer } from "react-leaflet";
import { useHistory } from "react-router-dom";
import { LoadingBoundary } from "src/components/LoadingBoundary";
import { PageHeader, PageHeaderActions } from "src/components/PageHeader";
import { useDocumentTitle } from "src/hooks/useDocumentTitle";
import { MapTileLayer } from "src/routes/maps/MapBaseLayers";
import { getMapBoundsFromCoordsOrMetro } from "src/routes/maps/mapUtils";
import { beautifyMetro } from "src/routes/reports/ReportsPage";
import { createPolygonUrl } from "src/routes/routesDef";
import {
  PolygonCollectionFilter,
  getFilteredPolygonCollections,
  usePolygonCollectionFilter,
} from "./PolygonCollectionFilter";
import {
  PolygonListEndpoint,
  SavePolygonInput,
  UwPolygonFeature,
  UwPolygonFeatureCollection,
} from "./PolygonEndpoints";
import { SimplePolygonFeatureView } from "./components/SimplePolygonFeatureView";

export function PolygonsListPage() {
  useDocumentTitle("Polygons");

  return (
    <div data-testid="polygonsListPage">
      <PageHeader title="Polygon Collections" />
      <LoadingBoundary useProgressBar>
        <LoadPolygonCollections />
      </LoadingBoundary>
    </div>
  );
}

export function LoadPolygonCollections() {
  const history = useHistory();
  const { feature_collections } = useSuspense(PolygonListEndpoint);

  const filterDefs = usePolygonCollectionFilter();
  const [filter, setFilter] = useState<PolygonCollectionFilter>({ hideInactive: true });

  function onFilterChange(filter: PolygonCollectionFilter) {
    setFilter(filter);
  }

  const filteredCollections = useMemo(
    () => feature_collections && getFilteredPolygonCollections(feature_collections, filter),
    [filter, feature_collections],
  );

  return (
    <div css={Css.dg.gap2.$}>
      <PageHeaderActions>
        <Button label="Create Polygon" onClick={() => history.push(createPolygonUrl("new"))} />
      </PageHeaderActions>
      <Filters<PolygonCollectionFilter> filter={filter} filterDefs={filterDefs} onChange={onFilterChange} />
      <div css={Css.dg.gap2.gtc("repeat(auto-fit, 300px)").$}>
        {filteredCollections?.map((pg) => <PolygonPreview collection={pg} key={pg.id} />)}
      </div>
    </div>
  );
}

type PolygonPreviewProps = {
  collection: UwPolygonFeatureCollection;
};

function PolygonPreview({ collection }: PolygonPreviewProps) {
  const history = useHistory();
  const { properties, features } = collection;

  const [latitudeCenter, longitudeCenter, bounds] = useMemo(() => {
    const flatCoords = features.map((p: UwPolygonFeature) => p.geometry.coordinates[0]).flat() as LatLngTuple[];
    return getMapBoundsFromCoordsOrMetro(flatCoords, collection.properties.metro);
  }, [features, collection.properties.metro]);

  return (
    <div
      data-testid={`polygonPreview`}
      onClick={() => history.push(createPolygonUrl(collection.id.toLocaleString()))}
      css={Css.cursorPointer.$}
    >
      <div css={Css.baseBd.$}>{properties.name}</div>
      <div>{beautifyMetro(properties.metro).full}</div>
      <MapContainer
        key={collection.id}
        css={Css.hPx(280).$}
        center={[latitudeCenter, longitudeCenter]}
        scrollWheelZoom={false}
        bounds={bounds}
        boundsOptions={{ padding: [10, 10] }}
        zoom={bounds ? undefined : 10}
        zoomControl={false}
        dragging={false}
        doubleClickZoom={false}
        preferCanvas={true}
      >
        <MapTileLayer isActive={properties.active} />
        {features.map((feature: UwPolygonFeature) => {
          const uwPolygon: SavePolygonInput = {
            id: feature.id,
            active: feature.properties.active,
            name: feature.properties.name,
            type: feature.properties.type,
            color: feature.properties.color,
            geometry: feature.geometry,
          };
          return <SimplePolygonFeatureView key={feature.id} searchGeometry={uwPolygon.geometry} />;
        })}
      </MapContainer>
    </div>
  );
}
