import { Button, Css, Icon, useModal, useTestIds } from "@homebound/beam";
import { useEffect, useState } from "react";
import { ReadyPlanOutdatedModal } from "src/components/outdatedReadyPlanModal/ReadyPlanOutdatedModal";
import { UnderwritingReport } from "src/routes/cma/endpoints/reports";
import {
  checkBlueprintRPTemplateVersion,
  CheckRPTemplateVersionResponse,
} from "src/routes/cma/steps/ready-plan/v2/endpoints/BlueprintReadyPlansEndpoint";
import { ValuationStage } from "src/routes/cma/endpoints/reports/ValuationStage";

interface ReadyPlanOutdatedBannerProps {
  report: UnderwritingReport;
}

export function ReadyPlanOutdatedBanner(props: ReadyPlanOutdatedBannerProps) {
  const [bpVersionCheckRes, setBpVersionCheckRes] = useState<CheckRPTemplateVersionResponse | null>(null);
  const { openModal } = useModal();
  const tid = useTestIds(props, "readyPlanOutdatedBanner");
  const { report } = props;

  useEffect(() => {
    const checkBpTemplateVersion = async () => {
      // Only UW reports are dependent on being in sync with BP prior to finalization
      if (report?.valuation_stage === ValuationStage.underwriting) {
        const data = await checkBlueprintRPTemplateVersion({
          rpTemplateId: report?.ready_plans![0].bp_ready_plan_id!,
          rpTemplateVersion: report?.ready_plans![0].bp_ready_plan_template_version!,
        });
        setBpVersionCheckRes(data);
      }
    };

    checkBpTemplateVersion();
    // We want one, cache-free, check on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!bpVersionCheckRes || bpVersionCheckRes?.isLatestVersion) {
    return null;
  }

  return (
    <div
      {...tid}
      css={
        Css.fs0.ba.br4.bcGray300.w100.hPx(48).my2.px3.df.aic.jcsb.if(!!bpVersionCheckRes.currentIncrement).bgYellow200
          .else.bgRed200.$
      }
    >
      <div css={Css.df.aic.$}>
        <Icon icon={!!bpVersionCheckRes.currentIncrement ? "outlineFlag" : "error"} />
        {!!bpVersionCheckRes.currentIncrement
          ? "This report's configured ready plan is out of sync with its BluePrint template"
          : "We're unable to confirm that this ready plan is in sync with BluePrint because its template is missing."}
      </div>
      <div css={Css.df.gap2.$}>
        <Button
          onClick={() =>
            openModal({
              content: <ReadyPlanOutdatedModal report={report} checkResponse={bpVersionCheckRes} />,
              size: "lg",
            })
          }
          label={"Update"}
        />
      </div>
    </div>
  );
}
