import { Property } from "src/routes/cma/endpoints";
import { UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { Tooltip } from "@homebound/beam";
import { FORMAT_BASIC_DATE, Maybe } from "src/utils";
import { EstimateSection, EstStatContainer, EstSingleStat, EstSingleChildStat, LandBasisBuyBox } from "./";
import React from "react";
import { ValuationStage } from "src/routes/cma/endpoints/reports/ValuationStage";

export function AcquisitionDetailsSection({ report, property }: { report: UnderwritingReport; property: Property }) {
  const isC2C = report.valuation_stage === ValuationStage.clear_to_close;

  const { opportunity, costs_and_metrics } = report;
  const { origin, close_date, zestimate, asking_price } = opportunity ?? {};

  function AskingOrListPrice() {
    if (isC2C && origin === "iBuyer" && asking_price) {
      return (
        <Tooltip title="Asking price is updated when changed in Salesforce">
          <EstSingleStat testId="activeAskingPrice" title="Asking Price" value={asking_price} type="money" />;
        </Tooltip>
      );
    } else if (property.mls_status === "ACT" && property.mls_list_price) {
      return (
        <EstSingleStat testId="activeListingPrice" title="List Price" value={property.mls_list_price} type="money" />
      );
    } else {
      return null;
    }
  }

  return (
    <EstimateSection title="Acquisition Details" headerColor="blue" forceOpen>
      <div>
        <EstSingleStat title="Origin" value={origin} />
        <AskingOrListPrice />
        <Tooltip title={"HB AVM"}>
          <EstStatContainer title="Current Market Value Estimate" value={property?.homebound_avm ?? "Unk"} type="money">
            {isC2C && zestimate && (
              <Tooltip title="Zestimate is updated when changed in Salesforce">
                <EstSingleChildStat type="money" value={zestimate} title={"Zestimate:"} />
              </Tooltip>
            )}
          </EstStatContainer>
        </Tooltip>
        {/* This is for display only, editing happens in BuyBoxEligibility */}
        <EstStatContainer
          title="Site Acq Bid Recommendation"
          type="money"
          value={report.reference_property?.site_acquisition_bid_recommendation}
        >
          <EstSingleChildStat
            type="money"
            value={costs_and_metrics!.backsolved_site_acquisition_target}
            title={`Site Acq Target Max Bid:`}
          />
        </EstStatContainer>
        <Tooltip title={`Land Basis = (total lost cost + indirect hard costs + land acquisition fee) / net sale price`}>
          <EstSingleStat
            title="Land Basis"
            type="percentage"
            testId="landBasis"
            value={<LandBasisBuyBox report={report} />}
          />
        </Tooltip>
      </div>
      {isC2C && (
        <div>
          <EstStatContainer
            title="Under Contract Amount"
            value={costs_and_metrics!.final_estimate_under_contract_amount_in_cents / 100}
            type="money"
          >
            {property.mls_status === "ACT" && property.mls_list_price && (
              <EstSingleChildStat
                type="percentage"
                value={calculateUCListRatio(
                  costs_and_metrics!.final_estimate_under_contract_amount_in_cents / 100,
                  property.mls_list_price,
                )}
                title="UC -> List Ratio:"
              />
            )}
          </EstStatContainer>
          <EstSingleStat
            title="UC Date"
            value={
              costs_and_metrics?.final_estimate_under_contract_date &&
              FORMAT_BASIC_DATE.format(new Date(costs_and_metrics?.final_estimate_under_contract_date))
            }
          />
          <EstSingleStat
            title="Contingency Expiration Date"
            value={
              costs_and_metrics?.final_estimate_contingency_exp_date &&
              FORMAT_BASIC_DATE.format(new Date(costs_and_metrics?.final_estimate_contingency_exp_date))
            }
          />
          <EstSingleStat
            title="Expected Close Date"
            value={close_date && FORMAT_BASIC_DATE.format(new Date(close_date))}
          />
        </div>
      )}
    </EstimateSection>
  );
}

const calculateUCListRatio = (ucAmount: Maybe<number>, listPrice: Maybe<number>) =>
  !!ucAmount && !!listPrice && listPrice > 0 ? ucAmount / listPrice : null;
