import { BoundNumberField, Css } from "@homebound/beam";
import {
  EstimateSection,
  EstSectionFooterCss,
  EstStatContainer,
  EstSingleStat,
  EstSingleChildStat,
  shouldUseOldCostComputations,
} from "src/routes/cma/steps/estimate/components";
import { EstimateFormState, miscSiteCostCode } from "src/routes/cma/steps/estimate/estimate-forms/EstimateForm";
import React from "react";
import { UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { ValuationStage } from "src/routes/cma/endpoints/reports/ValuationStage";
import { hasV2ReadyPlans } from "src/routes/cma/steps/readyPlanUtils";
import { BoundNumberFieldProps } from "@homebound/beam/dist/forms/BoundNumberField";

type CostFieldsProps = {
  report: UnderwritingReport;
  estimateFormState: EstimateFormState;
};

export function CostFields({ report, estimateFormState }: CostFieldsProps) {
  return (
    <EstimateSection title="Costs" numColumns={10}>
      {/* We could set gtc to 2 here but our EstimateSection already defaults to 5 */}
      <div css={Css.dg.gap2.gtc("repeat(10, 1fr)").$}>
        <BuildCostFields report={report} estimateFormState={estimateFormState} />
        <LotCostFields report={report} estimateFormState={estimateFormState} />
        <FeesCostFields report={report} estimateFormState={estimateFormState} />
        <FinancingCostFields report={report} estimateFormState={estimateFormState} />
      </div>
    </EstimateSection>
  );
}

export function BuildCostFields({ report, estimateFormState }: CostFieldsProps) {
  const hasBpRp = hasV2ReadyPlans(report);
  const isC2C = report.valuation_stage === ValuationStage.clear_to_close;

  const hasDemoCostValue = estimateFormState.costs_and_metrics.demo_costs_base.value !== 0;

  return (
    <EstimateSection title="Build Costs" headerColor="lightGray">
      <div>
        <EstStatContainer title="Total Hard Costs" type="money" value={report.costs_and_metrics?.hard_costs}>
          {hasBpRp || isC2C ? (
            <>
              <EstSingleChildStat
                title="Indirect Hard Costs: "
                value={(report.costs_and_metrics?.indirect_hard_cost_in_cents ?? 0) / 100}
                type="money"
              />
              <EstSingleChildStat
                title="Direct Hard Costs: "
                value={(report.costs_and_metrics?.direct_hard_cost_in_cents ?? 0) / 100}
                type="money"
              />
            </>
          ) : (
            // Legacy RPC costs for reports outside c2c
            <>
              <EstSingleChildStat
                title="Hard Costs: "
                value={
                  (report.costs_and_metrics?.hard_costs ?? 0) -
                  (report.costs_and_metrics?.soft_costs_incremental_site_costs ?? 0) -
                  (report.costs_and_metrics?.demo_costs_base ?? 0)
                }
                type="money"
              />
              <EstSingleChildStat
                highlight={!estimateFormState.readOnly}
                title="Incremental Site Costs: "
                value={
                  <BoundNumberField
                    {...commonDollarFieldProps}
                    label="Incremental Site Costs"
                    field={estimateFormState.costs_and_metrics.soft_costs_incremental_site_costs}
                  />
                }
                type="money"
              />
              {/* Show demo costs for historical reports where the value is not 0 */}
              {hasDemoCostValue && (
                <EstSingleChildStat
                  title="Demolition Costs: "
                  value={
                    <BoundNumberField
                      {...commonDollarFieldProps}
                      label="Demolition Costs"
                      field={estimateFormState.costs_and_metrics.demo_costs_base}
                    />
                  }
                  type="money"
                />
              )}
            </>
          )}
        </EstStatContainer>
        <EstStatContainer title="Soft Costs" type="money" value={report.costs_and_metrics?.soft_costs}>
          <>
            {!isC2C && (
              <>
                {!hasBpRp && (
                  <EstSingleChildStat
                    title="Baseline Soft Costs: "
                    highlight={!estimateFormState.readOnly}
                    value={
                      <BoundNumberField
                        {...commonDollarFieldProps}
                        label="Baseline Soft Costs: "
                        field={estimateFormState.costs_and_metrics.soft_costs_baseline_costs}
                      />
                    }
                    type="money"
                  />
                )}
                {estimateFormState.site_costs.rows
                  .filter(({ cost_code }) => cost_code.value === miscSiteCostCode)
                  .map(({ cost_in_cents }) => {
                    return (
                      <EstSingleChildStat
                        title="Misc Site Costs: "
                        highlight={!estimateFormState.readOnly}
                        key="Misc"
                        value={
                          <BoundNumberField
                            {...commonDollarFieldProps}
                            // Only cents field
                            type="cents"
                            readOnly={cost_in_cents.readOnly || estimateFormState.readOnly}
                            field={cost_in_cents}
                            label="Misc Site Costs"
                          />
                        }
                      />
                    );
                  })}
              </>
            )}
          </>
        </EstStatContainer>
        {!!estimateFormState.costs_and_metrics.custom_costs.value && (
          <EstSingleStat
            title="Custom Costs"
            value={
              <div css={Css.maxwPx(75).$}>
                <BoundNumberField
                  {...commonDollarFieldProps}
                  type="dollars"
                  field={estimateFormState.costs_and_metrics.custom_costs}
                />
              </div>
            }
            type="money"
          />
        )}
      </div>
      <div css={EstSectionFooterCss}>
        <EstSingleStat bold title="Total Build Costs" value={report.costs_and_metrics?.build_costs} type="money" />
      </div>
    </EstimateSection>
  );
}

export function LotCostFields({ report, estimateFormState }: CostFieldsProps) {
  return (
    <EstimateSection title="Lot Costs" headerColor="lightGray">
      <div>
        <EstSingleStat title="Lot Cost" value={getLotCost(report)} type="money" />
      </div>
      <div>
        <EstStatContainer
          highlight={!estimateFormState.readOnly}
          title="Acquisition Closing Costs"
          type="money"
          value={getAcquisitionClosingCosts(report)}
          testId="acquisitionClosingCosts"
        >
          {!shouldUseBuyerClosingCost(report) && (
            <EstSingleChildStat
              title="Acquisition Closing Cost Rate:"
              value={
                <div css={Css.maxwPx(75).$}>
                  <BoundNumberField
                    {...commonPercentFieldProps}
                    label="Site Acquisition Closing Cost Percentage"
                    field={estimateFormState.costs_and_metrics.site_acquisition_closing_cost_percent}
                  />
                </div>
              }
              type="percentage"
            />
          )}
        </EstStatContainer>
      </div>
      <div css={EstSectionFooterCss}>
        <EstSingleStat
          testId="totalLotCosts"
          bold
          title="Total Lot Costs"
          value={report.costs_and_metrics?.total_lot_cost}
          type="money"
        />
      </div>
    </EstimateSection>
  );
}

export function FeesCostFields({ report, estimateFormState }: CostFieldsProps) {
  const landMarkup = (report.costs_and_metrics?.site_acquisition_margin_site_markup_percent ?? 0) / 100;
  const landAcqFee = getLotCost(report) * landMarkup;

  return (
    <EstimateSection title="Fees" headerColor="lightGray">
      <div>
        <EstStatContainer title="Land Acquisition Fee" type="money" value={landAcqFee}>
          <EstSingleChildStat
            highlight={!estimateFormState.readOnly}
            title="Markup:"
            value={
              <div css={Css.maxwPx(75).$}>
                <BoundNumberField
                  {...commonPercentFieldProps}
                  label="Land Acquisition Margin Site Markup"
                  field={estimateFormState.costs_and_metrics.site_acquisition_margin_site_markup_percent}
                />
              </div>
            }
            fractional
            type="percentage"
          />
        </EstStatContainer>
        <EstStatContainer title="Builder Fee" type="money" value={report.costs_and_metrics?.build_margin}>
          <EstSingleChildStat
            highlight={!estimateFormState.readOnly}
            title="Markup:"
            value={
              <div css={Css.maxwPx(75).$}>
                <BoundNumberField
                  {...commonPercentFieldProps}
                  label="Land Builder Fee Site Markup"
                  field={estimateFormState.costs_and_metrics.build_margin_homebound_markup_percent}
                />
              </div>
            }
            type="percentage"
          />
        </EstStatContainer>
      </div>
      <div css={EstSectionFooterCss}>
        <EstSingleStat
          bold
          title="Total Fees"
          value={landAcqFee + (report.costs_and_metrics?.build_margin || 0)}
          type="money"
        />
      </div>
    </EstimateSection>
  );
}

export function FinancingCostFields({ report, estimateFormState }: CostFieldsProps) {
  const annualizedLoanDuration =
    ((estimateFormState.costs_and_metrics?.project_length_pre_con_months.value ?? 0) +
      (estimateFormState.costs_and_metrics?.project_length_construction_months.value ?? 0) +
      (estimateFormState.costs_and_metrics?.project_length_sales_period_months.value ?? 0)) /
    12;

  const financingMultiplier =
    ((estimateFormState.costs_and_metrics?.financing_costs_percent_debt.value ?? 0) / 100) *
    ((estimateFormState.costs_and_metrics?.financing_costs_weighted_financing_rate.value ?? 0) / 100) *
    ((estimateFormState.costs_and_metrics?.financing_costs_financing_rate.value ?? 0) / 100) *
    annualizedLoanDuration;

  return (
    <EstimateSection title="Financing Costs" headerColor="lightGray">
      <div css={Css.df.fdc.gap1.$}>
        <EstSingleStat
          highlight={!estimateFormState.readOnly}
          title="Debt - Capital Stack"
          value={
            <div css={Css.maxwPx(75).$}>
              <BoundNumberField
                {...commonPercentFieldProps}
                label="Percent Debt"
                field={estimateFormState.costs_and_metrics.financing_costs_percent_debt}
                numFractionDigits={2}
                type="percent"
              />
            </div>
          }
          type="percentage"
        />
        <EstSingleStat
          title="Wtd Avg Cost of Debt"
          highlight={!estimateFormState.readOnly}
          value={
            <div css={Css.maxwPx(75).$}>
              <BoundNumberField
                {...commonPercentFieldProps}
                label="Wtd Avg Cost of Debt"
                field={estimateFormState.costs_and_metrics.financing_costs_financing_rate}
                numFractionDigits={2}
                type="percent"
              />
            </div>
          }
          type="percentage"
        />
        <EstSingleStat
          highlight={!estimateFormState.readOnly}
          title="S-Curve Proxy"
          value={
            <div css={Css.maxwPx(75).$}>
              <BoundNumberField
                {...commonPercentFieldProps}
                label="S-Curve Proxy"
                field={estimateFormState.costs_and_metrics.financing_costs_weighted_financing_rate}
                numFractionDigits={2}
                type="percent"
              />
            </div>
          }
          type="percentage"
        />
        <EstSingleStat title="Loan Duration (Annualized)" value={annualizedLoanDuration} type="number" fractional />
        <EstSingleStat title="Financing Multiplier" value={financingMultiplier} type="percentage" />
      </div>
      <div css={EstSectionFooterCss}>
        <EstSingleStat
          bold
          title="Total Financing Costs"
          value={report.costs_and_metrics?.financing_costs ?? 0}
          type="money"
        />
      </div>
    </EstimateSection>
  );
}

// Dollar amount lot cost for a report based on valuation stage
const getLotCost = (report: UnderwritingReport) => {
  const isC2C = report.valuation_stage === ValuationStage.clear_to_close;

  const usesOldComputations = shouldUseOldCostComputations(report);

  if (
    isC2C &&
    !usesOldComputations &&
    !!report.costs_and_metrics &&
    report.costs_and_metrics.final_estimate_under_contract_amount_in_cents > 0
  ) {
    return report.costs_and_metrics.final_estimate_under_contract_amount_in_cents / 100;
  } else {
    return report.reference_property?.site_acquisition_bid_recommendation ?? 0;
  }
};

function getAcquisitionClosingCosts(report: UnderwritingReport) {
  return shouldUseBuyerClosingCost(report)
    ? report.costs_and_metrics?.buyer_closing_cost ?? 0
    : getLotCost(report) * ((report.costs_and_metrics?.site_acquisition_closing_cost_percent ?? 0) / 100);
}

/** Buyer closing cost (pulled from SFDC) should be used for acquisition closing costs
 *  in non-underwriting reports when it is available
 */
function shouldUseBuyerClosingCost(report: UnderwritingReport) {
  return (
    // eslint-disable-next-line eqeqeq
    report.valuation_stage !== ValuationStage.underwriting && report.costs_and_metrics?.buyer_closing_cost != null
  );
}

const commonPercentFieldProps: Pick<
  BoundNumberFieldProps,
  "type" | "labelStyle" | "compact" | "numFractionDigits" | "xss"
> = {
  type: "percent",
  labelStyle: "hidden",
  compact: true,
  numFractionDigits: 2,
  // Inherit type scale from parent when readonly
  xss: Css.tac.color("inherit").fw("inherit").lh("inherit").add("fontSize", "inherit").$,
};

const commonDollarFieldProps: Pick<BoundNumberFieldProps, "type" | "labelStyle" | "compact" | "sizeToContent" | "xss"> =
  {
    type: "dollars",
    labelStyle: "hidden",
    compact: true,
    sizeToContent: true,
    // Inherit type scale from parent when readonly
    xss: Css.tac.color("inherit").fw("inherit").lh("inherit").add("fontSize", "inherit").$,
  };
