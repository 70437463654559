import { Endpoint } from "@rest-hooks/endpoint";
import { Fixture } from "@rest-hooks/test";
import { api_url, auth_headers } from "src/context";
import { Property, PropertyComp } from "src/routes/cma/endpoints";

export const MapsApiEndpoint = new Endpoint(
  async () => {
    const headers = await auth_headers();
    const res = await fetch(api_url("/api-key"), { headers });
    return await res.json();
  },
  {
    dataExpiryLength: 1000 * 60 * 60, // 1 hour
    key: () => "/api-key",
    name: "MapsApiEndpoint",
  },
);

// Street view documentation https://developers.google.com/maps/documentation/streetview/request-streetview
// Static map documentation https://developers.google.com/maps/documentation/maps-static/start

// width x height in pixels
const size = "600x400";
// field of view in degrees
const fov = 70;
// pitch in degrees
const pitch = 10;
// zoom level
const zoom = 20;

export function streetViewUrl({ latitude, longitude }: Property | PropertyComp, apiKey: string) {
  return `https://maps.googleapis.com/maps/api/streetview?size=${size}&fov=${fov}&pitch=${pitch}&location=${latitude},${longitude}&key=${apiKey}`;
}

export function mapUrl({ latitude, longitude }: Property | PropertyComp, apiKey: string) {
  return `https://maps.googleapis.com/maps/api/staticmap?size=${size}&zoom=${zoom}&maptype=satellite&center=${latitude},${longitude}&key=${apiKey}`;
}

// Test fixture
export function mapsApiResponseFixture(): Fixture {
  return {
    endpoint: MapsApiEndpoint,
    args: [{}],
    response: { api_key: "test-key" },
  };
}
