import { AccordionList, Checkbox, Css, Icon, Palette } from "@homebound/beam";
import { capitalCase } from "change-case";
import { useContext, useEffect, useRef } from "react";
import { getPolygonColor } from "src/routes/maps/mapUtils";
import { PolygonContext } from "../PolygonContext";
import { SavePolygonInput, UWPolygonType, defaultPolygonName } from "../PolygonEndpoints";

type PolygonPaneProps = {
  polygons: Record<UWPolygonType, SavePolygonInput[]>;
};

export function PolygonPane({ polygons }: PolygonPaneProps) {
  const { selectedPolygonId, setSelectedPolygonId, polygonVisibility, setPolygonVisibility } =
    useContext(PolygonContext);
  const selectedPolygonRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // if a polygon is selected, scroll to it
    if (selectedPolygonId) {
      const selectedPolygon = Object.values(polygons)
        .flat()
        .find((p) => p.id === selectedPolygonId);
      if (selectedPolygon && selectedPolygonRef.current) {
        selectedPolygonRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  });

  return (
    <div data-testid="polygonPane">
      <AccordionList
        compact
        accordions={Object.entries(polygons).map(([type, polygons]) => {
          const isTypeVisible = polygonVisibility.find((v) => v.type === type)?.isVisible ?? true;
          const color = Palette.Blue700;
          const bgColor = isTypeVisible ? color : Palette.Gray100;
          const bcColor = isTypeVisible ? color : Palette.Gray500;
          const rest = polygonVisibility.filter((v) => v.type !== type);
          return {
            title: (
              <div css={Css.df.gap1.aic.addIn("& span", Css.bgColor(bgColor).bc(bcColor).$).$}>
                <div onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    checkboxOnly
                    label="visibility"
                    selected={isTypeVisible}
                    onChange={(isVisible) => {
                      setPolygonVisibility([...rest, { isVisible, type: type as UWPolygonType }]);
                    }}
                  />
                </div>
                <div>{capitalCase(type)}</div>
              </div>
            ),
            children: (
              <div key={type}>
                {polygons.sort(sortByName).map((p) => {
                  const isSelected = selectedPolygonId === p.id || p.id === undefined;
                  return (
                    <div
                      key={p.id ?? 0}
                      css={
                        Css.px1.df.gap1.aic.xs
                          .pyPx(3)
                          .if(isTypeVisible)
                          .cursorPointer.br4.if(isSelected)
                          .bgGray200.xsBd.if(!p.active).gray500.$
                      }
                      onClick={() => {
                        if (!isTypeVisible) return;
                        setSelectedPolygonId(p.id);
                      }}
                      ref={isSelected ? selectedPolygonRef : null}
                    >
                      <div>
                        <Icon
                          icon="circle"
                          color={getPolygonColor({ isActive: p.active, color: p.color, type: p.type })}
                          inc={2}
                        />
                      </div>
                      <div>{p.name ?? defaultPolygonName}</div>
                    </div>
                  );
                })}
              </div>
            ),
          };
        })}
      />
    </div>
  );
}

function sortByName(a: SavePolygonInput, b: SavePolygonInput) {
  if (!a.name || !b.name) return 0;
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  return 0;
}
