import { Css } from "@homebound/beam";
import { MultiPolygon } from "geojson";
import { useMemo } from "react";
import { FeatureGroup, MapContainer, Marker, Tooltip as ReactLeafletTooltip } from "react-leaflet";
import { FormattedPercentage } from "src/components/FormattedPercentage";
import { Property } from "src/routes/cma/endpoints";
import { PropertyComp } from "src/routes/cma/endpoints/PropertyCompEndpoint";
import { CompFilterPolygonFeatureView } from "src/routes/cma/steps/comparables/CompFilterPolygonFeatureView";
import { MapBaseLayers } from "src/routes/maps/MapBaseLayers";
import { houseLeafletIcon, MapIconColors, mapMarkerIcon, starFilledLeafletIcon } from "src/routes/maps/mapIcons";
import { getMapBoundsFromProperties } from "src/routes/maps/mapUtils";
import { Maybe } from "src/utils";

interface StaticMapProps {
  property: Property;
  comps: PropertyComp[];
  compSearchGeometry?: Maybe<MultiPolygon>;
}

export function StaticMap(props: StaticMapProps) {
  const { property, comps, compSearchGeometry } = props;
  const { latitude, longitude } = property;

  const compMarkers = useMemo(() => {
    return drawCompMarkers(comps);
  }, [comps]);

  function drawCompMarkers(comps: PropertyComp[]) {
    return comps.map((comp) => {
      const { latitude, longitude, dpid_of_neighbor, full_street_address, is_hb_comp, auto_uw_rec } = comp;
      const color = statusToColor(comp.mls_status);
      const icon = is_hb_comp
        ? houseLeafletIcon(color)
        : auto_uw_rec
          ? starFilledLeafletIcon(color)
          : mapMarkerIcon(color);

      return (
        <Marker key={dpid_of_neighbor} position={[latitude!, longitude!]} icon={icon} riseOnHover>
          <ReactLeafletTooltip permanent direction="top" offset={[0, -40]} css={Css.bshBasic.$}>
            <div css={Css.xs.ttc.$}>
              {full_street_address.toLowerCase()} - <FormattedPercentage percentage={comp.weight!} decimals={0} />
            </div>
          </ReactLeafletTooltip>
        </Marker>
      );
    });
  }

  const [latitudeCenter, longitudeCenter, bounds] = useMemo(
    () => getMapBoundsFromProperties(property, comps),
    [property, comps],
  );

  return (
    <MapContainer
      key={property.dpid}
      css={Css.w100.brt4.$}
      center={[latitudeCenter, longitudeCenter]}
      bounds={bounds}
      boundsOptions={{ padding: [50, 50] }}
      scrollWheelZoom={false}
    >
      <MapBaseLayers />
      <FeatureGroup>
        <CompFilterPolygonFeatureView compFilterGeometry={compSearchGeometry} />
      </FeatureGroup>
      {compMarkers}
      <Marker position={[latitude!, longitude!]} icon={mapMarkerIcon("blue")}>
        <ReactLeafletTooltip permanent direction="top" offset={[0, -40]} css={Css.bshBasic.$}>
          <div css={Css.xsBd.ttc.$}>{property.full_street_address.toLowerCase()}</div>
        </ReactLeafletTooltip>
      </Marker>
    </MapContainer>
  );
}

// FIXME: Tech debt single source of truth on map marker colors
export function statusToColor(status: Maybe<string>): MapIconColors {
  switch (status) {
    case "ACT":
      return "green";
    case "PND":
      return "gold";
    case "SLD":
      return "red";
    default:
      return "grey";
  }
}
