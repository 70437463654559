import { Css } from "@homebound/beam";
import { useSuspense } from "@rest-hooks/react";
import { Marker as LeafletMarker } from "leaflet";
import _ from "lodash";
import { ReactNode, useEffect, useMemo, useRef } from "react";
import { MapContainer, Marker, Popup } from "react-leaflet";
import { Property } from "src/routes/cma/endpoints";
import { PropertyComp } from "src/routes/cma/endpoints/PropertyCompEndpoint";
import { MapsApiEndpoint } from "src/routes/maps/MapsApiEndpoint";
import { MapIconColors, houseLeafletIcon, mapMarkerIcon, starFilledLeafletIcon } from "src/routes/maps/mapIcons";
import { getMapBoundsFromProperties } from "src/routes/maps/mapUtils";
import { Maybe } from "src/utils";
import { MapBaseLayers } from "../../../maps/MapBaseLayers";
import { FormState } from "./CompForm";
import { LegendInMap } from "./Legend";
import { LoadPropertyCompCard } from "./PropertyCompCard";
import { ReferencePropertyCard } from "./ReferencePropertyCard";

const maxPopupWidth = 1024;

interface MapBoxProps {
  property: Property;
  comps: PropertyComp[];
  formState: FormState;
  selectedComp: string | undefined;

  onCompSelected: (dpid: string | undefined) => void;

  children?: ReactNode;
}

export function MapBox({ property, comps, formState, selectedComp, onCompSelected, children }: MapBoxProps) {
  const { api_key: mapsApiKey } = useSuspense(MapsApiEndpoint);

  const selectedCompMarker = useRef<LeafletMarker>(null);
  const { latitude, longitude } = property;

  useEffect(() => {
    if (selectedCompMarker.current) {
      selectedCompMarker.current.openPopup();
    }
  }, [selectedComp]);

  // this prevents the markers from being rerendered and stops the popup from closing
  // when a comp is selected or deselected from the popup card
  const compMarkers = useMemo(() => {
    return drawCompMarkers(formState, comps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComp, comps]);

  function drawCompMarkers(formState: FormState, comps: PropertyComp[]) {
    const compsByDpid = _.keyBy(comps, (comp) => comp.dpid_of_neighbor);

    return formState.comps.rows.map((compForm) => {
      const c = compsByDpid[compForm.dpid_of_neighbor.value];
      if (!c) {
        // TODO: not sure what this is about, I think it's because of the filtering
        return undefined;
      }

      const neighbor = c.neighbor!;
      const { latitude, longitude } = neighbor;

      // if it's a user defined comp without lat/lon, we can't show it
      if (!latitude || !longitude) {
        return undefined;
      }

      const color = typeToColor({
        user_selected_comp: compForm.user_selected_comp.value!,
        mls_status: neighbor.mls_status,
      });
      const icon = c.is_hb_comp
        ? houseLeafletIcon(color)
        : c.auto_uw_rec
          ? starFilledLeafletIcon(color)
          : mapMarkerIcon(color);

      return (
        <Marker
          key={c.dpid_of_neighbor}
          position={[latitude!, longitude!]}
          icon={icon}
          ref={selectedComp === neighbor.dpid ? selectedCompMarker : undefined}
          eventHandlers={{
            popupopen: () => onCompSelected(neighbor.dpid),
            popupclose: () => onCompSelected(undefined),
          }}
          riseOnHover
        >
          <Popup maxWidth={maxPopupWidth}>
            <LoadPropertyCompCard comp={c} compForm={compForm} mapsApiKey={mapsApiKey} />
          </Popup>
        </Marker>
      );
    });
  }

  const [latitudeCenter, longitudeCenter, bounds] = useMemo(
    () => getMapBoundsFromProperties(property, comps),
    [property, comps],
  );

  return (
    <MapContainer
      key={property.dpid}
      css={Css.hPx(600).mwPx(530).relative.z1.$}
      center={[latitudeCenter, longitudeCenter]}
      scrollWheelZoom={false}
      bounds={bounds}
      boundsOptions={{ padding: [50, 50] }}
    >
      <MapBaseLayers />
      {children}
      <LegendInMap content={propertyStatusMarkers} />
      {compMarkers}
      <Marker position={[latitude!, longitude!]} riseOnHover>
        <Popup maxWidth={maxPopupWidth}>
          <ReferencePropertyCard property={property} />
        </Popup>
      </Marker>
    </MapContainer>
  );
}

// FIXME: Tech debt single source of truth on map marker colors
const propertyStatusMarkers: Record<string, string> = {
  blue: "Subject Property",
  orange: "Selected",
  grey: "Not Selected",
  green: "Active or Pending",
  homebound: "HB Comp",
  mlRec: "ML Comp",
};

interface ScoreIconProps {
  user_selected_comp: boolean;
  mls_status: Maybe<string>;
}

// FIXME: Tech debt single source of truth on map marker colors
function typeToColor({ user_selected_comp, mls_status }: ScoreIconProps): MapIconColors {
  if (user_selected_comp) {
    return "orange";
  } else if (mls_status === "ACT" || mls_status === "PND") {
    return "green";
  } else {
    return "grey";
  }
}
