import { Css, Tag } from "@homebound/beam";
import { Datum, DatumValue, LineSvgProps, Point, ResponsiveLine, Serie, SliceTooltipProps } from "@nivo/line";
import React, { useMemo } from "react";
import { tierLegendPalette } from "src/components/confidenceIntervalChart";
import { FormattedPrice } from "src/components/FormattedPrice";
import { UWLabel } from "src/components/UWLabel";
import { ReadyPlan, UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { ActivityState } from "src/routes/cma/endpoints/reports/ActivityState";
import {
  calculateMarkupPercentage,
  formatMagnitude,
  formatNumberToString,
  parseMaybeStringISOOffsetDate,
} from "src/utils";
import { getReadyPlanDisplayName } from "src/utils/mappers";
import { getPlanNameAndCopies } from "src/routes/cma/steps/estimate/EstimatePage";

interface PropertyValuationGraphViewProps {
  // UnderwritingReportVersionsEndpoint
  versions: UnderwritingReport[];
}

interface ValuationDatum extends Datum {
  percentChange: number;
  isLatestActive: boolean;
  report: UnderwritingReport;
}

interface ValuationPoint extends Point {
  data: {
    x: DatumValue;
    xFormatted: string | number;
    y: DatumValue;
    yFormatted: string | number;
    yStacked?: number;
    percentChange: number;
    isLatestActive: boolean;
    report: UnderwritingReport;
  };
}

interface ValuationSerie extends Serie {
  data: ValuationDatum[];
}

export function PropertyValuationGraph(props: PropertyValuationGraphViewProps) {
  const { versions } = props;

  const finalizedVersionPoints: ValuationSerie[] = useMemo(() => {
    let foundLatestActive = false;
    const finalizedVersions = versions
      .filter((r) => r.status === "Finalized")
      // latest first
      .sort(
        (a, b) =>
          // NOTE: We're using `created_at` as a fallback over `updated_at` because it's better for showing the valuation over time
          new Date(b.underwritten_at || parseMaybeStringISOOffsetDate(b.created_at)!).getTime() -
          new Date(a.underwritten_at || parseMaybeStringISOOffsetDate(a.created_at)!).getTime(),
      )
      .map((v, idx, sortedReps) => {
        const isFirstFinalizedReport = idx === sortedReps.length - 1;

        const dataPoint = {
          finalPrice: v.final_weighted_price,
          finalAppreciatedPrice: v.final_weighted_price_with_appreciation,
          percentChange: isFirstFinalizedReport
            ? 0
            : calculateMarkupPercentage(v.final_weighted_price!, sortedReps[idx + 1].final_weighted_price!, true),
          percentChangeAppreciation: isFirstFinalizedReport
            ? 0
            : calculateMarkupPercentage(
                v.final_weighted_price_with_appreciation!,
                sortedReps[idx + 1].final_weighted_price_with_appreciation!,
                true,
              ),
          date: v.underwritten_at || parseMaybeStringISOOffsetDate(v.created_at),
          isLatestActive: false,
          report: v,
        };

        if (!foundLatestActive && v.activity_state === ActivityState.active) {
          dataPoint.isLatestActive = true;
          foundLatestActive = true;
        }

        return dataPoint;
      });

    // Separate finalized versions into two nivo line datasets
    return [
      {
        id: "Gross Estimated Price W/ Appreciation",
        data: finalizedVersions.map(
          ({ date, finalAppreciatedPrice, report, isLatestActive, percentChangeAppreciation }) => ({
            x: date,
            y: finalAppreciatedPrice,
            percentChange: percentChangeAppreciation,
            report,
            isLatestActive,
          }),
        ),
      },
      {
        id: "Gross Estimated Price",
        data: finalizedVersions.map(({ date, finalPrice, report, isLatestActive, percentChange }) => ({
          x: date,
          y: finalPrice,
          percentChange,
          report,
          isLatestActive,
        })),
      },
    ];
  }, [versions]);

  if (finalizedVersionPoints.length < 2) {
    return null;
  }

  return (
    <div css={Css.w100.hPx(370).$}>
      <ResponsiveLine
        // render components
        sliceTooltip={ValuationSliceTooltip}
        pointSymbol={({ datum: { isLatestActive }, size, color, borderWidth, borderColor }) => (
          <g>
            <circle r={size / 2} strokeWidth={borderWidth} stroke={borderColor} fill={color} />
            {/* latest active pulse */}
            {isLatestActive && (
              <circle fill="none" r={size / 2} stroke={borderColor} strokeWidth={borderWidth * 1.2}>
                <animate
                  attributeName="r"
                  from={size / 3}
                  to={size * 1.2}
                  dur="1.5s"
                  begin="0s"
                  repeatCount="indefinite"
                />
                <animate attributeName="opacity" from="1" to="0" dur="1.5s" begin="0s" repeatCount="indefinite" />
              </circle>
            )}
          </g>
        )}
        // memoized data
        data={finalizedVersionPoints}
        axisLeft={{
          tickValues: finalizedVersionPoints[0].data.length,
          legendOffset: 12,
          format: formatMagnitude,
        }}
        // static props
        {...propertyValuationGraphStaticProps}
      />
    </div>
  );
}

// An advantage to using slices is Nivo will make sure the tooltip renders 100% in view giving us more space for chart
function ValuationSliceTooltip({ slice }: SliceTooltipProps) {
  // Note: Even though we have declared a datum, nivo doesn't pass any extra keys to ScatterTooltip[] | Points[]
  const points = slice.points as unknown as ValuationPoint[];

  // We'll always have one or the other, when both are hidden empty chart slices will not trigger a tooltip render
  const appreciationPoint = points.find((p) => p.serieId === "Gross Estimated Price W/ Appreciation");
  const grossPoint = points.find((p) => p.serieId === "Gross Estimated Price");

  const { xFormatted: underwrittenDate } = appreciationPoint?.data ?? grossPoint!.data; // already formatted
  const {
    report: { version_name, activity_state, valuation_stage, ready_plans = [] },
    isLatestActive,
  } = appreciationPoint?.data || grossPoint!.data;

  return (
    <div css={Css.relative.df.fdc.gap1.p2.pt3.xs.bgWhite.ba.br4.bcGray700.$}>
      <UWLabel label={version_name} labelTypography="xsBd" />
      <UWLabel label={"Underwritten At: "} value={underwrittenDate} labelTypography="xsBd" />
      <UWLabel label={"Valuation Stage: "} value={valuation_stage} labelTypography="xsBd" />
      {grossPoint && (
        <UWLabel
          label={"Estimated Price: "}
          value={<ValuationPriceWDelta price={grossPoint.data.y} percentChange={grossPoint.data.percentChange} />}
          labelTypography="xsBd"
        />
      )}
      {appreciationPoint && (
        <UWLabel
          label={"W/ Appreciation: "}
          value={
            <ValuationPriceWDelta
              price={appreciationPoint.data.y}
              percentChange={appreciationPoint.data.percentChange}
            />
          }
          labelTypography="xsBd"
        />
      )}
      <UWLabel
        label={"Active: "}
        value={activity_state === ActivityState.active ? "Yes" : "No"}
        labelTypography="xsBd"
      />
      {ready_plans.map((rp) => (
        <PossibleMultiUnitValuation key={rp.id} readyPlan={rp} />
      ))}
      {/* Upper-Left Absolute tag */}
      {isLatestActive && (
        <span css={Css.absolute.top0.left0.addIn("& > span", { ...Css.bgBlue100.blue500.$ }).$}>
          <Tag text="Latest Active Version" />
        </span>
      )}
    </div>
  );
}

// Mirrors MultiUnitPricing.tsx that's displayed in Estimate Page
function PossibleMultiUnitValuation({ readyPlan }: { readyPlan: ReadyPlan }) {
  const { copies, sale_price_tier, final_weighted_price, final_weighted_price_low, final_weighted_price_high } =
    readyPlan;
  const hasMultipleCopies = copies > 1;

  return (
    <div css={Css.df.fdc.$}>
      <div css={Css.xsBd.ttc.$}>
        {hasMultipleCopies ? getPlanNameAndCopies(readyPlan) : getReadyPlanDisplayName(readyPlan)}&nbsp;
      </div>
      <div css={Css.df.fdc.pl1.$}>
        {sale_price_tier && tierLegendPalette[sale_price_tier].label}
        <div>
          <UWLabel label={"Est. Price: "} value={final_weighted_price} labelTypography="xs" />
          {hasMultipleCopies && " each"}
          {final_weighted_price_high && final_weighted_price_low && (
            <div>
              Est. Range: ${formatNumberToString(final_weighted_price_low, true, true)}&nbsp;-&nbsp;$
              {formatNumberToString(final_weighted_price_high, true, true)}
            </div>
          )}
          {hasMultipleCopies && (
            <div>
              <FormattedPrice price={readyPlan.final_weighted_price * readyPlan.copies} /> total
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function ValuationPriceWDelta({ price, percentChange }: { price: DatumValue; percentChange: number }) {
  const increased = percentChange > 0;
  return (
    <>
      <FormattedPrice price={price as number} />
      &nbsp;
      {percentChange !== 0 && (
        <span css={Css.if(increased).green600.else.red600.$}>
          (∆ {increased && "+"}
          {percentChange}%)
        </span>
      )}
    </>
  );
}

const propertyValuationGraphStaticProps = {
  margin: { top: 30, right: 50, bottom: 60, left: 60 },
  animate: true,
  enableSlices: "x",
  legends: [
    {
      symbolShape: "circle",
      anchor: "bottom",
      direction: "row",
      symbolSize: 14,
      itemHeight: 20,
      itemWidth: 150,
      toggleSerie: true,
      translateY: 50,
    },
  ],
  xScale: {
    type: "time",
    format: "%Y-%m-%d",
    useUTC: false,
    precision: "day",
    nice: true,
  },
  xFormat: "time:%Y-%m-%d",
  yScale: {
    type: "linear",
    stacked: false,
    min: "auto",
    max: "auto",
    nice: true,
  },
  axisBottom: {
    format: "%b %d, %y",
    // we could render a tick for each date only w/
    // tickValues: finalizedVersionPoints[0].data.map((x) => new Date(x.x)),
    legendOffset: -12,
  },
  curve: "monotoneX", // Remove if request to be jagged
  colors: { scheme: "dark2" },
  enablePointLabel: true,
  pointLabelYOffset: -20,
  pointSize: 14,
  pointBorderWidth: 1,
  pointBorderColor: {
    from: "color",
    modifiers: [["darker", 0.3]],
  },
  useMesh: true,
  // @ts-ignore datums aren't passed in component
  pointLabel: (d) => d?.percentChange && `∆ ${d?.percentChange}%`,
} as Omit<LineSvgProps, "data">;
