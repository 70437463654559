import { Endpoint } from "@rest-hooks/endpoint";
import { api_url, auth_headers } from "src/context";
import { CompFilterPolygonGeometry } from "../../steps";
import { CompFilter } from "../../steps/comparables/CompFilter";
import { PropertyComp } from "../PropertyCompEndpoint";

export interface GenerateCompsEndpointParams {
  dpid: string;
  underwriting_report_ready_plan_id: number;
  filter: CompFilter;
  polygonFeature?: CompFilterPolygonGeometry;
}

export const GenerateCompsEndpoint = new Endpoint(
  async ({ dpid, underwriting_report_ready_plan_id, filter, polygonFeature }: GenerateCompsEndpointParams) => {
    const headers = {
      "content-type": "application/json",
      ...(await auth_headers()),
    };

    function formatFilters() {
      var filters: any = {
        ...filter,
      };

      if (polygonFeature) {
        filters["polygonFilter"] = polygonFeature;
      }

      return Object.entries(filters).length > 0 ? filters : undefined;
    }

    const comp_filters = formatFilters();

    const res = await fetch(
      api_url(`/v1/reports/${dpid}/ready_plan/${underwriting_report_ready_plan_id}/generate_comps`),
      {
        headers,
        method: "POST",
        body: JSON.stringify({ comp_filters }),
      },
    );

    return await res.json();
  },
  {
    schema: { comps: [PropertyComp] },
    key: (params) => {
      return JSON.stringify(params);
    },
    name: "GenerateCompsEndpoint",
  },
);
