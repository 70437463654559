import { useCallback, useState } from "react";

// Note we use a labeled tuple, but unfortunately it's for readability
// only and can't be used for accessors, so see useToggle2.
export type ToggleState = [open: boolean, toggle: () => void];

export function useToggle(initialState: boolean = false): ToggleState {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState(!state), [state, setState]);
  return [state, toggle];
}

export type ToggleHook = { toggle(): void; open: boolean };

/**
 * A version of `useToggle` that returns an object literal instead of an array.
 *
 * Makes it easier to pass around all three open/toggle/count keys together without
 * using array accessors like `addItemOpen[0]`.
 */
export function useToggle2(initialState: boolean = false): ToggleHook {
  const [open, toggle] = useToggle(initialState);
  return { open, toggle };
}

export function usePersistedToggle(key: string, initialState: boolean = false): ToggleState {
  const [state, setState] = useState(
    localStorage.getItem(key) !== null ? localStorage.getItem(key) === "true" : initialState,
  );
  const toggle = useCallback(() => {
    localStorage.setItem(key, `${!state}`);
    setState((state) => !state);
  }, [key, state]);

  return [state, toggle];
}
