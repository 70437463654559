import { Button, useModal } from "@homebound/beam";
import React from "react";
import { useHistory } from "react-router";
import { useAlertContext } from "src/components/AlertContext";
import { ConfirmationModal } from "src/components/ConfirmationModal";
import { useController } from "src/hooks";
import {
  UnderwritingReport,
  UnderwritingReportEndpoint,
  UpdateUnderwritingReportVersionEndpoint,
} from "src/routes/cma/endpoints/reports";
import { ActivityState } from "src/routes/cma/endpoints/reports/ActivityState";
import { reportsPath } from "src/routes/routesDef";

export type ArchiveReportConfirmationModalProps = {
  name: string;
  dpid: string;
  versionId: string;
  onConfirmClick?: () => Promise<void>;
};

export function ArchiveReportConfirmationModal(props: ArchiveReportConfirmationModalProps) {
  const { name, dpid, versionId } = props;
  const { fetch, invalidate } = useController();
  const history = useHistory();
  const { showSuccess } = useAlertContext();

  async function onConfirmClick() {
    await fetch(UpdateUnderwritingReportVersionEndpoint, {
      dpid,
      versionId,
      changes: { archive_report: true },
    });
    showSuccess(`Report ${name} successfully archived.`);
    history.push(reportsPath);
    invalidate(UnderwritingReportEndpoint, { dpid, versionId });
  }

  return (
    <ConfirmationModal
      confirmationMessage={"Are you sure? This a permanent action that cannot be undone."}
      onConfirmAction={onConfirmClick}
      title={`Archive Report: ${name}`}
      label="Archive"
      danger
    />
  );
}

export function OpenArchiveModalButton({ report }: { report?: UnderwritingReport }) {
  const { openModal } = useModal();
  if (
    !report ||
    report.status === "Finalized" ||
    report.status === "Unsaved" ||
    report.activity_state !== ActivityState.active
  )
    return null;

  return (
    <Button
      label=""
      onClick={() => {
        openModal({
          content: (
            <ArchiveReportConfirmationModal
              name={report?.version_name ?? ""}
              dpid={report.dpid}
              versionId={report.id!.toString()}
            />
          ),
        });
      }}
      icon="archive"
      tooltip={"Archive this version"}
      aria-label="Archive this version"
      variant="tertiaryDanger"
    />
  );
}
