import { LayersControl, TileLayer } from "react-leaflet";

// Default shared map layers
export function MapBaseLayers() {
  return (
    <LayersControl>
      <LayersControl.BaseLayer checked name="Map">
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      </LayersControl.BaseLayer>
      <LayersControl.BaseLayer name="Satellite">
        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
      </LayersControl.BaseLayer>
    </LayersControl>
  );
}

type MapTileLayerProps = {
  isActive?: boolean;
};

/** Tile Layer only, no controls */
export function MapTileLayer({ isActive = true }: MapTileLayerProps) {
  return <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" opacity={isActive ? 1 : 0.5} />;
}
