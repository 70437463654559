import { Button, Css, ModalBody, ModalFooter, ModalHeader, TextField, useModal } from "@homebound/beam";
import { useCallback, useState } from "react";
import { Maybe } from "src/utils";

export interface AddManualAdjustmentModalProps {
  onAdd: (description: Maybe<string>) => Promise<void>;
}

export function AddManualAdjustmentModal({ onAdd }: AddManualAdjustmentModalProps) {
  const { closeModal } = useModal();
  const [manualDescription, setManualDescription] = useState<string | undefined>(undefined);

  const onConfirmClick = useCallback(async () => {
    await onAdd(manualDescription);
    closeModal();
  }, [onAdd, manualDescription, closeModal]);

  return (
    <>
      <ModalHeader>Create New Manual Adjustment</ModalHeader>
      <ModalBody>
        <TextField
          label="New Manual Adjustment"
          onChange={setManualDescription}
          placeholder="Enter manual adjustment"
          value={manualDescription}
          xss={Css.sm.$}
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="tertiary" label="Cancel" onClick={closeModal} />
        <Button label={"Create"} onClick={onConfirmClick} variant={"primary"} />
      </ModalFooter>
    </>
  );
}
