import {
  BoundSelectField,
  BoundSwitchField,
  BoundTextField,
  Chip,
  ChipTypes,
  Css,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tooltip,
  useModal,
} from "@homebound/beam";
import { required } from "@homebound/form-state";
import { Observer } from "mobx-react";
import React, { useEffect } from "react";
import { FormActions } from "src/components/formFields/FormActions";
import { Css as UWCss } from "src/Css";
import { useController } from "src/hooks";
import { Lot } from "src/routes/cma/endpoints/PropCoEndpoint";
import {
  CreateNewUnderwritingReportVersionEndpoint,
  SaveUnderwritingReportVersionInput,
  UnderwritingReport,
  UnderwritingReportVersionsEndpoint,
} from "src/routes/cma/endpoints/reports";
import { ActivityState } from "src/routes/cma/endpoints/reports/ActivityState";
import { ValuationStage, valuationStageOptions } from "src/routes/cma/endpoints/reports/ValuationStage";
import { formatReportCollaborators, Maybe, maybeStringToDateLabel, titleCase } from "src/utils";
import { ObjectConfig, ObjectState, useFormState } from "src/utils/formState";
import { valuationStageToListingStatus } from "src/utils/mappers";

// Components using this modal will already have retrieved versions
export interface NewReportVersionModalProps {
  initialBaseVersionId?: Maybe<number>;
  currentPageVersionId?: Maybe<number>;
  latestVersionForLotId?: Maybe<number>;
  propertyAddress: string;
  valuation_stage?: ValuationStage;
  versions: UnderwritingReport[];
  c2cLot?: Lot | undefined;
  onSuccess?: (newReport: UnderwritingReport, useV2: boolean) => void;
}
interface NewVersionFormProps {
  dpid: string;
  versionId: Maybe<number>;
  goToBPReadyPlanStep: Maybe<boolean>;
  version_name?: string | null;
  valuation_stage?: Maybe<ValuationStage>;
}

type NewVersionFormInput = NewVersionFormProps;

type NewVersionFormState = ObjectState<NewVersionFormInput>;

export function NewReportVersionModal(props: NewReportVersionModalProps) {
  const {
    versions,
    initialBaseVersionId,
    propertyAddress,
    valuation_stage,
    c2cLot,
    onSuccess,
    latestVersionForLotId,
    currentPageVersionId,
  } = props;
  const { fetch, invalidate } = useController();
  const { closeModal } = useModal();

  const hasFinalizedPreListing = versions.some(
    (v) => v.valuation_stage === ValuationStage.pre_listing && v.status === "Finalized",
  );
  const newVersionFormState = useFormState({
    config: newVersionFormConfig,
    init: {
      input: {},
      map: () => ({
        dpid: versions[0].dpid,
        versionId: null,
        version_name: generateNewVersionName(valuation_stage, propertyAddress, versions),
        active: true,
        deactivate_old_versions: false,
        goToBPReadyPlanStep: valuation_stage ? valuationStageToListingStatus[valuation_stage] === "Pre-Closing" : false,
        valuation_stage: valuation_stage
          ? valuation_stage
          : hasFinalizedPreListing
            ? ValuationStage.post_listing
            : undefined,
      }),
    },
    addRules({ version_name }) {
      version_name.rules.push(() => {
        return !version_name.value
          ? "Required"
          : isUniqueName(version_name.value, versions)
            ? undefined
            : "Version name must be unique";
      });
    },
  });

  useEffect(() => {
    if (initialBaseVersionId) {
      newVersionFormState.versionId.set(initialBaseVersionId);
    }
  }, [initialBaseVersionId, newVersionFormState]);

  async function createVersion() {
    await fetch(
      CreateNewUnderwritingReportVersionEndpoint,
      mapNewVersionFormStateToInput(newVersionFormState, c2cLot),
    ).then(
      ({ report }) => {
        newVersionFormState.commitChanges();
        invalidate(UnderwritingReportVersionsEndpoint, { dpid: report.dpid });
        onSuccess ? onSuccess(report, newVersionFormState.goToBPReadyPlanStep.value ?? false) : closeModal();
      },
      () => {},
    );
  }

  function renderBody() {
    return (
      <>
        <div css={Css.df.fdc.gap2.mb1.$}>
          <BoundSelectField
            fullWidth
            field={newVersionFormState.versionId}
            options={versions}
            getOptionValue={(v) => v.id as number}
            getOptionLabel={getVersionOptionLabel}
            getOptionMenuLabel={(v) =>
              versionSelectMenuOption(v, currentPageVersionId === v.id, latestVersionForLotId === v.id)
            }
            label="Base Version"
          />
          <BoundSelectField
            fullWidth
            getOptionValue={(o) => o.id}
            getOptionMenuLabel={(o) => o.name}
            getOptionLabel={(o) => o.id}
            readOnly={valuation_stage === ValuationStage.clear_to_close}
            options={
              valuation_stage === ValuationStage.clear_to_close
                ? [{ id: ValuationStage.clear_to_close, name: "Clear to Close" }]
                : valuationStageOptions
            }
            field={newVersionFormState.valuation_stage}
            onSelect={(v) => {
              newVersionFormState.valuation_stage.set(v);
              newVersionFormState.version_name.set(generateNewVersionName(v!, propertyAddress, versions));
              // Default to landing on v2 ready plan step if valuation stage is pre-closing
              const formValuationStage = newVersionFormState.valuation_stage.value;
              const isPreClosing =
                formValuationStage && valuationStageToListingStatus[formValuationStage] === "Pre-Closing";
              newVersionFormState.goToBPReadyPlanStep.set(isPreClosing);
            }}
            disabledOptions={hasFinalizedPreListing ? hasPreListingDisabledOptions : []}
          />
          <BoundTextField
            fullWidth
            label="New Version Name"
            field={newVersionFormState.version_name}
            disabled={!newVersionFormState.valuation_stage.valid}
          />
          <Tooltip
            title={"Blueprint ready plans should be used for Underwriting and Clear to Close reports when available"}
          >
            <div css={Css.df.jcsb.aic.$}>
              <div>Configure Blueprint ready plan for new report:</div>
              <BoundSwitchField field={newVersionFormState.goToBPReadyPlanStep} labelStyle="hidden" />
            </div>
          </Tooltip>
        </div>
        {newVersionFormState.valid && (
          <div css={Css.df.mt3.xs.red500.$}>
            Note: Comp selections are updated with the latest metadata but should still be reviewed for accuracy.
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <ModalHeader>New Report Version</ModalHeader>
      <ModalBody>
        <Observer>{() => renderBody()}</Observer>
      </ModalBody>
      <ModalFooter>
        <FormActions
          mode="create"
          formState={newVersionFormState}
          onCancel={closeModal}
          onSave={createVersion}
          secondaryButton="cancel"
          autoCommitChanges={false}
        />
      </ModalFooter>
    </>
  );
}

const newVersionFormConfig: ObjectConfig<NewVersionFormInput> = {
  dpid: { type: "value", readOnly: true, rules: [required] },
  versionId: { type: "value", rules: [required] },
  goToBPReadyPlanStep: { type: "value", rules: [required] },
  version_name: { type: "value" },
  valuation_stage: { type: "value", rules: [required] },
};

function mapNewVersionFormStateToInput(
  state: NewVersionFormState,
  c2cLot: Lot | undefined,
): SaveUnderwritingReportVersionInput {
  return {
    dpid: state.dpid.value,
    versionId: state.versionId.value,
    newReportDetails: {
      version_name: state.version_name.value,
      valuation_stage: state.valuation_stage.value,
      ...(c2cLot && { lot: c2cLot }),
    },
  };
}

function versionSelectMenuOption(
  version: UnderwritingReport,
  currentPageVersion: boolean,
  latestVersionForLot: boolean,
) {
  const chipProps = {
    text: version.activity_state,
    type:
      version.activity_state === ActivityState.active
        ? ChipTypes.success
        : version.activity_state === ActivityState.inactive
          ? ChipTypes.caution
          : ChipTypes.neutral,
    compact: true,
  };

  return (
    <div css={UWCss.df.fdc.$}>
      <div css={UWCss.df.smEm.aic.gap1.$}>
        {getVersionOptionLabel(version)}
        {currentPageVersion ? <Chip text="Viewing" type={ChipTypes.dark} compact /> : <Chip {...chipProps} />}
        {latestVersionForLot && <Chip text="Recommended C2C Base" type={ChipTypes.info} compact />}
      </div>
      <div css={UWCss.xs.$}>{formatReportCollaborators(version?.collaborators ?? [])}</div>
    </div>
  );
}

// TODO: We have to do it this way because our beam select field doesnt support customizing the selector in the same
// way it does the menu. We should assess fixing it. Could be a time sink.
function getVersionOptionLabel(version: UnderwritingReport) {
  return version.version_name ?? getVersionTimeLabel(version);
}

// Edge case: Version names are now given by default, but technically they could reach us as `undefined` without issue
function getVersionTimeLabel(version: UnderwritingReport) {
  if (version.underwritten_at) {
    return `Underwritten on ${maybeStringToDateLabel(version.underwritten_at, "monthDate")}`;
  }

  return `Last updated ${maybeStringToDateLabel(version.updated_at, "monthDateTime")}`;
}

export function generateNewVersionName(
  vs: ValuationStage | undefined,
  propertyAddress: string,
  versions: UnderwritingReport[],
): string {
  if (!vs) return "";

  let newName = `${titleCase(propertyAddress)}-${vs}-${new Date().toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  })}`;

  // Edge Case: Multiple reports could be generated same day in same stage.
  if (isUniqueName(newName, versions)) {
    return newName;
  } else {
    let i = 1;
    while (allVersionNames(versions).includes(newName + ` (${i})`)) {
      i++;
    }
    return newName + ` (${i})`;
  }
}

function allVersionNames(versions: UnderwritingReport[]): Maybe<string>[] {
  return versions.map((v) => v.version_name);
}
const isUniqueName = (name: string, versions: UnderwritingReport[]) => !allVersionNames(versions).includes(name.trim());

const hasPreListingDisabledOptions = [
  { value: ValuationStage.pre_listing, reason: "A Pre-Listing valuation report has already been finalized" },
  { value: ValuationStage.underwriting, reason: "A Pre-Listing valuation report has already been finalized" },
  { value: ValuationStage.asset_valuation, reason: "A Pre-Listing valuation report has already been finalized" },
];
