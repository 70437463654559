import { useComputed } from "@homebound/beam";
import { useSuspense } from "@rest-hooks/react";
import { MultiPolygon } from "geojson";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { LoadingBoundary } from "src/components/LoadingBoundary";
import { PageHeader } from "src/components/PageHeader";
import {
  PolygonListEndpoint,
  SavePolygonInput,
  UWPolygonType,
  UwPolygonFeatureCollection,
} from "src/routes/admin/polygons";
import { getFilteredPolygonCollections } from "src/routes/admin/polygons/PolygonCollectionFilter";
import { PolygonContext } from "src/routes/admin/polygons/PolygonContext";
import { Maybe } from "src/utils";
import { Property, PropertyComp, PropertyEndpoint } from "../../endpoints";
import { GenerateCompsEndpoint } from "../../endpoints/reports/GenerateCompsEndpoint";
import {
  ReadyPlan,
  UnderwritingReport,
  UnderwritingReportEndpoint,
} from "../../endpoints/reports/UnderwritingReportEndpoint";
import { findPlan } from "../readyPlanUtils";
import { CompFilter } from "./CompFilter";
import { CompFilterPolygonEditor } from "./CompFilterPolygonEditor";
import { MapWithComps } from "./MapWithComps";
import { OverlayPolygonsFeatureGroup } from "./OverlayPolygonsFeatureGroup";

export function ComparablesStep() {
  const { dpid, versionId, readyPlanId } = useParams<{
    dpid: string;
    versionId: string;
    readyPlanId: string;
    readyPlanSubId: string;
  }>();

  return (
    <>
      <PageHeader title="Comparables" />
      <LoadingBoundary>
        <LoadPropertyReportAndOverlayFeatures dpid={dpid} versionId={versionId} readyPlanId={readyPlanId} />
      </LoadingBoundary>
    </>
  );
}

type LoadPropertyAndReportProps = {
  dpid: string;
  versionId: string;
  readyPlanId: string;
};

export function LoadPropertyReportAndOverlayFeatures({ dpid, versionId, readyPlanId }: LoadPropertyAndReportProps) {
  const { property } = useSuspense(PropertyEndpoint, { dpid });
  const { report } = useSuspense(UnderwritingReportEndpoint, { dpid, versionId });
  const { feature_collections } = useSuspense(PolygonListEndpoint);

  const readyPlan = findPlan(report, readyPlanId)!;

  // format overlay polygons from feature collections
  const overlayPolygons = useMemo(() => {
    const filter = { hideInactive: true, metro: property.metro };
    const featureCollections = feature_collections && getFilteredPolygonCollections(feature_collections, filter);

    let polygons = [] as SavePolygonInput[];
    featureCollections?.forEach((fc: UwPolygonFeatureCollection) => {
      fc.features.forEach(({ id, properties, geometry }) => {
        if (!properties.active) return;
        polygons.push({
          id,
          name: properties.name,
          geometry,
          color: properties.color,
          type: properties.type,
          active: properties.active,
        });
      });
    });
    return polygons;
  }, [feature_collections, property.metro]);

  return <LoadComps property={property} report={report} readyPlan={readyPlan} overlayPolygons={overlayPolygons} />;
}

interface LoadCompsProps {
  property: Property;
  report: UnderwritingReport;
  readyPlan: ReadyPlan;
  overlayPolygons: SavePolygonInput[];
}

export type CompFilterPolygonGeometry = {
  geometry: Maybe<MultiPolygon>;
};

export function LoadComps({ property, report, readyPlan, overlayPolygons }: LoadCompsProps) {
  // server side filters
  const [compFilter, setCompFilter] = useState<CompFilter>({});

  const [compFilterPolygon, setCompFilterPolygon] = useState<CompFilterPolygonGeometry | undefined>(
    () => ({ geometry: report.comp_search_polygon }) as CompFilterPolygonGeometry,
  );

  // selected overlay polygon
  const [selectedPolygonId, setSelectedPolygonId] = useState<Maybe<number>>(undefined);

  // overlay polygons are initially hidden
  const initialVisStates = useComputed(() => {
    return (
      Object.values(UWPolygonType).map((f) => ({
        isVisible: false,
        type: f,
      })) ?? []
    );
  }, [overlayPolygons]);

  const [polygonVisibility, setPolygonVisibility] = useState(initialVisStates);

  const params =
    // generate comps is skipped for finalized reports
    report.status === "Finalized"
      ? null
      : {
          dpid: report.dpid,
          underwriting_report_ready_plan_id: readyPlan.id,
          filter: compFilter,
          polygonFeature: compFilterPolygon,
        };

  const compsResult = useSuspense(GenerateCompsEndpoint, params);
  const comps = compsResult?.comps ?? [];

  return (
    <PolygonContext.Provider
      value={{ selectedPolygonId, setSelectedPolygonId, polygonVisibility, setPolygonVisibility }}
    >
      <MapWithComps
        property={property}
        report={report}
        readyPlan={readyPlan}
        comps={comps}
        key={getMapWithCompsKey(comps, compFilterPolygon)}
        compFilterPolygon={compFilterPolygon}
        compFilter={compFilter}
        setCompFilter={setCompFilter}
        overlayPolygons={overlayPolygons}
      >
        <OverlayPolygonsFeatureGroup
          overlayPolygons={overlayPolygons}
          compFilterPolygon={compFilterPolygon}
          setCompFilterPolygon={setCompFilterPolygon}
        />
        <CompFilterPolygonEditor
          compFilterPolygon={compFilterPolygon}
          setCompFilterPolygon={setCompFilterPolygon}
          isActive={!compFilter.ignorePolygonSearchFilter}
        />
      </MapWithComps>
    </PolygonContext.Provider>
  );
}

/**
 * the key is used to force a re-render when a manual comp is added
 * or when the polygon filter is changed
 */
function getMapWithCompsKey(comps: PropertyComp[], compFilterPolygon: CompFilterPolygonGeometry | undefined) {
  const compIds = comps.map((c) => c.id).join(",");

  if (compFilterPolygon) {
    return `${compIds}${compFilterPolygon.geometry?.coordinates.join("")}`;
  }

  return compIds;
}
