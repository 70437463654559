import { Css, Palette, Tooltip } from "@homebound/beam";
import { ScatterPlotTooltipProps } from "@nivo/scatterplot";
import { ReactNode } from "react";
import { CompTierTag } from "src/routes/cma/steps/comparables/CompTierTag";
import { formatNumberToString } from "src/utils";
import { ConfidenceDatum } from "./";
import { UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { isBefore } from "date-fns";
import { ActivityState } from "src/routes/cma/endpoints/reports/ActivityState";

type TierLegendPalette = {
  [x: string]: {
    color: Palette;
    label: string;
    compTierConfidenceDesc: string;
    mlSalePriceConfidenceDesc: string;
    renderEmoji: ReactNode;
    renderWTooltip: ReactNode;
  };
};

// It's a palette, it's a dictionary, it's a TierLegendPalette!
export const tierLegendPalette: TierLegendPalette = {
  Green: {
    color: Palette.Green500,
    compTierConfidenceDesc: "At least 3 T1 comps",
    mlSalePriceConfidenceDesc: "< 10% Error",
    label: "High Confidence 😀",
    renderEmoji: (
      <span role={"img"} aria-label="High Confidence">
        😀
      </span>
    ),
    renderWTooltip: (
      <Tooltip title="High Confidence">
        <span role={"img"} aria-label="High Confidence">
          😀
        </span>
      </Tooltip>
    ),
  },
  Yellow: {
    color: Palette.Yellow500,
    compTierConfidenceDesc: "1-2 T1 comps",
    mlSalePriceConfidenceDesc: "10-15% Error",
    label: "Mid Confidence 😐",
    renderEmoji: (
      <span role={"img"} aria-label="Mid Confidence">
        😐
      </span>
    ),
    renderWTooltip: (
      <Tooltip title="Mid Confidence">
        <span role={"img"} aria-label="Mid Confidence">
          😐
        </span>
      </Tooltip>
    ),
  },
  Red: {
    color: Palette.Red500,
    compTierConfidenceDesc: "0 T1 comps",
    mlSalePriceConfidenceDesc: "> 15% Error",
    label: "Low Confidence 😟",
    renderEmoji: (
      <span role={"img"} aria-label="Low Confidence">
        😟
      </span>
    ),
    renderWTooltip: (
      <Tooltip title="Low Confidence">
        <span role={"img"} aria-label="Low Confidence">
          😟
        </span>
      </Tooltip>
    ),
  },
};

export type CILegendProps = {
  finalizedDate: UnderwritingReport["underwritten_at"];
  reportStatus: UnderwritingReport["status"];
  activityState: UnderwritingReport["activity_state"];
  lastUpdated: UnderwritingReport["updated_at"];
};

export function CILegend({ finalizedDate, reportStatus, activityState, lastUpdated }: CILegendProps) {
  let showTierCentricLegend = false;
  const cutoffDate = new Date(SPT_ML_CI_CUTOFF_DATE);
  // Note: newer versions of date-fns can compare strings
  if (reportStatus === "Finalized" && !!finalizedDate) {
    const underwrittenDate = new Date(finalizedDate);
    showTierCentricLegend = isBefore(underwrittenDate, cutoffDate);
    // Edge case: Give our best guess to what CI was used based on updated at date
  } else if (activityState === ActivityState.archived) {
    const lastUpdatedDate = new Date(lastUpdated);
    showTierCentricLegend = isBefore(lastUpdatedDate, cutoffDate);
  }

  return showTierCentricLegend ? <CompTierCILegend /> : <MLCILegend />;
}

// Sale price confidence on Reports finalized prior to March 25, 2024, were based on the number of T1 comps used.
function CompTierCILegend() {
  return (
    <div css={Css.df.fdc.p1.sm.gap1.$}>
      <div css={Css.smBd.$}>Confidence Tiers</div>
      <div css={Css.df.$}>
        <div>High 😀 : {tierLegendPalette["Green"].compTierConfidenceDesc}</div>
      </div>
      <div css={Css.df.$}>
        <div>Mid 😐 : {tierLegendPalette["Yellow"].compTierConfidenceDesc}</div>
      </div>
      <div css={Css.df.$}>
        <div>Low 😟 : {tierLegendPalette["Red"].compTierConfidenceDesc}</div>
      </div>
    </div>
  );
}

function MLCILegend() {
  return (
    <div css={Css.df.fdc.p1.sm.gap1.$}>
      <div css={Css.smBd.$}>Confidence Tiers</div>
      <div css={Css.df.$}>Sale Price Tier is based on the ML CI range</div>
      <div css={Css.df.$}>
        <div>High 😀 : {tierLegendPalette["Green"].mlSalePriceConfidenceDesc}</div>
      </div>
      <div css={Css.df.$}>
        <div>Mid 😐 : {tierLegendPalette["Yellow"].mlSalePriceConfidenceDesc}</div>
      </div>
      <div css={Css.df.$}>
        <div>Low 😟 : {tierLegendPalette["Red"].mlSalePriceConfidenceDesc}</div>
      </div>
    </div>
  );
}

export function ConfidenceIntervalProperty({ node: { data } }: ScatterPlotTooltipProps<ConfidenceDatum>) {
  const { address, weight, kind, x: final_adjusted_price, color, tier } = data;
  return (
    <div
      css={
        Css.relative.df.fdc
          .gap("4px")
          .jcc.aic.p3.xs.bgWhite.ba.br4.if(kind === "subject")
          .bc(color).else.bcGray700.$
      }
    >
      <span css={Css.sm.$}>{address}</span>
      {kind !== "subject" && (
        <>
          <span css={Css.absolute.top0.left0.$}>
            <CompTierTag compTier={tier!} size="tinySb" />
          </span>
          <span>Weighted: {weight}</span>
        </>
      )}
      <span>${formatNumberToString(final_adjusted_price, true, true)}</span>
    </div>
  );
}

// SPT = Sale Price Tier
export const SPT_ML_CI_CUTOFF_DATE = "2024-03-25";
