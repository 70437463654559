import { Css } from "@homebound/beam";

export function NotFound() {
  return (
    <div css={Css.p4.df.dtc.w100.$}>
      <div css={Css.pb4.$}>
        <h1 css={Css.xl3Sb.$}>Page Not Found</h1>
      </div>
      <img width="500px" src="/images/construction.svg" alt="construction" />
    </div>
  );
}
