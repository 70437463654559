import { generatePath } from "react-router";
import { CmaStep } from "./cma/stepper/CmaStep";

export const reportsPath = "/reports";
export const cmaPath = "/cma";
export const opportunitiesPath = "/opportunities";
export const reviewPath = "/review";

export const polygonsListPath = "/admin/polygons";
export const polygonPath = `${polygonsListPath}/:polygonCollectionId`;

const dpidVersionPath = "/cma/:dpid/versions/:versionId";
export const cmaPathRouteMatchPath = `${dpidVersionPath}/:currentStep?`;
export const cmaPathRouteReadyPlanMatchPath = `${dpidVersionPath}/:readyPlanId/:currentStep`;

export const cmaPaths: Record<CmaStep, string> = {
  [CmaStep.subject_property]: `${dpidVersionPath}/${CmaStep.subject_property}`,
  [CmaStep.ready_plan]: `${dpidVersionPath}/${CmaStep.ready_plan}`,
  [CmaStep.comparables]: `${dpidVersionPath}/:readyPlanId/${CmaStep.comparables}`,
  [CmaStep.adjustments]: `${dpidVersionPath}/:readyPlanId/${CmaStep.adjustments}`,
  [CmaStep.estimate]: `${dpidVersionPath}/${CmaStep.estimate}`,
  [CmaStep.hpo]: `${dpidVersionPath}/${CmaStep.hpo}`,
};

// This path is outside of usual cma path for formatting without a stepper
export const printEstimatePage = `/:dpid/versions/:versionId/print-estimate`;

interface CmaSubjectPropertyUrlProps {
  dpid: string;
  versionId?: string;
  oppId?: string | null;
}

export const DEFAULT_UNDEFINED_VERSION_ID = "unsaved";

export function createCmaSubjectPropertyUrl({
  dpid,
  versionId = DEFAULT_UNDEFINED_VERSION_ID,
  oppId,
}: CmaSubjectPropertyUrlProps) {
  const path = generatePath(cmaPaths[CmaStep.subject_property], { dpid, versionId });
  return oppId ? `${path}?oppId=${oppId}` : path;
}

export function createCmaReadyPlanUrl(dpid: string, versionId: string, useV2 = false) {
  const path = generatePath(cmaPaths[CmaStep.ready_plan], { dpid, versionId });
  return useV2 ? `${path}?useV2=1` : path;
}

export function createCmaComparablesUrl(dpid: string, versionId: string, readyPlanId: string) {
  return generatePath(cmaPaths[CmaStep.comparables], { dpid, versionId, readyPlanId });
}

export function createCmaAdjustmentsUrl(dpid: string, versionId: string, readyPlanId: string) {
  return generatePath(cmaPaths[CmaStep.adjustments], { dpid, versionId, readyPlanId });
}

export function createCmaEstimateUrl(dpid: string, versionId: string) {
  return generatePath(cmaPaths[CmaStep.estimate], { dpid, versionId });
}

export function createCmaHpoUrl(dpid: string, versionId: string) {
  return generatePath(cmaPaths[CmaStep.hpo], { dpid, versionId });
}

export function createPolygonUrl(polygonCollectionId: string) {
  return `${polygonsListPath}/${polygonCollectionId}`;
}
