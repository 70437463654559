import React, { ReactNode, useState } from "react";
import { Css, Icon, Palette } from "@homebound/beam";
import { HIGHLIGHT_TEST_COLOR } from "src/routes/cma/steps/estimate/components/StatLines";
import { UnderwritingReport } from "src/routes/cma/endpoints/reports";
import { useRouteMatch } from "react-router-dom";
import { printEstimatePage } from "src/routes/routesDef";

type EstimateSectionProps = {
  title: string;
  headerColor?: "lightGray" | "darkGray" | "blue";
  children?: ReactNode;
  initOpen?: boolean;
  forceOpen?: boolean;
  // Defaults to 5 assuming that grid is 10 columns wide
  numColumns?: number;
};

export const EstimateSection = ({
  title,
  children,
  headerColor = "darkGray",
  initOpen = true,
  forceOpen = false,
  numColumns = 5,
}: EstimateSectionProps) => {
  const onPrintPath = useRouteMatch(printEstimatePage);
  const [showChildren, setShowChildren] = useState(initOpen);

  return (
    <div
      css={{
        ...Css.df.fdc.w100.gc(`span ${numColumns}`).$,
        ...Css.if(showChildren)
          .bgWhite.bc(headerColorMap[headerColor])
          .ba.br4.bshBasic.else.addIn("@media print", Css.dn.$).$, // Hide section if minimized when printing
      }}
    >
      <div
        css={{
          ...Css.p1
            .color(headerTextColorMap[headerColor])
            .bgColor(headerColorMap[headerColor])
            .bc(headerColorMap[headerColor])
            .xl.df.jcsb.aic.if(!forceOpen).cursorPointer.onHover.bshHover.$,
          ...Css.if(!showChildren).bshBasic.br4.$,
          ...Css.if(!!onPrintPath).lg.py0.$,
        }}
        onClick={() => !forceOpen && setShowChildren(!showChildren)}
      >
        <div>{title}</div>
        {!forceOpen && (
          <div css={Css.addIn("@media print", Css.dn.$).$}>
            <Icon icon={showChildren ? "chevronUp" : "chevronDown"} />
          </div>
        )}
      </div>
      {showChildren && <div css={Css.df.fdc.gap2.py1.px2.h100.if(!!onPrintPath).gap1.$}>{children}</div>}
    </div>
  );
};

// Renders element with top border and margin to push down to bottom of container
export const EstSectionFooterCss = Css.df.fdc.gap2.bt.bw2.pt1.mta.$;

// TODO: Tech debt SC-42745
// Determines if a report was finalized before the estimate page redesign. That redesign included updates to computed
// fields that were display only.
const USE_OLD_COST_COMPUTATIONS_DATE_CUTOFF = new Date("2024-06-11");
export function shouldUseOldCostComputations(report: UnderwritingReport): boolean {
  return !!report.underwritten_at && new Date(report.underwritten_at) <= USE_OLD_COST_COMPUTATIONS_DATE_CUTOFF;
}

const headerColorMap: { [color: string]: Palette } = {
  lightGray: Palette.Gray200,
  darkGray: Palette.Gray300,
  blue: HIGHLIGHT_TEST_COLOR,
};

const headerTextColorMap: { [color: string]: Palette } = {
  blue: Palette.White,
};
